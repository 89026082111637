import Editor from '@/components/Editor';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import UploadFiles from '@/components/Upload/UploadFiles';
import {petitionType} from '@/constants/petition';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {enumToKV} from '@/utils/enum';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {hasFeature, selectedComplex} from '@/services/store';
import {useMutation, useReactiveVar} from '@apollo/client';
import {CreatePetitionDocument, File} from '@gql/graphql';
import {Button, Form, Input, notification, Radio, Select} from 'antd';
import {useNavigate} from 'react-router-dom';

const title = 'Nueva solicitud';

const New = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const complex = useReactiveVar(selectedComplex)[0];
  const [createPetition, {data, loading, error}] = useMutation(CreatePetitionDocument, {
    update: (cache, {data}) => {
      if (data?.createPetition?.id) {
        notification.success({
          message: 'Creada con éxito',
          description: 'Se ha creado la solicitud.',
        });
        navigate('/petition/' + data.createPetition.id);
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (selectedComplex().length !== 1) return <SelectComplex />;

  const isDoormanEnabled = hasFeature(complex, 'PETITIONS_DOORMAN');

  const onInputFinish = async (values: any) => {
    let args = structuredClone(values);
    if (args.privacy) args.privacy = [args.privacy];
    if (args.files) args.files = args.files.map((item: File) => item.id);

    createPetition({variables: {complexId: complex.id, ...args}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/petition')}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          privacy: 'ADMIN',
        }}
      >
        <Form.Item
          name="title"
          extra="Escribe un título corto. Evita las mayúsculas sostenidas."
          rules={[
            {required: true, message: 'Escribe un título'},
            {max: 50, message: 'El título es muy largo'},
          ]}
        >
          <Input size="large" placeholder="Título de la solicitud" />
        </Form.Item>

        <Form.Item
          name="body"
          rules={[
            {required: true, message: 'Escribe la solicitud'},
            {max: 2000, message: 'La solicituds es muy larga'},
          ]}
        >
          <Editor maxLength={2000} placeholder="Recuerda ser conciso y una buena ortografía..." />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Tipo"
          name="type"
          rules={[{required: true, message: 'Selecciona el tipo de la solicitud'}]}
        >
          <Radio.Group>
            {enumToKV(petitionType).map(({key, value}) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        {isDoormanEnabled && (
          <Form.Item
            {...formItemLayout}
            name="privacy"
            label="Dirigir a"
            extra="Selecciona portería solo si es algo inmediato que el personal de seguridad o aseo pueden solucionar."
            rules={[{required: true, message: 'Selecciona el tipo de la solicitud'}]}
          >
            <Select
              style={{maxWidth: 200}}
              options={[
                {
                  value: 'ADMIN',
                  label: 'Administración',
                },
                {
                  value: 'DOORMAN',
                  label: 'Portería',
                },
              ]}
            />
          </Form.Item>
        )}

        <Form.Item {...formItemLayout} label="Adjuntos" name="files" extra="Adjunta documentos, imágenes o vídeos.">
          <UploadFiles maxUploads={5} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default New;
