import { VideoCameraOutlined } from "@ant-design/icons";
import { DocumentFile, File } from "@gql/graphql";
import { Fragment, useCallback, useEffect, useState } from "react";

const MediaVideo = ({ video }: { video: File }) => {
  const [visible, setVisible] = useState(false);
  const [mask, setMask] = useState(false);
  const links = video.urls as DocumentFile;

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key == "Escape" && visible) {
        setVisible(false);
      }
    },
    [visible]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [visible]);

  if (!links || !links.thumb || !links.original) return null;

  return (
    <div className="thumb-video" onMouseOver={() => setMask(true)} onMouseLeave={() => setMask(false)}>
      <img src={links.thumb} style={{ width: 60, height: 60 }} alt={"Ver vídeo"} />
      <div
        className="thumb-video-mask"
        style={{ opacity: mask === true ? 1 : 0 }}
        onClick={() => {
          setMask(false);
          setVisible(true);
        }}
      >
        <VideoCameraOutlined />
      </div>
      {visible && (
        <div
          onClick={() => setVisible(false)}
          style={{
            zIndex: 2000,
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <video
            controls
            autoPlay
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              maxWidth: "90%",
              maxHeight: "90%",
              margin: "auto",
              overflow: "auto",
            }}
          >
            <source src={links.original} />
            Este vídeo no es soportado.
          </video>
        </div>
      )}
    </div>
  );
};

export default MediaVideo;
