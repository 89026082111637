import {nowPlus} from '@/utils/dates';
import {DatePicker, Space} from 'antd';
import dayjs from 'dayjs';
import {useState} from 'react';

interface DateSelectProps {
  pickerValue?: number;
  onChange?: Function;
  value?: dayjs.Dayjs | string;
  disabledDate?: (current: dayjs.Dayjs) => boolean;
}

const DateSelect: React.FC<DateSelectProps> = ({value, pickerValue, onChange, disabledDate}) => {
  const [dateStep, setDateStep] = useState(0);
  const [date, setDate] = useState<dayjs.Dayjs | null>();
  let defaultValue = value;
  if (typeof defaultValue === 'string' || defaultValue instanceof String) defaultValue = dayjs(defaultValue);

  pickerValue = pickerValue ? pickerValue : -25;
  const localChange = (date: dayjs.Dayjs | null, nextStep: number) => {
    const newDate = date ? date : null;
    setDateStep(nextStep);
    setDate(newDate);
    onChange?.(newDate ? newDate?.format('YYYY-MM-DD') : newDate);
  };

  return (
    <Space.Compact>
      <DatePicker
        picker="year"
        open={dateStep == 1}
        defaultValue={defaultValue}
        placeholder="Año"
        format={'YYYY'}
        inputReadOnly
        value={date}
        defaultPickerValue={nowPlus(pickerValue, 'years')}
        style={{maxWidth: 90}}
        disabledDate={disabledDate}
        onOpenChange={open => {
          if (open) return setDateStep(1);
          if (dateStep == 1) return setDateStep(0);
        }}
        onChange={date => localChange(date, 2)}
      />
      <DatePicker
        open={dateStep == 2}
        picker="month"
        defaultValue={defaultValue}
        placeholder="Mes"
        format={'MM'}
        inputReadOnly
        value={date}
        onChange={date => localChange(date, 3)}
        disabledDate={disabledDate}
        onOpenChange={open => {
          if (open) return setDateStep(2);
          if (dateStep == 2) return setDateStep(0);
        }}
        style={{maxWidth: 80}}
      />
      <DatePicker
        open={dateStep == 3}
        placeholder="Día"
        defaultValue={defaultValue}
        format={'DD'}
        showToday={false}
        inputReadOnly
        value={date}
        onChange={date => localChange(date, 0)}
        disabledDate={disabledDate}
        onOpenChange={open => {
          if (open) return setDateStep(3);
          if (dateStep == 3) return setDateStep(0);
        }}
        style={{maxWidth: 80}}
      />
    </Space.Compact>
  );
};

export default DateSelect;
