import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {avatarConfig} from '@/components/UserAvatar';
import {pollTagColor, pollTagString} from '@/constants/poll';
import client from '@/graphql';
import {setDocumentTitle} from '@/hooks/useDocumentTitle';
import {findById, hasRoles, hasSuperRole} from '@/services/store';
import {createdAtFormat, diffFromNow, fromNow} from '@/utils/dates';
import {getCreatorComplexRoleStr} from '@/utils/roles';
import {DeleteOutlined, EditOutlined, LockOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {
  ClosePollDocument,
  DeletePollDocument,
  Poll,
  SinglePollDocument,
  SinglePollOptionQueryVariables,
} from '@gql/graphql';
import {Alert, Button, notification, Popconfirm, Tag, Tooltip} from 'antd';
import Markdown from 'marked-react';
import {useNavigate, useParams} from 'react-router-dom';
import Options from './Options';

const View = () => {
  let {pollId} = useParams();
  if (!pollId) return <NotFound />;

  const navigate = useNavigate();
  const queryVariables = {id: pollId, first: 0} as SinglePollOptionQueryVariables;
  const {loading, error, data, refetch} = useQuery(SinglePollDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const poll = data.node as Poll;
  const complex = findById(poll.complexId);
  setDocumentTitle(poll.question);

  const displayDate = createdAtFormat(poll.insertedAt);
  const createdSinceDays = Math.abs(diffFromNow(poll.insertedAt, 'days'));

  const hasRole = complex ? hasRoles(['ADMIN', 'COUNCIL'], [complex]) : undefined;
  const isSuperRole = hasSuperRole(['ADMIN']);
  const canDelete = (hasRole && createdSinceDays < 5 && poll.status == 'ACTIVE') || isSuperRole;
  const canClose = (hasRole && poll.status == 'ACTIVE') || isSuperRole;
  const canEdit = (hasRole && createdSinceDays < 5 && poll.status == 'ACTIVE') || isSuperRole;

  const creator = poll.createdBy ? poll.createdBy : undefined;
  const creatorRole = getCreatorComplexRoleStr({
    viewer: poll.createdBy,
    roles: poll.complex?.roles,
  });

  const creatorAvatar = avatarConfig({viewer: creator, size: 'default'});

  const editButton = canEdit ? (
    <Tooltip key="editButton" title="Editar" placement="top">
      <Button type="primary" onClick={() => navigate('/poll/' + pollId + '/edit')}>
        <EditOutlined />
      </Button>
    </Tooltip>
  ) : undefined;

  const closePoll = async () => {
    await client
      .mutate({mutation: ClosePollDocument, variables: {id: poll.id}})
      .then(({data}) => {
        if (data?.closePoll?.id) {
          notification.success({
            message: 'Votación cerrada',
            description: 'Ya no se recibirán más votos.',
          });
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al cerrar',
          description: 'No se pudo cerrar la votación. Intenta más tarde.',
        });
      });
  };

  const closeButton = canClose ? (
    <Popconfirm
      key="closeConfirm"
      placement="bottomRight"
      title="¿Cerrar votación?"
      description="No se permitirán más votos. No se puede revertir."
      onConfirm={closePoll}
      okText="Cerrar"
      cancelText="Cancelar"
    >
      <Tooltip title="Cerrar votación" placement="top">
        <Button type="primary">
          <LockOutlined />
        </Button>
      </Tooltip>
    </Popconfirm>
  ) : undefined;

  const deletePoll = async () => {
    await client
      .mutate({mutation: DeletePollDocument, variables: {id: poll.id}})
      .then(({data}) => {
        if (data?.deletePoll?.success) {
          notification.success({
            message: 'Votación eliminada',
            description: 'Se ha eliminado correctamente.',
          });
          navigate('/');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al eliminar',
          description: 'No se pudo eliminar la votación. Intenta más tarde.',
        });
      });
  };

  const deleteButton = canDelete ? (
    <Popconfirm
      key="delConfirm"
      placement="bottomRight"
      title="¿Eliminar votación?"
      description="La votación y sus votos no se podrán recuperar."
      onConfirm={deletePoll}
      okText="Eliminar"
      okType="danger"
      cancelText="Cancelar"
    >
      <Tooltip title="Eliminar votación" placement="top">
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </Popconfirm>
  ) : undefined;

  const statusTag = !poll.status ? undefined : (
    <Tag key="status" color={pollTagColor[poll.status]}>
      {pollTagString[poll.status]}
    </Tag>
  );
  const willClose = poll.status == 'ACTIVE' && poll.closeAt;

  return (
    <>
      <PageContent
        header={{
          title: poll.question,
          onBack: () => navigate('/'),
          avatar: creatorAvatar,
          extra: [deleteButton, editButton, closeButton],
          tags: statusTag ? [statusTag] : undefined,
        }}
      >
        <Markdown value={poll.description ? poll.description : undefined} />
        <p style={{marginTop: '15px'}}>
          <i>
            {poll.createdBy?.firstName} {poll.createdBy?.lastName},
          </i>
          <br />
          {creatorRole && (
            <i>
              {creatorRole}
              <br />
            </i>
          )}
          <i>{displayDate}</i>
        </p>
        {willClose && <Alert message={`Esta votación termina ${fromNow(poll.closeAt)}`} type="info" showIcon />}
      </PageContent>
      <Options pollId={poll.id} />
    </>
  );
};

export default View;
