import {getAccessToken} from '@/services/store';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import Icon from '@ant-design/icons';
import Logo from '@comp/svg/Logo';
import {Col, Layout, Row, Tabs, Typography} from 'antd';
import {Navigate} from 'react-router-dom';
import Email from './email';
import './index.css';
import Phone from './phone';
const {Content, Footer} = Layout;
const {Text} = Typography;

const Login = () => {
  if (getAccessToken()) return <Navigate replace to="/" />;
  useDocumentTitle('Iniciar sesión');
  const items = [
    {label: 'Entrar con celular', key: 'phone', children: <Phone />},
    {label: 'Entrar con email', key: 'email', children: <Email />},
  ];

  return (
    <Layout className="login-layout">
      <Content className="login-content">
        <Row justify="center">
          <Icon component={Logo} className="logo blue logo-login" />
        </Row>
        <Row justify="center">
          <Col>
            <Tabs defaultActiveKey="1" items={items} centered={true} />
          </Col>
        </Row>
      </Content>
      <Footer className="footer">
        <Text type="secondary">Kipo © {new Date().getFullYear()}</Text>
        <br />
        <Text type="secondary">v{__APP_VERSION__}</Text>
      </Footer>
    </Layout>
  );
};

export default Login;
