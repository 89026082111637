import {reactionDesc, reactionEmoji} from '@/constants/reactions';
import {enumToAllIndex, enumToValue} from '@/utils/enum';
import {useMutation} from '@apollo/client';
import {News, NewsReactionCount, NewsReactionType, SetNewsReactionDocument, SingleNewsDocument} from '@gql/graphql';
import {App, theme, Tooltip, Typography} from 'antd';
import React from 'react';
import './reactions.css';
const {Text} = Typography;

interface ReactionsProps {
  resource: News;
}
interface ReactionPillProps {
  reaction: NewsReactionCount;
  index: number;
  setReaction: (reaction: NewsReactionType) => void;
}

const findByReaction = (list: any[], reaction: any) => {
  return list.find(item => {
    return item.reaction === reaction;
  });
};

const Reactions: React.FC<ReactionsProps> = ({resource}) => {
  const reactions = resource.reactions?.total;
  if (!reactions || resource.allowReactions === false) return null;

  const {notification} = App.useApp();

  const [setReactionMutation, {loading: setLoading}] = useMutation(SetNewsReactionDocument, {
    refetchQueries: [{query: SingleNewsDocument, variables: {id: resource.id}}],
    update: (cache, {data}) => {
      const action = data?.setNewsReaction?.action;
      if (!action) {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Intenta más tarde.',
        });
      }
    },
  });

  const setReaction = (reaction: NewsReactionType) => {
    if (!setLoading) {
      setReactionMutation({variables: {newsId: resource.id, reaction: reaction}});
    }
  };

  const filteredList = enumToAllIndex(reactionDesc)
    .map(react => {
      const exists = findByReaction(reactions, react);
      return exists ?? {reaction: react, count: 0, reacted: false};
    })
    .sort(({count: c1}, {count: c2}) => c2 - c1);

  return (
    <div className="reactions-block">
      {filteredList.map((react, index) => (
        <ReactionPill key={index} reaction={react} index={index} setReaction={setReaction} />
      ))}
    </div>
  );
};

const ReactionPill: React.FC<ReactionPillProps> = ({reaction, index, setReaction}) => {
  const {token} = theme.useToken();
  const {count, reaction: reactionType, reacted} = reaction;
  const hideClass = index < 2 || count! > 0 ? '' : ' reaction-hide';

  return (
    <Tooltip placement="top" title={enumToValue(reactionDesc, reactionType!)}>
      <div
        className={'reaction-block' + hideClass}
        style={{borderColor: token.colorBorder, backgroundColor: reacted ? token.colorInfoBg : undefined}}
        onClick={() => setReaction(reactionType!)}
      >
        <span className="reaction-icon">{enumToValue(reactionEmoji, reactionType!)}</span>
        {count! > 0 && (
          <span className="reaction-counter">
            <Text strong style={{color: token.colorTextTertiary}}>
              {count}
            </Text>
          </span>
        )}
      </div>
    </Tooltip>
  );
};

export default Reactions;
