import client from '@/graphql';
import {makeVar} from '@apollo/client';
import {
  BasicProfileFragment,
  Building,
  Complex,
  ComplexFeatures,
  ComplexRole,
  ComplexRoles,
  Maybe,
  Scalars,
  Unit,
  UnitRole,
  UnitRoles,
  UnitType,
  User,
  UserRoles,
  ViewerProfileDocument,
} from '@gql/graphql';

export interface relatedUnit {
  id: Scalars['ID'];
  number: string;
  role: UnitRoles;
  type: UnitType;
  complexId: string;
  buildingId: string;
}

export interface relatedComplex {
  id: Scalars['ID'];
  name: string;
  roles: (ComplexRoles | UnitRoles)[];
  features?: ComplexFeatures;
  buildings?: Building[];
}
const emptyRelatedUnit: relatedUnit[] = [];
const emptyRelatedComplex: relatedComplex[] = [];
const emptyUser: Maybe<User> = null;
export const refetchViewer = makeVar<Function>(() => undefined);
export const viewer = makeVar(emptyUser);
export const relatedUnits = makeVar(emptyRelatedUnit);
export const relatedComplexes = makeVar(emptyRelatedComplex);
export const selectedComplex = makeVar(emptyRelatedComplex);

export const hasFeature = (complex: relatedComplex, feature: ComplexFeatures) => complex.features?.includes(feature);

let accessToken: string | null = null;
export const setAccessToken = (token: string | null) => {
  accessToken = token;
};
export const getAccessToken = (): string | null => accessToken;

export const clearStore = () => {
  viewer(emptyUser);
  relatedUnits(emptyRelatedUnit);
  relatedComplexes(emptyRelatedComplex);
  selectedComplex(emptyRelatedComplex);
};

export const getViewer = () => {
  const profile = client.readQuery({query: ViewerProfileDocument});
  if (!profile?.viewer) {
    return null;
  }

  return profile.viewer as User;
};

export const selectedComplexIDs = (): string[] => {
  return selectedComplex().map(({id}) => id);
};

export const selectedComplexFilter = (): string[] | null => {
  const selected = selectedComplexIDs();
  if (selected.length !== 1 && hasSuperRole(['ADMIN'])) {
    return null;
  } else {
    return selected;
  }
};

export const hasUnitRoles = (roles: UnitRoles[], units?: relatedUnit[]): relatedUnit | undefined => {
  if (!units) {
    units = relatedUnits();
  }
  return units.find(unit => roles.includes(unit.role));
};

export const getUnitRole = (unit: Unit): UnitRoles | undefined => {
  if (!unit) {
    return undefined;
  }
  const find = relatedUnits().find(({id}) => unit.id === id);
  if (!find) {
    return undefined;
  }
  return find.role;
};

export const hasSuperRole = (roles: UserRoles[]): boolean => {
  const viewer = getViewer();
  return Boolean(viewer?.role && roles.includes(viewer?.role));
};

export const hasRole = (role: ComplexRoles | UnitRoles, complexes?: relatedComplex[]): relatedComplex | undefined => {
  if (!complexes) {
    complexes = selectedComplex();
  }
  return complexes.find(({roles}) => roles.includes(role));
};

export const hasRoles = (
  frole: (ComplexRoles | UnitRoles)[],
  complexes?: relatedComplex[],
): relatedComplex | undefined => {
  if (!complexes) {
    complexes = selectedComplex();
  }
  return complexes.find(({roles}) => roles.find(drole => frole.includes(drole)));
};

export const findById = (cid: Maybe<Scalars['ID']> | undefined): relatedComplex | undefined => {
  if (!cid) {
    return undefined;
  }
  return relatedComplexes().find(({id}) => id === cid);
};

export const setLocalComplexRole = (complex: Complex, role: ComplexRoles): void => {
  if (!complex || !role) return;
  const newComplex = buildRolesComplex({role, complex} as ComplexRole);
  const addToList = [...relatedComplexes(), newComplex];
  relatedComplexes(addToList);
  selectedComplex(addToList);
};

export const hydrateStore = (user: User): void => {
  let units = unitRoles(user);
  let comp = complexRoles(user);
  let unitComp = unitRolesToComp(user);
  let combined = [...comp, ...unitComp];

  const empty: (ComplexRoles | UnitRoles)[] = [];
  const merged = new Map(
    combined.map(({id, name, features, buildings}) => [id, {id, name, features, roles: empty, buildings}]),
  );
  for (let {id, roles} of combined) {
    let nuevo = merged.get(id) as relatedComplex;
    if (nuevo) {
      nuevo.roles = [...nuevo.roles, ...roles];
    }
  }

  const complexes = [...merged.values()];
  relatedComplexes(complexes);
  selectedComplex(complexes);
  relatedUnits(units);
};

const unitRoles = (user: User): relatedUnit[] => {
  if (!user.unitRoles?.edges) {
    return emptyRelatedUnit;
  }

  return user.unitRoles.edges
    .map(edge => {
      if (!edge || !edge.node) {
        return null;
      }
      return buildRolesUnit(edge.node);
    })
    .filter((element): element is relatedUnit => {
      return element !== null;
    });
};

const unitRolesToComp = (user: User): relatedComplex[] => {
  if (!user.unitRoles?.edges) {
    return emptyRelatedComplex;
  }

  return user.unitRoles.edges
    .map(edge => {
      if (!edge || !edge.node) {
        return null;
      }
      return buildRolesUnitComp(edge.node);
    })
    .filter((element): element is relatedComplex => {
      return element !== null;
    });
};

const complexRoles = (user: User): relatedComplex[] => {
  if (!user.complexRoles?.edges) {
    return emptyRelatedComplex;
  }

  return user.complexRoles.edges
    .map(edge => {
      if (!edge || !edge.node) {
        return null;
      }
      return buildRolesComplex(edge.node);
    })
    .filter((element): element is relatedComplex => {
      return element !== null;
    });
};

const buildRolesUnit = ({role, unit}: UnitRole): relatedUnit => {
  return {
    role: role,
    id: unit?.id,
    type: unit?.type,
    complexId: unit?.complex?.id,
    buildingId: unit?.building?.id,
    number: unit?.number,
  } as relatedUnit;
};

const buildRolesUnitComp = ({role, unit}: UnitRole): relatedComplex => {
  return {
    roles: [role],
    id: unit?.complex?.id,
    name: unit?.complex?.name,
    features: unit?.complex?.features,
    buildings: unit?.complex?.buildings,
  } as relatedComplex;
};

const buildRolesComplex = ({role, complex}: ComplexRole): relatedComplex => {
  return {
    roles: [role],
    id: complex?.id,
    name: complex?.name,
    features: complex?.features,
    buildings: complex?.buildings,
  } as relatedComplex;
};
