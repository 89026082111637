import Editor from '@/components/Editor';
import Error403 from '@/components/loaders/Error403';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {isBeforeToday} from '@/utils/dates';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {buildBuildingList} from '@/utils/misc';
import {hasRoles, selectedComplex} from '@/services/store';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {useMutation, useReactiveVar} from '@apollo/client';
import {CreatePollDocument} from '@gql/graphql';
import {Button, DatePicker, Form, Input, notification, Popconfirm, Radio, RadioChangeEvent, Select} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

const title = 'Nueva votación';

const handlePrivacyChange = (e?: RadioChangeEvent) => {
  const type = e && e.target.value ? e.target.value : 'PUBLIC';
  switch (type) {
    case 'PRIVATE':
      return 'Solo la administración podrá saber quién votó.';
    case 'ANONYMOUS':
      return 'Nadie podrá saber quién votó.';
    case 'PUBLIC':
    default:
      return 'Todos los residentes podrán saber quién votó.';
  }
};

const New = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [privacyExtra, setPrivacyExtra] = useState(handlePrivacyChange());
  let complex = useReactiveVar(selectedComplex)[0];
  const [createPoll, {loading}] = useMutation(CreatePollDocument, {
    update: (cache, {data}) => {
      if (data?.createPoll?.id) {
        notification.success({
          message: 'Creada con éxito',
          description: 'Se ha creado la votación.',
        });
        navigate('/poll/' + data.createPoll.id);
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (selectedComplex().length !== 1) return <SelectComplex />;
  if (!hasRoles(['ADMIN', 'COUNCIL'])) return <Error403 />;

  const buildingList = buildBuildingList(complex.buildings, true);

  const onInputFinish = async (values: any) => {
    if (values.buildingId == 'ALL') values.buildingId = null;
    createPoll({variables: {complexId: complex.id, ...values}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/')}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          privacy: 'PUBLIC',
          buildingId: 'ALL',
          options: ['', ''],
        }}
      >
        <Form.Item
          name="question"
          extra="Recuerda abrir y cerrar los signos de pregunta: ¿?"
          rules={[
            {required: true, message: 'Escribe la pregunta'},
            {max: 200, message: 'La pregunta es muy larga'},
          ]}
        >
          <Input size="large" placeholder="Pregunta" />
        </Form.Item>

        <Form.Item
          name="description"
          rules={[
            {required: true, message: 'Escribe la votación'},
            {max: 2000, message: 'La votacións es muy larga'},
          ]}
        >
          <Editor maxLength={2000} placeholder="Escribe una descripción (opcional)" />
        </Form.Item>

        <Form.List
          name="options"
          rules={[
            {
              validator: async (_, options) => {
                if (!options || options.length < 2) {
                  return Promise.reject(new Error('Debes escribir al menos 2 opciones'));
                }
              },
            },
          ]}
        >
          {(fields, {add, remove}, {errors}) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0 ? formItemLayout : tailFormItemLayout)}
                  label={index === 0 ? 'Opciones' : ''}
                  required={true}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    noStyle
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {max: 99, message: 'La opción es muy larga.'},
                      {
                        required: true,
                        whitespace: true,
                        message: 'Por favor inserta una opción o elimina este campo.',
                      },
                    ]}
                  >
                    <Input placeholder={`Opción #${index + 1}`} style={{width: 'calc(100% - 80px)'}} />
                  </Form.Item>
                  {fields.length > 2 ? (
                    <MinusCircleOutlined style={{marginLeft: 7}} onClick={() => remove(field.name)} />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item {...(fields.length === 0 ? formItemLayout : tailFormItemLayout)}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{width: 'calc(100% - 80px)'}}
                  icon={<PlusOutlined />}
                >
                  Añadir opción
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          {...formItemLayout}
          label="Resultados"
          name="privacy"
          extra={privacyExtra}
          rules={[
            {
              required: true,
              message: 'Debes seleccionar la privacidad de los votos.',
            },
          ]}
        >
          <Radio.Group onChange={e => setPrivacyExtra(handlePrivacyChange(e))}>
            <Radio.Button value={'ANONYMOUS'}>Anonimos</Radio.Button>
            <Radio.Button value={'PRIVATE'}>Privados</Radio.Button>
            <Radio.Button value={'PUBLIC'}>Publicos</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="closeAt"
          label="Fecha límite"
          extra="Si seleccionas una fecha, la encuesta será cerrada en ese momento."
        >
          <DatePicker
            showNow={false}
            format="DD/MM/YYYY h:mm a"
            showTime={{use12Hours: true, format: 'h:mm a'}}
            disabledDate={isBeforeToday}
          />
        </Form.Item>

        {buildingList && (
          <Form.Item
            {...formItemLayout}
            name="buildingId"
            label="Mostrar en"
            extra="Limita la visibilidad de la votación a las propiedades del edificio seleccionado. Úsalo si la votación es únicamente de interés para ellos."
          >
            <Select style={{maxWidth: 200}} options={buildingList} />
          </Form.Item>
        )}

        <Form.Item hidden name="status">
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Popconfirm
            placement="top"
            title="¿Publicar votación?"
            description={<>Una vez publicada no se podrá editar {<br />}ningún valor, revisa bien antes de publicar.</>}
            onConfirm={() => form.submit()}
            okText="Publicar"
            cancelText="Revisar"
          >
            <Button type="primary" loading={loading}>
              Publicar
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default New;
