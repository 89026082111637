import {rolesString} from '@/constants/roles';
import {unitTypeShort} from '@/constants/unit';
import {ComplexRole, ComplexRoleConnection, Maybe, Unit, User} from '@gql/graphql';
import {Link} from 'react-router-dom';

interface viewerComplexProps {
  viewer?: Maybe<User>;
  roles?: Maybe<ComplexRoleConnection>;
}

export const getRelatedUnitsNumberStr = (units: Maybe<Unit>[]): string | undefined => {
  if (!units || units.length == 0) return undefined;

  return units
    .map(unit => {
      if (!unit?.type) return undefined;
      return unitTypeShort[unit.type] + ' ' + unit.number;
    })
    .join(', ');
};

export const getRelatedUnitsNumberLink = (units: Maybe<Unit>[]): (JSX.Element | undefined)[] | undefined => {
  return units.map((unit, index) => {
    if (!unit?.type) return undefined;

    return (
      <>
        {!!index && ', '}
        <Link to={'/unit/' + unit.id}>{unitTypeShort[unit.type] + ' ' + unit.number}</Link>
      </>
    );
  });
};

export const getCreatorComplexRole = ({viewer, roles}: viewerComplexProps): ComplexRole | undefined => {
  if (!viewer || !roles || !roles.edges) return undefined;

  const role = roles.edges.find(edge => {
    return edge && edge.node ? edge.node.userId == viewer.id : false;
  });

  if (role && role.node) return role.node;

  return undefined;
};

export const roleToString = (role?: ComplexRole): string => {
  if (!role || !role.role) return '';
  return rolesString[role.role];
};

export const getCreatorComplexRoleStr = ({viewer, roles}: viewerComplexProps): string | undefined => {
  const role = getCreatorComplexRole({viewer, roles});
  const str = roleToString(role);
  return str !== '' ? str : undefined;
};
