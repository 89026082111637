import {primary} from '@/constants/colors';
import {Building, Maybe} from '@gql/graphql';
import {LoadingOutlined} from '@ant-design/icons';

export const titleLoading = (title: string, loading: boolean) => {
  if (loading) {
    return (
      <>
        {title} <LoadingOutlined color="blue" style={{color: primary}} />
      </>
    );
  } else {
    return title;
  }
};

export const buildBuildingList = (buildings: Building[] | undefined, withAll: boolean) => {
  if (!buildings || buildings.length == 0) return undefined;
  let buildingList = buildings?.map(({id, name}) => {
    return {
      value: id,
      label: name,
    } as {value: string; label: Maybe<string> | undefined};
  });
  if (withAll) buildingList = [{value: 'ALL', label: 'Todos'}, ...buildingList];
  return buildingList;
};

export const userLocale = navigator.language;
