import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {feedbackStatus, feedbackStatusColor, feedbackTypeShort} from '@/constants/feedback';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasSuperRole, selectedComplex} from '@/services/store';
import {toSnakeCase} from '@/utils/case';
import {fromNowMaxTooltip} from '@/utils/dates';
import {enumToAllIndex} from '@/utils/enum';
import {titleLoading} from '@/utils/misc';
import {getFullOne} from '@/utils/names';
import {fontSize} from '@/utils/theme';
import {FilterOutlined, LeftOutlined, RightOutlined, SearchOutlined} from '@ant-design/icons';
import {makeVar, useQuery, useReactiveVar} from '@apollo/client';
import UserAvatar from '@comp/UserAvatar';
import {
  Feedback,
  FeedbackEdge,
  FeedbacksDocument,
  FeedbacksQueryVariables,
  FeedbackStatus,
  FeedbackType,
} from '@gql/graphql';
import {IconPaperclip} from '@tabler/icons-react';
import {Button, Col, Empty, Input, InputRef, Row, Skeleton, Space, Table, Tag, Typography} from 'antd';
import {ColumnsType, TableProps} from 'antd/es/table';
import {ColumnType, FilterValue, SorterResult, SortOrder} from 'antd/es/table/interface';
import {useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';

const {Text, Paragraph} = Typography;

type DataIndex = keyof Feedback;

interface tableFilterType {
  type: FeedbackType[] | undefined;
  status: FeedbackStatus[] | undefined;
  creatorName: FilterValue | null | undefined;
}

const emptyFilter: tableFilterType = {
  type: undefined,
  status: undefined,
  creatorName: undefined,
};

interface tableOrderType {
  field?: string;
  direction?: string;
}

const emptyOrder: tableOrderType = {
  field: undefined,
  direction: undefined,
};

const graphFilterStore = makeVar<tableFilterType>(emptyFilter);
const graphOrderStore = makeVar<tableOrderType>(emptyOrder);
const cursorStackStore = makeVar<string[]>([]);
const afterStore = makeVar<string | null>(null);
const title = 'Feedback';
const List = () => {
  useDocumentTitle(title);
  const searchInput = useRef<InputRef>(null);
  const [firstLoad, setFirstLoad] = useState<Array<FeedbackEdge>>();

  useReactiveVar(selectedComplex);
  useReactiveVar(graphFilterStore);
  useReactiveVar(graphOrderStore);
  useReactiveVar(cursorStackStore);
  useReactiveVar(afterStore);

  const graphFilters = graphFilterStore();
  const graphOrder = graphOrderStore();
  const cursorStack = cursorStackStore();
  const after = afterStore();

  const clearAllFilters = () => {
    graphFilterStore(emptyFilter);
    graphOrderStore(emptyOrder);
    resetPagination();
  };

  const hasFilters = graphFilters && !Object.values(graphFilters).every(el => el === undefined);
  const hasOrder = graphOrder && !Object.values(graphOrder).every(el => el === undefined);

  const hasRole = hasSuperRole(['ADMIN']);

  const clearAllButton =
    hasFilters || hasOrder ? (
      <Button onClick={clearAllFilters} key="clear">
        <FilterOutlined /> Limpiar filtros
      </Button>
    ) : undefined;

  const headButtons = [clearAllButton];

  const highlight = (searchTerm: any, toHighlight: any) => {
    if (!searchTerm || searchTerm.length == 0) return searchTerm;
    return (
      <Highlighter
        autoEscape
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchTerm]}
        textToHighlight={toHighlight ? toHighlight.toString() : ''}
      />
    );
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Feedback> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
      return (
        <div style={{padding: 8}}>
          <Input
            ref={searchInput}
            placeholder="Buscar"
            value={selectedKeys as string[]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm({closeDropdown: true})}
            style={{width: 188, marginBottom: 8, display: 'block'}}
          />
          <Button
            type="primary"
            onClick={() => confirm({closeDropdown: true})}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90, marginRight: 8}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) handleResetSearch(clearFilters);
            }}
            size="small"
            style={{width: 90}}
          >
            Limpiar
          </Button>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record, index) => {
      if (dataIndex == 'createdBy') {
        const fullName = getFullOne(record.createdBy!);
        const term = graphFilters.creatorName ? graphFilters.creatorName : undefined;
        const name = term ? highlight(term, fullName) : fullName;
        return (
          <Space>
            <UserAvatar viewer={record.createdBy!} size="small" />
            {name}
          </Space>
        );
      }
    },
  });

  const handleResetSearch = (clearFilters: Function) => {
    clearFilters({confirm: true, closeDropdown: true});
  };

  const resetPagination = () => {
    cursorStackStore([]);
    afterStore(undefined);
  };

  const calcSortOrder = (field: string) => {
    let order: SortOrder = null;
    if (graphOrder && graphOrder.field === toSnakeCase(field).toUpperCase()) {
      order = graphOrder.direction == 'ASC' ? 'ascend' : 'descend';
    }
    return order;
  };

  const handleTableChange: TableProps<Feedback>['onChange'] = (pagination, filters, sorter) => {
    const newSorter = sorter as SorterResult<Feedback>;
    const newFilter = {...emptyFilter};

    for (const key in filters) {
      const value = filters[key];
      if (value !== null && value.length > 0) {
        switch (key) {
          case 'createdBy':
            newFilter.creatorName = value[0] as any;
            break;

          default:
            newFilter[key as keyof typeof newFilter] = value as any;
            break;
        }
      }
    }

    if (graphFilters != newFilter) {
      resetPagination();
    }
    graphFilterStore(newFilter);

    if (newSorter.order) {
      const orderField = toSnakeCase(newSorter.columnKey as string).toUpperCase();
      let orderDirection = newSorter.order as string;
      orderDirection = orderDirection == 'descend' ? 'DESC' : 'ASC';
      graphOrderStore({field: orderField, direction: orderDirection});
    } else {
      graphOrderStore(undefined);
    }
  };

  let columns: ColumnsType<Feedback> = [
    {
      title: 'Mensaje',
      dataIndex: 'description',
      key: 'description',
      render: (value, record) => (
        <Space>
          {record.file && record.file.urls?.original && record.file.filename ? (
            <Link to={record.file.urls?.original} title={record.file.filename}>
              <IconPaperclip size={fontSize} />
            </Link>
          ) : null}
          <Paragraph ellipsis={{expandable: true}} style={{maxWidth: 600}}>
            {value}
          </Paragraph>
        </Space>
      ),
    },
    {
      title: 'Creado',
      dataIndex: 'createdBy',
      key: 'createdBy',
      filteredValue: graphFilters.creatorName || null,
      ...getColumnSearchProps('createdBy'),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      filteredValue: graphFilters?.type || null,
      filters: enumToAllIndex(feedbackTypeShort).map(value => {
        return {
          text: feedbackTypeShort[value as FeedbackType],
          value: value,
        };
      }),
      render: type => feedbackTypeShort[type as FeedbackType],
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      filteredValue: graphFilters?.status || null,
      filters: enumToAllIndex(feedbackStatus).map(value => {
        return {
          text: feedbackStatus[value as FeedbackStatus],
          value: value,
        };
      }),
      render: (type: FeedbackStatus) => <Tag color={feedbackStatusColor[type]}>{feedbackStatus[type]}</Tag>,
    },
    {
      title: 'Creado',
      dataIndex: 'insertedAt',
      key: 'insertedAt',
      sorter: true,
      filteredValue: null,
      sortOrder: calcSortOrder('insertedAt'),
      render: value => fromNowMaxTooltip(value),
    },
    {
      title: 'Actualizado',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      filteredValue: null,
      sortOrder: calcSortOrder('updatedAt'),
      render: value => fromNowMaxTooltip(value),
    },
    {
      title: 'Editar',
      key: 'edit',
      render: (value, record) => <Link to={'/feedback/' + record.id + '/edit'}>Editar</Link>,
    },
  ];

  columns = columns.filter(item => item.title);

  const pageSize = 10;
  const queryVariables = {
    first: pageSize,
    after: after,
    filter: graphFilters ? {...graphFilters} : null,
    order: graphOrder?.field ? {...graphOrder} : undefined,
  } as FeedbacksQueryVariables;

  const {loading, error, data, refetch} = useQuery(FeedbacksDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  if (!hasRole) return <Error403 />;
  if (loading && !firstLoad && !data) return <Skeleton paragraph={{rows: 4}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (data?.feedbacks?.edges && data.feedbacks.edges != firstLoad)
    setFirstLoad(data.feedbacks.edges as Array<FeedbackEdge>);
  if (!data?.feedbacks?.edges && !firstLoad) return <NotFound />;
  if (!firstLoad) return <NotFound />;

  const edges = firstLoad;
  const feedbacks = edges.map((edge: FeedbackEdge) => edge?.node as Feedback);
  const totalCount = data?.feedbacks?.total ? data?.feedbacks?.total : 0;
  const hasNextPage = data?.feedbacks?.pageInfo.hasNextPage;
  const endCursor = data?.feedbacks?.pageInfo.endCursor;

  const prevPage = () => {
    let existingStack = cursorStack;
    existingStack.pop();
    cursorStackStore(existingStack);
    let lastEndCursor = existingStack.length > 0 ? existingStack[existingStack.length - 1] : undefined;
    afterStore(lastEndCursor);
  };

  const nextPage = () => {
    let existingStack = cursorStack;
    if (endCursor) existingStack.push(endCursor);
    cursorStackStore(existingStack);
    afterStore(endCursor);
  };

  const paginationButtons = () => (
    <Row justify="end">
      <Col>
        <Button onClick={prevPage} size="small" key="prev" type="link" disabled={cursorStack.length == 0}>
          <LeftOutlined />
          Anterior
        </Button>{' '}
        <Text type="secondary">
          {cursorStack.length + 1}/{Math.ceil(totalCount / pageSize)}
        </Text>{' '}
        <Button onClick={nextPage} size="small" key="next" type="link" disabled={!hasNextPage}>
          Siguiente
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <PageContent fullWidth header={{title: titleLoading(title, loading), extra: [headButtons]}}>
        <Table<Feedback>
          footer={() => paginationButtons()}
          pagination={false}
          columns={columns}
          dataSource={feedbacks}
          onChange={handleTableChange}
          rowKey="id"
          size="middle"
          scroll={{x: true}}
          locale={{
            emptyText: <Empty description="Sin mensajes"></Empty>,
          }}
        />
      </PageContent>
    </>
  );
};

export default List;
