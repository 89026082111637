export const humanSex = {
  MALE: "Masculino",
  FEMALE: "Femenino",
  OTHER: "No binario",
};

export const commonColors = [
  "Blanco",
  "Negro",
  "Gris",
  "Plata",
  "Azul",
  "Rojo",
  "Café",
  "Verde",
  "Naranja",
  "Beige",
  "Púrpura",
  "Rosa",
  "Oro",
  "Amarillo",
];
