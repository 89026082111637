import DateSelect from "@/components/DateSelect";
import ErrorRender from "@/components/loaders/ErrorRender";
import LoadingSkeleton from "@/components/loaders/LoadingSkeleton";
import NotFound from "@/components/loaders/NotFound";
import PageContent from "@/components/PageContent";
import { petSex, petType } from "@/constants/pets";
import { unitTypeShort } from "@/constants/unit";
import { enumToKV } from "@/utils/enum";
import { formItemLayout } from "@/utils/forms";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { useMutation, useQuery } from "@apollo/client";
import { CreatePetDocument, SingleUnitDocument, Unit } from "@gql/graphql";
import { Button, Form, Input, notification, Radio } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { isAfterToday, isBeforeToday } from "@/utils/dates";

const PetNew = () => {
  let { unitId } = useParams();
  if (!unitId) return <NotFound />;

  useDocumentTitle("Nueva mascota");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [createPet, { loading: newLoading }] = useMutation(CreatePetDocument, {
    update: (cache, { data }) => {
      if (data?.createPet?.id) {
        notification.success({
          message: "Añadido con éxito",
          description: "Se ha añadido la mascota.",
        });
        navigate("/unit/" + unitId + "?tab=pet");
      } else {
        notification.error({
          message: "Ha ocurrido un error :(",
          description: "Verifica la información o intenta más tarde.",
        });
      }
    },
  });

  const { loading, error, data, refetch } = useQuery(SingleUnitDocument, {
    variables: { id: unitId },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  if (loading) return <LoadingSkeleton paragraph={{ rows: 6 }} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;
  const unit = data.node as Unit;
  if (!unit) return <NotFound />;
  const unitName = `${unit.type ? unitTypeShort[unit.type] : ""} ${unit.number}`;

  const onInputFinish = async (values: any) => {
    createPet({ variables: { unitId: unitId, ...values } });
  };

  return (
    <PageContent header={{ title: `Nueva mascota (${unitName})`, onBack: () => navigate("/unit/" + unitId) }}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          {...formItemLayout}
          label="Nombre"
          name="name"
          rules={[
            { required: true, message: "Escribe el nombre" },
            { max: 30, message: "Es muy largo" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Tipo"
          name="type"
          rules={[{ required: true, message: "Debes seleccionar el tipo" }]}
        >
          <Radio.Group>
            {enumToKV(petType).map(({ key, value }) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Sexo"
          name="sex"
          rules={[{ required: true, message: "Debes seleccionar el sexo" }]}
        >
          <Radio.Group>
            {enumToKV(petSex).map(({ key, value }) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Nacimiento"
          name="birthday"
          extra="Ingresa la fecha de nacimiento o un aproximado
"
        >
          <DateSelect disabledDate={(current) => isAfterToday(current)} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Vacunación"
          name="vaccinesDate"
          extra="Ingresa la próxima fecha de sus vacunas anuales
"
        >
          <DateSelect disabledDate={(current) => isBeforeToday(current)} />
        </Form.Item>

        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default PetNew;
