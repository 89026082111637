import { Button, Card, Col, Result, Row } from "antd";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const Error500 = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row justify="center">
        <Col className="max-content-width">
          <Card style={{ textAlign: "center" }} bordered={false}>
            <Result
              status="500"
              title="Ha ocurrido un problema con la operación"
              subTitle="Por favor, intenta más tarde. Si el problema persiste contacta con soporte."
              extra={
                <Button type="primary" onClick={() => navigate(-1)}>
                  Volver atrás
                </Button>
              }
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Error500;
