import {fontSize} from '@/utils/theme';
import {LineChartOutlined, PushpinOutlined, UserOutlined} from '@ant-design/icons';
import NoRoute404 from '@comp/loaders/NoRoute404';
import {
  IconAdjustmentsHorizontal,
  IconBuildingCommunity,
  IconBuildingSkyscraper,
  IconHome,
  IconLayoutGrid,
  IconLifebuoy,
  IconMessageCircle,
  IconNews,
  IconUsers,
} from '@tabler/icons-react';
import {ReactNode} from 'react';
import type {RouteObject} from 'react-router-dom';
import {Link} from 'react-router-dom';
import AccountEdit from './screens/Account/Edit';
import UpdateEmail from './screens/Account/UpdateEmail';
import UpdatePassword from './screens/Account/UpdatePassword';
import UpdatePhone from './screens/Account/UpdatePhone';
import App from './screens/App/App';
import BillboardNew from './screens/Billboard/New';
import BuildingEdit from './screens/Building/Edit';
import BuildingList from './screens/Building/List';
import BuildingNew from './screens/Building/New';
import ComplexEdit from './screens/Complex/Edit';
import ComplexList from './screens/Complex/List';
import ComplexNew from './screens/Complex/New';
import RestrictionNew from './screens/Complex/RestrictionNew';
import RestrictionsList from './screens/Complex/RestrictionsList';
import RolesList from './screens/Complex/RolesList';
import RolesNew from './screens/Complex/RolesNew';
import VehiclesList from './screens/Complex/VehiclesList';
import FeedbackEdit from './screens/Feedback/Edit';
import FeedbackList from './screens/Feedback/List';
import FeedbackNew from './screens/Feedback/New';
import Home from './screens/Home';
import Insights from './screens/Insights';
import Login from './screens/Login';
import Logout from './screens/Logout';
import GenerateAuth from './screens/Management/GenerateAuth';
import NewsEdit from './screens/News/Edit';
import NewsNew from './screens/News/New';
import NewsView from './screens/News/View';
import PetitionList from './screens/Petition/List';
import PetitionNew from './screens/Petition/New';
import PetitionView from './screens/Petition/View';
import PollEdit from './screens/Poll/Edit';
import PollNew from './screens/Poll/New';
import PollView from './screens/Poll/View';
import ContactNew from './screens/Unit/ContactNew';
import UnitEdit from './screens/Unit/Edit';
import JoinCodeNew from './screens/Unit/JoinCodeNew';
import UnitList from './screens/Unit/List';
import UnitNew from './screens/Unit/New';
import NoteNew from './screens/Unit/NoteNew';
import PetNew from './screens/Unit/PetNew';
import VehicleNew from './screens/Unit/VehicleNew';
import UnitView from './screens/Unit/View';
import UserEdit from './screens/User/Edit';
import UserList from './screens/User/List';

interface RouteObjectExtra {
  breadcrumb?: string | ReactNode;
  icon?: React.ReactNode;
}

type RouteMenu = RouteObject & RouteObjectExtra;
//export type MenuItem = Required<MenuProps>['items'][number];
export interface MenuItem extends RouteObjectExtra {
  key?: string;
  children?: MenuItem[];
}

export const RouterHome: RouteMenu[] = [
  {
    path: '/',
    element: <Home />,
    breadcrumb: 'Inicio',
    icon: <IconLayoutGrid size={fontSize} />,
  },
  {
    path: 'news',
    icon: <IconNews size={fontSize} />,
    children: [
      {
        path: 'new',
        element: <NewsNew />,
        breadcrumb: 'Nueva noticia',
        icon: <IconNews size={fontSize} />,
      } as RouteMenu,
      {
        path: ':newsId/edit',
        element: <NewsEdit />,
      } as RouteMenu,
      {
        path: ':newsId',
        element: <NewsView />,
      },
    ],
  },
  {
    path: 'billboard',
    children: [
      {
        path: 'new',
        element: <BillboardNew />,
        breadcrumb: 'Nueva cartelera',
        icon: <PushpinOutlined size={fontSize} />,
      } as RouteMenu,
    ],
  },
  {
    path: 'petition',
    breadcrumb: 'Solicitudes',
    icon: <IconMessageCircle size={fontSize} />,
    children: [
      {
        index: true,
        element: <PetitionList />,
      },
      {
        path: 'new',
        element: <PetitionNew />,
      },
      {
        path: ':petitionId',
        element: <PetitionView />,
      },
    ],
  },
  {
    path: 'poll',
    children: [
      {
        path: 'new',
        element: <PollNew />,
        breadcrumb: 'Nueva votación',
        icon: <PushpinOutlined size={fontSize} />,
      } as RouteMenu,
      {
        path: ':pollId',
        element: <PollView />,
      },
      {
        path: ':pollId/edit',
        element: <PollEdit />,
      },
    ],
  },

  {
    path: 'unit',
    breadcrumb: 'Propiedades',
    icon: <IconHome size={fontSize} />,
    children: [
      {
        index: true,
        element: <UnitList />,
      },
      {
        path: 'new',
        element: <UnitNew />,
      },
      {
        path: ':unitId/edit',
        element: <UnitEdit />,
      },
      {
        path: ':unitId',
        element: <UnitView />,
      },
      {
        path: ':unitId/note/new',
        element: <NoteNew />,
      },
      {
        path: ':unitId/contact/new',
        element: <ContactNew />,
      },
      {
        path: ':unitId/pet/new',
        element: <PetNew />,
      },
      {
        path: ':unitId/vehicle/new',
        element: <VehicleNew />,
      },
      {
        path: ':unitId/join/new',
        element: <JoinCodeNew />,
      },
    ],
  },
  {
    path: 'account',
    icon: <UserOutlined size={fontSize} />,
    children: [
      {
        path: 'edit',
        element: <AccountEdit />,
      },
      {
        path: 'phone',
        element: <UpdatePhone />,
      },
      {
        path: 'email',
        element: <UpdateEmail />,
      },
      {
        path: 'password',
        element: <UpdatePassword />,
      },
    ],
  },
  {
    path: 'complex',
    breadcrumb: 'Conjunto',
    icon: <IconBuildingSkyscraper size={fontSize} />,
    children: [
      {
        path: 'new',
        element: <ComplexNew />,
      },
      {
        path: ':complexId/edit',
        element: <ComplexEdit />,
      },
      {
        path: ':complexId',
        element: <ComplexEdit />,
      },
      {
        path: 'vehicles',
        breadcrumb: 'Vehículos',
        children: [
          {
            index: true,
            element: <VehiclesList />,
          },
        ],
      } as RouteMenu,
      {
        path: 'building',
        breadcrumb: 'Edificios',
        children: [
          {
            index: true,
            element: <BuildingList />,
          },
          {
            path: 'new',
            element: <BuildingNew />,
          },
          {
            path: ':buildingId/edit',
            element: <BuildingEdit />,
          },
        ],
      } as RouteMenu,
      {
        path: 'role',
        breadcrumb: 'Roles',
        children: [
          {
            index: true,
            element: <RolesList />,
          },
          {
            path: 'new',
            element: <RolesNew />,
          },
        ],
      } as RouteMenu,
      {
        path: 'restriction',
        breadcrumb: 'Restricciones',
        children: [
          {
            index: true,
            element: <RestrictionsList />,
          },
          {
            path: 'new',
            element: <RestrictionNew />,
          },
        ],
      } as RouteMenu,
    ],
  },
  {
    path: 'insights',
    breadcrumb: 'Estadísticas',
    icon: <LineChartOutlined size={fontSize} />,
    children: [
      {
        index: true,
        element: <Insights />,
      },
    ],
  },
  {
    path: 'complex/list',
    breadcrumb: 'Conjuntos',
    icon: <IconBuildingCommunity size={fontSize} />,
    children: [
      {
        index: true,
        element: <ComplexList />,
      },
    ],
  },
  {
    path: 'user',
    breadcrumb: 'Usuarios',
    icon: <IconUsers size={fontSize} />,
    children: [
      {
        index: true,
        element: <UserList />,
      },
      {
        path: ':userId/edit',
        element: <UserEdit />,
      },
    ],
  },
  {
    path: 'feedback',
    breadcrumb: 'Feedback',
    icon: <IconLifebuoy size={fontSize} />,
    children: [
      {
        index: true,
        element: <FeedbackList />,
      },
      {
        path: 'new',
        element: <FeedbackNew />,
      },
      {
        path: ':feedbackId/edit',
        element: <FeedbackEdit />,
      },
    ],
  },
  {
    path: 'management',
    breadcrumb: 'Administración',
    icon: <IconAdjustmentsHorizontal size={fontSize} />,
    children: [
      {
        path: 'generateauth',
        breadcrumb: 'Auth Code',
        element: <GenerateAuth />,
      } as RouteMenu,
    ],
  },
  // {
  //   path: 'location',
  //   breadcrumb: 'Lugares',
  //   icon: <IconCurrentLocation size={fontSize} />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Insights />,
  //     },
  //   ],
  // },
];

export const RouterRoot: RouteMenu[] = [
  {path: '/login', element: <Login />, breadcrumb: 'Iniciar sesión'},
  {path: '/logout', element: <Logout />, breadcrumb: 'Cerrar sesión'},
  {
    path: '/',
    element: <App />,
    children: RouterHome,
    errorElement: <NoRoute404 />,
  },
];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const createMenuItems = (router: RouteMenu[], parent?: RouteMenu): MenuItem[] => {
  let nonHidden = router.filter(item => item.breadcrumb);

  return nonHidden.map((item, index) => {
    let parentPath = parent ? parent.path + '/' : '';
    let menuIndex = item.path ? parentPath + item.path : parentPath + index.toString();

    let children = undefined;
    if (item.children) children = createMenuItems(item.children, item);
    children = children && children.length > 0 ? children : undefined;

    let label = children ? item.breadcrumb : <Link to={menuIndex}>{item.breadcrumb}</Link>;

    return getItem(label, menuIndex, item.icon, children);
  });
};

export const MenuItems: MenuItem[] = createMenuItems(RouterHome);
