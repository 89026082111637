import { HomeOutlined } from "@ant-design/icons";
import { Button, Card, Col, Result, Row } from "antd";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row justify="center">
        <Col className="max-content-width">
          <Card style={{ textAlign: "center" }} bordered={false}>
            <Result
              status="404"
              title="No hemos encontrado eso"
              subTitle="Lo sentimos, es posible que el contenido haya sido eliminado o ya no tengas acceso a el."
              extra={
                <Fragment>
                  <Button type="primary" onClick={() => navigate("/")}>
                    <HomeOutlined /> Ir al inicio
                  </Button>
                </Fragment>
              }
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default NotFound;
