import { Skeleton, SkeletonProps } from "antd";
import PageContent from "../PageContent";

const LoadingSkeleton: React.FC<SkeletonProps> = (props) => {
  return (
    <PageContent>
      <Skeleton {...props} active />
    </PageContent>
  );
};

export default LoadingSkeleton;
