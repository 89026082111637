import {getAccessToken, setAccessToken} from '@/services/store';
import {ApolloClient, from, fromPromise, InMemoryCache, Operation, split} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {createPersistedQueryLink} from '@apollo/client/link/persisted-queries';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition, relayStylePagination} from '@apollo/client/utilities';
import {notification} from 'antd';
import {createLink} from 'apollo-absinthe-upload-link';
import {sha256} from 'crypto-hash';
import {ASTNode, print} from 'graphql';
import {createClient} from 'graphql-ws';
import {RefreshTokenDocument} from './__generated__/graphql';

let isRefreshing = false;
let pendingRequests: any = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback: any) => callback());
  pendingRequests = [];
};

// const removeError = (forward: NextLink, operation: Operation) => {
//   return forward(operation).map((response) => {
//     if (response.errors) {
//       response.errors = undefined;
//     }
//     return response;
//   });
// };

const errorLink = onError(({graphQLErrors, networkError, operation, forward, response}) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.path && err.path[0] == 'refreshToken') return;
      switch (err.extensions?.code) {
        case 'FLOOD_LIMIT':
          notification.error({
            message: 'No tan rápido',
            description: 'No se pudo completar la operación. Intenta de nuevo más tarde.',
          });
          break;

        case 'INVALID_JOIN_CODE':
          return;

        case 'UNAUTHORIZED_FIELD':
          if (err.path && err.path.includes('joinCodes') && err.path.includes('code')) return;
          if (err.path && err.path[err.path.length - 1] == 'priority') {
            return;
            //return removeError(forward, operation);
          }

          notification.warning({
            message: 'No autorizado',
            description: 'Parece que no tienes permiso para acceder a toda la información.',
          });
          break;

        case 'UNAUTHORIZED':
          if (err.path && err.path[err.path.length - 1] == 'priority') {
            return;
            //return removeError(forward, operation);
          }

          notification.error({
            message: 'No autorizado',
            description: 'Parece que no tienes permiso para ejecutar esa acción.',
          });
          break;

        case 'UNAUTHENTICATED':
          if (err.path && err.path[0] == 'signOut') return;
          let forward$;
          console.log('[errorLink] UNAUTHENTICATED');
          if (!isRefreshing) {
            isRefreshing = true;
            forward$ = fromPromise(
              client
                .mutate({mutation: RefreshTokenDocument})
                .then(({data}) => {
                  const accessToken = data?.refreshToken?.access;
                  if (accessToken) {
                    setAccessToken(accessToken);
                    return true;
                  } else {
                    return false;
                  }
                })
                .then(() => {
                  resolvePendingRequests();
                  return true;
                })
                .catch(() => {
                  pendingRequests = [];
                  return false;
                })
                .finally(() => {
                  isRefreshing = false;
                  return false;
                }),
            );
          } else {
            forward$ = fromPromise(
              new Promise<void>(resolve => {
                pendingRequests.push(() => resolve());
              }),
            );
          }

          return forward$.flatMap(() => forward(operation));
        default:
          notification.error({
            message: 'Ha ocurrido un error',
            description: 'Intenta de nuevo más tarde.',
          });

          console.log(`[GraphQL Error]: Code: ${err.extensions?.code}, Message: ${err.message}, Path: ${err.path}`);
      }
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const isSubscription = ({query}: Operation) => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_API_WS_HOST,
    connectionParams: () => {
      const token = getAccessToken();
      return token ? {Authorization: `Bearer ${token}`} : {};
    },
    on: {
      // closed, connected, connecting, error message, opened, ping, pong
      connected: socket => {
        console.log('[WS EVEN] Connected');
        // // clear timeout on every connect for debouncing the expiry
        // clearTimeout(tokenExpiryTimeout);
        // // set a token expiry timeout for closing the socket
        // // with an `4403: Forbidden` close event indicating
        // // that the token expired. the `closed` event listner below
        // // will set the token refresh flag to true
        // tokenExpiryTimeout = setTimeout(() => {
        //   if (socket.readyState === WebSocket.OPEN)
        //     socket.close(CloseCode.Forbidden, 'Forbidden');
        // }, getCurrentTokenExpiresIn());
      },
      closed: event => {
        console.log('[WS EVEN] Closed');
        // if closed with the `4403: Forbidden` close event
        // the client or the server is communicating that the token
        // is no longer valid and should be therefore refreshed
        // if (event.code === CloseCode.Forbidden) shouldRefreshToken = true;
      },
    },
  }),
);

const persistedQueriesLink = createPersistedQueryLink({sha256});

const httpLink = persistedQueriesLink.concat(
  createLink({
    uri: import.meta.env.VITE_API_HTTP_HOST,
    credentials: 'include',
    print: (ast: ASTNode) => print(ast),
  }),
);

const authLink = setContext(async (operation, {headers}) => {
  const token = getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        petitionComments: relayStylePagination(),
      },
    },
    News: {
      fields: {
        comments: relayStylePagination(),
      },
    },
    PollOption: {
      fields: {
        answers: relayStylePagination(),
      },
    },
    Petition: {
      fields: {
        comments: relayStylePagination(),
      },
    },
    Unit: {
      fields: {
        joinCodes: relayStylePagination(),
        unitNotes: relayStylePagination(),
        unitContacts: relayStylePagination(),
        roles: relayStylePagination(),
        pets: relayStylePagination(),
        vehicles: relayStylePagination(),
      },
    },
    Complex: {
      fields: {
        restrictions: relayStylePagination(),
        roles: relayStylePagination(),
      },
    },
  },
});

const splitLink = split(isSubscription, wsLink, authLink.concat(httpLink));

const client = new ApolloClient({
  cache: cache,
  link: from([errorLink, splitLink]),
});

export default client;
