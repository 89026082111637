import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {unitTypeShort} from '@/constants/unit';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {buildBuildingList} from '@/utils/misc';
import {findById, hasRoles} from '@/services/store';
import {useMutation, useQuery} from '@apollo/client';
import {SingleUnitDocument, Unit, UpdateUnitDocument} from '@gql/graphql';
import {Button, Form, Input, InputNumber, notification, Radio, Select} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';

const title = 'Editar propiedad';

const Edit = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;

  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [updateUnit, {loading: editLoading}] = useMutation(UpdateUnitDocument, {
    onCompleted: () => {
      notification.success({
        message: 'Editada con éxito',
        description: 'Se ha editado la propiedad.',
      });
      navigate('/unit/' + unitId);
    },
  });

  const {loading, error, data, refetch} = useQuery(SingleUnitDocument, {
    variables: {id: unitId},
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const unit = data.node as Unit;
  if (!unit) return <NotFound />;

  const complex = findById(unit.complexId);
  if (!complex) return <Error403 />;
  if (!hasRoles(['ADMIN'], [complex])) return <Error403 />;

  const buildingList = buildBuildingList(complex.buildings, false);

  const onInputFinish = async (values: any) => {
    updateUnit({variables: {id: unitId, ...values}});
  };
  return (
    <PageContent header={{title: title, onBack: () => navigate('/unit/' + unitId)}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          ...unit,
        }}
      >
        <Form.Item
          {...formItemLayout}
          label="Número"
          name="number"
          rules={[{required: true, message: 'Debes escribir el número/nombre'}]}
        >
          <Input size="large" placeholder="302, 45A" style={{maxWidth: 100}} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Tipo"
          name="type"
          rules={[{required: true, message: 'Debes seleccionar el tipo'}]}
        >
          <Radio.Group>
            {enumToKV(unitTypeShort).map(({key, value}) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        {buildingList && (
          <Form.Item {...formItemLayout} name="buildingId" label="Edificio">
            <Select style={{maxWidth: 200}} options={buildingList} allowClear />
          </Form.Item>
        )}

        <Form.Item {...formItemLayout} label="Piso" name="floor">
          <Input placeholder="2, N2" style={{maxWidth: 100}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Teléfono" name="phone" extra="Teléfono fijo o móvil de contacto">
          <Input style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Coeficiente" name="coefficient">
          <InputNumber style={{width: 200}} min="0" step="0.000001" />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Editar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default Edit;
