import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {rolesString, unitRoleRights} from '@/constants/roles';
import {unitTypeShort} from '@/constants/unit';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {isBeforeToday} from '@/utils/dates';
import {formItemLayout} from '@/utils/forms';
import {findById, getUnitRole, hasRoles} from '@/services/store';
import {useMutation, useQuery} from '@apollo/client';
import {CreateJoinCodeDocument, SingleUnitDocument, Unit} from '@gql/graphql';
import {Button, DatePicker, Form, InputNumber, notification, Radio} from 'antd';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

const JoinCodeNew = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;

  useDocumentTitle('Nuevo código');
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      maxUses: 1,
    });
  }, []);

  const [createJoinCode, {loading: newLoading}] = useMutation(CreateJoinCodeDocument, {
    update: (cache, {data}) => {
      if (data?.createJoinCode?.id) {
        notification.success({
          message: 'Creado con éxito',
          description: 'Se ha creado el código.',
        });
        navigate('/unit/' + unitId + '?tab=join');
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  const {loading, error, data, refetch} = useQuery(SingleUnitDocument, {
    variables: {id: unitId},
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;
  const unit = data.node as Unit;
  if (!unit) return <NotFound />;
  const unitName = `${unit.type ? unitTypeShort[unit.type] : ''} ${unit.number}`;

  const complex = findById(unit.complexId);
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const viewerAdmin = hasRole ? 'ADMIN' : undefined;
  const viewerRole = (getUnitRole(unit) as string) || viewerAdmin;
  if (!viewerRole) return <Error403 />;
  const allCanActions = viewerRole ? unitRoleRights[viewerRole][viewerRole] : undefined;

  const onInputFinish = async (values: any) => {
    createJoinCode({variables: {unitId: unitId, ...values}});
  };

  return (
    <PageContent header={{title: `Nuevo código de invitacón (${unitName})`, onBack: () => navigate('/unit/' + unitId)}}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          {...formItemLayout}
          label="Rol"
          name="role"
          extra="Quién use este código será añadido con el rol seleccionado"
          rules={[{required: true, message: 'Debes seleccionar el rol'}]}
        >
          <Radio.Group>
            {allCanActions?.map(role => (
              <Radio.Button key={role} value={role}>
                {rolesString[role]}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Máx. usos"
          name="maxUses"
          extra="El código se podrá usar este número de veces"
          rules={[{required: true, message: 'Debes establer un máximo de usos'}]}
        >
          <InputNumber min={1} max={5} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Expira"
          name="expire"
          extra="Después de esta fecha el código dejará de funcionar"
          rules={[{required: true}]}
        >
          <DatePicker format={'DD/MM/YYYY h:mm a'} disabledDate={current => isBeforeToday(current)} showToday={false} />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default JoinCodeNew;
