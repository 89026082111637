import Editor from '@/components/Editor';
import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import UploadFiles from '@/components/Upload/UploadFiles';
import UploadImage from '@/components/Upload/UploadImage';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {findById, hasRoles, hasSuperRole} from '@/services/store';
import {diffFromNow} from '@/utils/dates';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {buildBuildingList} from '@/utils/misc';
import {DownOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {File, News, SingleNewsEditDocument, UpdateNewsDocument} from '@gql/graphql';
import {Button, Dropdown, Form, Input, notification, Radio, Select} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';

const title = 'Editar noticia';

const Edit = () => {
  let {newsId} = useParams();
  if (!newsId) return <NotFound />;

  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [updateNews, {loading: mutLoad}] = useMutation(UpdateNewsDocument, {
    onCompleted: () => {
      notification.success({
        message: 'Editada con éxito',
        description: 'Se ha editado la noticia.',
      });
      navigate('/news/' + newsId);
    },
  });

  const {loading, error, data, refetch} = useQuery(SingleNewsEditDocument, {
    variables: {id: newsId},
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const news = data.node as News;
  const complex = findById(news.complexId);
  if (!complex) return <Error403 />;

  const createdSinceDays = Math.abs(diffFromNow(news.insertedAt, 'days'));
  const hasRole = complex ? hasRoles(['ADMIN', 'COUNCIL'], [complex]) : undefined;
  const canEdit = (createdSinceDays < 15 && hasRole) || hasSuperRole(['ADMIN']);

  if (!canEdit) return <Error403 />;

  const buildingList = buildBuildingList(complex.buildings, true);

  const files = news.files ? (news.files as File[]) : undefined;

  const cover = files?.filter(item => item.type == 'COVER')[0];
  const attachments = files?.filter(item => item.type == 'DOCUMENT');

  const onInputFinish = async (values: any) => {
    let args = structuredClone(values);
    if (args.cover && args.files.length > 0) args.files.push(args.cover);
    if (args.cover && !args.files) args.files = [args.cover];
    if (args.files) args.files = args.files.map((item: File) => item.id);
    if (args.buildingId == 'ALL') args.buildingId = null;
    args.cover = undefined;
    updateNews({variables: {newsId: news.id, ...args}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/news/' + newsId)}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          ...news,
          cover: cover,
          files: attachments,
          buildingId: news.buildingId ?? 'ALL',
        }}
      >
        <Form.Item
          name="title"
          extra="Escribe un título corto. Evita las mayúsculas sostenidas."
          rules={[
            {required: true, message: 'Escribe un título'},
            {max: 50, message: 'El título es muy largo'},
          ]}
        >
          <Input size="large" placeholder="Título de la noticia" />
        </Form.Item>

        <Form.Item
          name="body"
          rules={[
            {required: true, message: 'Escribe la noticia'},
            {max: 2000, message: 'La noticias es muy larga'},
          ]}
        >
          <Editor maxLength={2000} placeholder="Contenido de la noticia..." />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Portada"
          name="cover"
          extra="Imagen de ilustración visible en la parte superior de la noticia."
        >
          <UploadImage fileType={'COVER'} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Adjuntos" name="files" extra="Adjunta documentos, imágenes o vídeos.">
          <UploadFiles />
        </Form.Item>

        {buildingList && (
          <Form.Item
            {...formItemLayout}
            name="buildingId"
            label="Mostrar en"
            extra="Limita la visibilidad de la noticia a las propiedades del edificio seleccionado. Úsalo si la noticia es únicamente de interés para ellos."
          >
            <Select style={{maxWidth: 200}} options={buildingList} />
          </Form.Item>
        )}

        <Form.Item {...formItemLayout} label="Comentarios" name="allowComments">
          <Radio.Group>
            <Radio.Button value={true}>Activados</Radio.Button>
            <Radio.Button value={false}>Desactivados</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Reacciones" name="allowReactions">
          <Radio.Group>
            <Radio.Button value={true}>Activadas</Radio.Button>
            <Radio.Button value={false}>Desactivadas</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item hidden name="status">
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          {news.status == 'DRAFT' && (
            <Dropdown.Button
              type="primary"
              icon={<DownOutlined />}
              loading={loading}
              menu={{
                items: [{label: 'Guardar como borrador', key: '1'}],
                onClick: () => {
                  form.setFieldValue('status', 'DRAFT');
                  form.submit();
                },
              }}
              onClick={() => {
                form.setFieldValue('status', 'PUBLISHED');
                form.submit();
              }}
            >
              Publicar
            </Dropdown.Button>
          )}

          {news.status == 'PUBLISHED' && (
            <Button type="primary" htmlType="submit" loading={mutLoad}>
              Editar
            </Button>
          )}
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default Edit;
