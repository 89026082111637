import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import UserAvatar from '@/components/UserAvatar';
import {unitTypeShort} from '@/constants/unit';
import {getFullOne} from '@/utils/names';
import {relatedComplex} from '@/services/store';
import {useQuery} from '@apollo/client';
import {
  InputMaybe,
  PollAnswerFilter,
  PollOption,
  SinglePollOptionDocument,
  SinglePollOptionQueryVariables,
} from '@gql/graphql';
import {Button, Empty, List, Row, Space, Typography} from 'antd';
import {Fragment} from 'react';
import {Link} from 'react-router-dom';
import './style.css';
const {Text, Title} = Typography;

const Answers = ({
  optionId,
  filters,
  hasRole,
}: {
  optionId: string | undefined;
  filters: InputMaybe<PollAnswerFilter> | undefined;
  hasRole: relatedComplex | undefined;
}) => {
  if (!optionId) return <NotFound />;

  let queryVariables = {id: optionId, first: 30, filter: {...filters}} as SinglePollOptionQueryVariables;

  const {loading, data, error, fetchMore, refetch} = useQuery(SinglePollOptionDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const option = data.node as PollOption;
  const edges = option.answers?.edges;
  if (!edges) return <NotFound />;

  const votes = edges.map(edge => edge?.node);
  const pageInfo = option.answers?.pageInfo;
  const countTotal = option.answers?.countTotal ? option.answers?.countTotal : 0;

  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                ...{queryVariables},
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Cargar más...
        </Button>
      </div>
    ) : null;

  return (
    <Fragment>
      <Title level={5} style={{margin: 0}}>
        {option.option}
      </Title>
      {countTotal > 0 && (
        <Text type="secondary" style={{fontSize: 14}} italic>
          {countTotal} {countTotal > 1 ? 'votos' : 'voto'}
        </Text>
      )}
      <List
        loadMore={loadMore}
        itemLayout="horizontal"
        loading={loading}
        locale={{
          emptyText: <Empty description="Sin votos por esta opción" />,
        }}
        dataSource={votes}
        renderItem={vote => {
          if (!vote || !vote.unitRole?.user || !vote.unitRole?.unit || !vote.unitRole?.unit.type) return undefined;

          const viewer = vote.unitRole?.user;
          return (
            <Row key={vote.id} justify="space-between" align="bottom" style={{marginTop: '20px'}}>
              <Text>
                <Space>
                  <UserAvatar size="small" viewer={viewer} />
                  {getFullOne(viewer)}
                </Space>
              </Text>
              <Text type="secondary">
                {hasRole && (
                  <Link to={'/unit/' + vote.unitRole.unit.id}>
                    {unitTypeShort[vote.unitRole.unit.type]} {vote.unitRole.unit?.number}
                  </Link>
                )}
                {!hasRole && (
                  <div>
                    {unitTypeShort[vote.unitRole.unit.type]} {vote.unitRole.unit?.number}
                  </div>
                )}
              </Text>
            </Row>
          );
        }}
      />
    </Fragment>
  );
};

export default Answers;
