import { Component } from "react";
import "./Logo.css";

class Logo extends Component {
  render() {
    return (
      <svg viewBox="0 0 500 350.28" focusable="false" role="presentation">
        <g fill="currentColor" fillRule="evenodd">
          <path d="M116.31,52.28H99A10.83,10.83,0,0,0,88.18,63.11V191.16a23.35,23.35,0,0,1-5.64,15.59,17.36,17.36,0,0,1-13.71,6.63H49.48V24.56C49.48,15.17,42,13.3,42,13.3H21.34A10.83,10.83,0,0,0,10.51,24.13V280.22a10.83,10.83,0,0,0,10.83,10.83H38.65a10.83,10.83,0,0,0,10.83-10.83V252.07H73.74l10,31.43a10.84,10.84,0,0,0,10.33,7.55h18.15a10.84,10.84,0,0,0,10.3-14.19l-11.66-35.72a10.89,10.89,0,0,1,2.27-10.36q14-16.82,14-39.62v-128A10.83,10.83,0,0,0,116.31,52.28Z" />
          <path d="M189.21,123.59H170.45q-10.11,0-10.1,10.1V281q0,10.11,10.1,10.1h18.76q10.13,0,10.11-10.1V133.69Q199.32,123.6,189.21,123.59Z" />
          <path d="M305.23,123.59H286.81A31,31,0,0,0,274,125.91a17.89,17.89,0,0,0-8.22,8.08l-.57-.3q-1.47-10.09-11-10.1H240.66a11,11,0,0,0-11,11V332a11,11,0,0,0,11,11h16.93a11,11,0,0,0,11-11V169a11.32,11.32,0,0,1,11.32-11.32h15.18A11.32,11.32,0,0,1,306.44,169V246.3Q306.44,257,296,257h-9.33a5.27,5.27,0,0,0-5.28,5.28v25.27c.32,3.52,5.26,3.52,8.27,3.52h15.53a39.9,39.9,0,0,0,39.9-39.9V163.49A39.91,39.91,0,0,0,305.23,123.59Z" />
          <path d="M489.49,252.07q0,39-39,39H413q-39,0-39-39v-89.5q0-39,39-39h37.53q39,0,39,39Zm-38.69-4.91v-79.4q0-10.66-10.4-10.67h-17q-10.39,0-10.39,10.38v80q0,10.13,10.39,10.11h17Q450.81,257.56,450.8,247.16Z" />
        </g>
      </svg>
    );
  }
}

export default Logo;
