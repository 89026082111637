import client from '@/graphql';
import {refetchViewer, setAccessToken} from '@/services/store';
import {LockOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {
  AvailableLoginMethodsDocument,
  RequestAuthCodeDocument,
  RequestAuthCodeMutationVariables,
  SignInDocument,
  SignUpDocument,
} from '@gql/graphql';
import {Button, Checkbox, Form, Input, Modal, Typography} from 'antd';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './index.css';
const {Text} = Typography;
const FormItem = Form.Item;

enum State {
  INPUT,
  SINGUP,
  CODE,
  PASSWORD,
}

const Email = () => {
  const [formState, setFormState] = useState(State.INPUT);
  const [withCode, setWithCode] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [timeNewCode, setTimeNewCode] = useState(0);
  const [requestCode, requestCodeResult] = useMutation(RequestAuthCodeDocument);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (timeNewCode > 0) setTimeNewCode(timeNewCode - 1);
    }, 1000);
  });

  const onInputFinish = async (values: any) => {
    setIsLoading(true);

    await client
      .query({
        query: AvailableLoginMethodsDocument,
        variables: values,
        fetchPolicy: 'network-only',
      })
      .then(({data: {availableLoginMethods}}) => {
        setEmailData(values);
        if (
          availableLoginMethods?.length &&
          (availableLoginMethods.includes('EMAIL_PASSWORD') || availableLoginMethods.includes('EMAIL_CODE'))
        ) {
          if (availableLoginMethods.includes('EMAIL_CODE')) setWithCode(true);

          if (availableLoginMethods.includes('EMAIL_PASSWORD')) {
            setFormState(State.PASSWORD);
          } else {
            if (timeNewCode == 0) requestCode({variables: values});
            setFormState(State.CODE);
          }
        } else {
          setFormState(State.SINGUP);
        }
        return true;
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions!.code) {
              case 'INVALID_EMAIL':
              case 'FORBIDDEN_EMAIL':
              case 'FORBIDDEN_EMAIL_PROVIDER':
                return true;
              default:
                return false;
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSignUpFinish = async (values: any) => {
    setIsLoading(true);

    let variables = {
      ...emailData,
      ...values,
    };

    await client
      .mutate({
        mutation: SignUpDocument,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({data}) => {
        if (data?.signUp?.success) {
          if (timeNewCode == 0) requestCode({variables: variables});
          setFormState(State.CODE);
        }
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          Modal.error({
            title: 'Ha ocurrido un error :(',
            content: 'Hubo un error al registrarte, por favor, verifica los datos.',
            centered: true,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    setInvalidCode(false);
    let variables = {
      ...emailData,
      ...values,
    };

    await client
      .mutate({
        mutation: SignInDocument,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({data}) => {
        if (data?.signIn?.access) {
          setAccessToken(data.signIn.access);
          refetchViewer()();
          navigate(location.state?.from || '/');
        }
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions!.code) {
              case 'INCORRECT_PASSWORD':
                setInvalidPassword(true);
              case 'INCORRECT_CODE':
                setInvalidCode(true);
              default:
                return true;
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  switch (formState) {
    case State.INPUT:
      return (
        <Form className="login-form" onFinish={onInputFinish}>
          <FormItem
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Ingresa un correo válido',
              },
              {
                required: true,
                message: 'Ingresa tu correo',
              },
            ]}
          >
            <Input type="email" autoFocus placeholder="Ingresa tu correo" autoComplete="username" />
          </FormItem>

          <FormItem>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Continuar
            </Button>
          </FormItem>
        </Form>
      );

    case State.SINGUP:
      return (
        <Form className="login-form" onFinish={onSignUpFinish}>
          <FormItem
            name="firstName"
            rules={[
              {required: true, message: 'Ingresa tu nombre'},
              {whitespace: true, message: 'Ingresa tu nombre'},
              {max: 25, message: 'El nombre es demasiado largo'},
            ]}
          >
            <Input autoFocus placeholder="Nombres" />
          </FormItem>
          <FormItem
            name="lastName"
            rules={[
              {required: true, message: 'Ingresa tu apellido'},
              {whitespace: true, message: 'Ingresa tu apellido'},
              {max: 25, message: 'El apellido es demasiado largo'},
            ]}
          >
            <Input placeholder="Apellidos" />
          </FormItem>

          <FormItem
            name="password"
            rules={[
              {
                required: true,
                message: 'Ingresa tu nueva contraseña',
              },
              {min: 8, message: 'Debe tener mínimo 8 caracteres'},
            ]}
          >
            <Input.Password placeholder="Ingresa tu contraseña" autoFocus autoComplete="new-password" />
          </FormItem>

          <FormItem
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Debes aceptar'))),
              },
            ]}
          >
            <Checkbox>
              He leído y acepto los{' '}
              <a href="https://kipo.app/terminos-y-condiciones" target="_blank">
                Términos y Condiciones
              </a>{' '}
              y{' '}
              <a href="https://kipo.app/proteccion-de-datos" target="_blank">
                Políticas de Privacidad
              </a>
              .
            </Checkbox>
          </FormItem>

          <FormItem>
            <Button block type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
              Continuar
            </Button>
            <Button
              block
              type="link"
              onClick={() => {
                setEmailData({});
                setFormState(State.INPUT);
              }}
            >
              Atrás
            </Button>
          </FormItem>
        </Form>
      );

    case State.PASSWORD:
      return (
        <Form className="login-form" onFinish={onFinish}>
          <FormItem
            name="password"
            hasFeedback={invalidPassword}
            extra={invalidPassword ? <Text type="danger">Contraseña incorrecta</Text> : undefined}
            validateStatus={invalidPassword ? 'error' : undefined}
            rules={[
              {
                required: true,
                message: 'Ingresa tu contraseña',
              },
              {min: 8, message: 'Debe tener mínimo 8 caracteres'},
            ]}
          >
            <Input.Password placeholder="Ingresa tu contraseña" autoFocus autoComplete="current-password" />
          </FormItem>

          <FormItem>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={isLoading || requestCodeResult.loading}
            >
              Ingresar
            </Button>
          </FormItem>

          <FormItem>
            {withCode && (
              <Button
                block
                type="link"
                onClick={() => {
                  if (timeNewCode == 0) requestCode({variables: emailData as RequestAuthCodeMutationVariables});
                  setFormState(State.CODE);
                }}
              >
                Iniciar con código al correo
              </Button>
            )}
            <Button
              block
              type="link"
              onClick={() => {
                setEmailData({});
                setFormState(State.INPUT);
              }}
            >
              Atrás
            </Button>
          </FormItem>
        </Form>
      );

    case State.CODE:
      if (requestCodeResult.loading && timeNewCode == 0) setTimeNewCode(120);

      return (
        <Form className="login-form" onFinish={onFinish}>
          <FormItem
            name="code"
            rules={[{required: true, message: 'Ingresa el código'}]}
            hasFeedback={invalidCode}
            extra={invalidCode ? <Text type="danger">Código no valido</Text> : 'Te hemos enviado un código a tu correo'}
            validateStatus={invalidCode ? 'error' : undefined}
          >
            <Input
              autoFocus
              size="large"
              autoComplete="one-time-code"
              inputMode="numeric"
              prefix={<LockOutlined />}
              placeholder="Ingresa el código"
            />
          </FormItem>

          <FormItem>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={isLoading || requestCodeResult.loading}
            >
              Continuar
            </Button>
            <Button
              disabled={timeNewCode != 0}
              block
              type="link"
              onClick={() => {
                requestCode({variables: emailData as RequestAuthCodeMutationVariables});
              }}
            >
              {`Reenviar código ${timeNewCode > 0 ? '(' + timeNewCode + 's)' : ''}`}
            </Button>
          </FormItem>
          <FormItem>
            <Button
              block
              type="link"
              onClick={() => {
                setEmailData({});
                setFormState(State.INPUT);
              }}
            >
              Atrás
            </Button>
          </FormItem>
        </Form>
      );
  }
};
export default Email;
