import ErrorRender from "@/components/loaders/ErrorRender";
import LoadingSkeleton from "@/components/loaders/LoadingSkeleton";
import NotFound from "@/components/loaders/NotFound";
import PageContent from "@/components/PageContent";
import { unitTypeShort } from "@/constants/unit";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { useMutation, useQuery } from "@apollo/client";
import { CreateUnitNoteDocument, SingleUnitDocument, Unit } from "@gql/graphql";
import { Alert, Button, Form, Input, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const NoteNew = () => {
  let { unitId } = useParams();
  if (!unitId) return <NotFound />;

  useDocumentTitle("Nueva nota");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [createUnitNote, { loading: newLoading }] = useMutation(CreateUnitNoteDocument, {
    update: (cache, { data }) => {
      if (data?.createUnitNote?.id) {
        notification.success({
          message: "Creada con éxito",
          description: "Se ha creado la nota.",
        });
        navigate("/unit/" + unitId + "?tab=note");
      } else {
        notification.error({
          message: "Ha ocurrido un error :(",
          description: "Verifica la información o intenta más tarde.",
        });
      }
    },
  });

  const { loading, error, data, refetch } = useQuery(SingleUnitDocument, {
    variables: { id: unitId },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  if (loading) return <LoadingSkeleton paragraph={{ rows: 6 }} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;
  const unit = data.node as Unit;
  if (!unit) return <NotFound />;
  const unitName = `${unit.type ? unitTypeShort[unit.type] : ""} ${unit.number}`;

  const onInputFinish = async (values: any) => {
    createUnitNote({ variables: { unitId: unitId, ...values } });
  };

  return (
    <PageContent header={{ title: `Nueva nota (${unitName})`, onBack: () => navigate("/unit/" + unitId) }}>
      <Alert
        message="Las notas solo son visibles para la administración."
        type="info"
        showIcon
        style={{ marginBottom: "20px" }}
      />

      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          name="note"
          rules={[
            { required: true, message: "Escribe una nota" },
            { max: 1000, message: "La nota es muy larga" },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={1000}
            placeholder="Escribe tu la nota..."
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default NoteNew;
