import {User} from '@gql/graphql';

export const getUniqueColor = ({id}: User) => {
  var colors = [
    '#e51c23',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#5677fc',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#259b24',
    '#8bc34a',
    '#afb42b',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
  ];

  if (id.length === 0) return colors[0];
  var hash = 0;
  for (var i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

export const getFirstLetter = (viewer: User) => {
  let initial = '';
  if (viewer.firstName) initial += viewer.firstName.charAt(0).toUpperCase();
  if (viewer.lastName) initial += viewer.lastName.charAt(0).toUpperCase();

  return initial;
};

export const getOne = (first: string, last: string) => {
  let name = '';
  if (first) name += first.split(' ')[0] + ' ';
  if (last) name += last.split(' ')[0];

  return name;
};

export const getFullOne = (viewer: User) => {
  let name = '';
  if (viewer.firstName) name += viewer.firstName.split(' ')[0];
  if (viewer.lastName) name += ' ' + viewer.lastName.split(' ')[0];
  return name;
};

export const getFullName = (viewer: User) => {
  let name = '';
  if (viewer.firstName) name += viewer.firstName;
  if (viewer.lastName) name += ' ' + viewer.lastName;
  return name;
};
