import client from '@/graphql';
import {
  Maybe,
  NewsComment,
  NewsCommentStatus,
  PetitionComment,
  PetitionCommentStatus,
  UpdateNewsCommentStatusDocument,
  UpdatePetitionCommentStatusDocument,
} from '@gql/graphql';
import {fromNowMaxTooltip} from '@/utils/dates';
import {relatedComplex} from '@/services/store';
import {DeleteOutlined, EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {Button, List, notification, Popconfirm, Space, Typography} from 'antd';
import {Fragment} from 'react';
import UserAvatar from '../UserAvatar';
import './single.css';

const CommentMeta = ({
  comment,
  hasRights,
  viewerId,
  customExtra,
}: {
  comment: Maybe<NewsComment> | Maybe<PetitionComment> | undefined;
  hasRights?: relatedComplex | undefined;
  viewerId?: string;
  customExtra?: JSX.Element;
}) => {
  if (!comment) return <></>;

  const type = comment.__typename;
  const Status = type == 'NewsComment' ? (type as NewsCommentStatus) : (type as PetitionCommentStatus);

  const hideButton = () => {
    if (!comment || comment.status == 'DELETED') return undefined;

    const isHidden = comment.status == 'HIDDEN';
    const newStatus = isHidden ? 'PUBLISHED' : 'HIDDEN';

    return (
      <Popconfirm
        key="hideConfirm"
        placement="topRight"
        title={isHidden ? '¿Mostrar este comentario?' : '¿Ocultar este comentario?'}
        description={isHidden ? 'Todos podrán ver este comentario' : 'Nadie podrá ver el contenido de este comentario'}
        onConfirm={() => updateStatus(newStatus)}
        okText={isHidden ? 'Mostrar' : 'Ocultar'}
        cancelText="Cancelar"
      >
        <Button>{isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}</Button>
      </Popconfirm>
    );
  };

  const deleteButton = () => {
    if (!comment || comment.status == 'DELETED') return undefined;

    return (
      <Popconfirm
        key="delConfirm"
        placement="topRight"
        title={'¿Eliminar comentario? '}
        description={'Solo será eliminado el contenido del comentario'}
        onConfirm={() => updateStatus('DELETED')}
        okText={'Eliminar'}
        okType="danger"
        cancelText="Cancelar"
      >
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  const updateStatus = (newStatus: NewsCommentStatus | PetitionCommentStatus) => {
    let message = '';
    let desc = '';

    switch (newStatus) {
      case 'HIDDEN':
        message = 'Comentario ocultado';
        desc = 'Ahora solo la admon. podrá ver el contenido de este comentario.';
        break;

      case 'PUBLISHED':
        message = 'Comentario visible';
        desc = 'Ahora todo podrán ver el contenido de este comentario.';

        break;

      case 'DELETED':
        message = 'Comentario eliminado';
        desc = 'El contenido ya no es visible para nadie.';

        break;
    }

    const newsMutation = {
      mutation: UpdateNewsCommentStatusDocument,
      variables: {
        commentId: comment.id,
        status: newStatus as NewsCommentStatus,
      },
    };

    const petitonMutation = {
      mutation: UpdatePetitionCommentStatusDocument,
      variables: {
        commentId: comment.id,
        status: newStatus as PetitionCommentStatus,
      },
    };

    const onSuccessMsg = () => {
      notification.success({
        message: message,
        description: desc,
      });
    };

    const onError = () => {
      notification.error({
        message: 'Error al actualizar',
        description: 'No se pudo actualizar el comentario.',
      });
    };

    switch (type) {
      case 'NewsComment':
        return client
          .mutate(newsMutation)
          .then(() => onSuccessMsg())
          .catch(onError);
      case 'PetitionComment':
        return client
          .mutate(petitonMutation)
          .then(() => onSuccessMsg())
          .catch(onError);
      default:
        return undefined;
    }
  };

  const fetchBody = () => {
    switch (comment.status) {
      case 'HIDDEN':
        return <Typography.Text italic>Comentario oculto por incumplimiento de normas</Typography.Text>;
      case 'DELETED':
        return <Typography.Text italic>[eliminado]</Typography.Text>;

      default:
        return <Typography.Text>{comment.comment}</Typography.Text>;
    }
  };

  let opacity = comment.status != 'PUBLISHED' ? 0.5 : 1;

  const title = (
    <Fragment>
      <Space>
        <Typography.Text style={{fontSize: '13px'}}>
          {`${comment.createdBy?.firstName} ${comment.createdBy?.lastName}`}
        </Typography.Text>
        <Typography.Text type="secondary" style={{fontSize: '11px'}}>
          {fromNowMaxTooltip(comment.insertedAt)}
        </Typography.Text>
      </Space>
    </Fragment>
  );

  return (
    <List.Item key={comment.id}>
      <List.Item.Meta
        style={{opacity: opacity}}
        className="comment-meta"
        title={title}
        avatar={<UserAvatar viewer={comment.createdBy} />}
        description={<Typography.Text>{fetchBody()}</Typography.Text>}
      />
      <Space>
        {viewerId == comment.createdBy?.id && !customExtra && deleteButton()}
        {hasRights && !customExtra && hideButton()}
        {customExtra}
      </Space>
    </List.Item>
  );
};

export default CommentMeta;
