export const PETITION_MAX_MINS_DELETE = 60;

import {ArrowDownOutlined, ArrowUpOutlined, MinusOutlined, WarningOutlined} from '@ant-design/icons';
import {blue, gray, green, red, yellow} from './colors';

export const petitionStatusPending = {
  OPEN: 'Esperando a ser revisada',
  UNDER_REVIEW: 'Esperando a ser iniciada',
  IN_PROGRESS: 'Esperando a ser solucionada',
  RESOLVED: undefined,
  CLOSED: undefined,
};

export const petitionStatus = {
  OPEN: 'Abierta',
  UNDER_REVIEW: 'En revisión',
  IN_PROGRESS: 'En progreso',
  RESOLVED: 'Solucionado',
  CLOSED: 'Rechazada',
};

export const peitionStatusDesc = {
  OPEN: 'La solicitud ha sido abierta y está en espera de ser revisada.',
  UNDER_REVIEW: 'La solicitud está siendo revisada.',
  IN_PROGRESS: 'La solicitud ha sido aceptada y está siendo ejecutada.',
  RESOLVED: 'La solicitud ha sido solucionada.',
  CLOSED: 'La solicitud no será solucionada o es inválida.',
};

export const petitionStatusActionDesc: {[char: string]: string} = {
  OPEN: 'La solicitud no se ha revisado',
  UNDER_REVIEW: 'Avisa que has leído la solicitud',
  IN_PROGRESS: 'Avisa que estás trabajando esto',
  RESOLVED: 'Avisa que le has dado solución',
  CLOSED: 'No será solucionada o es inválida',
} as const;

export const petitionType = {
  QUESTION: 'Pregunta',
  COMPLAINT: 'Queja',
  CLAIM: 'Reclamo',
  SUGGESTION: 'Sugerencia',
};

export const petitionStatusColor = {
  OPEN: red,
  UNDER_REVIEW: yellow,
  IN_PROGRESS: blue,
  RESOLVED: green,
  CLOSED: gray,
};

export const petitionPriority = [
  {
    value: 2,
    string: 'Urgente',
    icon: <WarningOutlined style={{color: red}} />,
  },
  {
    value: 1,
    string: 'Alta',
    icon: <ArrowUpOutlined style={{color: yellow}} />,
  },
  {
    value: 0,
    string: 'Media',
    icon: <MinusOutlined style={{color: blue}} />,
  },
  {
    value: -1,
    string: 'Baja',
    icon: <ArrowDownOutlined style={{color: gray}} />,
  },
];

export const priorityData = (value: number) => petitionPriority.find(item => item.value == value);

export const petitionPriorityStr = {
  '2': 'Urgente',
  '1': 'Alta',
  '0': 'Media',
  '-1': 'Baja',
};
