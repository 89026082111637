import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {complexFeatures, complexStatus, complexStatusColor, complexStratum, complexType} from '@/constants/complexes';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasSuperRole, setLocalComplexRole} from '@/services/store';
import {toSnakeCase} from '@/utils/case';
import {enumToAllIndex} from '@/utils/enum';
import {titleLoading} from '@/utils/misc';
import {
  FilterOutlined,
  LeftOutlined,
  LoginOutlined,
  PlusCircleOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {makeVar, useQuery, useReactiveVar} from '@apollo/client';
import {
  Complex,
  ComplexEdge,
  ComplexesDocument,
  ComplexesQueryVariables,
  ComplexFeatures,
  ComplexFilter,
  ComplexSort,
  ComplexStatus,
  ComplexType,
} from '@gql/graphql';
import {Button, Col, Empty, Input, InputRef, Row, Skeleton, Table, Tag, Typography} from 'antd';
import {ColumnsType, TableProps} from 'antd/es/table';
import {ColumnType, FilterValue, SorterResult, SortOrder} from 'antd/es/table/interface';
import {useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link, useNavigate} from 'react-router-dom';

const {Text} = Typography;

type DataIndex = keyof Complex;

interface tableFilterType {
  name: FilterValue | null | undefined;
  status: ComplexStatus[] | undefined;
  type: ComplexType[] | undefined;
  features: ComplexFeatures[] | null | undefined;
  stratum: number[] | undefined;
}

const emptyFilter: tableFilterType = {
  name: undefined,
  status: undefined,
  type: undefined,
  features: undefined,
  stratum: undefined,
};

interface tableOrderType {
  field?: string;
  direction?: string;
}

const emptyOrder: tableOrderType = {
  field: undefined,
  direction: undefined,
};

const graphFilterStore = makeVar<tableFilterType>(emptyFilter);
const graphOrderStore = makeVar<tableOrderType>(emptyOrder);
const cursorStackStore = makeVar<string[]>([]);
const afterStore = makeVar<string | null>(null);
const title = 'Conjuntos';
const List = () => {
  const hasRole = hasSuperRole(['ADMIN']);
  if (!hasRole) return <Error403 />;

  useDocumentTitle(title);
  const navigate = useNavigate();
  const searchInput = useRef<InputRef>(null);
  const [firstLoad, setFirstLoad] = useState<Array<ComplexEdge>>();

  useReactiveVar(graphFilterStore);
  useReactiveVar(graphOrderStore);
  useReactiveVar(cursorStackStore);
  useReactiveVar(afterStore);

  const graphFilters = graphFilterStore();
  const graphOrder = graphOrderStore();
  const cursorStack = cursorStackStore();
  const after = afterStore();

  const clearAllFilters = () => {
    graphFilterStore(emptyFilter);
    graphOrderStore(emptyOrder);
    resetPagination();
  };

  const hasFilters = graphFilters && !Object.values(graphFilters).every(el => el === undefined);
  const hasOrder = graphOrder && !Object.values(graphOrder).every(el => el === undefined);

  const setLocalComplexButton = (complex: Complex) => {
    if (!complex) return undefined;

    return (
      <Button type="primary" onClick={() => setLocalComplexRole(complex, 'ADMIN')}>
        <LoginOutlined />
      </Button>
    );
  };

  const clearAllButton =
    hasFilters || hasOrder ? (
      <Button onClick={clearAllFilters} key="clear">
        <FilterOutlined /> Limpiar filtros
      </Button>
    ) : undefined;

  const createButton = hasRole ? (
    <Button onClick={() => navigate('/complex/new')} key="add" type="primary">
      <PlusCircleOutlined /> Nuevo
    </Button>
  ) : undefined;

  let headButtons = [clearAllButton, createButton];

  const highlight = (searchTerm: any, toHighlight: any) => {
    if (!searchTerm || searchTerm.length == 0) return searchTerm;
    return (
      <Highlighter
        autoEscape
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchTerm]}
        textToHighlight={toHighlight ? toHighlight.toString() : ''}
      />
    );
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Complex> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
      return (
        <div style={{padding: 8}}>
          <Input
            ref={searchInput}
            placeholder="Buscar"
            value={selectedKeys as string[]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm({closeDropdown: true})}
            style={{width: 188, marginBottom: 8, display: 'block'}}
          />
          <Button
            type="primary"
            onClick={() => confirm({closeDropdown: true})}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90, marginRight: 8}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) handleResetSearch(clearFilters);
            }}
            size="small"
            style={{width: 90}}
          >
            Limpiar
          </Button>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record, index) => {
      if (dataIndex == 'name') {
        let term = graphFilters.name ? graphFilters.name : undefined;
        let name = term ? highlight(term, text) : text;
        return <Link to={'/complex/' + record.id + '/edit'}>{name}</Link>;
      }
    },
  });

  const handleResetSearch = (clearFilters: Function) => {
    clearFilters({confirm: true, closeDropdown: true});
  };

  const resetPagination = () => {
    cursorStackStore([]);
    afterStore(undefined);
  };

  const calcSortOrder = (field: string) => {
    let order: SortOrder = null;
    if (graphOrder && graphOrder.field === toSnakeCase(field).toUpperCase()) {
      order = graphOrder.direction == 'ASC' ? 'ascend' : 'descend';
    }
    return order;
  };

  const handleTableChange: TableProps<Complex>['onChange'] = (pagination, filters, sorter) => {
    const newSorter = sorter as SorterResult<Complex>;
    const newFilter = {...emptyFilter};
    for (const key in filters) {
      const value = filters[key];
      if (value !== null && value.length > 0) {
        switch (key) {
          case 'name':
            newFilter.name = Array.isArray(value) ? (value[0] as any) : value;
            break;

          default:
            newFilter[key as keyof typeof newFilter] = value as any;
            break;
        }
      }
    }

    if (graphFilters != newFilter) {
      resetPagination();
    }
    graphFilterStore(newFilter);

    if (newSorter.order) {
      const orderField = toSnakeCase(newSorter.columnKey as string).toUpperCase();
      let orderDirection = newSorter.order as string;
      orderDirection = orderDirection == 'descend' ? 'DESC' : 'ASC';
      graphOrderStore({field: orderField, direction: orderDirection});
    } else {
      graphOrderStore(undefined);
    }
  };

  let columns: ColumnsType<Complex> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: '25%',
      sortOrder: calcSortOrder('name'),
      filteredValue: graphFilters.name || null,
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      filteredValue: graphFilters?.status || null,
      filters: enumToAllIndex(complexStatus).map(value => {
        return {
          text: complexStatus[value as ComplexStatus],
          value: value,
        };
      }),
      render: value => (
        <Tag color={complexStatusColor[value as ComplexStatus]}>{complexStatus[value as ComplexStatus]}</Tag>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      filteredValue: graphFilters?.type || null,
      filters: enumToAllIndex(complexType).map(value => {
        return {
          text: complexType[value as ComplexType],
          value: value,
        };
      }),
      render: value => complexType[value as ComplexType],
    },
    {
      title: 'Estrato',
      dataIndex: 'stratum',
      key: 'stratum',
      filteredValue: graphFilters?.stratum || null,
      filters: complexStratum.map((value, index) => {
        return {
          text: complexStratum[index],
          value: index + 1,
        };
      }),
      render: value => value,
    },
    {
      title: 'Funciones',
      dataIndex: 'features',
      key: 'features',
      filteredValue: graphFilters?.features || null,
      filters: enumToAllIndex(complexFeatures).map(value => {
        return {
          text: complexFeatures[value as ComplexFeatures],
          value: value,
        };
      }),
      render: value => {
        if (value === null) {
          return '—';
        }
        return value
          .map((feature: ComplexFeatures) => {
            return complexFeatures[feature];
          })
          .join(', ');
      },
    },
    {
      title: 'Ciudad',
      dataIndex: ['district', 'city'],
      key: 'district',
      filteredValue: null,
      render: (text, record, index) => (text ? text.name : '—'),
    },
    {
      title: 'Acción',
      render: (text, record, index) => (record ? setLocalComplexButton(record) : '—'),
    },
  ];

  columns = columns.filter(item => item.title);

  const pageSize = 10;
  const queryVariables = {
    first: pageSize,
    after: after,
    filter: graphFilters ? (graphFilters as ComplexFilter) : {},
    order: graphOrder?.field ? (graphOrder as ComplexSort) : undefined,
  } as ComplexesQueryVariables;

  const {loading, error, data, refetch} = useQuery(ComplexesDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading && !firstLoad && !data) return <Skeleton paragraph={{rows: 4}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (data?.complexes?.edges && data.complexes.edges != firstLoad)
    setFirstLoad(data.complexes.edges as Array<ComplexEdge>);
  if (!data?.complexes?.edges && !firstLoad) return <NotFound />;
  if (!firstLoad) return <NotFound />;

  const edges = firstLoad;
  const complexes = edges.map((edge: ComplexEdge) => edge?.node as Complex);
  const totalCount = data?.complexes?.total ? data?.complexes?.total : 0;
  const hasNextPage = data?.complexes?.pageInfo.hasNextPage;
  const endCursor = data?.complexes?.pageInfo.endCursor;

  const prevPage = () => {
    let existingStack = cursorStack;
    existingStack.pop();
    cursorStackStore(existingStack);
    let lastEndCursor = existingStack.length > 0 ? existingStack[existingStack.length - 1] : undefined;
    afterStore(lastEndCursor);
  };

  const nextPage = () => {
    let existingStack = cursorStack;
    if (endCursor) existingStack.push(endCursor);
    cursorStackStore(existingStack);
    afterStore(endCursor);
  };

  const paginationButtons = () => (
    <Row justify="end">
      <Col>
        <Button onClick={prevPage} size="small" key="prev" type="link" disabled={cursorStack.length == 0}>
          <LeftOutlined />
          Anterior
        </Button>{' '}
        <Text type="secondary">
          {cursorStack.length + 1}/{Math.ceil(totalCount / pageSize)}
        </Text>{' '}
        <Button onClick={nextPage} size="small" key="next" type="link" disabled={!hasNextPage}>
          Siguiente
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <PageContent fullWidth header={{title: titleLoading(title, loading), extra: [headButtons]}}>
        <Table<Complex>
          footer={() => paginationButtons()}
          pagination={false}
          columns={columns}
          dataSource={complexes}
          onChange={handleTableChange}
          rowKey="id"
          size="middle"
          scroll={{x: true}}
          locale={{
            emptyText: <Empty description="Sin propiedades"></Empty>,
          }}
        />
      </PageContent>
    </>
  );
};

export default List;
