import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import {selectedComplex, selectedComplexIDs} from '@/services/store';
import {intervalFromString, rangePresets} from '@/utils/dates';
import {getPercentage, getVariation} from '@/utils/porcentages';
import {CalendarOutlined, CloseCircleOutlined, FundTwoTone} from '@ant-design/icons';
import {useQuery, useReactiveVar} from '@apollo/client';
import {PetitionInsight, PetitionInsightsDocument} from '@gql/graphql';
import {Card, DatePicker, Divider, Select, Space, Statistic, Typography} from 'antd';
import dayjs from 'dayjs';
import {Fragment, useEffect, useState} from 'react';
const {RangePicker} = DatePicker;

const strRanges = [
  {value: 'today', label: 'Hoy'},
  {value: 'yesterday', label: 'Ayer'},
  {value: 'lastSeven', label: 'Últimos 7 días'},
  {value: 'lastFourteen', label: 'Últimos 14 días'},
  {value: 'lastThirty', label: 'Últimos 30 días'},
  {value: 'thisWeek', label: 'Esta semana (lun. a dom.)'},
  {value: 'lastWeek', label: 'La semana pasada (lun. a dom.)'},
  {value: 'thisMonth', label: 'Este mes'},
  {value: 'lastMonth', label: 'El mes pasado'},
  {value: 'sinceEver', label: 'Desde siempre'},
];

const Insights = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>();
  const [endDate, setEndDate] = useState<dayjs.Dayjs>();
  const [stats, setStats] = useState<PetitionInsight>();

  useReactiveVar(selectedComplex);

  const selectorChange = (value: string) => {
    const dates = intervalFromString(value);
    if (dates[0] && dates[1]) {
      setStartDate(dates[0].startOf('day'));
      setEndDate(dates[1].endOf('day'));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const rangeChange = (dates: any, formatString: [string, string]) => {
    if (dates[0] && dates[1]) {
      setStartDate(dates[0].startOf('day'));
      setEndDate(dates[1].endOf('day'));
    }
  };

  useEffect(() => {
    selectorChange('thisMonth');
  }, []);

  const ranges = {startDate: startDate, endDate: endDate};
  const {loading, error, data, refetch, fetchMore} = useQuery(PetitionInsightsDocument, {
    variables: {previus: true, filter: {complexes: selectedComplexIDs(), ...ranges}},
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading && !stats) return <LoadingSkeleton active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (data?.petitionInsights && data.petitionInsights != stats) setStats(data.petitionInsights);
  if (!data?.petitionInsights && !stats) return <NotFound />;
  if (!stats) return <NotFound />;

  const dateRangeSelector = () => {
    if (showCalendar) return undefined;
    return (
      <Select
        key="strRangeSelect"
        defaultValue="lastThirty"
        placeholder="Select a person"
        optionFilterProp="children"
        options={strRanges}
        style={{minWidth: 150}}
        onChange={selectorChange}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider style={{margin: '4px 0'}} />
            <div
              style={{padding: '4px 8px', cursor: 'pointer'}}
              onMouseDown={e => e.preventDefault()}
              onClick={() => setShowCalendar(true)}
            >
              <CalendarOutlined /> Personalizado
            </div>
          </div>
        )}
      />
    );
  };

  const dateRangeCustomSelector = () => {
    if (!showCalendar) return undefined;

    return (
      <Fragment key="customSelector">
        <RangePicker
          format={'DD MMM YYYY'}
          presets={rangePresets}
          defaultValue={startDate && endDate ? [startDate, endDate] : [null, null]}
          onChange={rangeChange}
        />{' '}
        <CloseCircleOutlined onClick={() => setShowCalendar(false)} />
      </Fragment>
    );
  };

  return (
    <Card
      title={
        <Fragment>
          <FundTwoTone /> Resumen
        </Fragment>
      }
      bordered={false}
      style={{marginBottom: '30px'}}
      extra={[dateRangeSelector(), dateRangeCustomSelector()]}
    >
      <Space size={[25, 25]} wrap>
        <Card>
          <Statistic
            title="Solicitudes"
            loading={loading}
            value={stats?.petitions![0]!}
            suffix={getVariation(stats?.petitions)}
          />
        </Card>
        <Card>
          <Statistic
            title="Resueltas"
            loading={loading}
            value={stats.finished![0]!}
            suffix={getPercentage(stats.finished![0]!, stats.petitions![0]!)}
          />
        </Card>
        <Card>
          <Statistic
            title="Sin resolver"
            loading={loading}
            value={stats.unfinished![0]!}
            suffix={getPercentage(stats.unfinished![0]!, stats.petitions![0]!)}
          />
        </Card>
        <Card>
          <Statistic
            title="Preguntas"
            loading={loading}
            value={stats.typeQuestion![0]!}
            suffix={getPercentage(stats.typeQuestion![0]!, stats.petitions![0]!)}
          />
        </Card>
        <Card>
          <Statistic
            title="Quejas"
            loading={loading}
            value={stats.typeComplaint![0]!}
            suffix={getPercentage(stats.typeComplaint![0]!, stats.petitions![0]!)}
          />
        </Card>
        <Card>
          <Statistic
            title="Sugerencias"
            loading={loading}
            value={stats.typeSuggestion![0]!}
            suffix={getPercentage(stats.typeSuggestion![0]!, stats.petitions![0]!)}
          />
        </Card>
        <Card>
          <Statistic
            title="Reclamos"
            loading={loading}
            value={stats.typeClaim![0]!}
            suffix={getPercentage(stats.typeClaim![0]!, stats.petitions![0]!)}
          />
        </Card>
        <Card>
          <Statistic
            title="Comentarios"
            loading={loading}
            value={stats.comments![0]!}
            suffix={getVariation(stats.comments)}
          />
        </Card>
        <Card>
          <Statistic
            title="Puestas en revisión"
            loading={loading}
            value={stats.statusUnderReview![0]!}
            suffix={getVariation(stats.statusUnderReview)}
          />
        </Card>
        <Card>
          <Statistic
            title="Puestas en progreso"
            loading={loading}
            value={stats.statusInProgress![0]!}
            suffix={getVariation(stats.statusInProgress)}
          />
        </Card>
        <Card>
          <Statistic
            title="Puestas en solucionado"
            loading={loading}
            value={stats.statusResolved![0]!}
            suffix={getVariation(stats.statusResolved)}
          />
        </Card>
        <Card>
          <Statistic
            title="Puestas en revisión"
            loading={loading}
            value={stats.statusClosed![0]!}
            suffix={getVariation(stats.statusClosed)}
          />
        </Card>
      </Space>
    </Card>
  );
};

export default Insights;
