export const reactionDesc = {
  LIKE: 'Me gusta',
  DISLIKE: 'No me gusta',
  CLAP: 'Lo aplaudo',
  LOVE: 'Me encanta',
  SAD: 'Me entristece',
  ANGRY: 'Me enoja',
};

export const reactionEmoji = {
  ANGRY: '😠',
  SAD: '😢',
  DISLIKE: '👎',
  LIKE: '👍',
  LOVE: '❤️',
  CLAP: '👏',
};
