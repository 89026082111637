import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {getViewer, relatedComplex} from '@/services/store';
import {PlusCircleOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {
  CreateNewsCommentDocument,
  CreatePetitionCommentDocument,
  News,
  Petition,
  SingleNewsCommentsDocument,
  SingleNewsCommentsQueryVariables,
  SinglePetitionCommentsDocument,
} from '@gql/graphql';
import {Button, Empty, Form, Input, List, Modal, notification, Tooltip} from 'antd';
import {useState} from 'react';
import SingleComment from './SingleComment';

interface CreateCommentProps {
  open: boolean;
  onCancel: () => void;
}

const Comments = ({resource, hasRights}: {resource: Petition | News; hasRights?: relatedComplex | undefined}) => {
  if (!resource) return <NotFound />;

  const onError = () =>
    notification.error({
      message: 'Error al crear',
      description: 'No se pudo crear el comentario. Intenta más tarde.',
    });

  const onSuccess = () =>
    notification.success({
      message: 'Comentario publicado',
      description: 'Se ha publicado tu comentario.',
    });

  const [formOpen, setFormOpen] = useState(false);
  const viewer = getViewer();
  const isPetition = resource.__typename == 'Petition';
  const isNews = resource.__typename == 'News';

  const queryVariables = {nodeId: resource.id, first: 10} as SingleNewsCommentsQueryVariables;
  const queryDocument = isPetition ? SinglePetitionCommentsDocument : SingleNewsCommentsDocument;
  const mutationDocument = isPetition ? CreatePetitionCommentDocument : CreateNewsCommentDocument;
  const {loading, error, data, refetch, fetchMore} = useQuery(queryDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data) return <LoadingSkeleton active avatar />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;
  const item = isPetition ? (data.node as Petition) : (data.node as News);
  const nodes = item.comments?.edges?.map(edge => edge?.node);
  const pageInfo = item.comments?.pageInfo;

  if (nodes?.length === 0 && resource.allowComments === false) return null;

  const CreateForm: React.FC<CreateCommentProps> = ({open, onCancel}) => {
    const [form] = Form.useForm();
    const [createCommentPetition, {loading: createPetitionLoading}] = useMutation(CreatePetitionCommentDocument, {
      refetchQueries: [{query: queryDocument, variables: queryVariables}],
      onCompleted: result => {
        if (result.createPetitionComment?.id) {
          onSuccess();
          form.resetFields();
          setFormOpen(false);
        }
      },
      onError: onError,
    });

    const [createCommentNews, {loading: createNewsLoading}] = useMutation(CreateNewsCommentDocument, {
      refetchQueries: [{query: queryDocument, variables: queryVariables}],
      onCompleted: result => {
        if (result.createNewsComment?.id) {
          onSuccess();
        }
      },
      onError: onError,
    });

    return (
      <Modal
        centered
        open={open}
        title="Nuevo comentario"
        okText="Publicar"
        cancelText="Cancelar"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              const createVars = {id: item.id, comment: values.comment};
              if (isPetition) {
                createCommentPetition({variables: createVars});
              } else {
                createCommentNews({variables: createVars});
              }
            })
            .catch(() => {
              notification.error({
                message: 'Error al validar',
                description: 'Verifica que el comentario tenga valores válidos.',
              });
            });
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                message: 'El comentario no puede estar vacío',
              },
              {
                whitespace: true,
                message: 'El comentario no puede estar vacío',
              },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={250}
              placeholder="Escribe tu comentario..."
              autoSize={{minRows: 3, maxRows: 5}}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const createButton = item.allowComments ? (
    <Button
      key="create"
      type="primary"
      onClick={() => {
        setFormOpen(true);
      }}
    >
      <PlusCircleOutlined /> Añadir comentario
    </Button>
  ) : (
    <Tooltip key="create" placement="topRight" title={'Desactivados por la administración'}>
      <Button disabled type="primary">
        <PlusCircleOutlined /> Añadir comentario
      </Button>
    </Tooltip>
  );

  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Cargar más...
        </Button>
      </div>
    ) : null;

  return (
    <PageContent
      header={{
        title: 'Comentarios',
        extra: [createButton],
      }}
    >
      <List
        loadMore={loadMore}
        itemLayout="horizontal"
        loading={loading}
        locale={{emptyText: <Empty description="Sin comentarios" />}}
        dataSource={nodes}
        renderItem={item => <SingleComment comment={item} hasRights={hasRights} viewerId={viewer?.id} />}
      />
      <CreateForm
        open={formOpen}
        onCancel={() => {
          setFormOpen(false);
        }}
      />
    </PageContent>
  );
};

export default Comments;
