import { HomeOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NoRoute404 = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        textAlign: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Result
        status="404"
        title="Página no encontrada"
        subTitle="Disculpa, la página no existe o ha sido movida."
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            <HomeOutlined /> Ir al inicio
          </Button>
        }
      />
    </div>
  );
};

export default NoRoute404;
