import {File, Maybe} from '@gql/graphql';
import {Space} from 'antd';
import MediaDocument from './MediaDocument';
import MediaImage from './MediaImage';
import MediaVideo from './MediaVideo';

const MediaList = ({files}: {files: Maybe<Maybe<File>[]> | undefined}) => {
  if (!files) return null;
  const fileList = files.map((item: Maybe<File>) => {
    if (!item || item.type !== 'DOCUMENT') return null;
    if (item.contentType?.startsWith('image/')) return <MediaImage key={item.id} image={item} />;
    if (item.contentType?.startsWith('video/')) return <MediaVideo key={item.id} video={item} />;
    return <MediaDocument key={item.id} file={item} />;
  });

  return (
    <div style={{clear: 'both', paddingBottom: 20}}>
      <Space>{fileList}</Space>
    </div>
  );
};

export default MediaList;
