import DateSelect from '@/components/DateSelect';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import UploadAvatar from '@/components/Upload/UploadAvatar';
import {idType} from '@/constants/account';
import {humanSex} from '@/constants/misc';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {isAfterToday} from '@/utils/dates';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {useMutation, useQuery} from '@apollo/client';
import {UpdateProfileDocument, User, ViewerFullProfileDocument} from '@gql/graphql';
import {Button, Form, Input, notification, Radio, Select, Space} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
const Option = Select.Option;

const title = 'Actualizar perfil';

const Edit = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateProfile, {loading: editLoading}] = useMutation(UpdateProfileDocument, {
    onCompleted: () => {
      notification.success({
        message: 'Actualizado con éxito',
        description: 'Se ha actualizado tu información.',
      });
      navigate(-1);
    },
  });

  const {loading, error, data, refetch} = useQuery(ViewerFullProfileDocument, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.viewer) return <NotFound />;

  const user = data.viewer as User;

  const onInputFinish = async (values: any) => {
    updateProfile({variables: values});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate(-1)}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          ...user,
        }}
      >
        <Form.Item {...formItemLayout} name="avatarId" colon={false} label=" ">
          <UploadAvatar viewer={user} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Nombres" name="firstName" rules={[{required: true}]}>
          <Input style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Apellidos" name="lastName" rules={[{required: true}]}>
          <Input style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Celular">
          <Space>
            <Input
              disabled
              style={{maxWidth: 200}}
              value={user.celNumber ? '+' + user.celCountry + ' ' + user.celNumber : ''}
            />
            <Link to="/account/phone">{user.celNumber ? 'Cambiar' : 'Agregar'}</Link>
          </Space>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Correo">
          <Space>
            <Input disabled style={{maxWidth: 200}} value={user.email ? user.email : undefined} />
            <Link to="/account/email">{user.email ? 'Cambiar' : 'Agregar'}</Link>
          </Space>
        </Form.Item>

        {user.email && (
          <Form.Item {...formItemLayout} label="Contraseña">
            <Space>
              <Input disabled type="password" style={{maxWidth: 200}} value={'password'} />
              <Link to="/account/password">{user.hasPassword ? 'Cambiar' : 'Crear'}</Link>
            </Space>
          </Form.Item>
        )}

        <Form.Item {...formItemLayout} label="Sexo" name="sex">
          <Radio.Group>
            {enumToKV(humanSex).map(({key, value}) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item {...formItemLayout} name="idNumber" label="Identificación">
          <Input
            style={{maxWidth: 300}}
            placeholder="Número"
            addonBefore={
              <Form.Item name="idType" noStyle>
                <Select placeholder="Tipo">
                  {enumToKV(idType).map(({key, value}) => (
                    <Option key={key} value={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Nacimiento" name="birthday">
          <DateSelect disabledDate={current => isAfterToday(current)} />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Actualizar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default Edit;
