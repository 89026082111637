import {pollTagColor, pollTagString} from '@/constants/poll';
import {hasRoles, selectedComplex} from '@/services/store';
import {PieChartTwoTone, PlusCircleOutlined} from '@ant-design/icons';
import {useReactiveVar} from '@apollo/client';
import {HomeQuery, PollEdge} from '@gql/graphql';
import {Card, Empty, List, Skeleton, Tag} from 'antd';
import {Fragment} from 'react';
import {Link} from 'react-router-dom';

const PollsWidget = ({data}: {data: HomeQuery}) => {
  useReactiveVar(selectedComplex);
  const canCreate = hasRoles(['ADMIN', 'COUNCIL']);

  return (
    <Card
      title={
        <Fragment>
          <PieChartTwoTone /> Votaciones
        </Fragment>
      }
      bordered={false}
      style={{marginBottom: '24px'}}
      extra={
        canCreate ? (
          <Link to={'poll/new'}>
            <PlusCircleOutlined /> Nueva
          </Link>
        ) : undefined
      }
    >
      <Content data={data} />
    </Card>
  );
};

const Content = ({data}: {data: HomeQuery}) => {
  if (!data?.polls?.edges) return <Skeleton />;
  const pollList = data.polls?.edges.filter((element): element is PollEdge => element !== null);

  return (
    <List
      locale={{emptyText: <Empty description="Sin votaciones" />}}
      bordered={pollList.length > 0}
      dataSource={pollList}
      renderItem={({node}) => {
        const showStatus =
          node?.status != 'ACTIVE' ? undefined : (
            <Tag key="status" color={pollTagColor[node.status]}>
              {pollTagString[node.status]}
            </Tag>
          );
        return (
          <List.Item extra={[showStatus]}>
            <Link to={'poll/' + node?.id}>{node?.question}</Link>
          </List.Item>
        );
      }}
    />
  );
};

export default PollsWidget;
