import ErrorRender from "@/components/loaders/ErrorRender";
import LoadingSkeleton from "@/components/loaders/LoadingSkeleton";
import NotFound from "@/components/loaders/NotFound";
import PageContent from "@/components/PageContent";
import { commonColors } from "@/constants/misc";
import { unitTypeShort } from "@/constants/unit";
import { fuelType, vechicleMakers, vehicleType } from "@/constants/vehicle";
import { enumToKV } from "@/utils/enum";
import { formItemLayout } from "@/utils/forms";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { useMutation, useQuery } from "@apollo/client";
import { CreateVehicleDocument, SingleUnitDocument, Unit } from "@gql/graphql";
import { AutoComplete, Button, Form, Input, notification, Radio } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const VehicleNew = () => {
  let { unitId } = useParams();
  if (!unitId) return <NotFound />;

  useDocumentTitle("Nuevo vehículo");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [createVehicle, { loading: newLoading }] = useMutation(CreateVehicleDocument, {
    update: (cache, { data }) => {
      if (data?.createVehicle?.id) {
        notification.success({
          message: "Añadido con éxito",
          description: "Se ha añadido el vehículo.",
        });
        navigate("/unit/" + unitId + "?tab=vehicle");
      } else {
        notification.error({
          message: "Ha ocurrido un error :(",
          description: "Verifica la información o intenta más tarde.",
        });
      }
    },
  });

  const { loading, error, data, refetch } = useQuery(SingleUnitDocument, {
    variables: { id: unitId },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  if (loading) return <LoadingSkeleton paragraph={{ rows: 6 }} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;
  const unit = data.node as Unit;
  if (!unit) return <NotFound />;
  const unitName = `${unit.type ? unitTypeShort[unit.type] : ""} ${unit.number}`;

  const onInputFinish = async (values: any) => {
    createVehicle({ variables: { unitId: unitId, ...values } });
  };

  const colorOptions = commonColors.map((color) => {
    return { value: color };
  });

  const makers = vechicleMakers.map((make) => {
    return { value: make };
  });

  return (
    <PageContent header={{ title: `Nuevo vehículo (${unitName})`, onBack: () => navigate("/unit/" + unitId) }}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          {...formItemLayout}
          label="Placa"
          name="plate"
          rules={[
            { required: true, message: "Escribe la placa" },
            { max: 8, message: "Es muy larga" },
          ]}
        >
          <Input size="large" placeholder="ABC123" style={{ maxWidth: 100 }} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Tipo"
          name="type"
          rules={[{ required: true, message: "Debes seleccionar el tipo" }]}
        >
          <Radio.Group>
            {enumToKV(vehicleType).map(({ key, value }) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Marca" name="brand">
          <AutoComplete
            style={{ maxWidth: 200 }}
            placeholder="Escribe o selecciona"
            options={makers}
            filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Color" name="color">
          <AutoComplete
            style={{ maxWidth: 200 }}
            placeholder="Escribe o selecciona"
            options={colorOptions}
            filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Combustible" name="fuel">
          <Radio.Group>
            {enumToKV(fuelType).map(({ key, value }) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Servicio" name="publicService">
          <Radio.Group>
            <Radio.Button key={"servPub"} value={false}>
              Particular
            </Radio.Button>
            <Radio.Button key={"servPar"} value={true}>
              Publico
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/* <Form.Item
          {...formItemLayout}
          label="Técno mecánica"
          name="rtm"
          extra="Ingresa la próxima fecha de la revisión técno mecánica"
        >
          <DateSelect disabledDate={(current) => isBeforeToday(current)} />
        </Form.Item> */}

        {/* <Form.Item {...formItemLayout} label="SOAT" name="soat" extra="Ingresa la próxima fecha de renovación del SOAT">
          <DateSelect disabledDate={(current) => isBeforeToday(current)}  />
        </Form.Item> */}

        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default VehicleNew;
