import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {idType} from '@/constants/account';
import {feedbackStatus} from '@/constants/feedback';
import {humanSex} from '@/constants/misc';
import {superRoles, userStatus} from '@/constants/roles';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasSuperRole} from '@/services/store';
import {isAfterToday} from '@/utils/dates';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {useMutation, useQuery} from '@apollo/client';
import DateSelect from '@comp/DateSelect';
import UploadAvatar from '@comp/Upload/UploadAvatar';
import {SingleUserDocument, UpdateUserDocument, User} from '@gql/graphql';
import {Button, Form, Input, notification, Radio, Select, Space} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';

const title = 'Editar usuario';

const Edit = () => {
  const {userId} = useParams();
  if (!userId) return <NotFound />;
  if (!hasSuperRole(['ADMIN'])) return <Error403 />;

  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [updateUser, {loading: editLoading}] = useMutation(UpdateUserDocument, {
    onCompleted: () => {
      notification.success({
        message: 'Editado con éxito',
        description: 'Se ha editado el usuario.',
      });
      navigate('/user');
    },
  });

  const {loading, error, data, refetch} = useQuery(SingleUserDocument, {
    variables: {id: userId},
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const user = data.node as User;
  if (!user) return <NotFound />;

  const onInputFinish = async (values: any) => {
    updateUser({variables: {id: userId, changes: {...values}}});
  };
  return (
    <PageContent header={{title: title, onBack: () => navigate('/user')}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          ...user,
        }}
      >
        <Form.Item {...formItemLayout} name="avatarId" colon={false} label=" ">
          <UploadAvatar viewer={user} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Rol" name="role">
          <Select placeholder="Tipo" style={{maxWidth: 200}}>
            {enumToKV(superRoles).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Estado" name="status">
          <Select placeholder="Tipo" style={{maxWidth: 200}}>
            {enumToKV(userStatus).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Nombres" name="firstName" rules={[{required: true}]}>
          <Input style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Apellidos" name="lastName" rules={[{required: true}]}>
          <Input style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Correo" name="email">
          <Input style={{maxWidth: 200}} value={user.email ? user.email : undefined} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Contraseña" name="password">
          <Space>
            <Input type="password" style={{maxWidth: 200}} />
            {user.hasPassword ? '(Cambiar)' : '(Crear)'}
          </Space>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Sexo" name="sex">
          <Radio.Group>
            {enumToKV(humanSex).map(({key, value}) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item {...formItemLayout} name="idNumber" label="Identificación">
          <Input
            style={{maxWidth: 300}}
            placeholder="Número"
            addonBefore={
              <Form.Item name="idType" noStyle>
                <Select placeholder="Tipo">
                  {enumToKV(idType).map(({key, value}) => (
                    <Select.Option key={key} value={key}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Nacimiento" name="birthday">
          <DateSelect disabledDate={current => isAfterToday(current)} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Celular">
          <Space.Compact>
            <Form.Item name="celCountry">
              <Input style={{maxWidth: 70}} value={user.celCountry ?? ''} placeholder={'Cód.'} />
            </Form.Item>
            <Form.Item
              name="celNumber"
              rules={[
                {required: true, message: 'Ingresa tu teléfono móvil'},
                {whitespace: true, message: 'Ingresa tu teléfono móvil'},
              ]}
            >
              <Input placeholder="Número" value={user.celNumber ?? ''} style={{maxWidth: 130}} />
            </Form.Item>
          </Space.Compact>
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Editar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default Edit;
