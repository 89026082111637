import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import client from '@/graphql';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {useQuery} from '@apollo/client';
import {UpdateUserPasswordDocument, ViewerFullProfileDocument} from '@gql/graphql';
import {Button, Form, Input, notification} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

const title = 'Actualizar perfil';

const UpdatePassword = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const {loading, error, data, refetch} = useQuery(ViewerFullProfileDocument, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.viewer) return <NotFound />;

  const onInputFinish = async (values: any) => {
    setIsLoading(true);
    let variables = {
      ...values,
    };

    await client
      .mutate({
        mutation: UpdateUserPasswordDocument,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({data}) => {
        if (data?.updateUserPassword?.success) {
          notification.success({
            message: 'Contraseña actualizada',
            description: 'Ya puedes iniciar sesión con la nueva contraseña.',
          });
          navigate('/');
        }
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions!.code) {
              case 'INCORRECT_PASSWORD':
                notification.error({
                  message: 'Contraseña incorrecta',
                  description: 'Revisa de nuevo tu contraseña actual.',
                });
              default:
                return true;
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate(-1)}}>
      <Form form={form} onFinish={onInputFinish}>
        {data.viewer.hasPassword && (
          <Form.Item
            {...formItemLayout}
            name="password"
            label="Actual"
            rules={[
              {
                required: true,
                message: 'Ingresa tu contraseña actual',
              },
            ]}
          >
            <Input type="password" autoFocus style={{maxWidth: 250}} placeholder="Tu contraseña actual" />
          </Form.Item>
        )}

        <Form.Item
          {...formItemLayout}
          name="newPassword"
          label="Nueva"
          rules={[
            {
              required: true,
              message: 'Ingresa tu contraseña nueva',
            },
          ]}
        >
          <Input type="password" style={{maxWidth: 250}} placeholder="Tu contraseña nueva" />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Cambiar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default UpdatePassword;
