import UserAvatar from '@/components/UserAvatar';
import {getFullOne} from '@/utils/names';
import {DownOutlined, LogoutOutlined, QuestionCircleOutlined, UserOutlined} from '@ant-design/icons';
import {User} from '@gql/graphql';
import {IconHelp} from '@tabler/icons-react';
import type {MenuProps} from 'antd';
import {Dropdown} from 'antd';
import {Link} from 'react-router-dom';

const HeaderUser = ({viewer}: {viewer: User}) => {
  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: <Link to="/account/edit">Editar perfil</Link>,
      icon: <UserOutlined />,
    },
    {
      key: 'feedback',
      label: <Link to="/feedback/new">Ayuda y soporte</Link>,
      icon: <QuestionCircleOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: <Link to="/logout">Cerrar sesión</Link>,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <div className="right" style={{marginRight: 7}}>
      <Dropdown menu={{items}}>
        <span className="action account">
          <UserAvatar className="avatar" size="default" viewer={viewer} />
          <span className="name">{getFullOne(viewer)}</span>
          <DownOutlined style={{fontSize: '12px', marginLeft: '3px'}} />
        </span>
      </Dropdown>
    </div>
  );
};

export default HeaderUser;
