import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import UserAvatar from '@/components/UserAvatar';
import {humanSex} from '@/constants/misc';
import client from '@/graphql';
import {toNowTooltip} from '@/utils/dates';
import {findById, getUnitRole, getViewer, hasRoles} from '@/services/store';
import {DeleteOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {DeleteUnitContactDocument, SingleUnitContactsDocument, Unit, User} from '@gql/graphql';
import {App, Button, Descriptions, Empty, List, Popconfirm, Space, Typography} from 'antd';
import {useParams} from 'react-router-dom';

const MemberList = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;

  const {message, notification} = App.useApp();
  const queryVariables = {id: unitId, first: 10};
  const {loading, error, data, refetch, fetchMore} = useQuery(SingleUnitContactsDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const unit = data.node as Unit;
  if (!unit) return <NotFound />;

  const unitContacts = unit.unitContacts?.edges?.map(edge => edge?.node);
  const pageInfo = unit.unitContacts?.pageInfo;

  const complex = findById(unit.complexId);
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const viewerAdmin = hasRole ? 'ADMIN' : undefined;
  const viewerRole = getUnitRole(unit) || viewerAdmin;

  const deleteContact = async (id: string) => {
    await client
      .mutate({
        mutation: DeleteUnitContactDocument,
        variables: {id: id},
        refetchQueries: [{query: SingleUnitContactsDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.deleteUnitContact?.success) {
          message.success('Se ha eliminado el contacto con éxito.');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al eliminar',
          description: 'No se pudo eliminar el contacto. Intenta más tarde.',
        });
      });
  };

  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Ver anteriores...
        </Button>
      </div>
    ) : null;

  const deleteContactButton = (id?: string) => {
    if (!id) return undefined;

    return (
      <Popconfirm
        key="delConfirm"
        placement="topRight"
        title="¿Eliminar este contacto? "
        description="No se podrá recuperar la información"
        onConfirm={() => deleteContact(id)}
        okText={'Eliminar'}
        okType="danger"
        cancelText="Cancelar"
      >
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  return (
    <>
      <List
        loadMore={loadMore}
        itemLayout="horizontal"
        loading={loading}
        locale={{emptyText: <Empty description="Sin contactos no registrados" />}}
        dataSource={unitContacts}
        renderItem={contact => (
          <List.Item key={contact?.id}>
            <List.Item.Meta
              className="comment-meta"
              title={
                <Space>
                  <Typography.Text style={{fontSize: '13px'}} strong>
                    {`${contact?.firstName} ${contact?.lastName}`}{' '}
                    {contact?.description ? `(${contact?.description}) ` : null}
                  </Typography.Text>
                  <Typography.Text type="secondary" style={{fontSize: '11px'}}>
                    {toNowTooltip(contact?.insertedAt)}
                  </Typography.Text>
                </Space>
              }
              avatar={<UserAvatar viewer={contact as unknown as User} />}
              description={
                <Descriptions column={{xs: 1, sm: 1, md: 1, lg: 2}} size="small">
                  {contact?.phone && <Descriptions.Item label="Teléfono">{contact?.phone}</Descriptions.Item>}
                  {contact?.email && <Descriptions.Item label="Correo">{contact.email}</Descriptions.Item>}
                  <Descriptions.Item label="Agregado por">{`${contact?.createdBy?.firstName} ${contact?.createdBy?.lastName}`}</Descriptions.Item>
                </Descriptions>
              }
            />
            <Space>{viewerRole && deleteContactButton(contact?.id)}</Space>
          </List.Item>
        )}
      />
    </>
  );
};

export default MemberList;
