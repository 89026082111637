import client from '@/graphql';
import {AvatarFile, File as gqlFile, Maybe, UploadFileDocument, User} from '@gql/graphql';
import {DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import {App, Button, Upload as AntdUpload} from 'antd';
import {RcFile, UploadChangeParam} from 'antd/es/upload';
import {Fragment, useState} from 'react';
import UserAvatar from '../UserAvatar';
import './UploadCover.css';

interface UploadAvatarProps {
  value?: gqlFile;
  onChange?: (value: gqlFile | undefined | null) => void;
  viewer?: Maybe<User>;
}

const UploadAvatar: React.FC<UploadAvatarProps> = ({value, onChange, viewer}) => {
  const [avatarData, setAvatarData] = useState<gqlFile | undefined>(value);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [removed, setRemoved] = useState(false);
  const {message} = App.useApp();

  let avatarURL = avatarData?.urls as AvatarFile;

  function beforeCoverUpload(file: RcFile) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJPG) {
      message.error('Solo puedes subir imágenes .jpg o .png');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('La imagene debe tener un tamaño menor a 5MB');
    }
    return isJPG && isLt5M;
  }

  const handleAvatarChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      setUploadingAvatar(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadingAvatar(false);
      setRemoved(false);
      setAvatarData(info.file.response.data.uploadFile);
      onChange?.(info.file.response.data.uploadFile.id);
    }
  };

  const handleAvatarRemove = () => {
    setUploadingAvatar(false);
    setRemoved(true);
    setAvatarData(undefined);
    onChange?.(null);
  };
  let src = avatarURL ? avatarURL.thumb : undefined;
  if (removed) src = null;

  return (
    <Fragment>
      <UserAvatar viewer={viewer} src={src} size={64} />
      <AntdUpload
        name="avatar"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeCoverUpload}
        accept=".jpg, .jpeg, .png"
        onChange={handleAvatarChange}
        customRequest={({file, onError, onSuccess}) => {
          client
            .mutate({
              mutation: UploadFileDocument,
              variables: {file: file, type: 'AVATAR'},
            })
            .then(result => {
              if (onSuccess) onSuccess(result);
            })
            .catch(onError);

          return {
            abort() {
              console.warn('Upload was aborted.');
            },
          };
        }}
      >
        {((!avatarData && !viewer?.avatar) || removed) && (
          <div>
            <Button loading={uploadingAvatar} size="small" style={{marginLeft: 16, verticalAlign: 'middle'}}>
              {!uploadingAvatar && <UploadOutlined />} Cambiar
            </Button>
          </div>
        )}
      </AntdUpload>
      {(avatarData || avatarURL || viewer?.avatar) && !removed && (
        <Button size="small" style={{marginLeft: 16, verticalAlign: 'middle'}} onClick={handleAvatarRemove}>
          <DeleteOutlined />
        </Button>
      )}
    </Fragment>
  );
};

export default UploadAvatar;
