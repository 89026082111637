import Comments from '@/components/Comments';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import MediaList from '@/components/Media/MediaList';
import PageContent from '@/components/PageContent';
import {avatarConfig} from '@/components/UserAvatar';
import {blue} from '@/constants/colors';
import {petitionPriority, petitionType, PETITION_MAX_MINS_DELETE, priorityData} from '@/constants/petition';
import {rolesString} from '@/constants/roles';
import {fontSize} from '@/utils/theme';
import client from '@/graphql';
import {setDocumentTitle} from '@/hooks/useDocumentTitle';
import {createdAtFormat, diffFromNow} from '@/utils/dates';
import {enumToKV} from '@/utils/enum';
import {findById, getViewer, hasFeature, hasRoles} from '@/services/store';
import {getCreatorComplexRoleStr, getRelatedUnitsNumberLink, getRelatedUnitsNumberStr} from '@/utils/roles';
import {DeleteOutlined, EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {
  DeletePetitionDocument,
  Petition,
  SinglePetitionDocument,
  UpdatePetitionElevatedDocument,
  UpdatePetitionElevatedMutationVariables,
} from '@gql/graphql';
import {IconMessageCircle, IconMessageCircleOff} from '@tabler/icons-react';
import {App, Button, Checkbox, Form, Popconfirm, Popover, Radio, Row, Space, Tag, Tooltip, Typography} from 'antd';
import Markdown from 'marked-react';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Statuses from './Statuses';

const View = () => {
  let {petitionId} = useParams();
  if (!petitionId) return <NotFound />;

  const viewer = getViewer();
  const navigate = useNavigate();
  const {message, notification} = App.useApp();
  const [popoverPriority, setPopoverPriority] = useState(false);
  const [popoverPrivacy, setPopoverPrivacy] = useState(false);

  const [updateElevated, {loading: elevatedLoading}] = useMutation(UpdatePetitionElevatedDocument, {
    update: (cache, {data}) => {
      setPopoverPriority(false);
      setPopoverPrivacy(false);

      if (data?.updatePetitionElevated?.id) {
        if (popoverPriority) message.success('Prioridad actualizada');
        if (popoverPrivacy) message.success('Privacidad actualizada');
        if (!popoverPriority && !popoverPrivacy) message.success('Ajuste actualizado');
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  const {loading, error, data, refetch} = useQuery(SinglePetitionDocument, {
    variables: {id: petitionId},
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const petition = data.node as Petition;
  setDocumentTitle(petition.title);

  const complex = findById(petition.complexId);
  const isDoormanEnabled = complex ? hasFeature(complex, 'PETITIONS_DOORMAN') : false;

  const displayDate = createdAtFormat(petition.insertedAt);
  const createdSinceMins = Math.abs(diffFromNow(petition.insertedAt, 'minutes'));

  const isCreator = petition.createdBy?.id == viewer?.id;
  const canDelete = createdSinceMins < PETITION_MAX_MINS_DELETE && isCreator;
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;

  const creator = petition.createdBy ? petition.createdBy : undefined;
  let creatorRoleComplex: string | undefined = getCreatorComplexRoleStr({
    viewer: petition.createdBy,
    roles: petition.complex?.roles,
  });

  let creatorRoleUnit = undefined;
  if (!creatorRoleComplex && petition.createdBy?.unitsRelated) {
    creatorRoleUnit = hasRole
      ? getRelatedUnitsNumberLink(petition.createdBy.unitsRelated)
      : getRelatedUnitsNumberStr(petition.createdBy.unitsRelated);
  }

  const creatorAvatar = avatarConfig({viewer: creator, size: 'default'});

  const deletePetition = async () => {
    await client
      .mutate({
        mutation: DeletePetitionDocument,
        variables: {id: petition.id},
      })
      .then(({data}) => {
        if (data?.deletePetition?.success) {
          notification.success({
            message: 'Solicitud eliminada',
            description: 'Se ha eliminado la solicitud con éxito.',
          });
          navigate('/petition');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al eliminar',
          description: 'No se pudo eliminar la solicitud. Intenta más tarde.',
        });
      });
  };

  const deleteButton = canDelete ? (
    <Popconfirm
      key="deleteButton"
      placement="bottomRight"
      title="¿Eliminar solicitud?"
      description={`Tienes ${PETITION_MAX_MINS_DELETE - Math.round(createdSinceMins)} mins. para hacerlo.`}
      onConfirm={deletePetition}
      okText="Eliminar"
      okType="danger"
      cancelText="Cancelar"
    >
      <Tooltip title="Eliminar" placement="leftTop">
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </Popconfirm>
  ) : undefined;

  const chagePriorityContent = (
    <Form
      onFinish={(values: any) =>
        updateElevated({
          variables: {id: petition.id, priority: parseInt(values.priority)} as UpdatePetitionElevatedMutationVariables,
        })
      }
      initialValues={{priority: petition.priority?.toString()}}
    >
      <Form.Item noStyle name="priority" rules={[{required: true, message: 'Debes escoger una prioridad'}]}>
        <Radio.Group size="middle">
          <Space size={0} direction="vertical">
            {petitionPriority.map(prior => (
              <Radio key={prior.value.toString()} value={prior.value.toString()}>
                {prior.string}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Row justify="end" style={{marginTop: '15px'}}>
        <Button size="small" type="link" onClick={() => setPopoverPriority(false)}>
          Cancelar
        </Button>
        <Button type="primary" size="small" style={{marginRight: 8}} htmlType="submit" loading={elevatedLoading}>
          Guardar
        </Button>
      </Row>
    </Form>
  );

  const canChangePriority = hasRole && petition?.priority !== undefined;
  const chagePriorityButton = !canChangePriority ? undefined : (
    <Popover
      key="chagePriorityButton"
      title="Prioridad"
      placement="bottomRight"
      trigger="click"
      content={chagePriorityContent}
      open={popoverPriority}
      onOpenChange={visible => setPopoverPriority(visible)}
    >
      <Tooltip title={priorityData(petition.priority as any)?.string} placement="topLeft" mouseLeaveDelay={0}>
        <Button>{priorityData(petition.priority as any)?.icon}</Button>
      </Tooltip>
    </Popover>
  );
  const chagePrivacyContent = (
    <Form
      onFinish={(values: any) =>
        updateElevated({
          variables: {id: petition.id, privacy: values.privacy} as UpdatePetitionElevatedMutationVariables,
        })
      }
      initialValues={{privacy: petition.privacy}}
    >
      <Form.Item noStyle name="privacy" rules={[{required: true, message: 'Debes escoger una prioridad'}]}>
        <Checkbox.Group>
          <Space size={0} direction="vertical">
            {enumToKV(rolesString).map(({key, value}) => {
              if (!isDoormanEnabled && key == 'DOORMAN') {
                return undefined;
              }

              return (
                <Checkbox key={key} value={key} disabled={key == 'ADMIN'}>
                  {value}
                </Checkbox>
              );
            })}
          </Space>
        </Checkbox.Group>
      </Form.Item>
      <Row justify="end" style={{marginTop: '15px'}}>
        <Button size="small" type="link" onClick={() => setPopoverPrivacy(false)}>
          Cancelar
        </Button>
        <Button type="primary" size="small" style={{marginRight: 8}} htmlType="submit" loading={elevatedLoading}>
          Guardar
        </Button>
      </Row>
    </Form>
  );

  const canChangePrivacy = hasRole && petition?.privacy !== undefined;
  const hasMultiplePrivacy = petition.privacy?.length && petition.privacy.length > 1 ? true : false;
  const chagePrivacyButton = !canChangePrivacy ? undefined : (
    <Popover
      key="chagePrivacyButton"
      title="¿Quién puede ver esto?"
      placement="bottomRight"
      trigger="click"
      content={chagePrivacyContent}
      open={popoverPrivacy}
      onOpenChange={visible => setPopoverPrivacy(visible)}
    >
      <Tooltip
        title={hasMultiplePrivacy ? 'Visible para varios' : 'Visible para ti'}
        placement="topLeft"
        mouseLeaveDelay={0}
      >
        <Button>{hasMultiplePrivacy ? <EyeOutlined color={blue} /> : <EyeInvisibleOutlined />}</Button>
      </Tooltip>
    </Popover>
  );

  const commentSettingsButton = !hasRole ? undefined : (
    <Popconfirm
      key="commConfirm"
      placement="bottomRight"
      title={petition.allowComments ? '¿Desactivar comentarios?' : '¿Activar comentarios?'}
      description={
        petition.allowComments ? 'No se permitirán nuevos comentarios.' : 'Se permitirán nuevos comentarios.'
      }
      onConfirm={() =>
        updateElevated({
          variables: {
            id: petition.id,
            allowComments: !petition.allowComments,
          } as UpdatePetitionElevatedMutationVariables,
        })
      }
      okText={petition.allowComments ? 'Desactivar' : 'Activar'}
      cancelText="Cancelar"
    >
      <Tooltip
        title={petition.allowComments ? 'Comentarios activados' : 'Comentarios desactivados'}
        placement="topLeft"
      >
        <Button type="default">
          {petition.allowComments ? <IconMessageCircle size={fontSize} /> : <IconMessageCircleOff size={fontSize} />}
        </Button>
      </Tooltip>
    </Popconfirm>
  );

  const statusTag = petition.type ? [<Tag key={petition.id}>{petitionType[petition.type]}</Tag>] : undefined;
  const creatorRole =
    creatorRoleComplex || creatorRoleUnit ? (
      <Typography.Text italic>
        {creatorRoleComplex || creatorRoleUnit}
        <br />
      </Typography.Text>
    ) : undefined;

  return (
    <>
      <PageContent
        header={{
          title: petition.title,
          onBack: () => navigate('/petition'),
          avatar: creatorAvatar,
          extra: [deleteButton, commentSettingsButton, chagePrivacyButton, chagePriorityButton],
          tags: statusTag,
        }}
      >
        <Markdown value={petition.body ? petition.body : undefined} />
        <MediaList files={petition.files} />

        <Typography.Text italic style={{marginTop: '15px'}}>
          {petition.createdBy?.firstName} {petition.createdBy?.lastName} <br />
        </Typography.Text>
        {creatorRole}
        <Typography.Text italic>{displayDate}</Typography.Text>
      </PageContent>
      <Statuses petition={petition} />
      <Comments resource={petition} hasRights={hasRole} />
    </>
  );
};

export default View;
