import { Button, Card, Col, Result, Row } from "antd";
import { Fragment } from "react";

const ErrorNetwork = ({ refetch }: { refetch: Function }) => {
  return (
    <Fragment>
      <Row justify="center">
        <Col className="max-content-width">
          <Card style={{ textAlign: "center" }} bordered={false}>
            <Result
              status="warning"
              title="Revisa tu conexión a internet"
              subTitle="Parece que tienes problemas con la red. Revisa tu conexión o intenta más tarde."
              extra={
                !refetch ? undefined : (
                  <Button type="primary" onClick={() => refetch()}>
                    Reintentar
                  </Button>
                )
              }
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ErrorNetwork;
