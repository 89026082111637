import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {vehicleType} from '@/constants/vehicle';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasRoles, hasSuperRole, selectedComplex, selectedComplexFilter} from '@/services/store';
import {toSnakeCase} from '@/utils/case';
import {toNowTooltip} from '@/utils/dates';
import {enumToAllIndex} from '@/utils/enum';
import {titleLoading} from '@/utils/misc';
import {getFullOne} from '@/utils/names';
import {FilterOutlined, LeftOutlined, RightOutlined, SearchOutlined} from '@ant-design/icons';
import {makeVar, useQuery, useReactiveVar} from '@apollo/client';
import {
  Vehicle,
  VehicleEdge,
  VehicleFilter,
  VehiclesDocument,
  VehicleSort,
  VehiclesQueryVariables,
  VehicleType,
} from '@gql/graphql';
import {Button, Col, Empty, Input, InputRef, Row, Skeleton, Table, Typography} from 'antd';
import {ColumnsType, TableProps} from 'antd/es/table';
import {ColumnType, FilterValue, SorterResult} from 'antd/es/table/interface';
import {useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';

const {Text} = Typography;

type DataIndex = keyof Vehicle;

interface tableFilterType {
  plate: FilterValue | null | undefined;
  type: VehicleType[] | undefined;
}

const emptyFilter: tableFilterType = {
  plate: undefined,
  type: undefined,
};

interface tableOrderType {
  field?: string;
  direction?: string;
}

const emptyOrder: tableOrderType = {
  field: undefined,
  direction: undefined,
};

const graphFilterStore = makeVar<tableFilterType>(emptyFilter);
const graphOrderStore = makeVar<tableOrderType>(emptyOrder);
const cursorStackStore = makeVar<string[]>([]);
const afterStore = makeVar<string | null>(null);
const title = 'Vehículos';
const VehiclesList = () => {
  useDocumentTitle(title);
  const searchInput = useRef<InputRef>(null);
  const [firstLoad, setFirstLoad] = useState<Array<VehicleEdge>>();

  useReactiveVar(selectedComplex);
  useReactiveVar(graphFilterStore);
  useReactiveVar(graphOrderStore);
  useReactiveVar(cursorStackStore);
  useReactiveVar(afterStore);

  const graphFilters = graphFilterStore();
  const graphOrder = graphOrderStore();
  const cursorStack = cursorStackStore();
  const after = afterStore();

  const clearAllFilters = () => {
    graphFilterStore(emptyFilter);
    graphOrderStore(emptyOrder);
    resetPagination();
  };

  const hasFilters = graphFilters && !Object.values(graphFilters).every(el => el === undefined);
  const hasOrder = graphOrder && !Object.values(graphOrder).every(el => el === undefined);

  const superRight = hasSuperRole(['ADMIN']);
  const hasRole = hasRoles(['ADMIN']) || superRight;
  const multipleComplex = selectedComplex().length !== 1 || superRight;

  const clearAllButton =
    hasFilters || hasOrder ? (
      <Button onClick={clearAllFilters} key="clear">
        <FilterOutlined /> Limpiar filtros
      </Button>
    ) : undefined;

  let headButtons = [clearAllButton];

  const highlight = (searchTerm: any, toHighlight: any) => {
    if (!searchTerm || searchTerm.length == 0) return searchTerm;
    return (
      <Highlighter
        autoEscape
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchTerm]}
        textToHighlight={toHighlight ? toHighlight.toString() : ''}
      />
    );
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Vehicle> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
      return (
        <div style={{padding: 8}}>
          <Input
            ref={searchInput}
            placeholder="Buscar"
            value={selectedKeys as string[]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm({closeDropdown: true})}
            style={{width: 188, marginBottom: 8, display: 'block'}}
          />
          <Button
            type="primary"
            onClick={() => confirm({closeDropdown: true})}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90, marginRight: 8}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) handleResetSearch(clearFilters);
            }}
            size="small"
            style={{width: 90}}
          >
            Limpiar
          </Button>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record, index) => {
      if (dataIndex == 'plate') {
        const term = graphFilters.plate ?? undefined;
        const plate = term ? highlight(term, text) : text;
        return plate;
      }
    },
  });

  const handleResetSearch = (clearFilters: Function) => {
    clearFilters({confirm: true, closeDropdown: true});
  };

  const resetPagination = () => {
    cursorStackStore([]);
    afterStore(undefined);
  };

  const handleTableChange: TableProps<Vehicle>['onChange'] = (pagination, filters, sorter) => {
    const newSorter = sorter as SorterResult<Vehicle>;
    const newFilter = {...emptyFilter};
    for (const key in filters) {
      const value = filters[key];
      if (value !== null && value.length > 0) {
        switch (key) {
          case 'plate':
            newFilter.plate = Array.isArray(value) ? (value[0] as any) : value;
            break;

          default:
            newFilter[key as keyof typeof newFilter] = value as any;
            break;
        }
      }
    }

    if (graphFilters != newFilter) {
      resetPagination();
    }
    graphFilterStore(newFilter);

    if (newSorter.order) {
      const orderField = toSnakeCase(newSorter.columnKey as string).toUpperCase();
      let orderDirection = newSorter.order as string;
      orderDirection = orderDirection == 'descend' ? 'DESC' : 'ASC';
      graphOrderStore({field: orderField, direction: orderDirection});
    } else {
      graphOrderStore(undefined);
    }
  };

  const expandedRowRender = (record: Vehicle) => {
    const columns: ColumnsType<Vehicle> = [
      {
        title: 'Marca',
        dataIndex: 'brand',
        key: 'brand',
        render: text => (text ? text : '—'),
      },
      {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
        render: text => (text ? text : '—'),
      },
      {
        title: 'Añadido',
        dataIndex: 'insertedAt',
        key: 'insertedAt',
        render: text => (text ? toNowTooltip(text) : '—'),
      },
      {
        title: 'Añadido por',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: text => getFullOne(text),
      },
    ];

    return (
      <Table columns={columns} size="small" rowKey={record => record.id} dataSource={[record]} pagination={false} />
    );
  };

  let columns: ColumnsType<Vehicle> = [
    {
      title: 'Placa',
      dataIndex: 'plate',
      key: 'plate',
      filteredValue: graphFilters.plate || null,
      ...getColumnSearchProps('plate'),
    },
    multipleComplex
      ? {
          title: 'Conjunto',
          dataIndex: ['unit', 'complex'],
          key: 'complex',
          filteredValue: null,
          render: text => (text ? text.name : '—'),
        }
      : {},
    {
      title: 'Propiedad',
      dataIndex: ['unit', 'number'],
      key: 'unit',
      render: (value, record) => <Link to={'/unit/' + record.unit?.id}>{record.unit?.number}</Link>,
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      filteredValue: graphFilters?.type || null,
      filters: enumToAllIndex(vehicleType).map(value => {
        return {
          text: vehicleType[value as VehicleType],
          value: value,
        };
      }),
      render: (text: string) => vehicleType[text],
    },
  ];

  columns = columns.filter(item => item.title);

  const pageSize = 20;
  const complexes = {complexes: selectedComplexFilter()};
  const queryVariables = {
    first: pageSize,
    after: after,
    filter: graphFilters ? ({...graphFilters, ...complexes} as VehicleFilter) : {complexes},
    order: graphOrder?.field ? {...(graphOrder as VehicleSort)} : undefined,
  } as VehiclesQueryVariables;

  const {loading, error, data, refetch} = useQuery(VehiclesDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });
  if (!hasRole) return <Error403 />;
  if (loading && !firstLoad && !data) return <Skeleton paragraph={{rows: 4}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (data?.vehicles?.edges && data.vehicles.edges != firstLoad)
    setFirstLoad(data.vehicles.edges as Array<VehicleEdge>);
  if (!data?.vehicles?.edges && !firstLoad) return <NotFound />;
  if (!firstLoad) return <NotFound />;

  const edges = firstLoad;
  const vehicles = edges.map((edge: VehicleEdge) => edge?.node as Vehicle);
  const totalCount = data?.vehicles?.total ? data?.vehicles?.total : 0;
  const hasNextPage = data?.vehicles?.pageInfo.hasNextPage;
  const endCursor = data?.vehicles?.pageInfo.endCursor;

  const prevPage = () => {
    let existingStack = cursorStack;
    existingStack.pop();
    cursorStackStore(existingStack);
    let lastEndCursor = existingStack.length > 0 ? existingStack[existingStack.length - 1] : undefined;
    afterStore(lastEndCursor);
  };

  const nextPage = () => {
    let existingStack = cursorStack;
    if (endCursor) existingStack.push(endCursor);
    cursorStackStore(existingStack);
    afterStore(endCursor);
  };

  const paginationButtons = () => (
    <Row justify="end">
      <Col>
        <Button onClick={prevPage} size="small" key="prev" type="link" disabled={cursorStack.length == 0}>
          <LeftOutlined />
          Anterior
        </Button>{' '}
        <Text type="secondary">
          {cursorStack.length + 1}/{Math.max(1, Math.ceil(totalCount / pageSize))}
        </Text>{' '}
        <Button onClick={nextPage} size="small" key="next" type="link" disabled={!hasNextPage}>
          Siguiente
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <PageContent header={{title: titleLoading(title, loading), extra: [headButtons]}}>
        <Table<Vehicle>
          footer={() => paginationButtons()}
          pagination={false}
          columns={columns}
          expandedRowRender={record => expandedRowRender(record)}
          dataSource={vehicles}
          onChange={handleTableChange}
          rowKey="id"
          size="middle"
          scroll={{x: true}}
          locale={{
            emptyText: <Empty description="Sin vehículos"></Empty>,
          }}
        />
      </PageContent>
    </>
  );
};

export default VehiclesList;
