import client from '@/graphql';
import {hasRoles, selectedComplex} from '@/services/store';
import {PlusCircleOutlined, PushpinTwoTone} from '@ant-design/icons';
import {useReactiveVar} from '@apollo/client';
import {BillboardEdge, BillboardFile, DeleteBillboardDocument, HomeQuery} from '@gql/graphql';
import {App, Card, Carousel, Empty, Image, Popconfirm, Skeleton} from 'antd';
import {Fragment} from 'react';
import {Link} from 'react-router-dom';

const BillboardsWidget = ({data, refetch}: {data: HomeQuery; refetch: () => void}) => {
  useReactiveVar(selectedComplex);
  const canCreate = hasRoles(['ADMIN', 'COUNCIL']);

  return (
    <Card
      title={
        <Fragment>
          <PushpinTwoTone /> Carteleras
        </Fragment>
      }
      bordered={false}
      style={{marginBottom: '24px'}}
      extra={
        canCreate ? (
          <Link to={'billboard/new'}>
            <PlusCircleOutlined /> Nueva
          </Link>
        ) : undefined
      }
    >
      <div style={{textAlign: 'center'}}>
        <Content data={data} refetch={refetch} />
      </div>
    </Card>
  );
};

const Content = ({data, refetch}: {data: HomeQuery; refetch: () => void}) => {
  const {message, notification} = App.useApp();

  if (!data?.billboards?.edges) return <Skeleton active />;

  const billboardList = data.billboards?.edges.filter((edge): edge is BillboardEdge => edge !== null);
  if (billboardList.length == 0) return <Empty description="Sin carteleras" />;

  return (
    <Image.PreviewGroup>
      <Carousel autoplay /*infinite={false}*/ dots={{className: 'carousel-dots'}}>
        {billboardList.map(({node}) => {
          const links = node?.file?.urls as BillboardFile;
          if (!links.billboard) return <Fragment></Fragment>;
          return (
            <Popconfirm
              key={node?.id}
              placement="left"
              title="¿Eliminar esta cartelera?"
              okText="Eliminar"
              cancelText="Cancelar"
              trigger="contextMenu"
              onConfirm={() => {
                client
                  .mutate({
                    mutation: DeleteBillboardDocument,
                    variables: {id: node!.id},
                  })
                  .then(({data}) => {
                    message.success('Se ha eliminado la cartelera');
                    refetch();
                  })
                  .catch(() => {
                    notification.error({
                      message: 'Error al eliminar',
                      description: 'No se pudo eliminar la cartelera. Intenta más tarde.',
                    });
                  });
              }}
            >
              <Image src={links.billboard} />
            </Popconfirm>
          );
        })}
      </Carousel>
    </Image.PreviewGroup>
  );
};

export default BillboardsWidget;
