import Error403 from '@/components/loaders/Error403';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import UserSearch from '@/components/UserSearch';
import {rolesComplexString} from '@/constants/roles';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {hasRoles, selectedComplex} from '@/services/store';
import {useMutation, useReactiveVar} from '@apollo/client';
import {SetComplexRoleDocument} from '@gql/graphql';
import {Button, Form, notification, Select} from 'antd';
import {useNavigate} from 'react-router-dom';

const title = 'Nuevo rol';

const MemberNew = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const complexes = useReactiveVar(selectedComplex);
  let complex = complexes[0];
  const [setComplexRole, {loading: newLoading}] = useMutation(SetComplexRoleDocument, {
    update: (cache, {data}) => {
      if (data?.setComplexRole?.id) {
        notification.success({
          message: 'Añadido con éxito',
          description: 'Se ha creado al nuevo miembro.',
        });
        navigate('/complex/role');
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (complexes.length > 1) return <SelectComplex />;
  if (!hasRoles(['ADMIN', 'COUNCIL'])) return <Error403 />;

  const onInputFinish = async (values: any) => {
    setComplexRole({variables: {complexId: complex.id, ...values}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/complex/role')}}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item {...formItemLayout} label="Rol" name="role" rules={[{required: true, message: 'Selecciona un rol'}]}>
          <Select
            style={{maxWidth: 300}}
            options={enumToKV(rolesComplexString).map(({key, value}) => {
              return {value: key, label: value};
            })}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Usuario"
          name="userId"
          rules={[{required: true, message: 'Selecciona un usuario'}]}
        >
          <UserSearch />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default MemberNew;
