import Error403 from '@/components/loaders/Error403';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import UserSearch from '@/components/UserSearch';
import {restrictionTypes} from '@/constants/restrictions';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {nowPlus} from '@/utils/dates';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {hasRoles, selectedComplex} from '@/services/store';
import {useMutation, useReactiveVar} from '@apollo/client';
import {CreateComplexRestrictionDocument} from '@gql/graphql';
import {Button, Form, Input, notification, Select} from 'antd';
import {useNavigate} from 'react-router-dom';
const title = 'Nueva restricción';

const RestrictionNew = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const complexes = useReactiveVar(selectedComplex);
  let complex = complexes[0];
  const [createComplexRestriction, {loading: newLoading}] = useMutation(CreateComplexRestrictionDocument, {
    update: (cache, {data}) => {
      if (data?.createComplexRestriction?.id) {
        notification.success({
          message: 'Creada con éxito',
          description: 'Se ha limitado al usuario.',
        });
        navigate('/complex/restriction');
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (complexes.length > 1) return <SelectComplex />;
  if (!hasRoles(['ADMIN', 'COUNCIL'])) return <Error403 />;

  const onInputFinish = async (values: any) => {
    createComplexRestriction({variables: {complexId: complex.id, ...values}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/complex/restriction')}}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          {...formItemLayout}
          label="Restringir"
          name="type"
          extra="Selecciona la función que deseas limitar"
          rules={[{required: true, message: 'Selecciona un rol'}]}
        >
          <Select
            style={{maxWidth: 300}}
            options={enumToKV(restrictionTypes).map(({key, value}) => {
              return {value: key, label: value};
            })}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Usuario"
          name="userId"
          rules={[{required: true, message: 'Selecciona un usuario'}]}
        >
          <UserSearch />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Duración"
          name="expire"
          extra="La restricción se removerá automáticamente después de este tiempo"
          rules={[{required: true, message: 'Selecciona una duración'}]}
        >
          <Select
            style={{maxWidth: 120}}
            options={[
              {value: nowPlus(1, 'day').toISOString(), label: '1 día'},
              {value: nowPlus(7, 'days').toISOString(), label: '7 días'},
              {value: nowPlus(1, 'month').toISOString(), label: '1 mes'},
              {value: nowPlus(3, 'months').toISOString(), label: '3 meses'},
              {value: nowPlus(1, 'year').toISOString(), label: '1 año'},
            ]}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Nota"
          name="description"
          extra="Escribe la razón de la restricción"
          rules={[
            {required: true, message: 'Escribe una nota'},
            {max: 200, message: 'Demasiado larga'},
          ]}
        >
          <Input style={{maxWidth: 300}} />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default RestrictionNew;
