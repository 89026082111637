export const pollTagColor = {
  ACTIVE: "green",
  CLOSED: "gold",
  EXPIRED: "gold",
};

export const pollTagString = {
  ACTIVE: "Activa",
  CLOSED: "Terminada",
  EXPIRED: "Terminada",
};

export const pollPrivacyStr = {
  ANONYMOUS: "anónima",
  PRIVATE: "privada",
  PUBLIC: "publica",
};

export const pollPrivacyDesc = {
  ANONYMOUS: "La admón. ni nadie podrá ver por cuál opción votas",
  PRIVATE: "Únicamente la admón. puede ver por cuál opción votas",
  PUBLIC: "Admón. y residentes pueden ver por cuál opción votas",
};

export const AnswersType = {
  ownersDistinc: "OWNERS_DISTINC",
  usersDistinc: "USERS_DISTINC",
};
