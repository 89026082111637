import {makeVar, useReactiveVar} from '@apollo/client';

import {primary} from '@/constants/colors';
import {theme} from 'antd';

export const themeMode = makeVar<string>(localStorage.getItem('themeMode') || 'LIGHT');
export const fontSize = 16;

export const antdConfig = (newTheme: string) => {
  const customPrimary = newTheme == 'LIGHT' ? primary : '#1890ff';
  return {
    algorithm: newTheme == 'LIGHT' ? theme.defaultAlgorithm : theme.darkAlgorithm,
    token: {
      colorPrimary: customPrimary,
      fontSize: fontSize,
      colorLink: customPrimary,
    },
  };
};
