import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasRoles, selectedComplex, selectedComplexFilter} from '@/services/store';
import {PlusCircleOutlined} from '@ant-design/icons';
import {useQuery, useReactiveVar} from '@apollo/client';
import Error403 from '@comp/loaders/Error403';
import {Building, BuildingsDocument, BuildingsQueryVariables} from '@gql/graphql';
import {Button, Empty, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {Link, useNavigate} from 'react-router-dom';
const title = 'Edificios';
const List = () => {
  useDocumentTitle(title);
  useReactiveVar(selectedComplex);
  const navigate = useNavigate();
  if (!hasRoles(['ADMIN', 'COUNCIL'])) return <Error403 />;

  const queryVariables = {first: 100, filter: {complexes: selectedComplexFilter()}} as BuildingsQueryVariables;
  const {loading, error, data, refetch, fetchMore} = useQuery(BuildingsDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading && !data) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.buildings?.edges) return <NotFound />;

  const building = data.buildings.edges.map(edge => edge?.node as Building);
  const hasRole = hasRoles(['ADMIN']);

  const columns: ColumnsType<Building> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => <Link to={'/complex/building/' + record.id + '/edit'}>{text}</Link>,
    },
    {
      title: 'Conjunto',
      dataIndex: ['complex', 'name'],
      key: 'complex',
    },
    {
      title: 'Propiedades',
      dataIndex: ['units', 'total'],
      key: 'units',
    },
  ];

  const createButton = hasRole ? (
    <Button onClick={() => navigate('/complex/building/new')} key="add" type="primary">
      <PlusCircleOutlined /> Nuevo
    </Button>
  ) : undefined;

  let headButtons = [createButton];

  return (
    <>
      <PageContent header={{title: title, extra: [headButtons]}}>
        <Table<Building>
          columns={columns}
          size="small"
          pagination={false}
          rowKey={record => record.id}
          dataSource={building}
          locale={{emptyText: <Empty description="Sin edificios"></Empty>}}
        />
      </PageContent>
    </>
  );
};

export default List;
