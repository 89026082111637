interface IDictionary {
  [index: string]: string;
}

interface IDictionaryArr {
  [index: string]: string[];
}

interface IDictionaryObj {
  [index: string]: IDictionaryArr;
}

export const superRoles: IDictionary = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  SUPPORT: 'Soporte',
  USER: 'Usuario',
};

export const userStatus: IDictionary = {
  UNCONFIRMED: 'Sin confirmar',
  DELETED: 'Eliminado',
  SUSPENDED: 'Suspendido',
  ACTIVE: 'Activo',
};

export const rolesString: IDictionary = {
  ADMIN: 'Administrador',
  COUNCIL: 'Consejo',
  DOORMAN: 'Portero',
  OWNER: 'Propietario',
  HABITANT: 'Residente',
  HOUSEHOLDER: 'Arrendatario',
};

export const rolesComplexString: IDictionary = {
  ADMIN: 'Administrador',
  COUNCIL: 'Consejo',
  DOORMAN: 'Portero',
};

export const unitRoleRights: IDictionaryObj = {
  ADMIN: {
    ADMIN: ['OWNER', 'HOUSEHOLDER', 'HABITANT'],
    HABITANT: ['OWNER', 'HOUSEHOLDER', 'HABITANT'],
    HOUSEHOLDER: ['OWNER', 'HOUSEHOLDER', 'HABITANT'],
    OWNER: ['OWNER', 'HOUSEHOLDER', 'HABITANT'],
  },
  OWNER: {
    HABITANT: ['OWNER', 'HOUSEHOLDER', 'HABITANT'],
    HOUSEHOLDER: ['OWNER', 'HOUSEHOLDER', 'HABITANT'],
    OWNER: ['OWNER', 'HOUSEHOLDER', 'HABITANT'],
  },
  HOUSEHOLDER: {
    HABITANT: ['HOUSEHOLDER', 'HABITANT'],
    HOUSEHOLDER: ['HOUSEHOLDER', 'HABITANT'],
    OWNER: [],
  },
  HABITANT: {
    HABITANT: ['HABITANT'],
    HOUSEHOLDER: [],
    OWNER: [],
  },
};
