import SingleComment from '@/components/Comments/SingleComment';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import {selectedComplex, selectedComplexIDs} from '@/services/store';
import {MessageTwoTone} from '@ant-design/icons';
import {useQuery, useReactiveVar} from '@apollo/client';
import {
  PetitionComment,
  PetitionCommentsDocument,
  PetitionCommentsQueryVariables,
  PetitionCommentStatus,
} from '@gql/graphql';
import {Button, Card, Empty, List, Tooltip} from 'antd';
import {Link} from 'react-router-dom';

const RecentComments = () => {
  useReactiveVar(selectedComplex);

  const queryVariables = {
    first: 5,
    filter: {complexes: selectedComplexIDs(), status: ['PUBLISHED'] as PetitionCommentStatus[]},
  } as PetitionCommentsQueryVariables;
  const {loading, error, data, refetch, fetchMore} = useQuery(PetitionCommentsDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data) return <LoadingSkeleton active avatar />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.petitionComments) return <NotFound />;
  const item = data.petitionComments;
  const nodes = item.edges?.map(edge => edge?.node);
  const pageInfo = item.pageInfo;

  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
            })
          }
        >
          Cargar más...
        </Button>
      </div>
    ) : null;

  return (
    <Card
      title={
        <>
          <MessageTwoTone /> Comentarios recientes
        </>
      }
      bordered={false}
      style={{marginBottom: '30px'}}
    >
      <List
        loadMore={loadMore}
        itemLayout="horizontal"
        loading={loading}
        locale={{emptyText: <Empty description="Sin comentarios" />}}
        dataSource={nodes}
        renderItem={comment => (
          <SingleComment
            comment={comment as PetitionComment}
            customExtra={
              <Link to={'/petition/' + comment?.petition?.id}>
                <Tooltip placement="topRight" title={comment?.petition?.title}>
                  Ver solicitud
                </Tooltip>
              </Link>
            }
          />
        )}
      />
    </Card>
  );
};

export default RecentComments;
