import {blue, gray, green, red, yellow} from './colors';

export const feedbackType = {
  PROBLEM: 'Reportar problema',
  QUESTION: 'Hacer una pregunta',
  SUGGESTION: 'Dejar una sugerencia',
  OTHER: 'Otro',
};

export const feedbackTypeShort = {
  PROBLEM: 'Problema',
  QUESTION: 'Pregunta',
  SUGGESTION: 'Sugerencia',
  OTHER: 'Otro',
};

export const feedbackStatus = {
  OPEN: 'Abierta',
  UNDER_REVIEW: 'En revisión',
  IN_PROGRESS: 'En progreso',
  RESOLVED: 'Solucionado',
  CLOSED: 'Rechazada',
};

export const feedbackStatusColor = {
  OPEN: red,
  UNDER_REVIEW: yellow,
  IN_PROGRESS: blue,
  RESOLVED: green,
  CLOSED: gray,
};
