import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import UserAvatar from '@/components/UserAvatar';
import client from '@/graphql';
import {findById, hasRoles} from '@/services/store';
import {fromNowMaxTooltip} from '@/utils/dates';
import {DeleteOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {DeleteUnitNoteDocument, SingleUnitNotesDocument, SingleUnitNotesQueryVariables, Unit} from '@gql/graphql';
import {App, Button, Empty, List, Popconfirm, Space, Typography} from 'antd';
import {useParams} from 'react-router-dom';

const NoteList = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;
  const {message, notification} = App.useApp();

  const queryVariables = {id: unitId, first: 10} as SingleUnitNotesQueryVariables;
  const {loading, error, data, refetch, fetchMore} = useQuery(SingleUnitNotesDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const unit = data.node as Unit;
  if (!unit) return <NotFound />;

  const nodes = unit.unitNotes?.edges?.map(edge => edge?.node);
  const pageInfo = unit.unitNotes?.pageInfo;

  const complex = findById(unit.complexId);
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Ver anteriores...
        </Button>
      </div>
    ) : null;

  const deleteUnitNote = async (id: string) => {
    await client
      .mutate({
        mutation: DeleteUnitNoteDocument,
        variables: {id: id},
        refetchQueries: [{query: SingleUnitNotesDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.deleteUnitNote?.success) {
          message.success('Se ha eliminado la nota con éxito.');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al eliminar',
          description: 'No se pudo eliminar la nota. Intenta más tarde.',
        });
      });
  };

  const deleteButton = (id?: string) => {
    if (!id) return undefined;

    return (
      <Popconfirm
        key="delConfirm"
        placement="topRight"
        title="¿Eliminar esta nota?"
        description="No se podrá recuperar"
        onConfirm={() => deleteUnitNote(id)}
        okText={'Eliminar'}
        okType="danger"
        cancelText="Cancelar"
      >
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  return (
    <>
      <List
        loadMore={loadMore}
        itemLayout="horizontal"
        loading={loading}
        locale={{emptyText: <Empty description="Sin notas" />}}
        dataSource={nodes}
        renderItem={note => (
          <List.Item key={note?.id}>
            <List.Item.Meta
              className="comment-meta"
              title={
                <Space>
                  <Typography.Text style={{fontSize: '13px'}}>
                    {`${note?.createdBy?.firstName} ${note?.createdBy?.lastName}`}
                  </Typography.Text>
                  <Typography.Text type="secondary" style={{fontSize: '11px'}}>
                    {fromNowMaxTooltip(note?.insertedAt)}
                  </Typography.Text>
                </Space>
              }
              avatar={<UserAvatar viewer={note?.createdBy} />}
              description={<Typography.Text>{note?.note}</Typography.Text>}
            />
            <Space>{hasRole && deleteButton(note?.id)}</Space>
          </List.Item>
        )}
      />
    </>
  );
};

export default NoteList;
