import Error403 from '@/components/loaders/Error403';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import UploadImage from '@/components/Upload/UploadImage';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {buildBuildingList} from '@/utils/misc';
import {hasRoles, selectedComplex} from '@/services/store';
import {useMutation, useReactiveVar} from '@apollo/client';
import {CreateBillboardDocument} from '@gql/graphql';
import {Alert, Button, Form, notification, Select} from 'antd';
import {useNavigate} from 'react-router-dom';

const title = 'Nueva cartelera';

const New = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let complex = useReactiveVar(selectedComplex)[0];
  const [createBillboard, {loading}] = useMutation(CreateBillboardDocument, {
    update: (cache, {data}) => {
      if (data?.createBillboard?.id) {
        notification.success({
          message: 'Creada con éxito',
          description: 'Se ha creado la cartelera.',
        });
        navigate('/');
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (selectedComplex().length !== 1) return <SelectComplex />;
  if (!hasRoles(['ADMIN', 'COUNCIL'])) return <Error403 />;

  const buildingList = buildBuildingList(complex.buildings, true);

  const onInputFinish = async (values: any) => {
    let args = structuredClone(values);
    if (args.buildingId == 'ALL') args.buildingId = null;
    if (args.fileId) args.fileId = args.fileId.id;

    createBillboard({variables: {complexId: complex.id, ...args}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/')}}>
      <Alert
        message="Sube únicamente imágenes (.PNG, .JPG) que sean más altas que anchas. También puedes subir archivos .PDF, si usas Word u otra herramienta para crear las carteleras, puedes exportar el documento a .PDF para usarlo aquí."
        type="info"
        showIcon
      />
      <p> </p>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          buildingId: 'ALL',
        }}
      >
        <Form.Item
          {...formItemLayout}
          label="Cartelera"
          name="fileId"
          extra="Imagen de ilustración visible en la parte superior de la cartelera."
        >
          <UploadImage fileType={'BILLBOARD'} />
        </Form.Item>

        {buildingList && (
          <Form.Item
            {...formItemLayout}
            name="buildingId"
            label="Mostrar en"
            extra="Limita la visibilidad de la cartelera a las propiedades del edificio seleccionado. Úsalo si la cartelera es únicamente de interés para ellos."
          >
            <Select style={{maxWidth: 200}} options={buildingList} />
          </Form.Item>
        )}

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Publicar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default New;
