import LoadingIcon from '@/components/loaders/LoadingIcon';
import client from '@/graphql';
import {fromNow, nowPlus} from '@/utils/dates';
import {BellOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {
  Notification,
  NotificationEdge,
  NotificationsDocument,
  NotificationsQueryVariables,
  ReadAllNotificationsDocument,
  User,
} from '@gql/graphql';
import {Badge, Card, Dropdown, Empty, List, Typography} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import NotificationSound from '../../assets/notification.wav';
const {Text} = Typography;
const audio = new Audio(NotificationSound);

const notificationsRouter = ({type, resourceId, resourceExtra}: Notification) => {
  switch (type) {
    case 'NEWS_NEW':
      return '/news/' + resourceId;

    case 'POLL_NEW':
    case 'POLL_CLOSED':
      return '/poll/' + resourceId;

    case 'PETITION_NEW':
    case 'PETITION_COMMENT_NEW':
    case 'PETITION_STATUS_NEW':
      return '/petiton/' + resourceId;

    case 'UNIT_ROLE_NEW':
    case 'UNIT_ROLE_REMOVE':
    case 'VEHICLE_NEW':
      return '/unit/' + resourceId;

    default:
      return undefined;
  }
};

const after = nowPlus(-7, 'days');
const queryVariables = {
  first: 10,
  filter: {afterOrUnread: after},
  order: {
    direction: 'DESC',
    field: 'INSERTED_AT',
  },
} as NotificationsQueryVariables;

const Notifications = ({viewer}: {viewer: User}) => {
  const navigate = useNavigate();
  const [nonRead, setNonRead] = useState<number | null>(null);
  const {loading, error, data} = useQuery(NotificationsDocument, {
    variables: queryVariables,
    pollInterval: 60000,
    fetchPolicy: 'cache-and-network',
  });

  const loadingElem = (
    <div className="right" style={{marginRight: 7}}>
      <LoadingIcon />
    </div>
  );

  if (loading || error || !data) return loadingElem;
  if (!data.viewer?.notifications?.edges) return loadingElem;

  const items = data.viewer?.notifications?.edges.filter((element): element is NotificationEdge => {
    return element !== null;
  });

  if (nonRead === null) {
    setNonRead(items.length);
  } else if (nonRead !== items.length) {
    if (nonRead < items.length) {
      audio.play();
    }
    setNonRead(items.length);
  }

  return (
    <div className="right" style={{marginRight: 7}}>
      <Dropdown
        trigger={['click']}
        onOpenChange={visible => {
          if (!visible)
            client.mutate({
              mutation: ReadAllNotificationsDocument,
              refetchQueries: [{query: NotificationsDocument, variables: queryVariables}],
            });
        }}
        dropdownRender={menu => (
          <Card bordered={false} size="small" style={{minWidth: 325, width: '100%'}}>
            <List
              className="notifications"
              size="small"
              locale={{
                emptyText: <Empty description="Sin notificaciones recientes"></Empty>,
              }}
              // footer={
              //   <Fragment>
              //     <Button block>Ver todas</Button>
              //   </Fragment>
              // }
              dataSource={items}
              renderItem={item => (
                <List.Item
                  style={{padding: 0, margin: 0, cursor: 'pointer'}}
                  onClick={() => {
                    if (item?.node) {
                      let path = notificationsRouter(item?.node);
                      if (path) navigate(path);
                    }
                  }}
                >
                  <List.Item.Meta
                    title={
                      <Text ellipsis style={{margin: 0}}>
                        {!item?.node?.read && <Badge color="blue" />} {item?.node?.body}
                      </Text>
                    }
                    description={
                      <Text type="secondary" ellipsis style={{margin: 0}}>
                        {`${item?.node?.title} — ${fromNow(item?.node?.insertedAt)}`}
                      </Text>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        )}
      >
        <span className="action">
          <Badge count={data.viewer?.notifications?.unreadCount}>
            <BellOutlined style={{color: 'white'}} />
          </Badge>
        </span>
      </Dropdown>
    </div>
  );
};

export default Notifications;
