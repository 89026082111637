import Comments from '@/components/Comments';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import MediaList from '@/components/Media/MediaList';
import PageContent from '@/components/PageContent';
import {avatarConfig} from '@/components/UserAvatar';
import client from '@/graphql';
import {setDocumentTitle} from '@/hooks/useDocumentTitle';
import {findById, hasRoles, hasSuperRole} from '@/services/store';
import {createdAtFormat, diffFromNow} from '@/utils/dates';
import {getCreatorComplexRoleStr} from '@/utils/roles';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import Reactions from '@comp/Reactions';
import {CoverFile, DeleteNewsDocument, File, News, SingleNewsDocument} from '@gql/graphql';
import {Button, Image, notification, Popconfirm, Tag, Tooltip, Typography} from 'antd';
import Markdown from 'marked-react';
import {useNavigate, useParams} from 'react-router-dom';

const View = () => {
  let {newsId} = useParams();
  if (!newsId) return <NotFound />;

  const navigate = useNavigate();

  const {loading, error, data, refetch} = useQuery(SingleNewsDocument, {
    variables: {id: newsId},
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const news = data.node as News;
  const complex = findById(news.complexId);
  setDocumentTitle(news.title);

  const displayDate = createdAtFormat(news.insertedAt);
  const createdSinceDays = Math.abs(diffFromNow(news.insertedAt, 'days'));

  const hasRole = complex ? hasRoles(['ADMIN', 'COUNCIL'], [complex]) : undefined;
  const canEdit = (createdSinceDays < 15 && hasRole) || hasSuperRole(['ADMIN']);

  const creator = news.createdBy ? news.createdBy : undefined;
  const creatorRoleComplex: string | undefined = getCreatorComplexRoleStr({
    viewer: news.createdBy,
    roles: news.complex?.roles,
  });

  const creatorAvatar = avatarConfig({viewer: creator, size: 'default'});

  const editButton = canEdit ? (
    <Tooltip key="editButton" title="Editar" placement="top">
      <Button type="primary" onClick={() => navigate('/news/' + newsId + '/edit')}>
        <EditOutlined />
      </Button>
    </Tooltip>
  ) : undefined;

  const deleteNews = async () => {
    await client
      .mutate({mutation: DeleteNewsDocument, variables: {id: news.id}})
      .then(({data}) => {
        if (data?.deleteNews?.success) {
          notification.success({
            message: 'Noticia eliminada',
            description: 'Se ha eliminado la noticia con éxito.',
          });
          navigate('/');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al eliminar',
          description: 'No se pudo eliminar la noticia. Intenta más tarde.',
        });
      });
  };

  const deleteContent = canEdit ? (
    <Popconfirm
      key="delConfirm"
      placement="bottomRight"
      title="¿Eliminar noticia?"
      description={'No se podrá recuperar'}
      onConfirm={deleteNews}
      okText="Eliminar"
      okType="danger"
      cancelText="Cancelar"
    >
      <Tooltip title="Eliminar" placement="top">
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </Popconfirm>
  ) : undefined;

  const files = news.files ? (news.files as File[]) : undefined;
  const cover = files?.map(item => {
    if (item.type != 'COVER') return null;
    const links = item.urls as CoverFile;
    if (!links.cover) return null;
    return <Image key={item.id} src={links.cover} preview={false} style={{borderRadius: 7}} />;
  });

  const statusTag = news.status == 'DRAFT' ? [<Tag color="blue">Borrador</Tag>] : undefined;
  const creatorRole = creatorRoleComplex ? (
    <Typography.Text italic>
      {creatorRoleComplex}
      <br />
    </Typography.Text>
  ) : undefined;

  return (
    <>
      <PageContent
        header={{
          title: news.title,
          onBack: () => navigate('/'),
          avatar: creatorAvatar,
          extra: [deleteContent, editButton],
          tags: statusTag,
        }}
      >
        {cover}
        <Markdown value={news.body ? news.body : undefined} gfm={true} breaks={true} />
        <MediaList files={news.files} />

        <Typography.Text italic style={{marginTop: '15px'}}>
          {news.createdBy?.firstName} {news.createdBy?.lastName} <br />
        </Typography.Text>
        {creatorRole}
        <Typography.Text italic>{displayDate}</Typography.Text>
        <Reactions resource={news} />
      </PageContent>
      <Comments resource={news} hasRights={hasRole} />
    </>
  );
};

export default View;
