import ComplexSelector from "@comp/ComplexSelector";
import { Card, Col, Row } from "antd";
import { Fragment } from "react";

const SelectComplex: React.FC = () => {
  return (
    <Fragment>
      <Row justify="center">
        <Col className="max-content-width">
          <Card style={{ textAlign: "center" }} bordered={false}>
            <ComplexSelector />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SelectComplex;
