import client from '@/graphql';
import {refetchViewer, setAccessToken} from '@/services/store';
import {LockOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {
  AvailableLoginMethodsDocument,
  RequestAuthCodeDocument,
  RequestAuthCodeMutationVariables,
  SignInDocument,
  SignUpDocument,
} from '@gql/graphql';
import {Button, Checkbox, Form, Input, Modal, Select, Typography} from 'antd';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './index.css';
const {Text} = Typography;
const Option = Select.Option;
const FormItem = Form.Item;

enum State {
  INPUT,
  SINGUP,
  CODE,
}

const Phone = () => {
  const [formState, setFormState] = useState(State.INPUT);
  const [phoneData, setPhoneData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [timeNewCode, setTimeNewCode] = useState(0);
  const [requestCode, requestCodeResult] = useMutation(RequestAuthCodeDocument);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (timeNewCode > 0) setTimeNewCode(timeNewCode - 1);
    }, 1000);
  });

  const onInputFinish = async (values: any) => {
    setIsLoading(true);

    await client
      .query({
        query: AvailableLoginMethodsDocument,
        variables: values,
        fetchPolicy: 'network-only',
      })
      .then(({data: {availableLoginMethods}}) => {
        setPhoneData(values);
        if (availableLoginMethods?.length && availableLoginMethods.includes('PHONE_CODE')) {
          if (timeNewCode == 0) requestCode({variables: values});
          setFormState(State.CODE);
        } else {
          setFormState(State.SINGUP);
        }
        return true;
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions!.code) {
              case 'INVALID_PHONE_NUMBER':
                setInvalidPhone(true);
                return true;
              default:
                return false;
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSignUpFinish = async (values: any) => {
    setIsLoading(true);

    let variables = {
      ...phoneData,
      ...values,
    };

    await client
      .mutate({
        mutation: SignUpDocument,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({data}) => {
        if (data?.signUp?.success) {
          if (timeNewCode == 0) requestCode({variables: variables});
          setFormState(State.CODE);
        }
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          Modal.error({
            title: 'Ha ocurrido un error :(',
            content: 'Hubo un error al registrarte, por favor, verifica los datos.',
            centered: true,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCodeFinish = async (values: any) => {
    setIsLoading(true);
    setInvalidCode(false);
    let variables = {
      ...phoneData,
      ...values,
    };

    await client
      .mutate({
        mutation: SignInDocument,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({data}) => {
        if (data?.signIn?.access) {
          setAccessToken(data.signIn.access);
          refetchViewer()();
          navigate(location.state?.from || '/');
        }
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions!.code) {
              case 'INCORRECT_CODE':
                setInvalidCode(true);
              default:
                return true;
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  switch (formState) {
    case State.INPUT:
      return (
        <Form className="login-form" onFinish={onInputFinish}>
          <FormItem
            name="celNumber"
            hasFeedback={invalidPhone}
            extra={invalidPhone ? <Text type="danger">Número de celular inválido</Text> : undefined}
            validateStatus={invalidPhone ? 'error' : undefined}
            rules={[
              {required: true, message: 'Ingresa tu teléfono móvil'},
              {whitespace: true, message: 'Ingresa tu teléfono móvil'},
            ]}
          >
            <Input
              autoFocus
              placeholder="Teléfono móvil"
              addonBefore={
                <FormItem name="celCountry" noStyle initialValue="+57">
                  <Select style={{width: 75}}>
                    <Option value="+57">+57</Option>
                    <Option value="+1">+1</Option>
                    <Option value="">Otro</Option>
                  </Select>
                </FormItem>
              }
            />
          </FormItem>

          <FormItem>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Continuar
            </Button>
            <Text type="secondary">Te enviaremos un SMS con el código de verificación.</Text>
          </FormItem>
        </Form>
      );

    case State.SINGUP:
      return (
        <Form className="login-form" onFinish={onSignUpFinish}>
          <FormItem
            name="firstName"
            rules={[
              {required: true, message: 'Ingresa tu nombre'},
              {whitespace: true, message: 'Ingresa tu nombre'},
              {max: 25, message: 'El nombre es demasiado largo'},
            ]}
          >
            <Input autoFocus placeholder="Nombres" />
          </FormItem>
          <FormItem
            name="lastName"
            rules={[
              {required: true, message: 'Ingresa tu apellido'},
              {whitespace: true, message: 'Ingresa tu apellido'},
              {max: 25, message: 'El apellido es demasiado largo'},
            ]}
          >
            <Input placeholder="Apellidos" />
          </FormItem>
          <FormItem
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Debes aceptar'))),
              },
            ]}
          >
            <Checkbox>
              He leído y acepto los{' '}
              <a href="https://kipo.app/terminos-y-condiciones" target="_blank">
                Términos y Condiciones
              </a>{' '}
              y{' '}
              <a href="https://kipo.app/proteccion-de-datos" target="_blank">
                Políticas de Privacidad
              </a>
              .
            </Checkbox>
          </FormItem>

          <FormItem>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Continuar
            </Button>
            <Button
              block
              type="link"
              onClick={() => {
                setPhoneData({});
                setFormState(State.INPUT);
              }}
            >
              Atrás
            </Button>
          </FormItem>
        </Form>
      );

    case State.CODE:
      if (requestCodeResult.loading && timeNewCode == 0) setTimeNewCode(120);

      return (
        <Form className="login-form" onFinish={onCodeFinish}>
          <FormItem
            name="code"
            rules={[{required: true, message: 'Ingresa el código'}]}
            hasFeedback={invalidCode}
            extra={invalidCode ? <Text type="danger">Código no valido</Text> : undefined}
            validateStatus={invalidCode ? 'error' : undefined}
          >
            <Input
              autoFocus
              size="large"
              autoComplete="one-time-code"
              inputMode="numeric"
              prefix={<LockOutlined />}
              placeholder="Ingresa el código del SMS"
            />
          </FormItem>

          <FormItem>
            <Button block type="primary" htmlType="submit" loading={isLoading || requestCodeResult.loading}>
              Continuar
            </Button>
            <Button
              disabled={timeNewCode != 0}
              block
              type="link"
              onClick={() => {
                requestCode({variables: phoneData as RequestAuthCodeMutationVariables});
              }}
            >
              {`Reenviar código ${timeNewCode > 0 ? '(' + timeNewCode + 's)' : ''}`}
            </Button>
          </FormItem>
          <FormItem>
            <Button
              block
              type="link"
              onClick={() => {
                setPhoneData({});
                setFormState(State.INPUT);
              }}
            >
              Atrás
            </Button>
          </FormItem>
        </Form>
      );
  }
};
export default Phone;
