import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {complexFeatures, complexStatus, complexType} from '@/constants/complexes';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasSuperRole} from '@/services/store';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {useMutation, useQuery} from '@apollo/client';
import {Complex, SingleComplexDocument, UpdateComplexDocument} from '@gql/graphql';
import {Button, Form, Input, InputNumber, notification, Select} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';

const title = 'Editar conjunto';

const Edit = () => {
  const {complexId} = useParams();
  if (!complexId) return <NotFound />;
  if (!hasSuperRole(['ADMIN'])) return <Error403 />;

  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [updateComplex, {loading: editLoading}] = useMutation(UpdateComplexDocument, {
    onCompleted: () => {
      notification.success({
        message: 'Editado con éxito',
        description: 'Se ha editado el conjunto.',
      });
      navigate('/complex/list');
    },
  });

  const {loading, error, data, refetch} = useQuery(SingleComplexDocument, {
    variables: {id: complexId},
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const complex = data.node as Complex;
  if (!complex) return <NotFound />;

  const onInputFinish = async (values: any) => {
    updateComplex({variables: {id: complexId, ...values}});
  };
  return (
    <PageContent header={{title: title, onBack: () => navigate('/complex/list')}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          ...complex,
        }}
      >
        <Form.Item
          {...formItemLayout}
          label="Nombre"
          name="name"
          rules={[
            {required: true, message: 'Debes escribir el nombre'},
            {max: 100, message: 'Demasiado largo'},
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Estado"
          name="status"
          rules={[{required: true, message: 'Debes seleccionar el estado'}]}
        >
          <Select>
            {enumToKV(complexStatus).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Tipo"
          name="type"
          rules={[{required: true, message: 'Debes seleccionar el tipo'}]}
        >
          <Select allowClear>
            {enumToKV(complexType).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Functiones" name="features">
          <Select mode="multiple" allowClear>
            {enumToKV(complexFeatures).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Estrato" name="stratum">
          <InputNumber min={1} max={6} style={{width: 200}} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Dirección"
          name="address"
          rules={[{max: 100, message: 'Demasiado largo'}]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Latitud" name="lat">
          <InputNumber style={{width: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Longitud" name="long">
          <InputNumber style={{width: 200}} />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Editar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default Edit;
