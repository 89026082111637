import {fontSize, themeMode} from '@/utils/theme';
import {useReactiveVar} from '@apollo/client';
import {IconBrightnessUp, IconMoon} from '@tabler/icons-react';

const ThemeSwitch = () => {
  const mode = useReactiveVar(themeMode);

  const toggleTheme = () => {
    if (mode == 'LIGHT') {
      themeMode('DARK');
    } else {
      themeMode('LIGHT');
    }
  };
  return (
    <div className="right" style={{marginRight: 7}} onClick={toggleTheme}>
      <span className="action">
        {mode == 'LIGHT' && <IconBrightnessUp size={fontSize} style={{color: 'white'}} />}
        {mode == 'DARK' && <IconMoon size={fontSize} style={{color: 'white'}} />}
      </span>
    </div>
  );
};

export default ThemeSwitch;
