import {Tooltip} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import calendar from 'dayjs/plugin/calendar';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.locale('es-mx');

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const NORMAL_DATE = 'lll'; // 12
const NORMAL_STR_MONTH = 'MMMM';
const SHORT_DATE = 'DD/MM/YY h:mm a'; // 12
const SHORT_DATE_NT = 'DD/MM/YY';
const FULL_TEXT_DATE = 'ddd D [de] MMMM [a las] h:mm a';
const FULL_TEXT_DATE_YEAR = 'ddd D [de] MMMM [del] YYYY [a las] h:mm a';

export const timeZone = (): string => dayjs.tz.guess();

export const date = (date?: string) => dayjs(date);
export const nowPlus = (value: number, unit?: dayjs.ManipulateType) => dayjs().add(value, unit);

export const fromNow = (date: string) => dayjs(date).fromNow();
export const fromNowTooltip = (date: string) => (
  <Tooltip title={dayjs(date).format(FULL_TEXT_DATE)}>{fromNow(date)}</Tooltip>
);
export const toNow = (date: string) => dayjs().to(date);
export const toNowTooltip = (date: string) => (
  <Tooltip title={dayjs(date).format(FULL_TEXT_DATE)}>{toNow(date)}</Tooltip>
);

export const relativeDateTooltip = (date: string) => {
  const datejs = dayjs(date);
  return (
    <Tooltip title={dayjs(date).format(FULL_TEXT_DATE)}>
      {isBeforeToday(datejs) ? dayjs().to(date) : dayjs(date).fromNow()}
    </Tooltip>
  );
};

export const createdAtFormat = (date: string) => dayjs(date).format(NORMAL_DATE);
export const dateFormat = (date: string) => dayjs(date).format(SHORT_DATE_NT);
export const createdAtFormatFull = (date: string) => dayjs(date).format(FULL_TEXT_DATE);

export const createdAtFormatShort = (date: string) => dayjs(date).format(SHORT_DATE);

export const fromNowMaxTooltip = (date: string) => {
  const day = dayjs(date);
  const diff = Math.abs(diffFromNow(day, 'days'));
  if (diff > 0) {
    return createdAtFormatShort(date);
  } else {
    return <Tooltip title={createdAtFormatShort(date)}>{day.fromNow()}</Tooltip>;
  }
};

export const fromNowMaxTooltipFull = (date: string) => {
  const parsed = dayjs(date);
  const diff = Math.abs(diffFromNow(parsed, 'days'));

  if (diff <= 1) {
    return <Tooltip title={parsed.format(FULL_TEXT_DATE)}>{fromNow(date)}</Tooltip>;
  } else if (diff <= 7) {
    return <Tooltip title={parsed.format(FULL_TEXT_DATE)}>{dayjs().calendar(parsed)}</Tooltip>;
  } else if (diff <= 360) {
    return parsed.format(FULL_TEXT_DATE);
  } else {
    return parsed.format(FULL_TEXT_DATE_YEAR);
  }
};

export const diffFromNow = (date: dayjs.Dayjs, unit?: dayjs.QUnitType | dayjs.OpUnitType) =>
  dayjs(date).diff(dayjs(), unit);

export const isBeforeToday = (date: dayjs.Dayjs) => date.isBefore(dayjs().endOf('day'));
export const isAfterToday = (date: dayjs.Dayjs) => date.isAfter(dayjs().startOf('day'));

export const rangePresets: {
  label: string;
  value: [dayjs.Dayjs, dayjs.Dayjs];
}[] = [
  {label: 'Hoy', value: [dayjs().startOf('day'), dayjs().endOf('day')]},
  {label: 'Ayer', value: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')]},
  {label: 'Últimos 7 días', value: [dayjs().subtract(7, 'days').startOf('day'), dayjs().endOf('day')]},
  {label: 'Últimos 14 días', value: [dayjs().subtract(14, 'days').startOf('day'), dayjs().endOf('day')]},
  {label: 'Últimos 30 días', value: [dayjs().subtract(30, 'days').startOf('day'), dayjs().endOf('day')]},
  {label: 'Esta semana', value: [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')]},
  {
    label: 'Semana pasada',
    value: [dayjs().subtract(7, 'days').startOf('isoWeek'), dayjs().subtract(7, 'days').endOf('isoWeek')],
  },
  {label: 'Este mes', value: [dayjs().startOf('month'), dayjs().endOf('month')]},
  {
    label: 'El mes pasado',
    value: [dayjs().subtract(1, 'months').startOf('month'), dayjs().subtract(1, 'months').endOf('month')],
  },
];

export function intervalFromString(string: string): (dayjs.Dayjs | null)[] {
  switch (string) {
    case 'today':
      return [dayjs().startOf('day'), dayjs().endOf('day')];

    case 'yesterday':
      return [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')];

    case 'thisWeek':
      return [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')];

    case 'lastSeven':
      return [dayjs().subtract(7, 'days').startOf('day'), dayjs().endOf('day')];

    case 'lastWeek':
      return [dayjs().subtract(7, 'days').startOf('isoWeek'), dayjs().subtract(7, 'days').endOf('isoWeek')];

    case 'lastFourteen':
      return [dayjs().subtract(14, 'days').startOf('day'), dayjs().endOf('day')];

    case 'thisMonth':
      return [dayjs().startOf('month'), dayjs().endOf('month')];

    case 'lastThirty':
      return [dayjs().subtract(30, 'days').startOf('day'), dayjs().endOf('day')];

    case 'lastMonth':
      return [dayjs().subtract(1, 'months').startOf('month'), dayjs().subtract(1, 'months').endOf('month')];

    case 'sinceEver':
      return [null, null];

    default:
      return [null, null];
  }
}
