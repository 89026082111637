import client from '@/graphql';
import {UseJoinCodeDocument} from '@gql/graphql';
import {refetchViewer} from '@/services/store';
import {fontSize} from '@/utils/theme';
import {InfoCircleOutlined} from '@ant-design/icons';
import {IconKey} from '@tabler/icons-react';
import {App, Button, Form, Input, Result, Space, theme, Tooltip} from 'antd';
import {Fragment, useState} from 'react';
import {useNavigate} from 'react-router-dom';
const {useToken} = theme;

const NoComplex = () => {
  const navigate = useNavigate();
  const {token} = useToken();
  const [showCode, setShowCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const {message, notification} = App.useApp();

  const onCodeFinish = async (values: any) => {
    setIsLoading(true);
    setInvalidCode(false);
    let variables = {
      ...values,
    };

    await client
      .mutate({
        mutation: UseJoinCodeDocument,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({data}) => {
        if (data?.useJoinCode?.id) {
          message.success('Bienvenido a Kipo');
          refetchViewer()();
        }
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions!.code) {
              case 'INVALID_JOIN_CODE':
                setInvalidCode(true);
                notification.error({
                  message: 'Código incorrecto',
                  description: 'Puede que haya expirado o ya se haya usado. Revisa que lo hayas escrito bien.',
                });
              default:
                return true;
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: token.colorBgContainer,
      }}
    >
      <Result
        status="404"
        title="No estás asociado a un conjunto"
        subTitle="Para usar Kipo debes estar en un conjunto que tenga nuestros servicios. "
        extra={
          <Fragment>
            {!showCode && (
              <>
                <Button type="primary" onClick={() => setShowCode(true)}>
                  Ingresar código de invitación
                </Button>
                <Button type="link" onClick={() => navigate('/logout')}>
                  Cerrar sesión
                </Button>
              </>
            )}
            {showCode && (
              <>
                <Form onFinish={onCodeFinish}>
                  <Form.Item
                    name="code"
                    rules={[{required: true, message: 'Ingresa el código'}]}
                    hasFeedback={invalidCode}
                    validateStatus={invalidCode ? 'error' : undefined}
                  >
                    <Space direction="vertical">
                      <Space>
                        <Input
                          autoFocus
                          size="large"
                          prefix={<IconKey size={fontSize} />}
                          placeholder="Código de invitación"
                          style={{maxWidth: 300}}
                        />
                        <Tooltip
                          placement="topRight"
                          title={'Ingresa el código que te dio la administración o un miembro de tu propiedad'}
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Space>

                      <Button type="primary" htmlType="submit" loading={isLoading}>
                        Continuar
                      </Button>
                      <Button type="link" onClick={() => navigate('/logout')}>
                        Cerrar sesión
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </>
            )}
          </Fragment>
        }
      />
    </div>
  );
};

export default NoComplex;
