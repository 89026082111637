import Error403 from '@/components/loaders/Error403';
import PageContent from '@/components/PageContent';
import {complexFeatures, complexStatus, complexType} from '@/constants/complexes';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasSuperRole} from '@/services/store';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {useMutation} from '@apollo/client';
import {CreateComplexDocument} from '@gql/graphql';
import {Button, Form, Input, InputNumber, notification, Select} from 'antd';
import {useNavigate} from 'react-router-dom';

const New = () => {
  useDocumentTitle('Nuevo conjunto');
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [createComplex, {loading: newLoading}] = useMutation(CreateComplexDocument, {
    update: (cache, {data}) => {
      if (data?.createComplex?.id) {
        notification.success({
          message: 'Creado con éxito',
          description: 'Se ha creado la propiedad',
        });
        navigate('/complex/list');
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (!hasSuperRole(['ADMIN'])) return <Error403 />;

  const onInputFinish = async (values: any) => {
    createComplex({variables: values});
  };

  return (
    <PageContent header={{title: `Nuevo conjunto`, onBack: () => navigate('/complex/list')}}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          {...formItemLayout}
          label="Nombre"
          name="name"
          rules={[
            {required: true, message: 'Debes escribir el nombre'},
            {max: 100, message: 'Demasiado largo'},
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Dirección"
          name="address"
          rules={[{max: 100, message: 'Demasiado largo'}]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Estado"
          name="status"
          rules={[{required: true, message: 'Debes seleccionar el estado'}]}
        >
          <Select>
            {enumToKV(complexStatus).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Tipo"
          name="type"
          rules={[{required: true, message: 'Debes seleccionar el tipo'}]}
        >
          <Select allowClear>
            {enumToKV(complexType).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Functiones" name="features">
          <Select mode="multiple" allowClear>
            {enumToKV(complexFeatures).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Estrato" name="stratum">
          <InputNumber min={1} max={6} style={{width: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Latitud" name="lat">
          <InputNumber style={{width: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Longitud" name="long">
          <InputNumber style={{width: 200}} />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Crear
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default New;
