import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {
  petitionStatus,
  petitionStatusActionDesc,
  petitionStatusColor,
  petitionStatusPending,
} from '@/constants/petition';
import {findById, getViewer, hasRoles} from '@/services/store';
import {fromNowMaxTooltipFull} from '@/utils/dates';
import {enumToKV} from '@/utils/enum';
import {CheckCircleOutlined, PlusCircleOutlined, ReloadOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {CreatePetitionStatusDocument, Petition} from '@gql/graphql';
import {
  Button,
  Form,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Space,
  Timeline,
  TimelineItemProps,
  Tooltip,
  Typography,
} from 'antd';
import {Fragment, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const Statuses = ({petition}: {petition: Petition}) => {
  if (!petition) return <NotFound />;

  const navigate = useNavigate();
  const [popoverStatus, setPopoverStatus] = useState(false);
  const viewer = getViewer();
  const complex = findById(petition.complexId);
  const isCreator = petition.createdBy?.id == viewer?.id;
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;

  const [createPetitionStatus, {loading}] = useMutation(CreatePetitionStatusDocument, {
    update: (cache, {data}) => {
      setPopoverStatus(false);

      if (data?.createPetitionStatus?.id) {
        notification.success({
          message: 'Estado actualizado',
          description: 'Recuerda dejar un comentario al respecto',
        });
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  const addStatusElevatedContent = (
    <Form
      onFinish={(values: any) =>
        createPetitionStatus({
          variables: {petitionId: petition.id, status: values.status},
        })
      }
      initialValues={{status: petition.currentStatus}}
    >
      <Form.Item noStyle name="status" rules={[{required: true, message: 'Debes escoger un estado'}]}>
        <Radio.Group size="middle">
          <Space size={0} direction="vertical">
            {enumToKV(petitionStatus).map(({key, value}) => (
              <Tooltip key={key} title={petitionStatusActionDesc[key]} placement="leftTop" mouseLeaveDelay={0}>
                <Radio disabled={key == petition.currentStatus} value={key}>
                  {value}
                </Radio>
              </Tooltip>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Row justify="end" style={{marginTop: '15px'}}>
        <Button size="small" type="link" onClick={() => setPopoverStatus(false)}>
          Cancelar
        </Button>
        <Button type="primary" size="small" style={{marginRight: 8}} htmlType="submit" loading={loading}>
          Guardar
        </Button>
      </Row>
    </Form>
  );

  const addStatusElevatedButton = !hasRole ? undefined : (
    <Popover
      key="addStatusElevatedButton"
      placement="bottomRight"
      trigger="click"
      content={addStatusElevatedContent}
      open={popoverStatus}
      onOpenChange={visible => setPopoverStatus(visible)}
    >
      <Button type="primary">
        <PlusCircleOutlined /> Nuevo estado
      </Button>
    </Popover>
  );

  const canResolve =
    petition.currentStatus && isCreator && !hasRole && !['RESOLVED', 'CLOSED'].includes(petition.currentStatus);
  const addStatusResolvedButton = !canResolve ? undefined : (
    <Popconfirm
      key="addStatusResolvedButton"
      placement="bottomRight"
      title={'¿Marcar como solucionada?'}
      description={
        <Typography.Text>
          Al marcar la solicitud como solucionada <br />
          la administración ya no tendrá que ver <br />
          tu solicitud y no podrás enviar más <br />
          comentarios.
        </Typography.Text>
      }
      onConfirm={() =>
        createPetitionStatus({
          variables: {
            petitionId: petition.id,
            status: 'RESOLVED',
          },
        })
      }
      okText="Solucionada"
      cancelText="Cancelar"
    >
      <Button type="primary">
        <CheckCircleOutlined /> Solucionada
      </Button>
    </Popconfirm>
  );

  const canOpen =
    petition.currentStatus && isCreator && !hasRole && ['RESOLVED', 'CLOSED'].includes(petition.currentStatus);
  const addStatusOpenButton = !canOpen ? undefined : (
    <Popconfirm
      key="addStatusOpenButton"
      placement="bottomRight"
      title="¿Reabrir solicitud?"
      description={
        <Typography.Text>
          Solo reabre la solicitud si el mismo <br />
          tema en específico ha vuelto a surgir, <br />
          si no, mejor crea una nueva solicitud.
        </Typography.Text>
      }
      onCancel={() => navigate('/petition/new')}
      onConfirm={() =>
        createPetitionStatus({
          variables: {
            petitionId: petition.id,
            status: 'OPEN',
          },
        })
      }
      okText="Reabrir"
      cancelText="Crear nueva"
    >
      <Button>
        <ReloadOutlined /> Reabrir
      </Button>
    </Popconfirm>
  );

  const statusesList = petition.statuses
    ? petition.statuses.slice().sort((a, b) => {
        return Number(new Date(a!.insertedAt)) - Number(new Date(b!.insertedAt));
      })
    : [];

  const items: TimelineItemProps[] = statusesList.map((status, index) => {
    return {
      color: petitionStatusColor[status!.status!],
      children: (
        <>
          <Typography.Text strong>{petitionStatus[status!.status!]} </Typography.Text>
          <Typography.Text>por {status!.createdBy?.firstName}</Typography.Text>
          <br />
          <Typography.Text>{fromNowMaxTooltipFull(status!.insertedAt)}</Typography.Text>
        </>
      ),
    };
  });

  return (
    <PageContent
      header={{
        title: 'Seguimiento',
        extra: [addStatusElevatedButton, addStatusResolvedButton, addStatusOpenButton],
      }}
    >
      <Fragment>
        <Timeline pending={petition.currentStatus && petitionStatusPending[petition.currentStatus]} items={items} />
      </Fragment>
    </PageContent>
  );
};

export default Statuses;
