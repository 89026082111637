import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import UserAvatar from '@/components/UserAvatar';
import {restrictionTypes} from '@/constants/restrictions';
import {fontSize} from '@/utils/theme';
import client from '@/graphql';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {fromNowTooltip, toNowTooltip} from '@/utils/dates';
import {enumToKV} from '@/utils/enum';
import {hasRoles, selectedComplex} from '@/services/store';
import {DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {useLazyQuery, useReactiveVar} from '@apollo/client';
import {
  Complex,
  ComplexRestriction,
  ComplexRestrictionType,
  RemoveComplexRestrictionDocument,
  SingleComplexRestrictionsDocument,
  SingleComplexRestrictionsQueryVariables,
} from '@gql/graphql';
import {IconFilter} from '@tabler/icons-react';
import {App, Button, Empty, List, Popconfirm, Select, Space, Typography} from 'antd';
import {useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const title = 'Restricciones';

const RestrictionsList = () => {
  useDocumentTitle(title);
  const {message, notification} = App.useApp();
  const complexes = useReactiveVar(selectedComplex);
  const complex = complexes[0];

  if (!hasRoles(['ADMIN', 'COUNCIL']) || !complex) return <Error403 />;

  const navigate = useNavigate();
  const [restFilter, setRestFilter] = useState<string | undefined>();

  const filter = restFilter ? {type: [restFilter as ComplexRestrictionType]} : undefined;
  const queryVariables = {id: complex.id, first: 10, filter: filter} as SingleComplexRestrictionsQueryVariables;
  const [getComplex, {loading, error, data, refetch, fetchMore}] = useLazyQuery(SingleComplexRestrictionsDocument);

  useMemo(() => {
    if (complexes.length == 1)
      getComplex({variables: queryVariables, fetchPolicy: 'cache-and-network', errorPolicy: 'all'});
  }, [complexes, restFilter]);

  if (complexes.length > 1) return <SelectComplex />;

  if (loading && !data) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const item = data.node as Complex;
  const restNodes = item.restrictions?.edges?.map(edge => edge?.node as ComplexRestriction);
  const pageInfo = item.restrictions?.pageInfo;
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Ver anteriores...
        </Button>
      </div>
    ) : null;

  const removeRole = async (restId: string) => {
    await client
      .mutate({
        mutation: RemoveComplexRestrictionDocument,
        variables: {id: restId},
        refetchQueries: [{query: SingleComplexRestrictionsDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.removeComplexRestriction?.success) {
          message.success('Se ha removido la restricción con éxito.');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al remover',
          description: 'No se pudo remover la restricción. Intenta más tarde.',
        });
      });
  };

  const removeRestButton = (id?: string) => {
    if (!id) return undefined;

    return (
      <Popconfirm
        key="delConfirm"
        placement="topRight"
        title={'¿Remover restricción? '}
        description={'El usuario ya no estará limitado'}
        onConfirm={() => removeRole(id)}
        okText={'Remover'}
        cancelText="Cancelar"
      >
        <Button>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  const filterButton = hasRole ? (
    <Select
      key="filterButton"
      allowClear
      onChange={setRestFilter}
      placeholder={
        <>
          <IconFilter size={fontSize} /> Restricción
        </>
      }
      options={enumToKV(restrictionTypes).map(({key, value}) => {
        return {value: key, label: value};
      })}
    />
  ) : undefined;

  const addButton = hasRole ? (
    <Button key="editButton" type="primary" onClick={() => navigate('/complex/restriction/new')}>
      <PlusCircleOutlined /> Añadir
    </Button>
  ) : undefined;

  return (
    <>
      <PageContent
        header={{
          title: title,
          extra: [filterButton, addButton],
        }}
      >
        <List
          itemLayout="horizontal"
          loadMore={loadMore}
          loading={loading}
          locale={{emptyText: <Empty description="Sin usuarios" />}}
          dataSource={restNodes}
          renderItem={rest => {
            return (
              <List.Item key={rest?.id}>
                <List.Item.Meta
                  className="comment-meta"
                  title={
                    <Space>
                      <Typography.Text style={{fontSize: '13px'}} strong>
                        {`${rest?.user?.firstName} ${rest?.user?.lastName}`}
                      </Typography.Text>
                      <Typography.Text type="secondary" style={{fontSize: '11px'}}>
                        desde: {toNowTooltip(rest?.insertedAt)},
                      </Typography.Text>
                      <Typography.Text type="secondary" style={{fontSize: '11px'}}>
                        termina: {fromNowTooltip(rest?.expire)}
                      </Typography.Text>
                    </Space>
                  }
                  avatar={<UserAvatar viewer={rest?.user} />}
                  description={
                    <Typography.Text>
                      {rest?.type ? `${restrictionTypes[rest.type]} — ` : null}
                      Restringido por:{' '}
                      {rest?.createdBy ? `${rest?.createdBy?.firstName} ${rest?.createdBy?.lastName}` : '—'}
                      <br />
                      Nota: {rest.description}
                    </Typography.Text>
                  }
                />
                <Space>{removeRestButton(rest?.id)}</Space>
              </List.Item>
            );
          }}
        />
      </PageContent>
    </>
  );
};

export default RestrictionsList;
