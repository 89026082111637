interface IDictionary {
  [index: string]: string;
}

export const petType: IDictionary = {
  DOG: "Perro",
  CAT: "Gato",
  HAMSTER: "Hamster",
  BIRD: "Ave",
  PIG: "Mini Pig",
  RABBIT: "Conejo",
  OTHER: "Otro",
};

export const petSex: IDictionary = {
  MALE: "Macho",
  FEMALE: "Hembra",
};
