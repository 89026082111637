import PageContent from '@/components/PageContent';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {useMutation} from '@apollo/client';
import {AuthCode, GenerateAuthCodeDocument} from '@gql/graphql';
import {Button, Form, Input, notification, Space, Typography} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

const title = 'Generar código';

const GenerateAuth = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [codeData, setCodeData] = useState<AuthCode>();

  const [createFeedback, {loading: editLoading}] = useMutation(GenerateAuthCodeDocument, {
    update: (cache, {data}) => {
      if (data?.generateAuthCode?.code) {
        setCodeData(data?.generateAuthCode);
        notification.success({
          message: 'Generado con éxito',
        });
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  const onInputFinish = async (values: any) => {
    let data = structuredClone(values);
    if (values.email === '') {
      data.email = undefined;
    }

    if (values.celCountry === '' || values.celNumber === '') {
      data.celCountry = undefined;
      data.celNumber = undefined;
    }

    createFeedback({variables: data});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate(-1)}}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item {...formItemLayout} label="Correo" name="email">
          <Input style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Celular">
          <Space.Compact>
            <Form.Item name="celCountry">
              <Input style={{maxWidth: 70}} placeholder={'57'} />
            </Form.Item>
            <Form.Item name="celNumber" rules={[{whitespace: true}]}>
              <Input placeholder="Número" style={{maxWidth: 130}} />
            </Form.Item>
          </Space.Compact>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Generar
          </Button>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          {codeData && (
            <Typography.Title copyable level={3}>
              {codeData.code}
            </Typography.Title>
          )}
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default GenerateAuth;
