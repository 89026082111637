import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import {petSex, petType} from '@/constants/pets';
import client from '@/graphql';
import {findById, getUnitRole, hasRoles} from '@/services/store';
import {dateFormat, toNowTooltip} from '@/utils/dates';
import {getFullOne} from '@/utils/names';
import {DeleteOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {DeletePetDocument, Pet, SingleUnitPetsDocument, SingleUnitPetsQueryVariables, Unit} from '@gql/graphql';
import {App, Button, Empty, Popconfirm, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {useParams} from 'react-router-dom';

const PetList = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;
  const {message, notification} = App.useApp();

  const queryVariables = {id: unitId, first: 10} as SingleUnitPetsQueryVariables;
  const {loading, error, data, refetch, fetchMore} = useQuery(SingleUnitPetsDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const unit = data.node as Unit;
  if (!unit) return <NotFound />;

  const pets = unit.pets?.edges?.map(edge => edge?.node as Pet);
  const pageInfo = unit.pets?.pageInfo;

  const complex = findById(unit.complexId);
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const viewerAdmin = hasRole ? 'ADMIN' : undefined;
  const viewerRole = getUnitRole(unit) || viewerAdmin;

  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Ver anteriores...
        </Button>
      </div>
    ) : null;

  const deletePet = async (id: string) => {
    await client
      .mutate({
        mutation: DeletePetDocument,
        variables: {id: id},
        refetchQueries: [{query: SingleUnitPetsDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.deletePet?.success) {
          message.success('Se ha removido la mascota con éxito.');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al remover',
          description: 'No se pudo remover la mascota. Intenta más tarde.',
        });
      });
  };

  const deleteButton = (id?: string) => {
    if (!id) return undefined;

    return (
      <Popconfirm
        key="delConfirm"
        placement="topRight"
        title={'¿Remover esta mascota? '}
        onConfirm={() => deletePet(id)}
        okText={'Remover'}
        okType="danger"
        cancelText="Cancelar"
      >
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  const expandedRowRender = (record: Pet) => {
    const columns: ColumnsType<Pet> = [
      {
        title: 'Nacimiento',
        dataIndex: 'birthday',
        key: 'birthday',
        render: text => (text ? dateFormat(text) : '—'),
      },
      {
        title: 'Sexo',
        dataIndex: 'sex',
        key: 'sex',
        render: text => (text ? petSex[text] : '—'),
      },
      {
        title: 'Fecha vacunas',
        dataIndex: 'vaccinesDate',
        key: 'vaccinesDate',
        render: text => (text ? dateFormat(text) : '—'),
      },
      {
        title: 'Añadido por',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: text => getFullOne(text),
      },
    ];

    return (
      <Table columns={columns} size="small" rowKey={record => record.id} dataSource={[record]} pagination={false} />
    );
  };

  const columns: ColumnsType<Pet> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => petType[text],
    },
    {
      title: 'Añadido',
      dataIndex: 'insertedAt',
      key: 'insertedAt',
      render: text => toNowTooltip(text),
    },
    {
      title: 'Acciones',
      key: 'action',
      align: 'center',
      render: (text, record) => (viewerRole ? deleteButton(record.id) : undefined),
    },
  ];

  return (
    <>
      <Table<Pet>
        columns={columns}
        size="small"
        pagination={false}
        rowKey={record => record.id}
        expandedRowRender={record => expandedRowRender(record)}
        dataSource={pets}
        locale={{emptyText: <Empty description="Sin mascotas"></Empty>}}
      />
      {loadMore}
    </>
  );
};

export default PetList;
