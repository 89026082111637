import {themeMode} from '@/utils/theme';
import {PageHeader, PageHeaderProps} from '@ant-design/pro-layout';
import {useReactiveVar} from '@apollo/client';
import {Card, Col, Row} from 'antd';
import {Fragment} from 'react';

type PageContentProps = {children: React.ReactNode; header?: PageHeaderProps; fullWidth?: boolean; noCard?: boolean};
const PageContent: React.FC<PageContentProps> = ({children, header, fullWidth, noCard}) => {
  const mode = useReactiveVar(themeMode);
  const className = mode == 'DARK' ? 'pageheader-dark' : undefined;

  return (
    <Fragment>
      <Row justify="center" style={{marginBottom: 30}}>
        <Col className={fullWidth ? 'max-content-width-full' : 'max-content-width'}>
          {header && <PageHeader {...header} className={className} />}
          {!noCard && <Card bordered={false}>{children}</Card>}
          {noCard && children}
        </Col>
      </Row>
    </Fragment>
  );
};

export default PageContent;
