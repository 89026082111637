/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
  /** Represents an uploaded file. */
  Upload: any;
};

export type AuthCode = {
  __typename?: 'AuthCode';
  code: Maybe<Scalars['String']>;
  ttl: Maybe<Scalars['Int']>;
};

export type AuthResult = {
  __typename?: 'AuthResult';
  access: Maybe<Scalars['String']>;
  session: Maybe<Session>;
  user: Maybe<User>;
};

export type AvatarFile = {
  __typename?: 'AvatarFile';
  avatar: Maybe<Scalars['String']>;
  original: Maybe<Scalars['String']>;
  thumb: Maybe<Scalars['String']>;
};

export type Billboard = Node & {
  __typename?: 'Billboard';
  building: Maybe<Building>;
  buildingId: Maybe<Scalars['ID']>;
  complex: Maybe<Complex>;
  complexId: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  file: Maybe<File>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type BillboardConnection = {
  __typename?: 'BillboardConnection';
  edges: Maybe<Array<Maybe<BillboardEdge>>>;
  pageInfo: PageInfo;
};

export type BillboardEdge = {
  __typename?: 'BillboardEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Billboard>;
};

export type BillboardFile = {
  __typename?: 'BillboardFile';
  billboard: Maybe<Scalars['String']>;
  original: Maybe<Scalars['String']>;
  thumb: Maybe<Scalars['String']>;
};

/** Filtering options for billboard */
export type BillboardFilter = {
  /** IDs of the complexes from which the billboard will be obtained */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Fase to show billboard of buildings not related to the user */
  strictBuilding: InputMaybe<Scalars['Boolean']>;
};

/** Ordering options for billboard */
export type BillboardSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderBillboardField;
};

export type Building = Node & {
  __typename?: 'Building';
  complex: Maybe<Complex>;
  complexId: Maybe<Scalars['ID']>;
  /** The ID of an object */
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  units: Maybe<UnitConnection>;
};

export type BuildingUnitsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<UnitSort>;
};

export type BuildingConnection = {
  __typename?: 'BuildingConnection';
  edges: Maybe<Array<Maybe<BuildingEdge>>>;
  pageInfo: PageInfo;
};

export type BuildingEdge = {
  __typename?: 'BuildingEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Building>;
};

/** Filtering options for building */
export type BuildingFilter = {
  /** Matching a complex */
  complex: InputMaybe<Scalars['ID']>;
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching the name */
  name: InputMaybe<Scalars['String']>;
};

/** Ordering options for buildings */
export type BuildingSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: BuildingSortField;
};

/** The fields allowed to be sorted */
export type BuildingSortField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** First name */
  | 'NAME';

export type ByDayTypes = 'FRIDAY' | 'MONDAY' | 'SATURDAY' | 'SUNDAY' | 'THURSDAY' | 'TUESDAY' | 'WEDNESDAY';

export type City = Node & {
  __typename?: 'City';
  districts: Maybe<DistrictConnection>;
  /** The ID of an object */
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  state: Maybe<State>;
};

export type CityDistrictsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<DistrictFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<CustomLocationsSort>;
};

export type CityConnection = {
  __typename?: 'CityConnection';
  edges: Maybe<Array<Maybe<CityEdge>>>;
  pageInfo: PageInfo;
};

export type CityEdge = {
  __typename?: 'CityEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<City>;
};

/** Filtering options for city */
export type CityFilter = {
  /** Matching the name */
  name: InputMaybe<Scalars['String']>;
  /** Matching a state */
  state: InputMaybe<Scalars['ID']>;
};

export type Complex = Node & {
  __typename?: 'Complex';
  address: Maybe<Scalars['String']>;
  billboards: Maybe<BillboardConnection>;
  buildings: Maybe<Array<Maybe<Building>>>;
  district: Maybe<District>;
  districtId: Maybe<Scalars['ID']>;
  features: Maybe<Array<Maybe<ComplexFeatures>>>;
  /** The ID of an object */
  id: Scalars['ID'];
  lat: Maybe<Scalars['Float']>;
  long: Maybe<Scalars['Float']>;
  name: Maybe<Scalars['String']>;
  news: Maybe<NewsConnection>;
  restrictions: Maybe<ComplexRestrictionConnection>;
  roles: Maybe<ComplexRoleConnection>;
  status: Maybe<ComplexStatus>;
  stratum: Maybe<Scalars['Int']>;
  type: Maybe<ComplexType>;
  unitRoles: Maybe<UnitRoleConnection>;
  units: Maybe<UnitConnection>;
};

export type ComplexBillboardsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<BillboardFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<BillboardSort>;
};

export type ComplexNewsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexRoleFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<NewsSort>;
};

export type ComplexRestrictionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexRestrictionFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<ComplexRestrictionSort>;
};

export type ComplexRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexRoleFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<ComplexRoleSort>;
};

export type ComplexUnitRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<UnitSort>;
};

export type ComplexUnitsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<UnitSort>;
};

export type ComplexConnection = {
  __typename?: 'ComplexConnection';
  edges: Maybe<Array<Maybe<ComplexEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']>;
};

export type ComplexEdge = {
  __typename?: 'ComplexEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Complex>;
};

export type ComplexFeatures = 'GUESTS' | 'PACKAGES' | 'PANIC_ALERT' | 'PETITIONS' | 'PETITIONS_DOORMAN';

/** Filtering options for complex */
export type ComplexFilter = {
  /** Matching a feature */
  features: InputMaybe<Array<ComplexFeatures>>;
  /** Matching the name */
  name: InputMaybe<Scalars['String']>;
  /** Matching a status */
  status: InputMaybe<Array<ComplexStatus>>;
  /** Matching a stratum */
  stratum: InputMaybe<Array<Scalars['Int']>>;
  /** Matching a type */
  type: InputMaybe<Array<ComplexType>>;
};

export type ComplexRestriction = Node & {
  __typename?: 'ComplexRestriction';
  complex: Maybe<Complex>;
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  expire: Maybe<Scalars['DateTime']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  status: Maybe<ComplexRestrictionStatus>;
  type: Maybe<ComplexRestrictionType>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
  user: Maybe<User>;
};

export type ComplexRestrictionConnection = {
  __typename?: 'ComplexRestrictionConnection';
  edges: Maybe<Array<Maybe<ComplexRestrictionEdge>>>;
  pageInfo: PageInfo;
};

export type ComplexRestrictionEdge = {
  __typename?: 'ComplexRestrictionEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<ComplexRestriction>;
};

/** Filtering options for complex_restriction */
export type ComplexRestrictionFilter = {
  /** Matching a status */
  status: InputMaybe<Array<InputMaybe<ComplexRestrictionStatus>>>;
  /** Matching a type */
  type: InputMaybe<Array<InputMaybe<ComplexRestrictionType>>>;
  /** Matching a user id */
  userId: InputMaybe<Scalars['ID']>;
};

/** Ordering options for join codes */
export type ComplexRestrictionSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: ComplexRestrictionSortField;
};

/** The fields allowed to be sorted */
export type ComplexRestrictionSortField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** Type of the restriction */
  | 'TYPE';

export type ComplexRestrictionStatus = 'ACTIVE' | 'EXPIRED' | 'REMOVED';

export type ComplexRestrictionType =
  | 'ADD_MEMBERS'
  | 'ADD_VEHICLES'
  | 'CREATE_GUESTS'
  | 'CREATE_PANICS'
  | 'CREATE_PETITIONS'
  | 'PUBLIC_COMMENTS';

export type ComplexRole = Node & {
  __typename?: 'ComplexRole';
  complex: Maybe<Complex>;
  complexId: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  role: Maybe<ComplexRoles>;
  status: Maybe<RoleStatus>;
  updatedBy: Maybe<User>;
  user: Maybe<User>;
  userId: Maybe<Scalars['ID']>;
};

export type ComplexRoleConnection = {
  __typename?: 'ComplexRoleConnection';
  edges: Maybe<Array<Maybe<ComplexRoleEdge>>>;
  pageInfo: PageInfo;
};

export type ComplexRoleEdge = {
  __typename?: 'ComplexRoleEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<ComplexRole>;
};

/** Filtering options for the complex role */
export type ComplexRoleFilter = {
  /** Matching a role */
  role: InputMaybe<Array<InputMaybe<ComplexRoles>>>;
  /** Matching a status */
  status: InputMaybe<Array<InputMaybe<RoleStatus>>>;
};

/** Ordering options for complex roles */
export type ComplexRoleSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: ComplexRoleSortField;
};

/** The fields allowed to be sorted */
export type ComplexRoleSortField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** First name */
  | 'NAME';

export type ComplexRoles = 'ADMIN' | 'COUNCIL' | 'DOORMAN';

/** Ordering options for complexes */
export type ComplexSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: ComplexSortField;
};

/** The fields allowed to be sorted */
export type ComplexSortField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** First name */
  | 'NAME';

export type ComplexStatus = 'ACTIVE' | 'ENDED' | 'INACTIVE' | 'NO_PAYMENT' | 'SUSPENDED';

export type ComplexType = 'BUILDING' | 'MALL' | 'MIXED' | 'OFFICE_CENTER' | 'OTHER' | 'URBANIZATION';

export type Country = Node & {
  __typename?: 'Country';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  states: Maybe<StateConnection>;
};

export type CountryStatesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<StateFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<CustomLocationsSort>;
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  edges: Maybe<Array<Maybe<CountryEdge>>>;
  pageInfo: PageInfo;
};

export type CountryEdge = {
  __typename?: 'CountryEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Country>;
};

/** Filtering options for country */
export type CountryFilter = {
  /** Matching the name */
  name: InputMaybe<Scalars['String']>;
};

export type CoverFile = {
  __typename?: 'CoverFile';
  cover: Maybe<Scalars['String']>;
  original: Maybe<Scalars['String']>;
  thumb: Maybe<Scalars['String']>;
};

/** Ordering options for locations */
export type CustomLocationsSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderLocationField;
};

export type District = Node & {
  __typename?: 'District';
  city: Maybe<City>;
  /** The ID of an object */
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type DistrictConnection = {
  __typename?: 'DistrictConnection';
  edges: Maybe<Array<Maybe<DistrictEdge>>>;
  pageInfo: PageInfo;
};

export type DistrictEdge = {
  __typename?: 'DistrictEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<District>;
};

/** Filtering options for district */
export type DistrictFilter = {
  /** Matching a city */
  city: InputMaybe<Scalars['ID']>;
  /** Matching the name */
  name: InputMaybe<Scalars['String']>;
};

export type DocumentFile = {
  __typename?: 'DocumentFile';
  original: Maybe<Scalars['String']>;
  thumb: Maybe<Scalars['String']>;
};

export type Feedback = Node & {
  __typename?: 'Feedback';
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  file: Maybe<File>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  status: Maybe<FeedbackStatus>;
  type: Maybe<FeedbackType>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
};

export type FeedbackConnection = {
  __typename?: 'FeedbackConnection';
  edges: Maybe<Array<Maybe<FeedbackEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']>;
};

export type FeedbackEdge = {
  __typename?: 'FeedbackEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Feedback>;
};

/** Filtering options for feedback */
export type FeedbackFilter = {
  /** Matching a creator name */
  creatorName: InputMaybe<Scalars['String']>;
  /** Matching a status */
  status: InputMaybe<Array<FeedbackStatus>>;
  /** Matching a type */
  type: InputMaybe<Array<FeedbackType>>;
};

/** Ordering options for feedback */
export type FeedbackSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderFeedbackField;
};

export type FeedbackStatus = 'CLOSED' | 'IN_PROGRESS' | 'OPEN' | 'RESOLVED' | 'UNDER_REVIEW';

export type FeedbackType = 'OTHER' | 'PROBLEM' | 'QUESTION' | 'SUGGESTION';

export type File = Node & {
  __typename?: 'File';
  contentType: Maybe<Scalars['String']>;
  createdBy: Maybe<User>;
  filename: Maybe<Scalars['String']>;
  hash: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  size: Maybe<Scalars['Int']>;
  type: Maybe<FileType>;
  urls: Maybe<FileUrl>;
};

export type FileType = 'AVATAR' | 'BILLBOARD' | 'COVER' | 'DOCUMENT';

export type FileUrl = AvatarFile | BillboardFile | CoverFile | DocumentFile;

export type FrequencyTypes = 'DAILY' | 'MONTHLY' | 'WEEKLY';

export type FuelType = 'DIESEL' | 'ELECTRIC' | 'GASOLINE' | 'HYBRID_ELECTRIC' | 'HYBRID_GNV';

export type Guest = Node & {
  __typename?: 'Guest';
  byDay: Maybe<Array<Maybe<ByDayTypes>>>;
  createdBy: Maybe<User>;
  dateEnd: Maybe<Scalars['DateTime']>;
  dateStart: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  file: Maybe<File>;
  freq: Maybe<FrequencyTypes>;
  /** The ID of an object */
  id: Scalars['ID'];
  idNumber: Maybe<Scalars['String']>;
  idType: Maybe<IdType>;
  insertedAt: Maybe<Scalars['DateTime']>;
  name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  plate: Maybe<Scalars['String']>;
  private: Maybe<Scalars['Boolean']>;
  repetitionProcessed: Maybe<Scalars['Boolean']>;
  status: Maybe<GuestStatus>;
  type: Maybe<GuestType>;
  unit: Maybe<Unit>;
  until: Maybe<Scalars['DateTime']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
};

export type GuestConnection = {
  __typename?: 'GuestConnection';
  edges: Maybe<Array<Maybe<GuestEdge>>>;
  pageInfo: PageInfo;
};

export type GuestEdge = {
  __typename?: 'GuestEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Guest>;
};

/** Filtering options for guest */
export type GuestFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a ID number */
  idNumber: InputMaybe<Scalars['String']>;
  /** Matching a ID type */
  idType: InputMaybe<IdType>;
  /** Matching a name */
  name: InputMaybe<Scalars['String']>;
  /** Matching a phone */
  phone: InputMaybe<Scalars['String']>;
  /** Matching a plate */
  plate: InputMaybe<Scalars['String']>;
  /** Matching a status */
  status: InputMaybe<Array<GuestStatus>>;
  /** Not matching a status */
  statusExclude: InputMaybe<Array<GuestStatus>>;
  /** Matching a type */
  type: InputMaybe<Array<GuestType>>;
};

export type GuestInsight = {
  __typename?: 'GuestInsight';
  active: Maybe<Scalars['Int']>;
  activeGuests: Maybe<Scalars['Int']>;
  activeServices: Maybe<Scalars['Int']>;
  guests: Maybe<Scalars['Int']>;
  registered: Maybe<Scalars['Int']>;
  services: Maybe<Scalars['Int']>;
  total: Maybe<Scalars['Int']>;
};

/** Filtering options for the guest insight */
export type GuestInsightFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Ending date */
  endDate: InputMaybe<Scalars['DateTime']>;
  /** Starting date */
  startDate: InputMaybe<Scalars['DateTime']>;
};

/** Ordering options for guest */
export type GuestSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderGuestField;
};

export type GuestStatus = 'ACTIVE' | 'CANCELED' | 'DELETED' | 'EXPIRED' | 'PENDING' | 'PROCESSED' | 'REGISTERED';

export type GuestType = 'GUEST' | 'SERVICE';

export type HumanSex = 'FEMALE' | 'MALE' | 'OTHER';

export type IdType = 'CC' | 'CE' | 'OT' | 'PA' | 'TI';

export type JoinCode = Node & {
  __typename?: 'JoinCode';
  code: Maybe<Scalars['String']>;
  createdBy: Maybe<User>;
  expire: Maybe<Scalars['DateTime']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  maxUses: Maybe<Scalars['Int']>;
  role: Maybe<UnitRoles>;
  status: Maybe<JoinCodeStatus>;
  unit: Maybe<Unit>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
  uses: Maybe<Scalars['Int']>;
};

export type JoinCodeConnection = {
  __typename?: 'JoinCodeConnection';
  edges: Maybe<Array<Maybe<JoinCodeEdge>>>;
  pageInfo: PageInfo;
};

export type JoinCodeEdge = {
  __typename?: 'JoinCodeEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<JoinCode>;
};

/** Filtering options for join_code */
export type JoinCodeFilter = {
  /** Matching the code */
  code: InputMaybe<Scalars['String']>;
  /** Is max used? */
  maxUsed: InputMaybe<Scalars['Boolean']>;
  /** Matching a status */
  status: InputMaybe<JoinCodeStatus>;
  /** Matching a unit */
  unit: InputMaybe<Scalars['ID']>;
};

/** Ordering options for join codes */
export type JoinCodeSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: JoinCodeSortField;
};

/** The fields allowed to be sorted */
export type JoinCodeSortField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** Number of max uses */
  | 'MAX_USES'
  /** Unit */
  | 'UNIT_ID'
  /** Number of uses */
  | 'USES';

export type JoinCodeStatus = 'ACTIVE' | 'EXPIRED' | 'INACTIVE' | 'USED';

export type LoginMethod = 'EMAIL_CODE' | 'EMAIL_PASSWORD' | 'PHONE_CODE';

export type News = Node & {
  __typename?: 'News';
  allowComments: Maybe<Scalars['Boolean']>;
  allowReactions: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  building: Maybe<Building>;
  buildingId: Maybe<Scalars['String']>;
  comments: Maybe<NewsCommentConnection>;
  complex: Maybe<Complex>;
  complexId: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  files: Maybe<Array<Maybe<File>>>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  reactions: Maybe<NewsReactionConnection>;
  status: Maybe<NewsStatus>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
};

export type NewsCommentsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<NewsCommentFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<NewsCommentSort>;
};

export type NewsReactionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
};

export type NewsComment = Node & {
  __typename?: 'NewsComment';
  comment: Maybe<Scalars['String']>;
  complexId: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  news: Maybe<News>;
  status: Maybe<NewsCommentStatus>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
};

export type NewsCommentConnection = {
  __typename?: 'NewsCommentConnection';
  edges: Maybe<Array<Maybe<NewsCommentEdge>>>;
  pageInfo: PageInfo;
};

export type NewsCommentEdge = {
  __typename?: 'NewsCommentEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<NewsComment>;
};

/** Filtering options for news comments */
export type NewsCommentFilter = {
  /** Matching a comment */
  comment: InputMaybe<Scalars['String']>;
  /** Matching a news */
  news: InputMaybe<Scalars['ID']>;
  /** Matching a status */
  status: InputMaybe<NewsCommentStatus>;
};

/** Ordering options for news comments */
export type NewsCommentSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: NewsCommentSortField;
};

/** The fields allowed to be sorted */
export type NewsCommentSortField =
  /** Date it was created */
  'INSERTED_AT';

export type NewsCommentStatus = 'DELETED' | 'HIDDEN' | 'PUBLISHED';

export type NewsConnection = {
  __typename?: 'NewsConnection';
  edges: Maybe<Array<Maybe<NewsEdge>>>;
  pageInfo: PageInfo;
};

export type NewsEdge = {
  __typename?: 'NewsEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<News>;
};

/** Filtering options for news */
export type NewsFilter = {
  /** IDs of the complexes from which the news will be obtained */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a status */
  statuses: InputMaybe<Array<NewsStatus>>;
  /** Whether to limit by buildings or not */
  strictBuilding: InputMaybe<Scalars['Boolean']>;
  /** Matching a title */
  title: InputMaybe<Scalars['String']>;
};

export type NewsReaction = Node & {
  __typename?: 'NewsReaction';
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID'];
  news: Maybe<News>;
  reaction: Maybe<NewsReactionType>;
};

/** The action performed with the new reaction */
export type NewsReactionAction =
  /** Reaction created, there was no previous reaction */
  | 'CREATE'
  /** Reaction deleted, there was the same reaction */
  | 'DELETE'
  /** Reaction updated, there was a different reaction */
  | 'UPDATE';

export type NewsReactionConnection = {
  __typename?: 'NewsReactionConnection';
  edges: Maybe<Array<Maybe<NewsReactionEdge>>>;
  pageInfo: PageInfo;
  /** Total number of each reaction, only reactions with a count greater than zero are shown */
  total: Maybe<Array<Maybe<NewsReactionCount>>>;
};

export type NewsReactionCount = {
  __typename?: 'NewsReactionCount';
  count: Maybe<Scalars['Int']>;
  reacted: Maybe<Scalars['Boolean']>;
  reaction: Maybe<NewsReactionType>;
};

export type NewsReactionEdge = {
  __typename?: 'NewsReactionEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<NewsReaction>;
};

export type NewsReactionType = 'ANGRY' | 'CLAP' | 'DISLIKE' | 'LIKE' | 'LOVE' | 'SAD';

/** Ordering options for news */
export type NewsSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderNewsField;
};

export type NewsStatus = 'DRAFT' | 'PUBLISHED';

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  body: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  read: Maybe<Scalars['Boolean']>;
  resourceExtra: Maybe<Scalars['String']>;
  resourceId: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<NotificationType>;
  updatedAt: Maybe<Scalars['DateTime']>;
  user: Maybe<User>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Maybe<Array<Maybe<NotificationEdge>>>;
  pageInfo: PageInfo;
  /** Total number of unread notifications */
  unreadCount: Maybe<Scalars['Int']>;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Notification>;
};

/** Filtering options for notifications */
export type NotificationFilter = {
  /** After a date */
  after: InputMaybe<Scalars['DateTime']>;
  /** After a date or if is unread */
  afterOrUnread: InputMaybe<Scalars['DateTime']>;
  read: InputMaybe<Scalars['Boolean']>;
  type: InputMaybe<Scalars['String']>;
};

/** Ordering options for notifications */
export type NotificationSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: NotificationSortField;
};

/** The fields allowed to be sorted */
export type NotificationSortField =
  /** Date it was created */
  'INSERTED_AT';

export type NotificationType =
  | 'NEWS_NEW'
  | 'PETITION_COMMENT_NEW'
  | 'PETITION_NEW'
  | 'PETITION_STATUS_NEW'
  | 'POLL_CLOSED'
  | 'POLL_NEW'
  | 'UNIT_ROLE_NEW'
  | 'UNIT_ROLE_REMOVE'
  | 'VEHICLE_NEW';

/** The fields allowed to be sorted */
export type OrderBillboardField =
  /** Date it was created */
  'INSERTED_AT';

export type OrderDirection = 'ASC' | 'DESC';

/** The fields allowed to be sorted */
export type OrderFeedbackField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** Date it was updated */
  | 'UPDATED_AT';

/** The fields allowed to be sorted */
export type OrderGuestField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** First title */
  | 'TITLE';

/** The fields allowed to be sorted */
export type OrderLocationField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** First name */
  | 'NAME';

/** The fields allowed to be sorted */
export type OrderNewsField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** First title */
  | 'TITLE';

/** The fields allowed to be sorted */
export type OrderPackageField =
  /** Date it was created */
  'INSERTED_AT';

/** The fields allowed to be sorted */
export type OrderPanicAlertField =
  /** Date it was created */
  'INSERTED_AT';

/** The fields allowed to be sorted */
export type OrderPetField =
  /** Date it was created */
  'INSERTED_AT';

/** The fields allowed to be sorted */
export type OrderPetitionCommentField =
  /** Date it was created */
  'INSERTED_AT';

/** The fields allowed to be sorted */
export type OrderPetitionField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** Priority value */
  | 'PRIORITY'
  /** Date it was updated */
  | 'UPDATED_AT';

/** The fields allowed to be sorted */
export type OrderPetitionStatusField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** Order by title */
  | 'TITLE';

/** The fields allowed to be sorted */
export type OrderPollAnswerField =
  /** Date it was created */
  'INSERTED_AT';

/** The fields allowed to be sorted */
export type OrderPollField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** First title */
  | 'TITLE';

/** The fields allowed to be sorted */
export type OrderUnitContactField =
  /** Date it was created */
  'INSERTED_AT';

/** The fields allowed to be sorted */
export type OrderUnitNoteField =
  /** Date it was created */
  'INSERTED_AT';

/** The fields allowed to be sorted */
export type OrderUserField =
  /** Last name */
  | 'EMAIL'
  /** First name */
  | 'FIRST_NAME'
  /** Date it was created */
  | 'INSERTED_AT'
  /** Last name */
  | 'LAST_NAME'
  /** Date it was updated */
  | 'UPDATED_AT';

/** The fields allowed to be sorted */
export type OrderVehicleField =
  /** Date it was created */
  'INSERTED_AT';

export type Package = Node & {
  __typename?: 'Package';
  company: Maybe<Scalars['String']>;
  createdBy: Maybe<User>;
  deliveredBy: Maybe<User>;
  deliveredTo: Maybe<User>;
  description: Maybe<Scalars['String']>;
  file: Maybe<File>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  status: Maybe<PackageStatus>;
  type: Maybe<PackageType>;
  unit: Maybe<Unit>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
};

export type PackageConnection = {
  __typename?: 'PackageConnection';
  edges: Maybe<Array<Maybe<PackageEdge>>>;
  pageInfo: PageInfo;
};

export type PackageEdge = {
  __typename?: 'PackageEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Package>;
};

/** Filtering options for package */
export type PackageFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a status */
  status: InputMaybe<Array<PackageStatus>>;
  /** Matching a type */
  types: InputMaybe<Array<PackageType>>;
};

export type PackageInsight = {
  __typename?: 'PackageInsight';
  pending: Maybe<Scalars['Int']>;
  picked: Maybe<Scalars['Int']>;
  total: Maybe<Scalars['Int']>;
};

/** Filtering options for the ackage insight */
export type PackageInsightFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Ending date */
  endDate: InputMaybe<Scalars['DateTime']>;
  /** Starting date */
  startDate: InputMaybe<Scalars['DateTime']>;
};

/** Ordering options for package */
export type PackageSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderPackageField;
};

export type PackageStatus = 'PENDING' | 'PICKED';

export type PackageType = 'ENVELOPE' | 'OTHER' | 'PACKAGE' | 'RECEIPT';

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type PanicAlert = Node & {
  __typename?: 'PanicAlert';
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  type: Maybe<PanicType>;
  unit: Maybe<Unit>;
  updatedBy: Maybe<User>;
};

export type PanicAlertConnection = {
  __typename?: 'PanicAlertConnection';
  edges: Maybe<Array<Maybe<PanicAlertEdge>>>;
  pageInfo: PageInfo;
};

export type PanicAlertEdge = {
  __typename?: 'PanicAlertEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<PanicAlert>;
};

/** Filtering options for panic alerts */
export type PanicAlertFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a type */
  types: InputMaybe<Array<PanicType>>;
};

/** Ordering options for panic alert */
export type PanicAlertSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderPanicAlertField;
};

export type PanicType = 'FIRE' | 'GAS_LEAK' | 'HEALTH' | 'OTHER' | 'THEFT' | 'VIOLENCE';

export type Pet = Node & {
  __typename?: 'Pet';
  birthday: Maybe<Scalars['Date']>;
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  name: Maybe<Scalars['String']>;
  sex: Maybe<PetSex>;
  type: Maybe<PetType>;
  unit: Maybe<Unit>;
  updatedBy: Maybe<User>;
  vaccinesDate: Maybe<Scalars['Date']>;
};

export type PetConnection = {
  __typename?: 'PetConnection';
  edges: Maybe<Array<Maybe<PetEdge>>>;
  pageInfo: PageInfo;
};

export type PetEdge = {
  __typename?: 'PetEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Pet>;
};

/** Filtering options for pet */
export type PetFilter = {
  /** IDs of the complexes from which the pet will be obtained */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a name */
  name: InputMaybe<Scalars['String']>;
  /** Matching a sex */
  sex: InputMaybe<PetSex>;
  /** Matching a type */
  type: InputMaybe<PetType>;
};

export type PetInsight = {
  __typename?: 'PetInsight';
  total: Maybe<Scalars['Int']>;
  type: Maybe<Array<Maybe<PetTypeInsight>>>;
};

/** Filtering options for the pet insight */
export type PetInsightFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
};

export type PetSex = 'FEMALE' | 'MALE';

/** Ordering options for pet */
export type PetSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderPetField;
};

export type PetType = 'BIRD' | 'CAT' | 'DOG' | 'HAMSTER' | 'OTHER' | 'PIG' | 'RABBIT';

export type PetTypeInsight = {
  __typename?: 'PetTypeInsight';
  count: Maybe<Scalars['Int']>;
  type: Maybe<PetType>;
};

export type Petition = Node & {
  __typename?: 'Petition';
  allowComments: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  comments: Maybe<PetitionCommentConnection>;
  complex: Maybe<Complex>;
  complexId: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  currentStatus: Maybe<PetitionStatusType>;
  files: Maybe<Array<Maybe<File>>>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  priority: Maybe<Scalars['Int']>;
  privacy: Maybe<Array<Maybe<PetitionPrivacy>>>;
  statuses: Maybe<Array<Maybe<PetitionStatus>>>;
  title: Maybe<Scalars['String']>;
  type: Maybe<PetitionType>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
};

export type PetitionCommentsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PetitionCommentFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PetitionCommentSort>;
};

export type PetitionComment = Node & {
  __typename?: 'PetitionComment';
  comment: Maybe<Scalars['String']>;
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  petition: Maybe<Petition>;
  status: Maybe<PetitionCommentStatus>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
};

export type PetitionCommentConnection = {
  __typename?: 'PetitionCommentConnection';
  edges: Maybe<Array<Maybe<PetitionCommentEdge>>>;
  pageInfo: PageInfo;
};

export type PetitionCommentEdge = {
  __typename?: 'PetitionCommentEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<PetitionComment>;
};

/** Filtering options for petition_comment */
export type PetitionCommentFilter = {
  /** Matching a privacy */
  after: InputMaybe<Scalars['DateTime']>;
  /** Created before the date */
  before: InputMaybe<Scalars['DateTime']>;
  /** Matching a comment */
  comment: InputMaybe<Scalars['String']>;
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a creator user name */
  creatorName: InputMaybe<Scalars['String']>;
  /** Matching a status */
  status: InputMaybe<Array<PetitionCommentStatus>>;
  /** Not matching a status */
  statusExclude: InputMaybe<Array<PetitionCommentStatus>>;
};

/** Ordering options for petition_comment */
export type PetitionCommentSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderPetitionCommentField;
};

export type PetitionCommentStatus = 'DELETED' | 'HIDDEN' | 'PUBLISHED';

export type PetitionConnection = {
  __typename?: 'PetitionConnection';
  edges: Maybe<Array<Maybe<PetitionEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']>;
};

export type PetitionEdge = {
  __typename?: 'PetitionEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Petition>;
};

/** Filtering options for petition */
export type PetitionFilter = {
  /** Created after the date */
  after: InputMaybe<Scalars['DateTime']>;
  /** Created before the date */
  before: InputMaybe<Scalars['DateTime']>;
  /** IDs of the complexes from which the petition will be obtained */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a user name */
  creatorName: InputMaybe<Scalars['String']>;
  /** Matching a priority */
  priority: InputMaybe<Array<Scalars['Int']>>;
  /** Matching a privacy */
  privacy: InputMaybe<Array<PetitionPrivacy>>;
  /** Matching a status */
  status: InputMaybe<Array<PetitionStatusType>>;
  /** Not matching a status */
  statusExclude: InputMaybe<Array<PetitionStatusType>>;
  /** Matching a name */
  title: InputMaybe<Scalars['String']>;
  /** Matching a type */
  type: InputMaybe<Array<PetitionType>>;
};

export type PetitionInsight = {
  __typename?: 'PetitionInsight';
  comments: Maybe<Array<Maybe<Scalars['Int']>>>;
  finished: Maybe<Array<Maybe<Scalars['Int']>>>;
  petitions: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusClosed: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusInProgress: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusResolved: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusUnderReview: Maybe<Array<Maybe<Scalars['Int']>>>;
  typeClaim: Maybe<Array<Maybe<Scalars['Int']>>>;
  typeComplaint: Maybe<Array<Maybe<Scalars['Int']>>>;
  typeQuestion: Maybe<Array<Maybe<Scalars['Int']>>>;
  typeSuggestion: Maybe<Array<Maybe<Scalars['Int']>>>;
  unfinished: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Filtering options for the petition insight */
export type PetitionInsightFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Ending date */
  endDate: InputMaybe<Scalars['DateTime']>;
  /** Starting date */
  startDate: InputMaybe<Scalars['DateTime']>;
};

export type PetitionPrivacy = 'ADMIN' | 'COUNCIL' | 'DOORMAN' | 'HABITANT' | 'HOUSEHOLDER' | 'OWNER';

/** Ordering options for petition */
export type PetitionSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderPetitionField;
};

export type PetitionStatus = Node & {
  __typename?: 'PetitionStatus';
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  petition: Maybe<Petition>;
  status: Maybe<PetitionStatusType>;
};

export type PetitionStatusConnection = {
  __typename?: 'PetitionStatusConnection';
  edges: Maybe<Array<Maybe<PetitionStatusEdge>>>;
  pageInfo: PageInfo;
};

export type PetitionStatusEdge = {
  __typename?: 'PetitionStatusEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<PetitionStatus>;
};

/** Filtering options for petition_status */
export type PetitionStatusFilter = {
  /** Matching a privacy */
  after: InputMaybe<Scalars['DateTime']>;
  /** Created before the date */
  before: InputMaybe<Scalars['DateTime']>;
  /** Matching a type */
  status: InputMaybe<Array<PetitionStatusType>>;
};

/** Ordering options for petition_status */
export type PetitionStatusSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderPetitionStatusField;
};

export type PetitionStatusType = 'CLOSED' | 'IN_PROGRESS' | 'OPEN' | 'RESOLVED' | 'UNDER_REVIEW';

export type PetitionType = 'CLAIM' | 'COMPLAINT' | 'QUESTION' | 'SUGGESTION';

export type Poll = Node & {
  __typename?: 'Poll';
  building: Maybe<Building>;
  buildingId: Maybe<Scalars['ID']>;
  closeAt: Maybe<Scalars['DateTime']>;
  complex: Maybe<Complex>;
  complexId: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  options: Maybe<Array<Maybe<PollOption>>>;
  privacy: Maybe<PollPrivacy>;
  question: Maybe<Scalars['String']>;
  status: Maybe<PollStatus>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<User>;
};

export type PollAnswer = Node & {
  __typename?: 'PollAnswer';
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  option: Maybe<PollOption>;
  unitRole: Maybe<UnitRole>;
};

export type PollAnswerConnection = {
  __typename?: 'PollAnswerConnection';
  /** Total number of own votes */
  countMine: Maybe<Scalars['Int']>;
  /** Total number of votes */
  countTotal: Maybe<Scalars['Int']>;
  edges: Maybe<Array<Maybe<PollAnswerEdge>>>;
  pageInfo: PageInfo;
};

export type PollAnswerEdge = {
  __typename?: 'PollAnswerEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<PollAnswer>;
};

/** Filtering options for poll answer */
export type PollAnswerFilter = {
  /** Filter answers for unit */
  unitId: InputMaybe<Scalars['ID']>;
  /** Filter answers for role type */
  unitRole: InputMaybe<PollAnswerRoleFilter>;
};

export type PollAnswerRoleFilter = {
  distinc: InputMaybe<Scalars['Boolean']>;
  role: InputMaybe<UnitRoles>;
};

/** Ordering options for poll answer */
export type PollAnswerSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderPollAnswerField;
};

export type PollConnection = {
  __typename?: 'PollConnection';
  edges: Maybe<Array<Maybe<PollEdge>>>;
  pageInfo: PageInfo;
};

export type PollEdge = {
  __typename?: 'PollEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Poll>;
};

/** Filtering options for poll */
export type PollFilter = {
  /** IDs of the complexes from which the poll will be obtained */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a privacy */
  privacy: InputMaybe<Array<InputMaybe<PollPrivacy>>>;
  /** Matching a question */
  question: InputMaybe<Scalars['String']>;
  /** Matching a status */
  status: InputMaybe<Array<InputMaybe<PollStatus>>>;
  /** Not matching a status */
  statusExclude: InputMaybe<Array<InputMaybe<PollStatus>>>;
  /** False to show polls of buildings not related to the user */
  strictBuilding: InputMaybe<Scalars['Boolean']>;
};

export type PollOption = Node & {
  __typename?: 'PollOption';
  answers: Maybe<PollAnswerConnection>;
  complexId: Maybe<Scalars['ID']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  option: Maybe<Scalars['String']>;
  poll: Maybe<Poll>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type PollOptionAnswersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PollAnswerFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PollAnswerSort>;
};

export type PollOptionConnection = {
  __typename?: 'PollOptionConnection';
  edges: Maybe<Array<Maybe<PollOptionEdge>>>;
  pageInfo: PageInfo;
};

export type PollOptionEdge = {
  __typename?: 'PollOptionEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<PollOption>;
};

/** The fields allowed to be sorted */
export type PollOptionSort =
  /** Date it was created */
  'INSERTED_AT';

export type PollPrivacy = 'ANONYMOUS' | 'PRIVATE' | 'PUBLIC';

/** Ordering options for poll */
export type PollSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderPollField;
};

export type PollStatus = 'ACTIVE' | 'CLOSED' | 'EXPIRED';

/** The action performed with the new vote */
export type PollVoteActionType =
  /** Vote created, there was no previous vote */
  | 'CREATE'
  /** Vote deleted, there was the same vote */
  | 'DELETE'
  /** Vote updated, there was a different vote */
  | 'UPDATE';

export type RoleStatus = 'ACTIVE' | 'REMOVED';

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Cancels a guest authorization */
  cancelGuest: Maybe<Guest>;
  /** Cancel a join_code */
  cancelJoinCode: Maybe<JoinCode>;
  /** Closes a poll */
  closePoll: Maybe<Poll>;
  /** Create a new billboard */
  createBillboard: Maybe<Billboard>;
  /** Create a new building */
  createBuilding: Maybe<Building>;
  /** Create a new city */
  createCity: Maybe<City>;
  /** Create a new complex */
  createComplex: Maybe<Complex>;
  /** Create a new complex restriction */
  createComplexRestriction: Maybe<ComplexRestriction>;
  /** Create a new country */
  createCountry: Maybe<Country>;
  /** Create a new district */
  createDistrict: Maybe<District>;
  /** Create a new feedback */
  createFeedback: Maybe<Feedback>;
  /** Create a new guest */
  createGuest: Maybe<Guest>;
  /** Create a join code */
  createJoinCode: Maybe<JoinCode>;
  /** Create join codes for every unit of complex */
  createJoinCodeComplex: Maybe<Array<Maybe<JoinCode>>>;
  /** Create multiple join codes */
  createJoinCodeMultiple: Maybe<Array<Maybe<JoinCode>>>;
  /** Create a new news */
  createNews: Maybe<News>;
  /** Create a new news comment */
  createNewsComment: Maybe<NewsComment>;
  /** Create a notification */
  createNotification: Maybe<Notification>;
  /** Create a new package */
  createPackages: Maybe<Package>;
  /** Create a new panic_alert */
  createPanicAlert: Maybe<PanicAlert>;
  /** Create a new pet */
  createPet: Maybe<Pet>;
  /** Create a new petition */
  createPetition: Maybe<Petition>;
  /** Create a new petition comment */
  createPetitionComment: Maybe<PetitionComment>;
  /** Create a new petition status */
  createPetitionStatus: Maybe<PetitionStatus>;
  /** Create a new poll */
  createPoll: Maybe<Poll>;
  /** Create a new state */
  createState: Maybe<State>;
  /** Create a new unit */
  createUnit: Maybe<Unit>;
  /** Create a new unit contact */
  createUnitContact: Maybe<UnitContact>;
  /** Create a new unit note */
  createUnitNote: Maybe<UnitNote>;
  /** Create a new vehicle */
  createVehicle: Maybe<Vehicle>;
  /** Delete the current user account */
  deleteAccount: Maybe<SuccessResult>;
  /** Delete a billboard */
  deleteBillboard: Maybe<SuccessResult>;
  /** Delete a building */
  deleteBuilding: Maybe<SuccessResult>;
  /** Delete a new city */
  deleteCity: Maybe<SuccessResult>;
  /** Delete a complex */
  deleteComplex: Maybe<SuccessResult>;
  /** Delete a complex_restriction */
  deleteComplexRestriction: Maybe<SuccessResult>;
  /** Delete a new country */
  deleteCountry: Maybe<SuccessResult>;
  /** Delete a new district */
  deleteDistrict: Maybe<SuccessResult>;
  /** Delete a feedback */
  deleteFeedback: Maybe<SuccessResult>;
  /** Delete a guest */
  deleteGuest: Maybe<SuccessResult>;
  /** Delete a join_code */
  deleteJoinCode: Maybe<SuccessResult>;
  /** Delete a news */
  deleteNews: Maybe<SuccessResult>;
  /** Delete a news comment */
  deleteNewsComment: Maybe<SuccessResult>;
  /** Delete a news comment */
  deleteNewsReaction: Maybe<SuccessResult>;
  /** Delete a notifications */
  deleteNotification: Maybe<SuccessResult>;
  /** Delete a package */
  deletePackage: Maybe<SuccessResult>;
  /** Delete a panic_alert */
  deletePanicAlert: Maybe<SuccessResult>;
  /** Delete a pet */
  deletePet: Maybe<SuccessResult>;
  /** Delete a petition */
  deletePetition: Maybe<SuccessResult>;
  /** Delete a petition comment */
  deletePetitionComment: Maybe<SuccessResult>;
  /** Delete a petition status */
  deletePetitionStatus: Maybe<SuccessResult>;
  /** Delete a poll */
  deletePoll: Maybe<SuccessResult>;
  /** Delete a new state */
  deleteState: Maybe<SuccessResult>;
  /** Delete a unit */
  deleteUnit: Maybe<SuccessResult>;
  /** Delete a unit contact */
  deleteUnitContact: Maybe<SuccessResult>;
  /** Delete a unit note */
  deleteUnitNote: Maybe<SuccessResult>;
  /** Delete a user account */
  deleteUserAccount: Maybe<SuccessResult>;
  /** Delete a vehicle */
  deleteVehicle: Maybe<SuccessResult>;
  /** Creates an auth code */
  generateAuthCode: Maybe<AuthCode>;
  /** Mark package as picked */
  pickPackage: Maybe<Package>;
  /** Process a guest authorization */
  processGuest: Maybe<Guest>;
  /** Mark as read all unread notifications */
  readAllNotifications: Maybe<SuccessResult>;
  /** Mark as read one notifications */
  readNotification: Maybe<SuccessResult>;
  /** Exchange the refresh token for an access token */
  refreshToken: Maybe<AuthResult>;
  /** Registers a new guest */
  registerGuest: Maybe<Guest>;
  /** Remove a complex_restriction */
  removeComplexRestriction: Maybe<SuccessResult>;
  /** Remove complex role for a user */
  removeComplexRole: Maybe<ComplexRole>;
  /** Remove unit role for a user */
  removeUnitRole: Maybe<UnitRole>;
  /** Request the auth code. Either email or phone number are required. */
  requestAuthCode: Maybe<SuccessResult>;
  /** Reset the user's password */
  resetPassword: Maybe<SuccessResult>;
  /** Set complex role for a user */
  setComplexRole: Maybe<ComplexRole>;
  /** Set a new news reaction */
  setNewsReaction: Maybe<SetNewsReactionResult>;
  /** Set a user password if don't have one */
  setPassword: Maybe<User>;
  /** Vote in a poll */
  setPollVote: Maybe<SetPollVoteResult>;
  /** Set unit role for a user */
  setUnitRole: Maybe<UnitRole>;
  /** Retrive the token for auth */
  signIn: Maybe<AuthResult>;
  /** Terminates current user session */
  signOut: Maybe<SuccessResult>;
  /** Register a new user. Either email/password or phone number are required. */
  signUp: Maybe<SuccessResult>;
  /** Mark vehicle as deleted */
  softDeleteVehicle: Maybe<Vehicle>;
  /** Terminates a user session */
  terminateSession: Maybe<SuccessResult>;
  /** Update a billboard */
  updateBillboard: Maybe<Billboard>;
  /** Update a building */
  updateBuilding: Maybe<Building>;
  /** Update a city */
  updateCity: Maybe<City>;
  /** Update a complex */
  updateComplex: Maybe<Complex>;
  /** Update a complex restriction */
  updateComplexRestriction: Maybe<ComplexRestriction>;
  /** Update a country */
  updateCountry: Maybe<Country>;
  /** Update a district */
  updateDistrict: Maybe<District>;
  /** Update or add the email of the user, with code it verifies it */
  updateEmail: Maybe<User>;
  /** Update a feedback */
  updateFeedback: Maybe<Feedback>;
  /** Update a guest */
  updateGuest: Maybe<Guest>;
  /** Update a join_code */
  updateJoinCode: Maybe<JoinCode>;
  /** Update a news */
  updateNews: Maybe<News>;
  /** Update a news comment */
  updateNewsComment: Maybe<NewsComment>;
  /** Update a news comment status */
  updateNewsCommentStatus: Maybe<NewsComment>;
  /** Update a notification */
  updateNotification: Maybe<SuccessResult>;
  /** Update a package */
  updatePackage: Maybe<Package>;
  /** Update a panic_alert */
  updatePanicAlert: Maybe<PanicAlert>;
  /** Update a pet */
  updatePet: Maybe<Pet>;
  /** Update a petition */
  updatePetition: Maybe<Petition>;
  /** Update a petition comment */
  updatePetitionComment: Maybe<PetitionComment>;
  /** Update a petition comment status */
  updatePetitionCommentStatus: Maybe<PetitionComment>;
  /** Update a petition with elevated rights */
  updatePetitionElevated: Maybe<Petition>;
  /** Update or add the phone of the user */
  updatePhone: Maybe<User>;
  /** Update a poll */
  updatePoll: Maybe<Poll>;
  /** Update the user profile data */
  updateProfile: Maybe<User>;
  /** Update the user's session info */
  updateSession: Maybe<Session>;
  /** Update a state */
  updateState: Maybe<State>;
  /** Update a unit */
  updateUnit: Maybe<Unit>;
  /** Update a unit contact */
  updateUnitContact: Maybe<UnitContact>;
  /** Update a unit note */
  updateUnitNote: Maybe<UnitNote>;
  /** Update given user */
  updateUser: Maybe<User>;
  /** Change the user's password */
  updateUserPassword: Maybe<SuccessResult>;
  /** Update a vehicle */
  updateVehicle: Maybe<Vehicle>;
  /** Upload a new file */
  uploadFile: Maybe<File>;
  /** Use a join code */
  useJoinCode: Maybe<UnitRole>;
};

export type RootMutationTypeCancelGuestArgs = {
  id: Scalars['ID'];
  withRepetitions: Scalars['Boolean'];
};

export type RootMutationTypeCancelJoinCodeArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeClosePollArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeCreateBillboardArgs = {
  buildingId: InputMaybe<Scalars['ID']>;
  complexId: Scalars['ID'];
  fileId: Scalars['ID'];
};

export type RootMutationTypeCreateBuildingArgs = {
  complexId: Scalars['ID'];
  name: Scalars['String'];
};

export type RootMutationTypeCreateCityArgs = {
  name: Scalars['String'];
  stateId: Scalars['ID'];
};

export type RootMutationTypeCreateComplexArgs = {
  address: InputMaybe<Scalars['String']>;
  districtId: InputMaybe<Scalars['ID']>;
  features: InputMaybe<Array<InputMaybe<ComplexFeatures>>>;
  lat: InputMaybe<Scalars['Float']>;
  long: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  status: ComplexStatus;
  stratum: InputMaybe<Scalars['Int']>;
  type: ComplexType;
};

export type RootMutationTypeCreateComplexRestrictionArgs = {
  complexId: Scalars['ID'];
  description: Scalars['String'];
  expire: Scalars['DateTime'];
  type: ComplexRestrictionType;
  userId: Scalars['ID'];
};

export type RootMutationTypeCreateCountryArgs = {
  name: Scalars['String'];
};

export type RootMutationTypeCreateDistrictArgs = {
  cityId: Scalars['ID'];
  name: Scalars['String'];
};

export type RootMutationTypeCreateFeedbackArgs = {
  description: Scalars['String'];
  fileId: InputMaybe<Scalars['ID']>;
  type: FeedbackType;
};

export type RootMutationTypeCreateGuestArgs = {
  byDay: InputMaybe<Array<ByDayTypes>>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  description: InputMaybe<Scalars['String']>;
  fileId: Scalars['ID'];
  freq: InputMaybe<FrequencyTypes>;
  idNumber: InputMaybe<Scalars['String']>;
  idType: InputMaybe<IdType>;
  name: Scalars['String'];
  phone: InputMaybe<Scalars['String']>;
  plate: InputMaybe<Scalars['String']>;
  private: InputMaybe<Scalars['Boolean']>;
  type: GuestType;
  unitId: Scalars['ID'];
  until: InputMaybe<Scalars['DateTime']>;
};

export type RootMutationTypeCreateJoinCodeArgs = {
  expire: Scalars['DateTime'];
  maxUses: Scalars['Int'];
  role: UnitRoles;
  unitId: Scalars['ID'];
};

export type RootMutationTypeCreateJoinCodeComplexArgs = {
  complexId: Scalars['ID'];
  expire: Scalars['DateTime'];
  maxUses: Scalars['Int'];
  role: UnitRoles;
};

export type RootMutationTypeCreateJoinCodeMultipleArgs = {
  expire: Scalars['DateTime'];
  maxUses: Scalars['Int'];
  role: UnitRoles;
  unitIds: Array<Scalars['ID']>;
};

export type RootMutationTypeCreateNewsArgs = {
  allowComments: InputMaybe<Scalars['Boolean']>;
  allowReactions: InputMaybe<Scalars['Boolean']>;
  body: InputMaybe<Scalars['String']>;
  buildingId: InputMaybe<Scalars['ID']>;
  complexId: Scalars['ID'];
  files: InputMaybe<Array<Scalars['ID']>>;
  status: InputMaybe<NewsStatus>;
  title: Scalars['String'];
};

export type RootMutationTypeCreateNewsCommentArgs = {
  comment: Scalars['String'];
  newsId: Scalars['ID'];
};

export type RootMutationTypeCreateNotificationArgs = {
  body: InputMaybe<Scalars['String']>;
  read: InputMaybe<Scalars['Boolean']>;
  resourceExtra: InputMaybe<Scalars['String']>;
  resourceId: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: NotificationType;
  userId: Scalars['ID'];
};

export type RootMutationTypeCreatePackagesArgs = {
  company: InputMaybe<Scalars['String']>;
  description: InputMaybe<Scalars['String']>;
  fileId: Scalars['ID'];
  type: PackageType;
  units: Array<Scalars['ID']>;
  unitsExclude: InputMaybe<Array<Scalars['ID']>>;
};

export type RootMutationTypeCreatePanicAlertArgs = {
  description: InputMaybe<Scalars['String']>;
  type: PanicType;
  unitId: Scalars['ID'];
};

export type RootMutationTypeCreatePetArgs = {
  birthday: InputMaybe<Scalars['Date']>;
  name: Scalars['String'];
  sex: InputMaybe<PetSex>;
  type: PetType;
  unitId: Scalars['ID'];
  vaccinesDate: InputMaybe<Scalars['Date']>;
};

export type RootMutationTypeCreatePetitionArgs = {
  body: Scalars['String'];
  complexId: Scalars['ID'];
  files: InputMaybe<Array<Scalars['ID']>>;
  privacy: InputMaybe<Array<PetitionPrivacy>>;
  title: Scalars['String'];
  type: PetitionType;
};

export type RootMutationTypeCreatePetitionCommentArgs = {
  comment: Scalars['String'];
  petitionId: Scalars['ID'];
};

export type RootMutationTypeCreatePetitionStatusArgs = {
  petitionId: Scalars['ID'];
  status: PetitionStatusType;
};

export type RootMutationTypeCreatePollArgs = {
  buildingId: InputMaybe<Scalars['ID']>;
  closeAt: InputMaybe<Scalars['DateTime']>;
  complexId: Scalars['ID'];
  description: InputMaybe<Scalars['String']>;
  options: Array<Scalars['String']>;
  privacy: PollPrivacy;
  question: Scalars['String'];
};

export type RootMutationTypeCreateStateArgs = {
  countryId: Scalars['ID'];
  name: Scalars['String'];
};

export type RootMutationTypeCreateUnitArgs = {
  buildingId: InputMaybe<Scalars['ID']>;
  coefficient: InputMaybe<Scalars['Float']>;
  complexId: Scalars['ID'];
  floor: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
  phone: InputMaybe<Scalars['String']>;
  type: UnitType;
};

export type RootMutationTypeCreateUnitContactArgs = {
  description: Scalars['String'];
  email: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  unitId: Scalars['ID'];
};

export type RootMutationTypeCreateUnitNoteArgs = {
  note: Scalars['String'];
  unitId: Scalars['ID'];
};

export type RootMutationTypeCreateVehicleArgs = {
  brand: InputMaybe<Scalars['String']>;
  color: InputMaybe<Scalars['String']>;
  fuel: InputMaybe<FuelType>;
  model: InputMaybe<Scalars['Int']>;
  plate: Scalars['String'];
  publicService: InputMaybe<Scalars['Boolean']>;
  rtm: InputMaybe<Scalars['Date']>;
  soat: InputMaybe<Scalars['Date']>;
  type: VehicleType;
  unitId: Scalars['ID'];
};

export type RootMutationTypeDeleteAccountArgs = {
  code: Scalars['String'];
};

export type RootMutationTypeDeleteBillboardArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteBuildingArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteCityArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteComplexArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteComplexRestrictionArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteCountryArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteDistrictArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteFeedbackArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteGuestArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteJoinCodeArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteNewsArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteNewsCommentArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteNewsReactionArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteNotificationArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeletePackageArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeletePanicAlertArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeletePetArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeletePetitionArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeletePetitionCommentArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeletePetitionStatusArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeletePollArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteStateArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteUnitArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteUnitContactArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteUnitNoteArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeDeleteUserAccountArgs = {
  code: Scalars['String'];
  userId: Scalars['ID'];
};

export type RootMutationTypeDeleteVehicleArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeGenerateAuthCodeArgs = {
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
};

export type RootMutationTypePickPackageArgs = {
  deliveredById: InputMaybe<Scalars['ID']>;
  deliveredToId: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type RootMutationTypeProcessGuestArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeReadNotificationArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRegisterGuestArgs = {
  description: InputMaybe<Scalars['String']>;
  fileId: Scalars['ID'];
  idNumber: InputMaybe<Scalars['String']>;
  idType: InputMaybe<IdType>;
  name: InputMaybe<Scalars['String']>;
  plate: InputMaybe<Scalars['String']>;
  type: GuestType;
  unitId: Scalars['ID'];
};

export type RootMutationTypeRemoveComplexRestrictionArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRemoveComplexRoleArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRemoveUnitRoleArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeRequestAuthCodeArgs = {
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeResetPasswordArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type RootMutationTypeSetComplexRoleArgs = {
  complexId: Scalars['ID'];
  role: ComplexRoles;
  userId: Scalars['ID'];
};

export type RootMutationTypeSetNewsReactionArgs = {
  newsId: Scalars['ID'];
  reaction: NewsReactionType;
};

export type RootMutationTypeSetPasswordArgs = {
  password: Scalars['String'];
};

export type RootMutationTypeSetPollVoteArgs = {
  optionId: Scalars['ID'];
  unitId: Scalars['ID'];
};

export type RootMutationTypeSetUnitRoleArgs = {
  role: UnitRoles;
  unitId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RootMutationTypeSignInArgs = {
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  password: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeSignUpArgs = {
  birthday: InputMaybe<Scalars['Date']>;
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  idNumber: InputMaybe<Scalars['String']>;
  idType: InputMaybe<IdType>;
  lastName: Scalars['String'];
  password: InputMaybe<Scalars['String']>;
  sex: InputMaybe<HumanSex>;
};

export type RootMutationTypeSoftDeleteVehicleArgs = {
  id: Scalars['ID'];
};

export type RootMutationTypeTerminateSessionArgs = {
  sessionId: Scalars['ID'];
};

export type RootMutationTypeUpdateBillboardArgs = {
  buildingId: InputMaybe<Scalars['ID']>;
  fileId: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type RootMutationTypeUpdateBuildingArgs = {
  complexId: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateCityArgs = {
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
  stateId: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUpdateComplexArgs = {
  address: InputMaybe<Scalars['String']>;
  districtId: InputMaybe<Scalars['ID']>;
  features: InputMaybe<Array<InputMaybe<ComplexFeatures>>>;
  id: Scalars['ID'];
  lat: InputMaybe<Scalars['Float']>;
  long: InputMaybe<Scalars['Float']>;
  name: InputMaybe<Scalars['String']>;
  status: InputMaybe<ComplexStatus>;
  stratum: InputMaybe<Scalars['Int']>;
  type: InputMaybe<ComplexType>;
};

export type RootMutationTypeUpdateComplexRestrictionArgs = {
  description: InputMaybe<Scalars['String']>;
  expire: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  status: InputMaybe<ComplexRestrictionStatus>;
  type: InputMaybe<ComplexRestrictionType>;
  userId: InputMaybe<Scalars['ID']>;
};

export type RootMutationTypeUpdateCountryArgs = {
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateDistrictArgs = {
  cityId: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateEmailArgs = {
  code: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

export type RootMutationTypeUpdateFeedbackArgs = {
  description: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  status: InputMaybe<FeedbackStatus>;
  type: InputMaybe<FeedbackType>;
};

export type RootMutationTypeUpdateGuestArgs = {
  byDay: InputMaybe<Array<ByDayTypes>>;
  dateEnd: InputMaybe<Scalars['DateTime']>;
  dateStart: InputMaybe<Scalars['DateTime']>;
  description: InputMaybe<Scalars['String']>;
  fileId: InputMaybe<Scalars['ID']>;
  freq: InputMaybe<FrequencyTypes>;
  id: InputMaybe<Scalars['ID']>;
  idNumber: InputMaybe<Scalars['String']>;
  idType: InputMaybe<IdType>;
  name: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  plate: InputMaybe<Scalars['String']>;
  private: InputMaybe<Scalars['Boolean']>;
  type: InputMaybe<GuestType>;
  unitId: InputMaybe<Scalars['ID']>;
  until: InputMaybe<Scalars['DateTime']>;
};

export type RootMutationTypeUpdateJoinCodeArgs = {
  expire: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  maxUses: InputMaybe<Scalars['Int']>;
  role: InputMaybe<UnitRoles>;
  status: InputMaybe<JoinCodeStatus>;
  unitId: InputMaybe<Scalars['ID']>;
  uses: InputMaybe<Scalars['Int']>;
};

export type RootMutationTypeUpdateNewsArgs = {
  allowComments: InputMaybe<Scalars['Boolean']>;
  allowReactions: InputMaybe<Scalars['Boolean']>;
  body: InputMaybe<Scalars['String']>;
  buildingId: InputMaybe<Scalars['ID']>;
  files: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  status: InputMaybe<NewsStatus>;
  title: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateNewsCommentArgs = {
  comment: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type RootMutationTypeUpdateNewsCommentStatusArgs = {
  id: Scalars['ID'];
  status: NewsCommentStatus;
};

export type RootMutationTypeUpdateNotificationArgs = {
  body: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  read: InputMaybe<Scalars['Boolean']>;
  resourceExtra: InputMaybe<Scalars['String']>;
  resourceId: InputMaybe<Scalars['String']>;
  title: InputMaybe<Scalars['String']>;
  type: InputMaybe<NotificationType>;
};

export type RootMutationTypeUpdatePackageArgs = {
  company: InputMaybe<Scalars['String']>;
  description: InputMaybe<Scalars['String']>;
  fileId: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  type: InputMaybe<PackageType>;
};

export type RootMutationTypeUpdatePanicAlertArgs = {
  description: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  type: InputMaybe<PanicType>;
  unitId: Scalars['ID'];
};

export type RootMutationTypeUpdatePetArgs = {
  birthday: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
  sex: InputMaybe<PetSex>;
  type: InputMaybe<PetType>;
  vaccinesDate: InputMaybe<Scalars['Date']>;
};

export type RootMutationTypeUpdatePetitionArgs = {
  body: InputMaybe<Scalars['String']>;
  files: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  title: InputMaybe<Scalars['String']>;
  type: InputMaybe<PetitionType>;
};

export type RootMutationTypeUpdatePetitionCommentArgs = {
  comment: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type RootMutationTypeUpdatePetitionCommentStatusArgs = {
  id: Scalars['ID'];
  status: InputMaybe<PetitionCommentStatus>;
};

export type RootMutationTypeUpdatePetitionElevatedArgs = {
  allowComments: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  priority: InputMaybe<Scalars['Int']>;
  privacy: InputMaybe<Array<PetitionPrivacy>>;
};

export type RootMutationTypeUpdatePhoneArgs = {
  celCountry: Scalars['String'];
  celNumber: Scalars['String'];
  code: Scalars['String'];
};

export type RootMutationTypeUpdatePollArgs = {
  buildingId: InputMaybe<Scalars['ID']>;
  closeAt: InputMaybe<Scalars['DateTime']>;
  description: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  question: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateProfileArgs = {
  avatarId: InputMaybe<Scalars['ID']>;
  birthday: InputMaybe<Scalars['Date']>;
  emailNotifications: InputMaybe<Scalars['Boolean']>;
  firstName: InputMaybe<Scalars['String']>;
  idNumber: InputMaybe<Scalars['String']>;
  idType: InputMaybe<IdType>;
  lastName: InputMaybe<Scalars['String']>;
  sex: InputMaybe<HumanSex>;
};

export type RootMutationTypeUpdateSessionArgs = {
  appBuild: InputMaybe<Scalars['String']>;
  appId: InputMaybe<Scalars['Int']>;
  appVersion: InputMaybe<Scalars['String']>;
  carrier: InputMaybe<Scalars['String']>;
  device: InputMaybe<Scalars['String']>;
  deviceId: InputMaybe<Scalars['String']>;
  langCode: InputMaybe<Scalars['String']>;
  pushToken: InputMaybe<Scalars['String']>;
  system: InputMaybe<Scalars['String']>;
  systemVersion: InputMaybe<Scalars['String']>;
  timezone: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateStateArgs = {
  countryId: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateUnitArgs = {
  buildingId: InputMaybe<Scalars['ID']>;
  coefficient: InputMaybe<Scalars['Float']>;
  complexId: InputMaybe<Scalars['ID']>;
  floor: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  number: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  type: InputMaybe<UnitType>;
};

export type RootMutationTypeUpdateUnitContactArgs = {
  description: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateUnitNoteArgs = {
  id: Scalars['ID'];
  note: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateUserArgs = {
  changes: UpdateUserInput;
  id: Scalars['ID'];
};

export type RootMutationTypeUpdateUserPasswordArgs = {
  newPassword: Scalars['String'];
  password: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdateVehicleArgs = {
  brand: InputMaybe<Scalars['String']>;
  color: InputMaybe<Scalars['String']>;
  fuel: InputMaybe<FuelType>;
  id: Scalars['ID'];
  model: InputMaybe<Scalars['Int']>;
  publicService: InputMaybe<Scalars['Boolean']>;
  rtm: InputMaybe<Scalars['Date']>;
  soat: InputMaybe<Scalars['Date']>;
  type: InputMaybe<VehicleType>;
};

export type RootMutationTypeUploadFileArgs = {
  file: Scalars['Upload'];
  type: FileType;
};

export type RootMutationTypeUseJoinCodeArgs = {
  code: Scalars['String'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Shows the methods available to login. Needs an email or a phone number. */
  availableLoginMethods: Maybe<Array<Maybe<LoginMethod>>>;
  billboards: Maybe<BillboardConnection>;
  buildings: Maybe<BuildingConnection>;
  cities: Maybe<CityConnection>;
  complexRestrictions: Maybe<ComplexRestrictionConnection>;
  complexes: Maybe<ComplexConnection>;
  countries: Maybe<CountryConnection>;
  districts: Maybe<DistrictConnection>;
  feedbacks: Maybe<FeedbackConnection>;
  /** Check if the file is already uploaded by the same user */
  findFileByHash: Maybe<File>;
  /** Guests insight data */
  guestInsights: Maybe<GuestInsight>;
  guests: Maybe<GuestConnection>;
  joinCodes: Maybe<UnitConnection>;
  news: Maybe<NewsConnection>;
  newsComments: Maybe<NewsCommentConnection>;
  node: Maybe<Node>;
  notifications: Maybe<NotificationConnection>;
  /** Packages insight data */
  packageInsights: Maybe<PackageInsight>;
  packages: Maybe<PackageConnection>;
  panicAlerts: Maybe<PanicAlertConnection>;
  /** Insight data of pets */
  petInsights: Maybe<PetInsight>;
  petitionComments: Maybe<PetitionCommentConnection>;
  /** Insight data of petitions */
  petitionInsights: Maybe<PetitionInsight>;
  petitionStatuses: Maybe<PetitionStatusConnection>;
  petitions: Maybe<PetitionConnection>;
  pets: Maybe<PetConnection>;
  pollAnswers: Maybe<PollAnswerConnection>;
  pollOptions: Maybe<PollOptionConnection>;
  polls: Maybe<PollConnection>;
  states: Maybe<StateConnection>;
  unitContacts: Maybe<UnitContactConnection>;
  /** Insight data of units */
  unitInsights: Maybe<UnitInsight>;
  unitNotes: Maybe<UnitNoteConnection>;
  units: Maybe<UnitConnection>;
  /** Insight data of users */
  userInsights: Maybe<UserInsight>;
  users: Maybe<UserConnection>;
  /** Insight data of vehicles */
  vehicleInsights: Maybe<VehicleInsight>;
  vehicles: Maybe<VehicleConnection>;
  versions: Maybe<Versions>;
  /** Returns the current authenticated user. */
  viewer: Maybe<User>;
};

export type RootQueryTypeAvailableLoginMethodsArgs = {
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeBillboardsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<BillboardFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<BillboardSort>;
};

export type RootQueryTypeBuildingsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<BuildingFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<BuildingSort>;
};

export type RootQueryTypeCitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<CityFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<CustomLocationsSort>;
};

export type RootQueryTypeComplexRestrictionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexRestrictionFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<ComplexRestrictionSort>;
};

export type RootQueryTypeComplexesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<ComplexSort>;
};

export type RootQueryTypeCountriesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<CountryFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<CustomLocationsSort>;
};

export type RootQueryTypeDistrictsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<DistrictFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<CustomLocationsSort>;
};

export type RootQueryTypeFeedbacksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<FeedbackFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<FeedbackSort>;
};

export type RootQueryTypeFindFileByHashArgs = {
  hash: Scalars['String'];
  type: FileType;
};

export type RootQueryTypeGuestInsightsArgs = {
  filter: InputMaybe<GuestInsightFilter>;
};

export type RootQueryTypeGuestsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<GuestFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<GuestSort>;
};

export type RootQueryTypeJoinCodesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<JoinCodeFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<JoinCodeSort>;
};

export type RootQueryTypeNewsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<NewsFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<NewsSort>;
};

export type RootQueryTypeNewsCommentsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<NewsCommentFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<NewsCommentSort>;
};

export type RootQueryTypeNodeArgs = {
  id: Scalars['ID'];
};

export type RootQueryTypeNotificationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<NotificationFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<NotificationSort>;
};

export type RootQueryTypePackageInsightsArgs = {
  filter: InputMaybe<PackageInsightFilter>;
};

export type RootQueryTypePackagesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PackageFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PackageSort>;
};

export type RootQueryTypePanicAlertsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PanicAlertFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PanicAlertSort>;
};

export type RootQueryTypePetInsightsArgs = {
  filter: InputMaybe<PetInsightFilter>;
};

export type RootQueryTypePetitionCommentsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PetitionCommentFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PetitionCommentSort>;
};

export type RootQueryTypePetitionInsightsArgs = {
  filter: InputMaybe<PetitionInsightFilter>;
  previus: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryTypePetitionStatusesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PetitionStatusFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PetitionStatusSort>;
};

export type RootQueryTypePetitionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PetitionFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PetitionSort>;
};

export type RootQueryTypePetsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PetFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PetSort>;
};

export type RootQueryTypePollAnswersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PollAnswerFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PollAnswerSort>;
};

export type RootQueryTypePollOptionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PollOptionSort>;
};

export type RootQueryTypePollsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PollFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PollSort>;
};

export type RootQueryTypeStatesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<StateFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<CustomLocationsSort>;
};

export type RootQueryTypeUnitContactsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitContactFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<UnitContactSort>;
};

export type RootQueryTypeUnitInsightsArgs = {
  filter: InputMaybe<UnitInsightFilter>;
};

export type RootQueryTypeUnitNotesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitNoteFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<UnitNoteSort>;
};

export type RootQueryTypeUnitsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<UnitSort>;
};

export type RootQueryTypeUserInsightsArgs = {
  filter: InputMaybe<UserInsightFilter>;
};

export type RootQueryTypeUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<UserSort>;
};

export type RootQueryTypeVehicleInsightsArgs = {
  filter: InputMaybe<VehicleInsightFilter>;
};

export type RootQueryTypeVehiclesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<VehicleFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<VehicleSort>;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  guestInsightUpdates: Maybe<GuestInsight>;
  packageInsightUpdates: Maybe<PackageInsight>;
  panicAlertUpdates: Maybe<PanicAlert>;
  petitionCreated: Maybe<Petition>;
};

export type RootSubscriptionTypeGuestInsightUpdatesArgs = {
  complexes: Array<Scalars['ID']>;
};

export type RootSubscriptionTypePackageInsightUpdatesArgs = {
  complexes: Array<Scalars['ID']>;
};

export type RootSubscriptionTypePanicAlertUpdatesArgs = {
  complexes: Array<Scalars['ID']>;
};

export type RootSubscriptionTypePetitionCreatedArgs = {
  complexes: Array<Scalars['ID']>;
};

export type Session = Node & {
  __typename?: 'Session';
  appBuild: Maybe<Scalars['String']>;
  appId: Maybe<Scalars['Int']>;
  appVersion: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  langCode: Maybe<Scalars['String']>;
  pushToken: Maybe<Scalars['String']>;
  terminatedAt: Maybe<Scalars['Date']>;
  timezone: Maybe<Scalars['String']>;
  user: Maybe<User>;
};

export type SessionConnection = {
  __typename?: 'SessionConnection';
  edges: Maybe<Array<Maybe<SessionEdge>>>;
  pageInfo: PageInfo;
};

export type SessionEdge = {
  __typename?: 'SessionEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Session>;
};

export type SetNewsReactionResult = {
  __typename?: 'SetNewsReactionResult';
  action: Maybe<NewsReactionAction>;
  reaction: Maybe<NewsReaction>;
};

export type SetPollVoteResult = {
  __typename?: 'SetPollVoteResult';
  action: Maybe<PollVoteActionType>;
  pollAnswer: Maybe<PollAnswer>;
};

export type State = Node & {
  __typename?: 'State';
  cities: Maybe<CityConnection>;
  country: Maybe<Country>;
  /** The ID of an object */
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type StateCitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<CityFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<CustomLocationsSort>;
};

export type StateConnection = {
  __typename?: 'StateConnection';
  edges: Maybe<Array<Maybe<StateEdge>>>;
  pageInfo: PageInfo;
};

export type StateEdge = {
  __typename?: 'StateEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<State>;
};

/** Filtering options for state */
export type StateFilter = {
  /** Matching a country */
  country: InputMaybe<Scalars['ID']>;
  /** Matching the name */
  name: InputMaybe<Scalars['String']>;
};

/** Success result of the mutation */
export type SuccessResult = {
  __typename?: 'SuccessResult';
  success: Maybe<Scalars['Boolean']>;
};

export type Unit = Node & {
  __typename?: 'Unit';
  building: Maybe<Building>;
  buildingId: Maybe<Scalars['ID']>;
  coefficient: Maybe<Scalars['Float']>;
  complex: Maybe<Complex>;
  complexId: Maybe<Scalars['ID']>;
  floor: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  joinCodes: Maybe<JoinCodeConnection>;
  number: Maybe<Scalars['String']>;
  pets: Maybe<PetConnection>;
  phone: Maybe<Scalars['String']>;
  roles: Maybe<UnitRoleConnection>;
  type: Maybe<UnitType>;
  unitContacts: Maybe<UnitContactConnection>;
  unitNotes: Maybe<UnitNoteConnection>;
  vehicles: Maybe<VehicleConnection>;
};

export type UnitJoinCodesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<JoinCodeFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<JoinCodeSort>;
};

export type UnitPetsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PetFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PetSort>;
};

export type UnitRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitRoleFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<UnitRoleSort>;
};

export type UnitUnitContactsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitContactFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<UnitContactSort>;
};

export type UnitUnitNotesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitNoteFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<UnitNoteSort>;
};

export type UnitVehiclesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<VehicleFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<VehicleSort>;
};

export type UnitConnection = {
  __typename?: 'UnitConnection';
  edges: Maybe<Array<Maybe<UnitEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']>;
};

export type UnitContact = Node & {
  __typename?: 'UnitContact';
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  lastName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  unit: Maybe<Unit>;
  updatedBy: Maybe<User>;
};

export type UnitContactConnection = {
  __typename?: 'UnitContactConnection';
  edges: Maybe<Array<Maybe<UnitContactEdge>>>;
  pageInfo: PageInfo;
};

export type UnitContactEdge = {
  __typename?: 'UnitContactEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<UnitContact>;
};

/** Filtering options for unit_contact */
export type UnitContactFilter = {
  /** IDs of the complexes from which the pet will be obtained */
  complexes: InputMaybe<Array<Scalars['ID']>>;
};

/** Ordering options for unit_contact */
export type UnitContactSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderUnitContactField;
};

export type UnitEdge = {
  __typename?: 'UnitEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Unit>;
};

/** Filtering options for unit */
export type UnitFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching the name */
  memberName: InputMaybe<Scalars['String']>;
  /** Matching a number */
  number: InputMaybe<Scalars['String']>;
  /** Matching a type */
  type: InputMaybe<Array<InputMaybe<UnitType>>>;
};

export type UnitInsight = {
  __typename?: 'UnitInsight';
  total: Maybe<Scalars['Int']>;
  withoutMembers: Maybe<Scalars['Int']>;
};

/** Filtering options for the unit insight */
export type UnitInsightFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
};

export type UnitNote = Node & {
  __typename?: 'UnitNote';
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  note: Maybe<Scalars['String']>;
  unit: Maybe<Unit>;
  updatedBy: Maybe<User>;
};

export type UnitNoteConnection = {
  __typename?: 'UnitNoteConnection';
  edges: Maybe<Array<Maybe<UnitNoteEdge>>>;
  pageInfo: PageInfo;
};

export type UnitNoteEdge = {
  __typename?: 'UnitNoteEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<UnitNote>;
};

/** Filtering options for unit_note */
export type UnitNoteFilter = {
  /** IDs of the complexes from which the pet will be obtained */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a note */
  note: InputMaybe<Scalars['String']>;
};

/** Ordering options for unit_note */
export type UnitNoteSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderUnitNoteField;
};

export type UnitRole = Node & {
  __typename?: 'UnitRole';
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  role: Maybe<UnitRoles>;
  status: Maybe<RoleStatus>;
  unit: Maybe<Unit>;
  unitId: Maybe<Scalars['ID']>;
  updatedBy: Maybe<User>;
  user: Maybe<User>;
  userId: Maybe<Scalars['ID']>;
};

export type UnitRoleConnection = {
  __typename?: 'UnitRoleConnection';
  edges: Maybe<Array<Maybe<UnitRoleEdge>>>;
  pageInfo: PageInfo;
  /** Total number of roles */
  total: Maybe<Scalars['Int']>;
};

export type UnitRoleEdge = {
  __typename?: 'UnitRoleEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<UnitRole>;
};

/** Filtering options for the unit role */
export type UnitRoleFilter = {
  /** Matching a role */
  role: InputMaybe<Array<InputMaybe<UnitRoles>>>;
  /** Matching a status */
  status: InputMaybe<Array<InputMaybe<RoleStatus>>>;
};

/** Ordering options for unit roles */
export type UnitRoleSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: UnitRoleSortField;
};

/** The fields allowed to be sorted */
export type UnitRoleSortField =
  /** Date it was created */
  | 'INSERTED_AT'
  /** First name */
  | 'NAME';

export type UnitRoles = 'HABITANT' | 'HOUSEHOLDER' | 'OWNER';

/** Ordering options for units */
export type UnitSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: UnitSortField;
};

/** The fields allowed to be sorted */
export type UnitSortField = 'COEFFICIENT' | 'INSERTED_AT' | 'NUMBER';

export type UnitType = 'APARTMENT' | 'HOUSE' | 'LOCAL' | 'OFFICE' | 'OTHER' | 'WAREHOUSE';

/** Input for update user data */
export type UpdateUserInput = {
  avatarId: InputMaybe<Scalars['ID']>;
  birthday: InputMaybe<Scalars['Date']>;
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  emailConfirmedAt: InputMaybe<Scalars['DateTime']>;
  firstName: InputMaybe<Scalars['String']>;
  idNumber: InputMaybe<Scalars['String']>;
  idType: InputMaybe<IdType>;
  lastName: InputMaybe<Scalars['String']>;
  password: InputMaybe<Scalars['String']>;
  role: InputMaybe<UserRoles>;
  sex: InputMaybe<HumanSex>;
  status: InputMaybe<UserStatuses>;
};

export type User = Node & {
  __typename?: 'User';
  avatar: Maybe<File>;
  birthday: Maybe<Scalars['Date']>;
  birthdayNonYear: Maybe<Scalars['Date']>;
  celCountry: Maybe<Scalars['String']>;
  celNumber: Maybe<Scalars['String']>;
  complexRestrictions: Maybe<ComplexRestrictionConnection>;
  complexRoles: Maybe<ComplexRoleConnection>;
  email: Maybe<Scalars['String']>;
  emailConfirmedAt: Maybe<Scalars['DateTime']>;
  firstName: Maybe<Scalars['String']>;
  hasPassword: Maybe<Scalars['Boolean']>;
  /** The ID of an object */
  id: Scalars['ID'];
  idNumber: Maybe<Scalars['String']>;
  idType: Maybe<IdType>;
  insertedAt: Maybe<Scalars['DateTime']>;
  lastName: Maybe<Scalars['String']>;
  notifications: Maybe<NotificationConnection>;
  role: Maybe<UserRoles>;
  sessions: Maybe<SessionConnection>;
  sex: Maybe<HumanSex>;
  status: Maybe<UserStatuses>;
  unitRoles: Maybe<UnitRoleConnection>;
  unitsRelated: Maybe<Array<Maybe<Unit>>>;
};

export type UserComplexRestrictionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexRestrictionFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<ComplexRestrictionSort>;
};

export type UserComplexRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexRoleFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
};

export type UserNotificationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<NotificationFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order: InputMaybe<NotificationSort>;
};

export type UserSessionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderDirection>;
};

export type UserUnitRolesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UnitRoleFilter>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
};

export type UserAgeInsight = {
  __typename?: 'UserAgeInsight';
  age: Maybe<Scalars['Int']>;
  count: Maybe<Scalars['Int']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<User>;
};

/** Filtering options for users */
export type UserFilter = {
  /** Matching the cel number */
  celNumber: InputMaybe<Scalars['String']>;
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a email */
  email: InputMaybe<Scalars['String']>;
  /** Matching the first name */
  firstName: InputMaybe<Scalars['String']>;
  /** Matching the ID number */
  idNumber: InputMaybe<Scalars['String']>;
  /** Matching the last name */
  lastName: InputMaybe<Scalars['String']>;
  /** Matching full name */
  name: InputMaybe<Scalars['String']>;
  /** Matching a role */
  role: InputMaybe<Array<UserRoles>>;
  /** Matching a status */
  status: InputMaybe<Array<UserStatuses>>;
};

export type UserInsight = {
  __typename?: 'UserInsight';
  age: Maybe<Array<Maybe<UserAgeInsight>>>;
  sex: Maybe<Array<Maybe<UserSexInsight>>>;
  total: Maybe<Scalars['Int']>;
};

/** Filtering options for the user insight */
export type UserInsightFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
};

export type UserRoles = 'ADMIN' | 'MANAGER' | 'SUPPORT' | 'USER';

export type UserSexInsight = {
  __typename?: 'UserSexInsight';
  count: Maybe<Scalars['Int']>;
  sex: Maybe<HumanSex>;
};

/** Ordering options for users */
export type UserSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderUserField;
};

export type UserStatuses = 'ACTIVE' | 'DELETED' | 'SUSPENDED' | 'UNCONFIRMED';

export type Vehicle = Node & {
  __typename?: 'Vehicle';
  brand: Maybe<Scalars['String']>;
  color: Maybe<Scalars['String']>;
  createdBy: Maybe<User>;
  fuel: Maybe<FuelType>;
  /** The ID of an object */
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['DateTime']>;
  model: Maybe<Scalars['Int']>;
  plate: Maybe<Scalars['String']>;
  publicService: Maybe<Scalars['Boolean']>;
  rtm: Maybe<Scalars['Date']>;
  soat: Maybe<Scalars['Date']>;
  status: Maybe<VehicleStatus>;
  type: Maybe<VehicleType>;
  unit: Maybe<Unit>;
  updatedBy: Maybe<User>;
};

export type VehicleConnection = {
  __typename?: 'VehicleConnection';
  edges: Maybe<Array<Maybe<VehicleEdge>>>;
  pageInfo: PageInfo;
  /** Total number of vehicles */
  total: Maybe<Scalars['Int']>;
};

export type VehicleEdge = {
  __typename?: 'VehicleEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Vehicle>;
};

/** Filtering options for vehicle */
export type VehicleFilter = {
  /** IDs of the complexes from which the pet will be obtained */
  complexes: InputMaybe<Array<Scalars['ID']>>;
  /** Matching a plate */
  plate: InputMaybe<Scalars['String']>;
  /** Matching a status */
  status: InputMaybe<Array<VehicleStatus>>;
  /** Matching a type */
  type: InputMaybe<Array<VehicleType>>;
};

export type VehicleInsight = {
  __typename?: 'VehicleInsight';
  total: Maybe<Scalars['Int']>;
  type: Maybe<Array<Maybe<VehicleTypeInsight>>>;
};

/** Filtering options for the vehicle insight */
export type VehicleInsightFilter = {
  /** Matching in complexes */
  complexes: InputMaybe<Array<Scalars['ID']>>;
};

/** Ordering options for vehicle */
export type VehicleSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderVehicleField;
};

export type VehicleStatus = 'ACTIVE' | 'ADDED' | 'DELETED';

export type VehicleType = 'CAR' | 'MOTORCYCLE' | 'OTHER' | 'SUV' | 'VAN';

export type VehicleTypeInsight = {
  __typename?: 'VehicleTypeInsight';
  count: Maybe<Scalars['Int']>;
  type: Maybe<VehicleType>;
};

export type Versions = {
  __typename?: 'Versions';
  api: Maybe<Scalars['String']>;
  core: Maybe<Scalars['String']>;
};

export type CancelJoinCodeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelJoinCodeMutation = {
  __typename?: 'RootMutationType';
  cancelJoinCode: {__typename?: 'JoinCode'; id: string; status: JoinCodeStatus | null} | null;
};

export type ClosePollMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ClosePollMutation = {
  __typename?: 'RootMutationType';
  closePoll: {__typename?: 'Poll'; id: string; status: PollStatus | null; closeAt: any | null} | null;
};

export type CreateBillboardMutationVariables = Exact<{
  fileId: Scalars['ID'];
  buildingId: InputMaybe<Scalars['ID']>;
  complexId: Scalars['ID'];
}>;

export type CreateBillboardMutation = {
  __typename?: 'RootMutationType';
  createBillboard: {
    __typename?: 'Billboard';
    id: string;
    insertedAt: any | null;
    complexId: string | null;
    file: {
      __typename?: 'File';
      id: string;
      urls:
        | {__typename?: 'AvatarFile'}
        | {__typename?: 'BillboardFile'; thumb: string | null; billboard: string | null}
        | {__typename?: 'CoverFile'}
        | {__typename?: 'DocumentFile'}
        | null;
    } | null;
  } | null;
};

export type CreateBuildingMutationVariables = Exact<{
  complexId: Scalars['ID'];
  name: Scalars['String'];
}>;

export type CreateBuildingMutation = {
  __typename?: 'RootMutationType';
  createBuilding: {__typename?: 'Building'; id: string; name: string | null} | null;
};

export type CreateComplexMutationVariables = Exact<{
  name: Scalars['String'];
  status: ComplexStatus;
  type: ComplexType;
  address: InputMaybe<Scalars['String']>;
  districtId: InputMaybe<Scalars['ID']>;
  features: InputMaybe<Array<InputMaybe<ComplexFeatures>> | InputMaybe<ComplexFeatures>>;
  lat: InputMaybe<Scalars['Float']>;
  long: InputMaybe<Scalars['Float']>;
  stratum: InputMaybe<Scalars['Int']>;
}>;

export type CreateComplexMutation = {
  __typename?: 'RootMutationType';
  createComplex: {__typename?: 'Complex'; id: string} | null;
};

export type CreateComplexRestrictionMutationVariables = Exact<{
  complexId: Scalars['ID'];
  expire: Scalars['DateTime'];
  type: ComplexRestrictionType;
  userId: Scalars['ID'];
  description: Scalars['String'];
}>;

export type CreateComplexRestrictionMutation = {
  __typename?: 'RootMutationType';
  createComplexRestriction: {__typename?: 'ComplexRestriction'; id: string} | null;
};

export type CreateFeedbackMutationVariables = Exact<{
  description: Scalars['String'];
  type: FeedbackType;
  fileId: InputMaybe<Scalars['ID']>;
}>;

export type CreateFeedbackMutation = {
  __typename?: 'RootMutationType';
  createFeedback: {__typename?: 'Feedback'; id: string} | null;
};

export type CreateJoinCodeMutationVariables = Exact<{
  expire: Scalars['DateTime'];
  maxUses: Scalars['Int'];
  role: UnitRoles;
  unitId: Scalars['ID'];
}>;

export type CreateJoinCodeMutation = {
  __typename?: 'RootMutationType';
  createJoinCode: {
    __typename?: 'JoinCode';
    id: string;
    code: string | null;
    expire: any | null;
    insertedAt: any | null;
    maxUses: number | null;
    role: UnitRoles | null;
    status: JoinCodeStatus | null;
    uses: number | null;
  } | null;
};

export type CreateNewsMutationVariables = Exact<{
  complexId: Scalars['ID'];
  title: Scalars['String'];
  allowComments: InputMaybe<Scalars['Boolean']>;
  allowReactions: InputMaybe<Scalars['Boolean']>;
  body: InputMaybe<Scalars['String']>;
  buildingId: InputMaybe<Scalars['ID']>;
  status: InputMaybe<NewsStatus>;
  files: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type CreateNewsMutation = {
  __typename?: 'RootMutationType';
  createNews: {__typename?: 'News'; id: string} | null;
};

export type CreateNewsCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  comment: Scalars['String'];
}>;

export type CreateNewsCommentMutation = {
  __typename?: 'RootMutationType';
  createNewsComment: {__typename?: 'NewsComment'; id: string} | null;
};

export type CreatePetMutationVariables = Exact<{
  name: Scalars['String'];
  type: PetType;
  unitId: Scalars['ID'];
  vaccinesDate: InputMaybe<Scalars['Date']>;
  sex: InputMaybe<PetSex>;
  birthday: InputMaybe<Scalars['Date']>;
}>;

export type CreatePetMutation = {
  __typename?: 'RootMutationType';
  createPet: {
    __typename?: 'Pet';
    birthday: any | null;
    id: string;
    insertedAt: any | null;
    name: string | null;
    sex: PetSex | null;
    type: PetType | null;
    vaccinesDate: any | null;
    createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
  } | null;
};

export type CreatePetitionMutationVariables = Exact<{
  body: Scalars['String'];
  complexId: Scalars['ID'];
  privacy: InputMaybe<Array<PetitionPrivacy> | PetitionPrivacy>;
  title: Scalars['String'];
  type: PetitionType;
  files: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type CreatePetitionMutation = {
  __typename?: 'RootMutationType';
  createPetition: {__typename?: 'Petition'; id: string} | null;
};

export type CreatePetitionCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  comment: Scalars['String'];
}>;

export type CreatePetitionCommentMutation = {
  __typename?: 'RootMutationType';
  createPetitionComment: {__typename?: 'PetitionComment'; id: string} | null;
};

export type CreatePetitionStatusMutationVariables = Exact<{
  petitionId: Scalars['ID'];
  status: PetitionStatusType;
}>;

export type CreatePetitionStatusMutation = {
  __typename?: 'RootMutationType';
  createPetitionStatus: {
    __typename?: 'PetitionStatus';
    id: string;
    status: PetitionStatusType | null;
    petition: {
      __typename?: 'Petition';
      id: string;
      currentStatus: PetitionStatusType | null;
      statuses: Array<{
        __typename?: 'PetitionStatus';
        id: string;
        insertedAt: any | null;
        status: PetitionStatusType | null;
        createdBy: {__typename?: 'User'; id: string; lastName: string | null} | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreatePollMutationVariables = Exact<{
  complexId: Scalars['ID'];
  options: Array<Scalars['String']> | Scalars['String'];
  privacy: PollPrivacy;
  question: Scalars['String'];
  buildingId: InputMaybe<Scalars['ID']>;
  closeAt: InputMaybe<Scalars['DateTime']>;
  description: InputMaybe<Scalars['String']>;
}>;

export type CreatePollMutation = {
  __typename?: 'RootMutationType';
  createPoll: {
    __typename?: 'Poll';
    id: string;
    privacy: PollPrivacy | null;
    question: string | null;
    status: PollStatus | null;
    description: string | null;
    complexId: string | null;
    closeAt: any | null;
  } | null;
};

export type CreateUnitMutationVariables = Exact<{
  complexId: Scalars['ID'];
  number: Scalars['String'];
  type: UnitType;
  phone: InputMaybe<Scalars['String']>;
  floor: InputMaybe<Scalars['String']>;
  coefficient: InputMaybe<Scalars['Float']>;
  buildingId: InputMaybe<Scalars['ID']>;
}>;

export type CreateUnitMutation = {
  __typename?: 'RootMutationType';
  createUnit: {__typename?: 'Unit'; id: string} | null;
};

export type CreateUnitContactMutationVariables = Exact<{
  unitId: Scalars['ID'];
  lastName: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  description: Scalars['String'];
  email: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
}>;

export type CreateUnitContactMutation = {
  __typename?: 'RootMutationType';
  createUnitContact: {
    __typename?: 'UnitContact';
    id: string;
    email: string | null;
    phone: string | null;
    firstName: string | null;
    lastName: string | null;
    description: string | null;
    insertedAt: any | null;
    createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
  } | null;
};

export type CreateUnitNoteMutationVariables = Exact<{
  unitId: Scalars['ID'];
  note: Scalars['String'];
}>;

export type CreateUnitNoteMutation = {
  __typename?: 'RootMutationType';
  createUnitNote: {
    __typename?: 'UnitNote';
    id: string;
    insertedAt: any | null;
    note: string | null;
    createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
  } | null;
};

export type CreateVehicleMutationVariables = Exact<{
  plate: Scalars['String'];
  unitId: Scalars['ID'];
  soat: InputMaybe<Scalars['Date']>;
  rtm: InputMaybe<Scalars['Date']>;
  publicService: InputMaybe<Scalars['Boolean']>;
  type: VehicleType;
  model: InputMaybe<Scalars['Int']>;
  fuel: InputMaybe<FuelType>;
  color: InputMaybe<Scalars['String']>;
  brand: InputMaybe<Scalars['String']>;
}>;

export type CreateVehicleMutation = {
  __typename?: 'RootMutationType';
  createVehicle: {
    __typename?: 'Vehicle';
    id: string;
    brand: string | null;
    color: string | null;
    insertedAt: any | null;
    model: number | null;
    plate: string | null;
    publicService: boolean | null;
    rtm: any | null;
    soat: any | null;
    status: VehicleStatus | null;
    type: VehicleType | null;
    createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
  } | null;
};

export type DeleteBillboardMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBillboardMutation = {
  __typename?: 'RootMutationType';
  deleteBillboard: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteNewsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteNewsMutation = {
  __typename?: 'RootMutationType';
  deleteNews: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeletePetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePetMutation = {
  __typename?: 'RootMutationType';
  deletePet: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeletePetitionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePetitionMutation = {
  __typename?: 'RootMutationType';
  deletePetition: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeletePollMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePollMutation = {
  __typename?: 'RootMutationType';
  deletePoll: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteUnitContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUnitContactMutation = {
  __typename?: 'RootMutationType';
  deleteUnitContact: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteUnitNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUnitNoteMutation = {
  __typename?: 'RootMutationType';
  deleteUnitNote: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type GenerateAuthCodeMutationVariables = Exact<{
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
}>;

export type GenerateAuthCodeMutation = {
  __typename?: 'RootMutationType';
  generateAuthCode: {__typename?: 'AuthCode'; code: string | null; ttl: number | null} | null;
};

export type ReadAllNotificationsMutationVariables = Exact<{[key: string]: never}>;

export type ReadAllNotificationsMutation = {
  __typename?: 'RootMutationType';
  readAllNotifications: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type RefreshTokenMutationVariables = Exact<{[key: string]: never}>;

export type RefreshTokenMutation = {
  __typename?: 'RootMutationType';
  refreshToken: {__typename?: 'AuthResult'; access: string | null} | null;
};

export type RemoveComplexRestrictionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveComplexRestrictionMutation = {
  __typename?: 'RootMutationType';
  removeComplexRestriction: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type RemoveComplexRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveComplexRoleMutation = {
  __typename?: 'RootMutationType';
  removeComplexRole: {__typename?: 'ComplexRole'; id: string} | null;
};

export type RemoveUnitRoleMutationVariables = Exact<{
  roleId: Scalars['ID'];
}>;

export type RemoveUnitRoleMutation = {
  __typename?: 'RootMutationType';
  removeUnitRole: {
    __typename?: 'UnitRole';
    id: string;
    role: UnitRoles | null;
    status: RoleStatus | null;
    user: {__typename?: 'User'; id: string} | null;
  } | null;
};

export type RequestAuthCodeMutationVariables = Exact<{
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
}>;

export type RequestAuthCodeMutation = {
  __typename?: 'RootMutationType';
  requestAuthCode: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type SetComplexRoleMutationVariables = Exact<{
  complexId: Scalars['ID'];
  role: ComplexRoles;
  userId: Scalars['ID'];
}>;

export type SetComplexRoleMutation = {
  __typename?: 'RootMutationType';
  setComplexRole: {__typename?: 'ComplexRole'; id: string} | null;
};

export type SetNewsReactionMutationVariables = Exact<{
  newsId: Scalars['ID'];
  reaction: NewsReactionType;
}>;

export type SetNewsReactionMutation = {
  __typename?: 'RootMutationType';
  setNewsReaction: {
    __typename?: 'SetNewsReactionResult';
    action: NewsReactionAction | null;
    reaction: {__typename?: 'NewsReaction'; id: string; reaction: NewsReactionType | null} | null;
  } | null;
};

export type SetPollVoteMutationVariables = Exact<{
  optionId: Scalars['ID'];
  unitId: Scalars['ID'];
}>;

export type SetPollVoteMutation = {
  __typename?: 'RootMutationType';
  setPollVote: {
    __typename?: 'SetPollVoteResult';
    action: PollVoteActionType | null;
    pollAnswer: {__typename?: 'PollAnswer'; id: string} | null;
  } | null;
};

export type SetUnitRoleMutationVariables = Exact<{
  role: UnitRoles;
  unitId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type SetUnitRoleMutation = {
  __typename?: 'RootMutationType';
  setUnitRole: {__typename?: 'UnitRole'; id: string; role: UnitRoles | null} | null;
};

export type SignInMutationVariables = Exact<{
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  password: InputMaybe<Scalars['String']>;
}>;

export type SignInMutation = {
  __typename?: 'RootMutationType';
  signIn: {__typename?: 'AuthResult'; access: string | null} | null;
};

export type SignOutMutationVariables = Exact<{[key: string]: never}>;

export type SignOutMutation = {
  __typename?: 'RootMutationType';
  signOut: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type SignUpMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  password: InputMaybe<Scalars['String']>;
}>;

export type SignUpMutation = {
  __typename?: 'RootMutationType';
  signUp: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type SoftDeleteVehicleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SoftDeleteVehicleMutation = {
  __typename?: 'RootMutationType';
  softDeleteVehicle: {__typename?: 'Vehicle'; id: string; status: VehicleStatus | null} | null;
};

export type UpdateBuildingMutationVariables = Exact<{
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
}>;

export type UpdateBuildingMutation = {
  __typename?: 'RootMutationType';
  updateBuilding: {__typename?: 'Building'; id: string; name: string | null} | null;
};

export type UpdateComplexMutationVariables = Exact<{
  id: Scalars['ID'];
  lat: InputMaybe<Scalars['Float']>;
  long: InputMaybe<Scalars['Float']>;
  features: InputMaybe<Array<InputMaybe<ComplexFeatures>> | InputMaybe<ComplexFeatures>>;
  districtId: InputMaybe<Scalars['ID']>;
  address: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
  status: InputMaybe<ComplexStatus>;
  type: InputMaybe<ComplexType>;
  stratum: InputMaybe<Scalars['Int']>;
}>;

export type UpdateComplexMutation = {
  __typename?: 'RootMutationType';
  updateComplex: {__typename?: 'Complex'; id: string} | null;
};

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
}>;

export type UpdateEmailMutation = {
  __typename?: 'RootMutationType';
  updateEmail: {__typename?: 'User'; id: string; email: string | null} | null;
};

export type UpdateNewsMutationVariables = Exact<{
  newsId: Scalars['ID'];
  title: Scalars['String'];
  allowComments: InputMaybe<Scalars['Boolean']>;
  allowReactions: InputMaybe<Scalars['Boolean']>;
  body: InputMaybe<Scalars['String']>;
  buildingId: InputMaybe<Scalars['ID']>;
  status: InputMaybe<NewsStatus>;
  files: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UpdateNewsMutation = {
  __typename?: 'RootMutationType';
  updateNews: {__typename?: 'News'; id: string} | null;
};

export type UpdateNewsCommentStatusMutationVariables = Exact<{
  commentId: Scalars['ID'];
  status: NewsCommentStatus;
}>;

export type UpdateNewsCommentStatusMutation = {
  __typename?: 'RootMutationType';
  updateNewsCommentStatus: {__typename?: 'NewsComment'; id: string; status: NewsCommentStatus | null} | null;
};

export type UpdatePetitionCommentStatusMutationVariables = Exact<{
  commentId: Scalars['ID'];
  status: PetitionCommentStatus;
}>;

export type UpdatePetitionCommentStatusMutation = {
  __typename?: 'RootMutationType';
  updatePetitionCommentStatus: {
    __typename?: 'PetitionComment';
    id: string;
    status: PetitionCommentStatus | null;
  } | null;
};

export type UpdatePetitionElevatedMutationVariables = Exact<{
  id: Scalars['ID'];
  priority: InputMaybe<Scalars['Int']>;
  allowComments: InputMaybe<Scalars['Boolean']>;
  privacy: InputMaybe<Array<PetitionPrivacy> | PetitionPrivacy>;
}>;

export type UpdatePetitionElevatedMutation = {
  __typename?: 'RootMutationType';
  updatePetitionElevated: {
    __typename?: 'Petition';
    id: string;
    priority: number | null;
    privacy: Array<PetitionPrivacy | null> | null;
    allowComments: boolean | null;
  } | null;
};

export type UpdatePhoneMutationVariables = Exact<{
  celCountry: Scalars['String'];
  code: Scalars['String'];
  celNumber: Scalars['String'];
}>;

export type UpdatePhoneMutation = {
  __typename?: 'RootMutationType';
  updatePhone: {__typename?: 'User'; id: string; celCountry: string | null; celNumber: string | null} | null;
};

export type UpdatePollMutationVariables = Exact<{
  id: Scalars['ID'];
  buildingId: InputMaybe<Scalars['ID']>;
  closeAt: InputMaybe<Scalars['DateTime']>;
  description: InputMaybe<Scalars['String']>;
  question: InputMaybe<Scalars['String']>;
}>;

export type UpdatePollMutation = {
  __typename?: 'RootMutationType';
  updatePoll: {
    __typename?: 'Poll';
    id: string;
    buildingId: string | null;
    description: string | null;
    question: string | null;
    closeAt: any | null;
  } | null;
};

export type UpdateProfileMutationVariables = Exact<{
  sex: InputMaybe<HumanSex>;
  lastName: InputMaybe<Scalars['String']>;
  idType: InputMaybe<IdType>;
  idNumber: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  emailNotifications: InputMaybe<Scalars['Boolean']>;
  birthday: InputMaybe<Scalars['Date']>;
  avatarId: InputMaybe<Scalars['ID']>;
}>;

export type UpdateProfileMutation = {
  __typename?: 'RootMutationType';
  updateProfile:
    | ({
        __typename?: 'User';
        birthday: any | null;
        celCountry: string | null;
        celNumber: string | null;
        email: string | null;
        emailConfirmedAt: any | null;
        idNumber: string | null;
        idType: IdType | null;
        sex: HumanSex | null;
        status: UserStatuses | null;
        role: UserRoles | null;
      } & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
    | null;
};

export type UpdateSessionMutationVariables = Exact<{
  appBuild: InputMaybe<Scalars['String']>;
  appId: InputMaybe<Scalars['Int']>;
  appVersion: InputMaybe<Scalars['String']>;
  device: InputMaybe<Scalars['String']>;
  langCode: InputMaybe<Scalars['String']>;
  system: InputMaybe<Scalars['String']>;
  systemVersion: InputMaybe<Scalars['String']>;
  timezone: InputMaybe<Scalars['String']>;
}>;

export type UpdateSessionMutation = {
  __typename?: 'RootMutationType';
  updateSession: {
    __typename?: 'Session';
    id: string;
    appVersion: string | null;
    appBuild: string | null;
    appId: number | null;
    langCode: string | null;
    pushToken: string | null;
    terminatedAt: any | null;
    timezone: string | null;
  } | null;
};

export type UpdateUnitMutationVariables = Exact<{
  id: Scalars['ID'];
  type: InputMaybe<UnitType>;
  phone: InputMaybe<Scalars['String']>;
  number: InputMaybe<Scalars['String']>;
  floor: InputMaybe<Scalars['String']>;
  coefficient: InputMaybe<Scalars['Float']>;
  buildingId: InputMaybe<Scalars['ID']>;
}>;

export type UpdateUnitMutation = {
  __typename?: 'RootMutationType';
  updateUnit: {
    __typename?: 'Unit';
    id: string;
    floor: string | null;
    coefficient: number | null;
    buildingId: string | null;
    number: string | null;
    phone: string | null;
    type: UnitType | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  changes: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'RootMutationType';
  updateUser: {__typename?: 'User'; id: string} | null;
};

export type UpdateUserPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  password: InputMaybe<Scalars['String']>;
}>;

export type UpdateUserPasswordMutation = {
  __typename?: 'RootMutationType';
  updateUserPassword: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type UploadFileMutationVariables = Exact<{
  type: FileType;
  file: Scalars['Upload'];
}>;

export type UploadFileMutation = {
  __typename?: 'RootMutationType';
  uploadFile: {
    __typename?: 'File';
    id: string;
    type: FileType | null;
    urls:
      | {__typename?: 'AvatarFile'; thumb: string | null; avatar: string | null}
      | {__typename?: 'BillboardFile'; thumb: string | null; billboard: string | null}
      | {__typename?: 'CoverFile'; thumb: string | null; cover: string | null}
      | {__typename?: 'DocumentFile'; thumb: string | null; original: string | null}
      | null;
  } | null;
};

export type UseJoinCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type UseJoinCodeMutation = {
  __typename?: 'RootMutationType';
  useJoinCode: {__typename?: 'UnitRole'; id: string} | null;
};

export type AvailableLoginMethodsQueryVariables = Exact<{
  celCountry: InputMaybe<Scalars['String']>;
  celNumber: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
}>;

export type AvailableLoginMethodsQuery = {
  __typename?: 'RootQueryType';
  availableLoginMethods: Array<LoginMethod | null> | null;
};

export type BillboardsQueryVariables = Exact<{
  filter: InputMaybe<BillboardFilter>;
  first: InputMaybe<Scalars['Int']>;
  order: InputMaybe<BillboardSort>;
}>;

export type BillboardsQuery = {
  __typename?: 'RootQueryType';
  billboards: {
    __typename?: 'BillboardConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'BillboardEdge';
      node: {
        __typename?: 'Billboard';
        id: string;
        complexId: string | null;
        file: {
          __typename?: 'File';
          id: string;
          type: FileType | null;
          urls:
            | {__typename?: 'AvatarFile'}
            | {__typename?: 'BillboardFile'; thumb: string | null; billboard: string | null}
            | {__typename?: 'CoverFile'}
            | {__typename?: 'DocumentFile'}
            | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type BuildingsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']>;
  filter: InputMaybe<BuildingFilter>;
}>;

export type BuildingsQuery = {
  __typename?: 'RootQueryType';
  buildings: {
    __typename?: 'BuildingConnection';
    edges: Array<{
      __typename?: 'BuildingEdge';
      node: {
        __typename?: 'Building';
        id: string;
        name: string | null;
        complexId: string | null;
        complex: {__typename?: 'Complex'; id: string; name: string | null} | null;
        units: {__typename?: 'UnitConnection'; total: number | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ComplexesQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']>;
  filter: InputMaybe<ComplexFilter>;
  after: InputMaybe<Scalars['String']>;
  order: InputMaybe<ComplexSort>;
}>;

export type ComplexesQuery = {
  __typename?: 'RootQueryType';
  complexes: {
    __typename?: 'ComplexConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'ComplexEdge';
      node: {
        __typename?: 'Complex';
        id: string;
        name: string | null;
        lat: number | null;
        long: number | null;
        type: ComplexType | null;
        status: ComplexStatus | null;
        address: string | null;
        stratum: number | null;
        features: Array<ComplexFeatures | null> | null;
        districtId: string | null;
        district: {
          __typename?: 'District';
          id: string;
          name: string | null;
          city: {
            __typename?: 'City';
            id: string;
            name: string | null;
            state: {
              __typename?: 'State';
              id: string;
              name: string | null;
              country: {__typename?: 'Country'; id: string; name: string | null} | null;
            } | null;
          } | null;
        } | null;
        buildings: Array<{__typename?: 'Building'; id: string; name: string | null} | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type FeedbacksQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
  filter: InputMaybe<FeedbackFilter>;
  order: InputMaybe<FeedbackSort>;
}>;

export type FeedbacksQuery = {
  __typename?: 'RootQueryType';
  feedbacks: {
    __typename?: 'FeedbackConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'FeedbackEdge';
      node: {
        __typename?: 'Feedback';
        id: string;
        type: FeedbackType | null;
        status: FeedbackStatus | null;
        description: string | null;
        insertedAt: any | null;
        updatedAt: any | null;
        file: {
          __typename?: 'File';
          id: string;
          type: FileType | null;
          filename: string | null;
          urls:
            | {__typename?: 'AvatarFile'}
            | {__typename?: 'BillboardFile'}
            | {__typename?: 'CoverFile'}
            | {__typename?: 'DocumentFile'; original: string | null}
            | null;
        } | null;
        createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
        updatedBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
      } | null;
    } | null> | null;
  } | null;
};

export type HomeQueryVariables = Exact<{
  billboardFirst: InputMaybe<Scalars['Int']>;
  newsFirst: InputMaybe<Scalars['Int']>;
  pollsFirst: InputMaybe<Scalars['Int']>;
  complexes: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type HomeQuery = {
  __typename?: 'RootQueryType';
  billboards: {
    __typename?: 'BillboardConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'BillboardEdge';
      node: {
        __typename?: 'Billboard';
        id: string;
        complexId: string | null;
        file: {
          __typename?: 'File';
          id: string;
          type: FileType | null;
          urls:
            | {__typename?: 'AvatarFile'}
            | {__typename?: 'BillboardFile'; thumb: string | null; billboard: string | null}
            | {__typename?: 'CoverFile'}
            | {__typename?: 'DocumentFile'}
            | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  news: {
    __typename?: 'NewsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'NewsEdge';
      node: {
        __typename?: 'News';
        id: string;
        insertedAt: any | null;
        title: string | null;
        complexId: string | null;
      } | null;
    } | null> | null;
  } | null;
  polls: {
    __typename?: 'PollConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'PollEdge';
      node: {
        __typename?: 'Poll';
        id: string;
        insertedAt: any | null;
        status: PollStatus | null;
        question: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type InsightsQueryVariables = Exact<{
  complexes: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type InsightsQuery = {
  __typename?: 'RootQueryType';
  petInsights: {
    __typename?: 'PetInsight';
    total: number | null;
    type: Array<{__typename?: 'PetTypeInsight'; count: number | null; type: PetType | null} | null> | null;
  } | null;
  vehicleInsights: {
    __typename?: 'VehicleInsight';
    total: number | null;
    type: Array<{__typename?: 'VehicleTypeInsight'; count: number | null; type: VehicleType | null} | null> | null;
  } | null;
  unitInsights: {__typename?: 'UnitInsight'; total: number | null; withoutMembers: number | null} | null;
  userInsights: {
    __typename?: 'UserInsight';
    total: number | null;
    sex: Array<{__typename?: 'UserSexInsight'; sex: HumanSex | null; count: number | null} | null> | null;
    age: Array<{__typename?: 'UserAgeInsight'; age: number | null; count: number | null} | null> | null;
  } | null;
};

export type NewsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']>;
  filter: InputMaybe<NewsFilter>;
  order: InputMaybe<NewsSort>;
}>;

export type NewsQuery = {
  __typename?: 'RootQueryType';
  news: {
    __typename?: 'NewsConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'NewsEdge';
      node: {
        __typename?: 'News';
        id: string;
        insertedAt: any | null;
        title: string | null;
        complexId: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type NotificationsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']>;
  filter: InputMaybe<NotificationFilter>;
  order: InputMaybe<NotificationSort>;
}>;

export type NotificationsQuery = {
  __typename?: 'RootQueryType';
  viewer: {
    __typename?: 'User';
    id: string;
    notifications: {
      __typename?: 'NotificationConnection';
      unreadCount: number | null;
      pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
      edges: Array<{
        __typename?: 'NotificationEdge';
        node: {
          __typename?: 'Notification';
          id: string;
          insertedAt: any | null;
          title: string | null;
          body: string | null;
          type: NotificationType | null;
          resourceId: string | null;
          resourceExtra: string | null;
          read: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PetitionCommentsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']>;
  filter: InputMaybe<PetitionCommentFilter>;
  after: InputMaybe<Scalars['String']>;
}>;

export type PetitionCommentsQuery = {
  __typename?: 'RootQueryType';
  petitionComments: {
    __typename?: 'PetitionCommentConnection';
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'PetitionCommentEdge';
      node: {
        __typename?: 'PetitionComment';
        id: string;
        comment: string | null;
        status: PetitionCommentStatus | null;
        insertedAt: any | null;
        createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
        petition: {__typename?: 'Petition'; id: string; title: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PetitionInsightsQueryVariables = Exact<{
  previus: InputMaybe<Scalars['Boolean']>;
  filter: InputMaybe<PetitionInsightFilter>;
}>;

export type PetitionInsightsQuery = {
  __typename?: 'RootQueryType';
  petitionInsights: {
    __typename?: 'PetitionInsight';
    comments: Array<number | null> | null;
    finished: Array<number | null> | null;
    statusClosed: Array<number | null> | null;
    petitions: Array<number | null> | null;
    statusInProgress: Array<number | null> | null;
    statusResolved: Array<number | null> | null;
    statusUnderReview: Array<number | null> | null;
    typeClaim: Array<number | null> | null;
    typeComplaint: Array<number | null> | null;
    typeQuestion: Array<number | null> | null;
    typeSuggestion: Array<number | null> | null;
    unfinished: Array<number | null> | null;
  } | null;
};

export type PetitionListQueryVariables = Exact<{
  filter: InputMaybe<PetitionFilter>;
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
  order: InputMaybe<PetitionSort>;
}>;

export type PetitionListQuery = {
  __typename?: 'RootQueryType';
  petitions: {
    __typename?: 'PetitionConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
    edges: Array<{
      __typename?: 'PetitionEdge';
      node: {
        __typename?: 'Petition';
        id: string;
        currentStatus: PetitionStatusType | null;
        insertedAt: any | null;
        title: string | null;
        type: PetitionType | null;
        updatedAt: any | null;
        complexId: string | null;
        createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PetitionListAdminQueryVariables = Exact<{
  filter: InputMaybe<PetitionFilter>;
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
  order: InputMaybe<PetitionSort>;
}>;

export type PetitionListAdminQuery = {
  __typename?: 'RootQueryType';
  petitions: {
    __typename?: 'PetitionConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
    edges: Array<{
      __typename?: 'PetitionEdge';
      node: {
        __typename?: 'Petition';
        id: string;
        currentStatus: PetitionStatusType | null;
        insertedAt: any | null;
        priority: number | null;
        title: string | null;
        type: PetitionType | null;
        updatedAt: any | null;
        complexId: string | null;
        createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PollsQueryVariables = Exact<{
  filter: InputMaybe<PollFilter>;
  first: InputMaybe<Scalars['Int']>;
  order: InputMaybe<PollSort>;
}>;

export type PollsQuery = {
  __typename?: 'RootQueryType';
  polls: {
    __typename?: 'PollConnection';
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'PollEdge';
      node: {__typename?: 'Poll'; id: string; status: PollStatus | null; question: string | null} | null;
    } | null> | null;
  } | null;
};

export type SearchComplexUserQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  filter: InputMaybe<UnitFilter>;
}>;

export type SearchComplexUserQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {
        __typename?: 'Complex';
        id: string;
        unitRoles: {
          __typename?: 'UnitRoleConnection';
          total: number | null;
          pageInfo: {__typename?: 'PageInfo'; hasPreviousPage: boolean; hasNextPage: boolean; endCursor: string | null};
          edges: Array<{
            __typename?: 'UnitRoleEdge';
            node: {
              __typename?: 'UnitRole';
              id: string;
              role: UnitRoles | null;
              unit: {__typename?: 'Unit'; id: string; type: UnitType | null; number: string | null} | null;
              user: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleBuildingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SingleBuildingQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'; id: string; name: string | null; complexId: string | null}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleComplexQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SingleComplexQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {
        __typename?: 'Complex';
        id: string;
        lat: number | null;
        long: number | null;
        name: string | null;
        type: ComplexType | null;
        status: ComplexStatus | null;
        stratum: number | null;
        features: Array<ComplexFeatures | null> | null;
        address: string | null;
        district: {
          __typename?: 'District';
          id: string;
          name: string | null;
          city: {
            __typename?: 'City';
            id: string;
            name: string | null;
            state: {
              __typename?: 'State';
              id: string;
              name: string | null;
              country: {__typename?: 'Country'; id: string; name: string | null} | null;
            } | null;
          } | null;
        } | null;
      }
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleComplexRestrictionsQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexRestrictionFilter>;
}>;

export type SingleComplexRestrictionsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {
        __typename?: 'Complex';
        id: string;
        restrictions: {
          __typename?: 'ComplexRestrictionConnection';
          pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean};
          edges: Array<{
            __typename?: 'ComplexRestrictionEdge';
            node: {
              __typename?: 'ComplexRestriction';
              id: string;
              description: string | null;
              insertedAt: any | null;
              expire: any | null;
              type: ComplexRestrictionType | null;
              user: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleComplexRolesQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
  filter: InputMaybe<ComplexRoleFilter>;
}>;

export type SingleComplexRolesQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {
        __typename?: 'Complex';
        id: string;
        roles: {
          __typename?: 'ComplexRoleConnection';
          pageInfo: {__typename?: 'PageInfo'; hasPreviousPage: boolean; hasNextPage: boolean; endCursor: string | null};
          edges: Array<{
            __typename?: 'ComplexRoleEdge';
            node: {
              __typename?: 'ComplexRole';
              id: string;
              insertedAt: any | null;
              role: ComplexRoles | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
              user: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleFeedbackQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SingleFeedbackQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {
        __typename?: 'Feedback';
        id: string;
        description: string | null;
        status: FeedbackStatus | null;
        type: FeedbackType | null;
      }
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleNewsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SingleNewsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {
        __typename?: 'News';
        id: string;
        complexId: string | null;
        insertedAt: any | null;
        title: string | null;
        status: NewsStatus | null;
        updatedAt: any | null;
        allowReactions: boolean | null;
        allowComments: boolean | null;
        body: string | null;
        createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
        reactions: {
          __typename?: 'NewsReactionConnection';
          total: Array<{
            __typename?: 'NewsReactionCount';
            count: number | null;
            reaction: NewsReactionType | null;
            reacted: boolean | null;
          } | null> | null;
        } | null;
        files: Array<{
          __typename?: 'File';
          id: string;
          type: FileType | null;
          filename: string | null;
          contentType: string | null;
          urls:
            | {__typename?: 'AvatarFile'}
            | {__typename?: 'BillboardFile'}
            | {__typename?: 'CoverFile'; cover: string | null; original: string | null; thumb: string | null}
            | {__typename?: 'DocumentFile'; original: string | null; thumb: string | null}
            | null;
        } | null> | null;
        building: {__typename?: 'Building'; id: string; name: string | null} | null;
        complex: {
          __typename?: 'Complex';
          id: string;
          roles: {
            __typename?: 'ComplexRoleConnection';
            pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
            edges: Array<{
              __typename?: 'ComplexRoleEdge';
              node: {__typename?: 'ComplexRole'; id: string; role: ComplexRoles | null; userId: string | null} | null;
            } | null> | null;
          } | null;
        } | null;
      }
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleNewsCommentsQueryVariables = Exact<{
  nodeId: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
}>;

export type SingleNewsCommentsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'; id: string}
    | {__typename?: 'Building'; id: string}
    | {__typename?: 'City'; id: string}
    | {__typename?: 'Complex'; id: string}
    | {__typename?: 'ComplexRestriction'; id: string}
    | {__typename?: 'ComplexRole'; id: string}
    | {__typename?: 'Country'; id: string}
    | {__typename?: 'District'; id: string}
    | {__typename?: 'Feedback'; id: string}
    | {__typename?: 'File'; id: string}
    | {__typename?: 'Guest'; id: string}
    | {__typename?: 'JoinCode'; id: string}
    | {
        __typename?: 'News';
        allowComments: boolean | null;
        id: string;
        comments: {
          __typename?: 'NewsCommentConnection';
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
          edges: Array<{
            __typename?: 'NewsCommentEdge';
            node: {
              __typename?: 'NewsComment';
              id: string;
              comment: string | null;
              insertedAt: any | null;
              status: NewsCommentStatus | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'NewsComment'; id: string}
    | {__typename?: 'NewsReaction'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Package'; id: string}
    | {__typename?: 'PanicAlert'; id: string}
    | {__typename?: 'Pet'; id: string}
    | {__typename?: 'Petition'; id: string}
    | {__typename?: 'PetitionComment'; id: string}
    | {__typename?: 'PetitionStatus'; id: string}
    | {__typename?: 'Poll'; id: string}
    | {__typename?: 'PollAnswer'; id: string}
    | {__typename?: 'PollOption'; id: string}
    | {__typename?: 'Session'; id: string}
    | {__typename?: 'State'; id: string}
    | {__typename?: 'Unit'; id: string}
    | {__typename?: 'UnitContact'; id: string}
    | {__typename?: 'UnitNote'; id: string}
    | {__typename?: 'UnitRole'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'Vehicle'; id: string}
    | null;
};

export type SingleNewsEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SingleNewsEditQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {
        __typename?: 'News';
        id: string;
        complexId: string | null;
        buildingId: string | null;
        insertedAt: any | null;
        title: string | null;
        status: NewsStatus | null;
        updatedAt: any | null;
        allowReactions: boolean | null;
        allowComments: boolean | null;
        body: string | null;
        files: Array<{
          __typename?: 'File';
          id: string;
          type: FileType | null;
          filename: string | null;
          urls:
            | {__typename?: 'AvatarFile'}
            | {__typename?: 'BillboardFile'}
            | {__typename?: 'CoverFile'; cover: string | null; original: string | null; thumb: string | null}
            | {__typename?: 'DocumentFile'; original: string | null; thumb: string | null}
            | null;
        } | null> | null;
        createdBy: {__typename?: 'User'; id: string; lastName: string | null; firstName: string | null} | null;
        building: {__typename?: 'Building'; id: string; name: string | null} | null;
      }
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SinglePetitionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SinglePetitionQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'; id: string}
    | {__typename?: 'Building'; id: string}
    | {__typename?: 'City'; id: string}
    | {__typename?: 'Complex'; id: string}
    | {__typename?: 'ComplexRestriction'; id: string}
    | {__typename?: 'ComplexRole'; id: string}
    | {__typename?: 'Country'; id: string}
    | {__typename?: 'District'; id: string}
    | {__typename?: 'Feedback'; id: string}
    | {__typename?: 'File'; id: string}
    | {__typename?: 'Guest'; id: string}
    | {__typename?: 'JoinCode'; id: string}
    | {__typename?: 'News'; id: string}
    | {__typename?: 'NewsComment'; id: string}
    | {__typename?: 'NewsReaction'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Package'; id: string}
    | {__typename?: 'PanicAlert'; id: string}
    | {__typename?: 'Pet'; id: string}
    | {
        __typename?: 'Petition';
        body: string | null;
        type: PetitionType | null;
        title: string | null;
        priority: number | null;
        currentStatus: PetitionStatusType | null;
        complexId: string | null;
        privacy: Array<PetitionPrivacy | null> | null;
        allowComments: boolean | null;
        insertedAt: any | null;
        updatedAt: any | null;
        id: string;
        statuses: Array<{
          __typename?: 'PetitionStatus';
          id: string;
          insertedAt: any | null;
          status: PetitionStatusType | null;
          createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
        } | null> | null;
        createdBy:
          | ({
              __typename?: 'User';
              unitsRelated: Array<{
                __typename?: 'Unit';
                id: string;
                number: string | null;
                type: UnitType | null;
              } | null> | null;
            } & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
          | null;
        complex: {
          __typename?: 'Complex';
          id: string;
          roles: {
            __typename?: 'ComplexRoleConnection';
            pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
            edges: Array<{
              __typename?: 'ComplexRoleEdge';
              node: {__typename?: 'ComplexRole'; id: string; role: ComplexRoles | null; userId: string | null} | null;
            } | null> | null;
          } | null;
        } | null;
        files: Array<{
          __typename?: 'File';
          id: string;
          type: FileType | null;
          filename: string | null;
          contentType: string | null;
          urls:
            | {__typename?: 'AvatarFile'}
            | {__typename?: 'BillboardFile'}
            | {__typename?: 'CoverFile'}
            | {__typename?: 'DocumentFile'; original: string | null; thumb: string | null}
            | null;
        } | null> | null;
      }
    | {__typename?: 'PetitionComment'; id: string}
    | {__typename?: 'PetitionStatus'; id: string}
    | {__typename?: 'Poll'; id: string}
    | {__typename?: 'PollAnswer'; id: string}
    | {__typename?: 'PollOption'; id: string}
    | {__typename?: 'Session'; id: string}
    | {__typename?: 'State'; id: string}
    | {__typename?: 'Unit'; id: string}
    | {__typename?: 'UnitContact'; id: string}
    | {__typename?: 'UnitNote'; id: string}
    | {__typename?: 'UnitRole'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'Vehicle'; id: string}
    | null;
};

export type SinglePetitionCommentsQueryVariables = Exact<{
  nodeId: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
}>;

export type SinglePetitionCommentsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'; id: string}
    | {__typename?: 'Building'; id: string}
    | {__typename?: 'City'; id: string}
    | {__typename?: 'Complex'; id: string}
    | {__typename?: 'ComplexRestriction'; id: string}
    | {__typename?: 'ComplexRole'; id: string}
    | {__typename?: 'Country'; id: string}
    | {__typename?: 'District'; id: string}
    | {__typename?: 'Feedback'; id: string}
    | {__typename?: 'File'; id: string}
    | {__typename?: 'Guest'; id: string}
    | {__typename?: 'JoinCode'; id: string}
    | {__typename?: 'News'; id: string}
    | {__typename?: 'NewsComment'; id: string}
    | {__typename?: 'NewsReaction'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Package'; id: string}
    | {__typename?: 'PanicAlert'; id: string}
    | {__typename?: 'Pet'; id: string}
    | {
        __typename?: 'Petition';
        allowComments: boolean | null;
        id: string;
        comments: {
          __typename?: 'PetitionCommentConnection';
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
          edges: Array<{
            __typename?: 'PetitionCommentEdge';
            node: {
              __typename?: 'PetitionComment';
              id: string;
              comment: string | null;
              insertedAt: any | null;
              status: PetitionCommentStatus | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'PetitionComment'; id: string}
    | {__typename?: 'PetitionStatus'; id: string}
    | {__typename?: 'Poll'; id: string}
    | {__typename?: 'PollAnswer'; id: string}
    | {__typename?: 'PollOption'; id: string}
    | {__typename?: 'Session'; id: string}
    | {__typename?: 'State'; id: string}
    | {__typename?: 'Unit'; id: string}
    | {__typename?: 'UnitContact'; id: string}
    | {__typename?: 'UnitNote'; id: string}
    | {__typename?: 'UnitRole'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'Vehicle'; id: string}
    | null;
};

export type SinglePollQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  filter: InputMaybe<PollAnswerFilter>;
}>;

export type SinglePollQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {
        __typename?: 'Poll';
        id: string;
        closeAt: any | null;
        status: PollStatus | null;
        question: string | null;
        privacy: PollPrivacy | null;
        insertedAt: any | null;
        description: string | null;
        complexId: string | null;
        options: Array<{
          __typename?: 'PollOption';
          id: string;
          option: string | null;
          answers: {__typename?: 'PollAnswerConnection'; countMine: number | null; countTotal: number | null} | null;
        } | null> | null;
        createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
      }
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SinglePollOptionQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
  filter: InputMaybe<PollAnswerFilter>;
}>;

export type SinglePollOptionQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {
        __typename?: 'PollOption';
        id: string;
        option: string | null;
        answers: {
          __typename?: 'PollAnswerConnection';
          countMine: number | null;
          countTotal: number | null;
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
          edges: Array<{
            __typename?: 'PollAnswerEdge';
            node: {
              __typename?: 'PollAnswer';
              id: string;
              insertedAt: any | null;
              unitRole: {
                __typename?: 'UnitRole';
                id: string;
                unit: {__typename?: 'Unit'; id: string; number: string | null; type: UnitType | null} | null;
                user:
                  | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                  | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleUnitQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SingleUnitQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {
        __typename?: 'Unit';
        id: string;
        floor: string | null;
        number: string | null;
        type: UnitType | null;
        phone: string | null;
        coefficient: number | null;
        complexId: string | null;
        buildingId: string | null;
        building: {__typename?: 'Building'; id: string; name: string | null} | null;
        complex: {__typename?: 'Complex'; id: string; name: string | null} | null;
      }
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleUnitContactsQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
}>;

export type SingleUnitContactsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {
        __typename?: 'Unit';
        id: string;
        complexId: string | null;
        unitContacts: {
          __typename?: 'UnitContactConnection';
          edges: Array<{
            __typename?: 'UnitContactEdge';
            node: {
              __typename?: 'UnitContact';
              id: string;
              insertedAt: any | null;
              firstName: string | null;
              lastName: string | null;
              email: string | null;
              phone: string | null;
              description: string | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
        } | null;
      }
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleUnitJoinCodeQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
}>;

export type SingleUnitJoinCodeQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {
        __typename?: 'Unit';
        id: string;
        complexId: string | null;
        joinCodes: {
          __typename?: 'JoinCodeConnection';
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null; hasPreviousPage: boolean};
          edges: Array<{
            __typename?: 'JoinCodeEdge';
            node: {
              __typename?: 'JoinCode';
              id: string;
              code: string | null;
              expire: any | null;
              maxUses: number | null;
              insertedAt: any | null;
              role: UnitRoles | null;
              status: JoinCodeStatus | null;
              uses: number | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleUnitMembersQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
  withRights: Scalars['Boolean'];
}>;

export type SingleUnitMembersQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {
        __typename?: 'Unit';
        id: string;
        complexId: string | null;
        roles: {
          __typename?: 'UnitRoleConnection';
          total: number | null;
          edges: Array<{
            __typename?: 'UnitRoleEdge';
            node: {
              __typename?: 'UnitRole';
              id: string;
              role: UnitRoles | null;
              insertedAt: any | null;
              userId: string | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
              user:
                | ({
                    __typename?: 'User';
                    birthdayNonYear?: any | null;
                    celCountry?: string | null;
                    celNumber?: string | null;
                    email?: string | null;
                    sex?: HumanSex | null;
                    idNumber?: string | null;
                    idType?: IdType | null;
                  } & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
          pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean};
        } | null;
      }
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleUnitNotesQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
}>;

export type SingleUnitNotesQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {
        __typename?: 'Unit';
        id: string;
        complexId: string | null;
        unitNotes: {
          __typename?: 'UnitNoteConnection';
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
          edges: Array<{
            __typename?: 'UnitNoteEdge';
            node: {
              __typename?: 'UnitNote';
              id: string;
              note: string | null;
              insertedAt: any | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleUnitPetsQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
}>;

export type SingleUnitPetsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {
        __typename?: 'Unit';
        id: string;
        complexId: string | null;
        pets: {
          __typename?: 'PetConnection';
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
          edges: Array<{
            __typename?: 'PetEdge';
            node: {
              __typename?: 'Pet';
              id: string;
              birthday: any | null;
              insertedAt: any | null;
              sex: PetSex | null;
              name: string | null;
              type: PetType | null;
              vaccinesDate: any | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | {__typename?: 'User'}
    | {__typename?: 'Vehicle'}
    | null;
};

export type SingleUnitVehiclesQueryVariables = Exact<{
  id: Scalars['ID'];
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
}>;

export type SingleUnitVehiclesQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'; id: string}
    | {__typename?: 'Building'; id: string}
    | {__typename?: 'City'; id: string}
    | {__typename?: 'Complex'; id: string}
    | {__typename?: 'ComplexRestriction'; id: string}
    | {__typename?: 'ComplexRole'; id: string}
    | {__typename?: 'Country'; id: string}
    | {__typename?: 'District'; id: string}
    | {__typename?: 'Feedback'; id: string}
    | {__typename?: 'File'; id: string}
    | {__typename?: 'Guest'; id: string}
    | {__typename?: 'JoinCode'; id: string}
    | {__typename?: 'News'; id: string}
    | {__typename?: 'NewsComment'; id: string}
    | {__typename?: 'NewsReaction'; id: string}
    | {__typename?: 'Notification'; id: string}
    | {__typename?: 'Package'; id: string}
    | {__typename?: 'PanicAlert'; id: string}
    | {__typename?: 'Pet'; id: string}
    | {__typename?: 'Petition'; id: string}
    | {__typename?: 'PetitionComment'; id: string}
    | {__typename?: 'PetitionStatus'; id: string}
    | {__typename?: 'Poll'; id: string}
    | {__typename?: 'PollAnswer'; id: string}
    | {__typename?: 'PollOption'; id: string}
    | {__typename?: 'Session'; id: string}
    | {__typename?: 'State'; id: string}
    | {
        __typename?: 'Unit';
        complexId: string | null;
        id: string;
        vehicles: {
          __typename?: 'VehicleConnection';
          edges: Array<{
            __typename?: 'VehicleEdge';
            node: {
              __typename?: 'Vehicle';
              id: string;
              brand: string | null;
              color: string | null;
              fuel: FuelType | null;
              insertedAt: any | null;
              model: number | null;
              plate: string | null;
              publicService: boolean | null;
              rtm: any | null;
              soat: any | null;
              status: VehicleStatus | null;
              type: VehicleType | null;
              createdBy:
                | ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
                | null;
            } | null;
          } | null> | null;
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null; hasPreviousPage: boolean};
        } | null;
      }
    | {__typename?: 'UnitContact'; id: string}
    | {__typename?: 'UnitNote'; id: string}
    | {__typename?: 'UnitRole'; id: string}
    | {__typename?: 'User'; id: string}
    | {__typename?: 'Vehicle'; id: string}
    | null;
};

export type SingleUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SingleUserQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Billboard'}
    | {__typename?: 'Building'}
    | {__typename?: 'City'}
    | {__typename?: 'Complex'}
    | {__typename?: 'ComplexRestriction'}
    | {__typename?: 'ComplexRole'}
    | {__typename?: 'Country'}
    | {__typename?: 'District'}
    | {__typename?: 'Feedback'}
    | {__typename?: 'File'}
    | {__typename?: 'Guest'}
    | {__typename?: 'JoinCode'}
    | {__typename?: 'News'}
    | {__typename?: 'NewsComment'}
    | {__typename?: 'NewsReaction'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Package'}
    | {__typename?: 'PanicAlert'}
    | {__typename?: 'Pet'}
    | {__typename?: 'Petition'}
    | {__typename?: 'PetitionComment'}
    | {__typename?: 'PetitionStatus'}
    | {__typename?: 'Poll'}
    | {__typename?: 'PollAnswer'}
    | {__typename?: 'PollOption'}
    | {__typename?: 'Session'}
    | {__typename?: 'State'}
    | {__typename?: 'Unit'}
    | {__typename?: 'UnitContact'}
    | {__typename?: 'UnitNote'}
    | {__typename?: 'UnitRole'}
    | ({
        __typename?: 'User';
        birthday: any | null;
        celCountry: string | null;
        celNumber: string | null;
        email: string | null;
        emailConfirmedAt: any | null;
        hasPassword: boolean | null;
        idNumber: string | null;
        idType: IdType | null;
        role: UserRoles | null;
        sex: HumanSex | null;
        status: UserStatuses | null;
      } & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
    | {__typename?: 'Vehicle'}
    | null;
};

export type UnitsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  order: InputMaybe<UnitSort>;
  filter: InputMaybe<UnitFilter>;
}>;

export type UnitsQuery = {
  __typename?: 'RootQueryType';
  units: {
    __typename?: 'UnitConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean; endCursor: string | null};
    edges: Array<{
      __typename?: 'UnitEdge';
      node: {
        __typename?: 'Unit';
        id: string;
        number: string | null;
        type: UnitType | null;
        building: {__typename?: 'Building'; id: string; name: string | null} | null;
        complex: {__typename?: 'Complex'; id: string; name: string | null} | null;
        roles: {
          __typename?: 'UnitRoleConnection';
          total: number | null;
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
          edges: Array<{
            __typename?: 'UnitRoleEdge';
            node: {
              __typename?: 'UnitRole';
              id: string;
              user: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateFeedbackMutationVariables = Exact<{
  id: Scalars['ID'];
  description: InputMaybe<Scalars['String']>;
  status: InputMaybe<FeedbackStatus>;
  type: InputMaybe<FeedbackType>;
}>;

export type UpdateFeedbackMutation = {
  __typename?: 'RootMutationType';
  updateFeedback: {
    __typename?: 'Feedback';
    id: string;
    status: FeedbackStatus | null;
    type: FeedbackType | null;
    description: string | null;
    updatedBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
  } | null;
};

export type UsersQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  order: InputMaybe<UserSort>;
  filter: InputMaybe<UserFilter>;
}>;

export type UsersQuery = {
  __typename?: 'RootQueryType';
  users: {
    __typename?: 'UserConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'UserEdge';
      node:
        | ({
            __typename?: 'User';
            email: string | null;
            role: UserRoles | null;
            status: UserStatuses | null;
            celCountry: string | null;
            celNumber: string | null;
            idNumber: string | null;
            hasPassword: boolean | null;
            insertedAt: any | null;
          } & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
        | null;
    } | null> | null;
  } | null;
};

export type VehiclesQueryVariables = Exact<{
  filter: InputMaybe<VehicleFilter>;
  first: InputMaybe<Scalars['Int']>;
  after: InputMaybe<Scalars['String']>;
}>;

export type VehiclesQuery = {
  __typename?: 'RootQueryType';
  vehicles: {
    __typename?: 'VehicleConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'VehicleEdge';
      node: {
        __typename?: 'Vehicle';
        id: string;
        brand: string | null;
        color: string | null;
        fuel: FuelType | null;
        insertedAt: any | null;
        model: number | null;
        plate: string | null;
        publicService: boolean | null;
        rtm: any | null;
        soat: any | null;
        status: VehicleStatus | null;
        type: VehicleType | null;
        createdBy: ({__typename?: 'User'} & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}}) | null;
        unit: {
          __typename?: 'Unit';
          id: string;
          number: string | null;
          complex: {__typename?: 'Complex'; id: string; name: string | null} | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type BasicProfileFragment = {
  __typename?: 'User';
  id: string;
  firstName: string | null;
  lastName: string | null;
  avatar: {
    __typename?: 'File';
    id: string;
    urls:
      | {__typename?: 'AvatarFile'; thumb: string | null}
      | {__typename?: 'BillboardFile'}
      | {__typename?: 'CoverFile'}
      | {__typename?: 'DocumentFile'}
      | null;
  } | null;
} & {' $fragmentName'?: 'BasicProfileFragment'};

export type ViewerQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']>;
}>;

export type ViewerQuery = {
  __typename?: 'RootQueryType';
  viewer:
    | ({
        __typename?: 'User';
        role: UserRoles | null;
        unitRoles: {
          __typename?: 'UnitRoleConnection';
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
          edges: Array<{
            __typename?: 'UnitRoleEdge';
            node: {
              __typename?: 'UnitRole';
              id: string;
              role: UnitRoles | null;
              unit: {
                __typename?: 'Unit';
                id: string;
                number: string | null;
                type: UnitType | null;
                complex: {
                  __typename?: 'Complex';
                  id: string;
                  name: string | null;
                  features: Array<ComplexFeatures | null> | null;
                  buildings: Array<{__typename?: 'Building'; id: string; name: string | null} | null> | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        complexRoles: {
          __typename?: 'ComplexRoleConnection';
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
          edges: Array<{
            __typename?: 'ComplexRoleEdge';
            node: {
              __typename?: 'ComplexRole';
              id: string;
              role: ComplexRoles | null;
              complex: {
                __typename?: 'Complex';
                id: string;
                name: string | null;
                features: Array<ComplexFeatures | null> | null;
                buildings: Array<{__typename?: 'Building'; id: string; name: string | null} | null> | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
    | null;
};

export type ViewerFullProfileQueryVariables = Exact<{[key: string]: never}>;

export type ViewerFullProfileQuery = {
  __typename?: 'RootQueryType';
  viewer:
    | ({
        __typename?: 'User';
        email: string | null;
        emailConfirmedAt: any | null;
        hasPassword: boolean | null;
        celNumber: string | null;
        celCountry: string | null;
        birthday: any | null;
        idNumber: string | null;
        idType: IdType | null;
        role: UserRoles | null;
        sex: HumanSex | null;
      } & {' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment}})
    | null;
};

export type ViewerProfileQueryVariables = Exact<{[key: string]: never}>;

export type ViewerProfileQuery = {
  __typename?: 'RootQueryType';
  viewer:
    | ({__typename?: 'User'; role: UserRoles | null} & {
        ' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment};
      })
    | null;
};

export const BasicProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasicProfileFragment, unknown>;
export const CancelJoinCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cancelJoinCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cancelJoinCode'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelJoinCodeMutation, CancelJoinCodeMutationVariables>;
export const ClosePollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'closePoll'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'closePoll'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'closeAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClosePollMutation, ClosePollMutationVariables>;
export const CreateBillboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createBillboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'fileId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createBillboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fileId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'fileId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'buildingId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'complexId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'file'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'urls'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BillboardFile'}},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'billboard'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBillboardMutation, CreateBillboardMutationVariables>;
export const CreateBuildingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createBuilding'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createBuilding'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'complexId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBuildingMutation, CreateBuildingMutationVariables>;
export const CreateComplexDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createComplex'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexStatus'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'address'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'districtId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'features'}},
          type: {kind: 'ListType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexFeatures'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'lat'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'long'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'stratum'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createComplex'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'address'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'address'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'districtId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'districtId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'features'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'features'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'lat'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'lat'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'long'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'long'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'stratum'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'stratum'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateComplexMutation, CreateComplexMutationVariables>;
export const CreateComplexRestrictionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createComplexRestriction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'expire'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexRestrictionType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createComplexRestriction'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'complexId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'expire'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'expire'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'userId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateComplexRestrictionMutation, CreateComplexRestrictionMutationVariables>;
export const CreateFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createFeedback'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'FeedbackType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'fileId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createFeedback'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fileId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'fileId'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const CreateJoinCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createJoinCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'expire'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'maxUses'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'UnitRoles'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createJoinCode'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'expire'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'expire'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'maxUses'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'maxUses'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'role'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'expire'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'maxUses'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'uses'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateJoinCodeMutation, CreateJoinCodeMutationVariables>;
export const CreateNewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createNews'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'title'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'allowComments'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'allowReactions'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'body'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'NewsStatus'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'files'}},
          type: {
            kind: 'ListType',
            type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createNews'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'complexId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'title'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'title'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'allowComments'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'allowComments'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'allowReactions'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'allowReactions'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'body'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'body'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'buildingId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'files'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'files'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateNewsMutation, CreateNewsMutationVariables>;
export const CreateNewsCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createNewsComment'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'comment'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createNewsComment'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'newsId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'comment'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'comment'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateNewsCommentMutation, CreateNewsCommentMutationVariables>;
export const CreatePetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPet'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'vaccinesDate'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Date'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sex'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetSex'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'birthday'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Date'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPet'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'vaccinesDate'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'vaccinesDate'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sex'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sex'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'birthday'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'birthday'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'birthday'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createdBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'sex'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'vaccinesDate'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePetMutation, CreatePetMutationVariables>;
export const CreatePetitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPetition'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'body'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'privacy'}},
          type: {
            kind: 'ListType',
            type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionPrivacy'}}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'title'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'files'}},
          type: {
            kind: 'ListType',
            type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPetition'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'body'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'body'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'complexId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'privacy'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'privacy'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'title'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'title'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'files'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'files'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePetitionMutation, CreatePetitionMutationVariables>;
export const CreatePetitionCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPetitionComment'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'comment'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPetitionComment'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'petitionId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'comment'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'comment'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePetitionCommentMutation, CreatePetitionCommentMutationVariables>;
export const CreatePetitionStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPetitionStatus'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'petitionId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionStatusType'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPetitionStatus'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'petitionId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'petitionId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'petition'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'currentStatus'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'statuses'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePetitionStatusMutation, CreatePetitionStatusMutationVariables>;
export const CreatePollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPoll'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'options'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'privacy'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'PollPrivacy'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'question'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'closeAt'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPoll'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'complexId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'options'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'options'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'privacy'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'privacy'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'question'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'question'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'buildingId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'closeAt'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'closeAt'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'privacy'}},
                {kind: 'Field', name: {kind: 'Name', value: 'question'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'closeAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePollMutation, CreatePollMutationVariables>;
export const CreateUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createUnit'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'number'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'UnitType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'floor'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'coefficient'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createUnit'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'complexId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'number'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'number'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'phone'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'floor'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'floor'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'coefficient'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'coefficient'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'buildingId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUnitMutation, CreateUnitMutationVariables>;
export const CreateUnitContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createUnitContact'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'lastName'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'firstName'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createUnitContact'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'lastName'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'lastName'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'firstName'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'firstName'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'phone'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {kind: 'Field', name: {kind: 'Name', value: 'phone'}},
                {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createdBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUnitContactMutation, CreateUnitContactMutationVariables>;
export const CreateUnitNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createUnitNote'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'note'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createUnitNote'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'note'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'note'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'note'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createdBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUnitNoteMutation, CreateUnitNoteMutationVariables>;
export const CreateVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createVehicle'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'plate'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'soat'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Date'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'rtm'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Date'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'publicService'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'VehicleType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'fuel'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'FuelType'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'color'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'brand'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createVehicle'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'plate'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'plate'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'soat'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'soat'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'rtm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'rtm'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'publicService'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'publicService'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'model'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'model'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fuel'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'fuel'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'color'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'color'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'brand'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'brand'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                {kind: 'Field', name: {kind: 'Name', value: 'color'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'model'}},
                {kind: 'Field', name: {kind: 'Name', value: 'plate'}},
                {kind: 'Field', name: {kind: 'Name', value: 'publicService'}},
                {kind: 'Field', name: {kind: 'Name', value: 'rtm'}},
                {kind: 'Field', name: {kind: 'Name', value: 'soat'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createdBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateVehicleMutation, CreateVehicleMutationVariables>;
export const DeleteBillboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteBillboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteBillboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBillboardMutation, DeleteBillboardMutationVariables>;
export const DeleteNewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'DeleteNews'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteNews'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteNewsMutation, DeleteNewsMutationVariables>;
export const DeletePetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deletePet'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deletePet'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePetMutation, DeletePetMutationVariables>;
export const DeletePetitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deletePetition'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deletePetition'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePetitionMutation, DeletePetitionMutationVariables>;
export const DeletePollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deletePoll'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deletePoll'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePollMutation, DeletePollMutationVariables>;
export const DeleteUnitContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteUnitContact'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteUnitContact'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUnitContactMutation, DeleteUnitContactMutationVariables>;
export const DeleteUnitNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteUnitNote'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteUnitNote'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUnitNoteMutation, DeleteUnitNoteMutationVariables>;
export const GenerateAuthCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'generateAuthCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'generateAuthCode'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celCountry'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'ttl'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateAuthCodeMutation, GenerateAuthCodeMutationVariables>;
export const ReadAllNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'readAllNotifications'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'readAllNotifications'},
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;
export const RefreshTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'refreshToken'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'refreshToken'},
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'access'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const RemoveComplexRestrictionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'removeComplexRestriction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'removeComplexRestriction'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveComplexRestrictionMutation, RemoveComplexRestrictionMutationVariables>;
export const RemoveComplexRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'removeComplexRole'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'removeComplexRole'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveComplexRoleMutation, RemoveComplexRoleMutationVariables>;
export const RemoveUnitRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'removeUnitRole'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'roleId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'removeUnitRole'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'roleId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'user'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveUnitRoleMutation, RemoveUnitRoleMutationVariables>;
export const RequestAuthCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'requestAuthCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'requestAuthCode'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celCountry'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestAuthCodeMutation, RequestAuthCodeMutationVariables>;
export const SetComplexRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'setComplexRole'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexRoles'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'setComplexRole'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'complexId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'complexId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'role'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'userId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetComplexRoleMutation, SetComplexRoleMutationVariables>;
export const SetNewsReactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'setNewsReaction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'newsId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'reaction'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'NewsReactionType'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'setNewsReaction'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'newsId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'newsId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reaction'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'reaction'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'action'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'reaction'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'reaction'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetNewsReactionMutation, SetNewsReactionMutationVariables>;
export const SetPollVoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'setPollVote'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'optionId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'setPollVote'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'optionId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'optionId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'action'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pollAnswer'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetPollVoteMutation, SetPollVoteMutationVariables>;
export const SetUnitRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'setUnitRole'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'UnitRoles'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'setUnitRole'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'role'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'unitId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'unitId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'userId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetUnitRoleMutation, SetUnitRoleMutationVariables>;
export const SignInDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'signIn'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'signIn'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celCountry'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'code'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'access'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'signOut'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'signOut'},
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignOutMutation, SignOutMutationVariables>;
export const SignUpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'signUp'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'firstName'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'lastName'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'signUp'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'firstName'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'firstName'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'lastName'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'lastName'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celCountry'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignUpMutation, SignUpMutationVariables>;
export const SoftDeleteVehicleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'softDeleteVehicle'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'softDeleteVehicle'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SoftDeleteVehicleMutation, SoftDeleteVehicleMutationVariables>;
export const UpdateBuildingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateBuilding'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateBuilding'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBuildingMutation, UpdateBuildingMutationVariables>;
export const UpdateComplexDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateComplex'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'lat'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'long'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'features'}},
          type: {kind: 'ListType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexFeatures'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'districtId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'address'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexStatus'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexType'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'stratum'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateComplex'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'lat'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'lat'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'long'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'long'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'features'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'features'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'districtId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'districtId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'address'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'address'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'stratum'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'stratum'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateComplexMutation, UpdateComplexMutationVariables>;
export const UpdateEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateEmail'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateEmail'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'code'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdateNewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateNews'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'newsId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'title'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'allowComments'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'allowReactions'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'body'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'NewsStatus'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'files'}},
          type: {
            kind: 'ListType',
            type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateNews'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'newsId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'title'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'title'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'allowComments'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'allowComments'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'allowReactions'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'allowReactions'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'body'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'body'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'buildingId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'files'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'files'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateNewsMutation, UpdateNewsMutationVariables>;
export const UpdateNewsCommentStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateNewsCommentStatus'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'commentId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'NewsCommentStatus'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateNewsCommentStatus'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'commentId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateNewsCommentStatusMutation, UpdateNewsCommentStatusMutationVariables>;
export const UpdatePetitionCommentStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UpdatePetitionCommentStatus'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'commentId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionCommentStatus'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatePetitionCommentStatus'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'commentId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePetitionCommentStatusMutation, UpdatePetitionCommentStatusMutationVariables>;
export const UpdatePetitionElevatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updatePetitionElevated'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'priority'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'allowComments'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'privacy'}},
          type: {
            kind: 'ListType',
            type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionPrivacy'}}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatePetitionElevated'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'priority'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'priority'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'allowComments'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'allowComments'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'privacy'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'privacy'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'priority'}},
                {kind: 'Field', name: {kind: 'Name', value: 'privacy'}},
                {kind: 'Field', name: {kind: 'Name', value: 'allowComments'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePetitionElevatedMutation, UpdatePetitionElevatedMutationVariables>;
export const UpdatePhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updatePhone'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatePhone'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celCountry'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'code'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'celCountry'}},
                {kind: 'Field', name: {kind: 'Name', value: 'celNumber'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePhoneMutation, UpdatePhoneMutationVariables>;
export const UpdatePollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updatePoll'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'closeAt'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'question'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatePoll'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'buildingId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'closeAt'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'closeAt'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'question'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'question'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'buildingId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'question'}},
                {kind: 'Field', name: {kind: 'Name', value: 'closeAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePollMutation, UpdatePollMutationVariables>;
export const UpdateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateProfile'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sex'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'HumanSex'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'lastName'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'idType'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'IdType'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'idNumber'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'firstName'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'emailNotifications'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'birthday'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Date'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'avatarId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateProfile'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sex'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sex'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'lastName'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'lastName'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'idType'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'idType'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'idNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'idNumber'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'firstName'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'firstName'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'emailNotifications'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'emailNotifications'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'birthday'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'birthday'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'avatarId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'avatarId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                {kind: 'Field', name: {kind: 'Name', value: 'birthday'}},
                {kind: 'Field', name: {kind: 'Name', value: 'celCountry'}},
                {kind: 'Field', name: {kind: 'Name', value: 'celNumber'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {kind: 'Field', name: {kind: 'Name', value: 'emailConfirmedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'idNumber'}},
                {kind: 'Field', name: {kind: 'Name', value: 'idType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'sex'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UpdateSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UpdateSession'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'appBuild'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'appId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'appVersion'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'device'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'langCode'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'system'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'systemVersion'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timezone'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateSession'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'appBuild'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'appBuild'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'appId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'appId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'appVersion'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'appVersion'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'device'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'device'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'langCode'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'langCode'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'system'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'system'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'systemVersion'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'systemVersion'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timezone'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timezone'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'appVersion'}},
                {kind: 'Field', name: {kind: 'Name', value: 'appBuild'}},
                {kind: 'Field', name: {kind: 'Name', value: 'appId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'langCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pushToken'}},
                {kind: 'Field', name: {kind: 'Name', value: 'terminatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'timezone'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSessionMutation, UpdateSessionMutationVariables>;
export const UpdateUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateUnit'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UnitType'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'number'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'floor'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'coefficient'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateUnit'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'phone'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'phone'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'number'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'number'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'floor'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'floor'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'coefficient'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'coefficient'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'buildingId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'buildingId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'floor'}},
                {kind: 'Field', name: {kind: 'Name', value: 'coefficient'}},
                {kind: 'Field', name: {kind: 'Name', value: 'buildingId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                {kind: 'Field', name: {kind: 'Name', value: 'phone'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUnitMutation, UpdateUnitMutationVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateUser'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'changes'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'UpdateUserInput'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateUser'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'changes'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'changes'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateUserPassword'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'newPassword'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateUserPassword'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'newPassword'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'newPassword'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const UploadFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'uploadFile'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'FileType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'file'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Upload'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'uploadFile'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'file'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'file'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'avatar'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CoverFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'cover'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BillboardFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'billboard'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DocumentFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'original'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadFileMutation, UploadFileMutationVariables>;
export const UseJoinCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useJoinCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'useJoinCode'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'code'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseJoinCodeMutation, UseJoinCodeMutationVariables>;
export const AvailableLoginMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'availableLoginMethods'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'availableLoginMethods'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celCountry'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celCountry'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'celNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'celNumber'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'email'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableLoginMethodsQuery, AvailableLoginMethodsQueryVariables>;
export const BillboardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'billboards'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'BillboardFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'BillboardSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'billboards'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'file'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'urls'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'BillboardFile'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'billboard'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillboardsQuery, BillboardsQueryVariables>;
export const BuildingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'buildings'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'BuildingFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'buildings'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'complex'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'units'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'first'},
                                  value: {kind: 'IntValue', value: '0'},
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'total'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuildingsQuery, BuildingsQueryVariables>;
export const ComplexesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'complexes'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'complexes'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lat'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'long'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'address'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'stratum'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'features'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'districtId'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'district'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'city'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'state'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'country'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                                    {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'buildings'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComplexesQuery, ComplexesQueryVariables>;
export const FeedbacksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'feedbacks'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'FeedbackFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'FeedbackSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'feedbacks'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'file'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'filename'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'urls'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'DocumentFile'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{kind: 'Field', name: {kind: 'Name', value: 'original'}}],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'updatedBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedbacksQuery, FeedbacksQueryVariables>;
export const HomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'home'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'billboardFirst'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'newsFirst'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pollsFirst'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
          type: {
            kind: 'ListType',
            type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'billboards'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'billboardFirst'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'complexes'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'file'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'urls'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {kind: 'Name', value: 'BillboardFile'},
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'billboard'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'news'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'newsFirst'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'complexes'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'polls'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'complexes'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'pollsFirst'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'question'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeQuery, HomeQueryVariables>;
export const InsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'insights'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
          type: {
            kind: 'ListType',
            type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'petInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'complexes'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'type'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'vehicleInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'complexes'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'type'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'unitInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'complexes'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {kind: 'Field', name: {kind: 'Name', value: 'withoutMembers'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'complexes'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'complexes'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'sex'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'sex'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'age'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'age'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsightsQuery, InsightsQueryVariables>;
export const NewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'news'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'NewsFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'NewsSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'news'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewsQuery, NewsQueryVariables>;
export const NotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'notifications'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'NotificationFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'NotificationSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'notifications'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'first'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                    },
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'filter'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
                    },
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'order'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'unreadCount'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'pageInfo'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'edges'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'node'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'body'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'resourceId'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'resourceExtra'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'read'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>;
export const PetitionCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'petitionComments'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionCommentFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'petitionComments'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'comment'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'petition'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetitionCommentsQuery, PetitionCommentsQueryVariables>;
export const PetitionInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'PetitionInsights'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'previus'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionInsightFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'petitionInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'previus'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'previus'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'comments'}},
                {kind: 'Field', name: {kind: 'Name', value: 'finished'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusClosed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'petitions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusInProgress'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusResolved'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusUnderReview'}},
                {kind: 'Field', name: {kind: 'Name', value: 'typeClaim'}},
                {kind: 'Field', name: {kind: 'Name', value: 'typeComplaint'}},
                {kind: 'Field', name: {kind: 'Name', value: 'typeQuestion'}},
                {kind: 'Field', name: {kind: 'Name', value: 'typeSuggestion'}},
                {kind: 'Field', name: {kind: 'Name', value: 'unfinished'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetitionInsightsQuery, PetitionInsightsQueryVariables>;
export const PetitionListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'petitionList'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'petitions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'currentStatus'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetitionListQuery, PetitionListQueryVariables>;
export const PetitionListAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'petitionListAdmin'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PetitionSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'petitions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'currentStatus'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'priority'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetitionListAdminQuery, PetitionListAdminQueryVariables>;
export const PollsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'polls'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PollFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PollSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'polls'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'question'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PollsQuery, PollsQueryVariables>;
export const SearchComplexUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'searchComplexUser'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UnitFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Complex'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitRoles'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'filter'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'unit'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'user'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchComplexUserQuery, SearchComplexUserQueryVariables>;
export const SingleBuildingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleBuilding'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Building'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleBuildingQuery, SingleBuildingQueryVariables>;
export const SingleComplexDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleComplex'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Complex'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lat'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'long'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'stratum'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'features'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'address'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'district'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'city'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'state'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'country'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleComplexQuery, SingleComplexQueryVariables>;
export const SingleComplexRestrictionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleComplexRestrictions'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexRestrictionFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Complex'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'restrictions'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'filter'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'expire'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'user'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleComplexRestrictionsQuery, SingleComplexRestrictionsQueryVariables>;
export const SingleComplexRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleComplexRoles'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ComplexRoleFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Complex'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'roles'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'filter'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'user'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleComplexRolesQuery, SingleComplexRolesQueryVariables>;
export const SingleFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleFeedback'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Feedback'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleFeedbackQuery, SingleFeedbackQueryVariables>;
export const SingleNewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleNews'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'News'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'allowReactions'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'allowComments'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'body'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'reactions'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'IntValue', value: '0'},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'total'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'reaction'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'reacted'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'files'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'filename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'contentType'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'urls'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CoverFile'}},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'cover'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'original'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DocumentFile'}},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'original'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'building'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'complex'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'roles'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'first'},
                                  value: {kind: 'IntValue', value: '50'},
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'pageInfo'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'edges'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'node'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleNewsQuery, SingleNewsQueryVariables>;
export const SingleNewsCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleNewsComments'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'nodeId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'nodeId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'News'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'allowComments'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'comments'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'comment'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleNewsCommentsQuery, SingleNewsCommentsQueryVariables>;
export const SingleNewsEditDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleNewsEdit'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'News'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'buildingId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'files'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'filename'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'urls'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CoverFile'}},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'cover'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'original'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DocumentFile'}},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'original'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'allowReactions'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'allowComments'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'body'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'building'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleNewsEditQuery, SingleNewsEditQueryVariables>;
export const SinglePetitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singlePetition'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Petition'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'body'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'priority'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'currentStatus'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'privacy'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'allowComments'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'statuses'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unitsRelated'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'complex'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'roles'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'first'},
                                  value: {kind: 'IntValue', value: '50'},
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'pageInfo'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'edges'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'node'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'files'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'filename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'contentType'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'urls'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DocumentFile'}},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'original'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SinglePetitionQuery, SinglePetitionQueryVariables>;
export const SinglePetitionCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singlePetitionComments'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'nodeId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'nodeId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Petition'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'allowComments'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'comments'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'comment'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SinglePetitionCommentsQuery, SinglePetitionCommentsQueryVariables>;
export const SinglePollDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singlePoll'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PollAnswerFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Poll'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'closeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'question'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'privacy'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'options'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'option'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'answers'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'first'},
                                  value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                                },
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'filter'},
                                  value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'countMine'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'countTotal'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SinglePollQuery, SinglePollQueryVariables>;
export const SinglePollOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singlePollOption'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PollAnswerFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PollOption'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'option'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'answers'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'filter'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'countMine'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'countTotal'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'unitRole'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'unit'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                                    {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                                                    {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'user'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {kind: 'Name', value: 'BasicProfile'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SinglePollOptionQuery, SinglePollOptionQueryVariables>;
export const SingleUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUnit'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Unit'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'floor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'phone'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'coefficient'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'buildingId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'building'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'complex'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUnitQuery, SingleUnitQueryVariables>;
export const SingleUnitContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUnitContacts'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Unit'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitContacts'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'phone'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUnitContactsQuery, SingleUnitContactsQueryVariables>;
export const SingleUnitJoinCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUnitJoinCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Unit'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'joinCodes'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'expire'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'maxUses'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'uses'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUnitJoinCodeQuery, SingleUnitJoinCodeQueryVariables>;
export const SingleUnitMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUnitMembers'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'withRights'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Unit'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'roles'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'user'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'birthdayNonYear'},
                                                directives: [
                                                  {
                                                    kind: 'Directive',
                                                    name: {kind: 'Name', value: 'include'},
                                                    arguments: [
                                                      {
                                                        kind: 'Argument',
                                                        name: {kind: 'Name', value: 'if'},
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {kind: 'Name', value: 'withRights'},
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'celCountry'},
                                                directives: [
                                                  {
                                                    kind: 'Directive',
                                                    name: {kind: 'Name', value: 'include'},
                                                    arguments: [
                                                      {
                                                        kind: 'Argument',
                                                        name: {kind: 'Name', value: 'if'},
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {kind: 'Name', value: 'withRights'},
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'celNumber'},
                                                directives: [
                                                  {
                                                    kind: 'Directive',
                                                    name: {kind: 'Name', value: 'include'},
                                                    arguments: [
                                                      {
                                                        kind: 'Argument',
                                                        name: {kind: 'Name', value: 'if'},
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {kind: 'Name', value: 'withRights'},
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'email'},
                                                directives: [
                                                  {
                                                    kind: 'Directive',
                                                    name: {kind: 'Name', value: 'include'},
                                                    arguments: [
                                                      {
                                                        kind: 'Argument',
                                                        name: {kind: 'Name', value: 'if'},
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {kind: 'Name', value: 'withRights'},
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'sex'},
                                                directives: [
                                                  {
                                                    kind: 'Directive',
                                                    name: {kind: 'Name', value: 'include'},
                                                    arguments: [
                                                      {
                                                        kind: 'Argument',
                                                        name: {kind: 'Name', value: 'if'},
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {kind: 'Name', value: 'withRights'},
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'idNumber'},
                                                directives: [
                                                  {
                                                    kind: 'Directive',
                                                    name: {kind: 'Name', value: 'include'},
                                                    arguments: [
                                                      {
                                                        kind: 'Argument',
                                                        name: {kind: 'Name', value: 'if'},
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {kind: 'Name', value: 'withRights'},
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'idType'},
                                                directives: [
                                                  {
                                                    kind: 'Directive',
                                                    name: {kind: 'Name', value: 'include'},
                                                    arguments: [
                                                      {
                                                        kind: 'Argument',
                                                        name: {kind: 'Name', value: 'if'},
                                                        value: {
                                                          kind: 'Variable',
                                                          name: {kind: 'Name', value: 'withRights'},
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUnitMembersQuery, SingleUnitMembersQueryVariables>;
export const SingleUnitNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUnitNotes'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Unit'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'unitNotes'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'note'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUnitNotesQuery, SingleUnitNotesQueryVariables>;
export const SingleUnitPetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUnitPets'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Unit'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'pets'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'birthday'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'sex'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'vaccinesDate'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUnitPetsQuery, SingleUnitPetsQueryVariables>;
export const SingleUnitVehiclesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUnitVehicles'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Unit'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'complexId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'vehicles'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'color'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'fuel'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'model'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'plate'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'publicService'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'rtm'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'soat'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUnitVehiclesQuery, SingleUnitVehiclesQueryVariables>;
export const SingleUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUser'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'birthday'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'celCountry'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'celNumber'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'emailConfirmedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPassword'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'idNumber'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'idType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'sex'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUserQuery, SingleUserQueryVariables>;
export const UnitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'units'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UnitSort'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UnitFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'units'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'building'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'complex'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'roles'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'first'},
                                  value: {kind: 'IntValue', value: '50'},
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'pageInfo'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'edges'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'node'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'user'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {kind: 'Name', value: 'BasicProfile'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnitsQuery, UnitsQueryVariables>;
export const UpdateFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateFeedback'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'FeedbackStatus'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'FeedbackType'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateFeedback'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'description'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'description'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'updatedBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>;
export const UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'users'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserSort'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'users'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'celCountry'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'celNumber'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'idNumber'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'hasPassword'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const VehiclesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'vehicles'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'VehicleFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'vehicles'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'brand'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'color'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'fuel'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'model'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'plate'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'publicService'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'rtm'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'soat'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}}],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'unit'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'complex'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VehiclesQuery, VehiclesQueryVariables>;
export const ViewerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'viewer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'unitRoles'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'first'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'pageInfo'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'edges'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'node'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'unit'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'complex'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'features'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'buildings'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                                    {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'complexRoles'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'first'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'pageInfo'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'edges'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'node'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'complex'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'features'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'buildings'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewerQuery, ViewerQueryVariables>;
export const ViewerFullProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'viewerFullProfile'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                {kind: 'Field', name: {kind: 'Name', value: 'email'}},
                {kind: 'Field', name: {kind: 'Name', value: 'emailConfirmedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'hasPassword'}},
                {kind: 'Field', name: {kind: 'Name', value: 'celNumber'}},
                {kind: 'Field', name: {kind: 'Name', value: 'celCountry'}},
                {kind: 'Field', name: {kind: 'Name', value: 'birthday'}},
                {kind: 'Field', name: {kind: 'Name', value: 'idNumber'}},
                {kind: 'Field', name: {kind: 'Name', value: 'idType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                {kind: 'Field', name: {kind: 'Name', value: 'sex'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewerFullProfileQuery, ViewerFullProfileQueryVariables>;
export const ViewerProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'viewerProfile'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
          {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'avatar'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'thumb'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewerProfileQuery, ViewerProfileQueryVariables>;
