import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import UserAvatar from '@/components/UserAvatar';
import {rolesComplexString, rolesString} from '@/constants/roles';
import {fontSize} from '@/utils/theme';
import client from '@/graphql';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {toNowTooltip} from '@/utils/dates';
import {enumToKV} from '@/utils/enum';
import {hasRoles, selectedComplex} from '@/services/store';
import {DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {useLazyQuery, useReactiveVar} from '@apollo/client';
import {
  ComplexRoles,
  RemoveComplexRoleDocument,
  SingleComplexRolesDocument,
  SingleComplexRolesQueryVariables,
  Unit,
} from '@gql/graphql';
import {IconFilter} from '@tabler/icons-react';
import {App, Button, Empty, List, Popconfirm, Select, Space, Typography} from 'antd';
import {useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const title = 'Roles del conjunto';

const RolesList = () => {
  useDocumentTitle(title);
  const {message, notification} = App.useApp();
  const complexes = useReactiveVar(selectedComplex);
  const complex = complexes[0];
  if (!hasRoles(['ADMIN', 'COUNCIL']) || !complex) return <Error403 />;

  const navigate = useNavigate();
  const [roleFilter, setRoleFilter] = useState<string | undefined>();

  const filter = roleFilter ? {role: [roleFilter as ComplexRoles]} : undefined;
  const queryVariables = {id: complex.id, first: 15, filter: filter} as SingleComplexRolesQueryVariables;
  const [getComplex, {loading, error, data, refetch, fetchMore}] = useLazyQuery(SingleComplexRolesDocument);

  useMemo(() => {
    if (complexes.length == 1)
      getComplex({variables: queryVariables, fetchPolicy: 'cache-and-network', errorPolicy: 'all'});
  }, [complexes, roleFilter]);

  if (complexes.length > 1) return <SelectComplex />;

  if (loading && !data) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const item = data.node as Unit;
  const rolesNodes = item.roles?.edges?.map(edge => edge?.node);
  const pageInfo = item.roles?.pageInfo;
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Ver anteriores...
        </Button>
      </div>
    ) : null;

  const removeRole = async (roleId: string) => {
    await client
      .mutate({
        mutation: RemoveComplexRoleDocument,
        variables: {id: roleId},
        refetchQueries: [{query: SingleComplexRolesDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.removeComplexRole?.id) {
          message.success('Se ha removido el miembro con éxito.');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al remover',
          description: 'No se pudo remover el miembro. Intenta más tarde.',
        });
      });
  };

  const removeRoleButton = (id?: string) => {
    if (!id) return undefined;

    return (
      <Popconfirm
        key="delConfirm"
        placement="topRight"
        title={'¿Quitar rol? '}
        description={'El usuario ya no tendrá los permisos de su rol'}
        onConfirm={() => removeRole(id)}
        okText={'Eliminar'}
        okType="danger"
        cancelText="Cancelar"
      >
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  const filterButton = hasRole ? (
    <Select
      key="filterButton"
      allowClear
      onChange={setRoleFilter}
      placeholder={
        <>
          <IconFilter size={fontSize} /> Rol
        </>
      }
      options={enumToKV(rolesComplexString).map(({key, value}) => {
        return {value: key, label: value};
      })}
    />
  ) : undefined;

  const addButton = hasRole ? (
    <Button key="editButton" type="primary" onClick={() => navigate('/complex/role/new')}>
      <PlusCircleOutlined /> Añadir
    </Button>
  ) : undefined;

  return (
    <>
      <PageContent
        header={{
          title: title,
          extra: [filterButton, addButton],
        }}
      >
        <List
          itemLayout="horizontal"
          loadMore={loadMore}
          loading={loading}
          locale={{emptyText: <Empty description="Sin usuarios" />}}
          dataSource={rolesNodes}
          renderItem={role => {
            return (
              <List.Item key={role?.id}>
                <List.Item.Meta
                  className="comment-meta"
                  title={
                    <Space>
                      <Typography.Text style={{fontSize: '13px'}} strong>
                        {`${role?.user?.firstName} ${role?.user?.lastName}`}
                      </Typography.Text>
                      <Typography.Text type="secondary" style={{fontSize: '11px'}}>
                        {toNowTooltip(role?.insertedAt)}
                      </Typography.Text>
                    </Space>
                  }
                  avatar={<UserAvatar viewer={role?.user} />}
                  description={
                    <Typography.Text>
                      {role?.role ? `${rolesString[role.role]} — ` : null}
                      Agregado por:{' '}
                      {role?.createdBy ? `${role?.createdBy?.firstName} ${role?.createdBy?.lastName}` : '—'}
                    </Typography.Text>
                  }
                />
                <Space>{removeRoleButton(role?.id)}</Space>
              </List.Item>
            );
          }}
        />
      </PageContent>
    </>
  );
};

export default RolesList;
