import {Card, Col, Row, Skeleton} from 'antd';
import {Fragment} from 'react';

const HomeSkeleton = () => {
  return (
    <Fragment>
      <Row justify="center" gutter={16}>
        <Col flex="auto" xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
          <Card bordered={false} style={{marginBottom: '24px'}}>
            <div style={{textAlign: 'center'}}>
              <Skeleton active />
            </div>
          </Card>
          <Card bordered={false} style={{marginBottom: '24px'}}>
            <div style={{textAlign: 'center'}}>
              <Skeleton active />
            </div>
          </Card>
        </Col>
        <Col flex="auto" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <Card bordered={false} style={{marginBottom: '24px'}}>
            <div style={{textAlign: 'center'}}>
              <Skeleton active />
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default HomeSkeleton;
