import ErrorRender from '@/components/loaders/ErrorRender';
import Logo from '@/components/svg/Logo';
import {unitTypeShort} from '@/constants/unit';
import client from '@/graphql';
import {useUserAgent} from '@/hooks/useUserAgent';
import {MenuItem, MenuItems} from '@/Router';
import {
  hasRole,
  hasSuperRole,
  hydrateStore,
  refetchViewer,
  relatedComplexes,
  relatedUnits,
  selectedComplex,
} from '@/services/store';
import {timeZone} from '@/utils/dates';
import {userLocale} from '@/utils/misc';
import {fontSize} from '@/utils/theme';
import Icon from '@ant-design/icons';
import {useQuery, useReactiveVar} from '@apollo/client';
import ComplexSelector from '@comp/ComplexSelector';
import LoadingFullscreen from '@comp/loaders/LoadingFullscreen';
import {UpdateSessionDocument, UpdateSessionMutationVariables, User, ViewerDocument} from '@gql/graphql';
import * as Sentry from '@sentry/react';
import {IconHome} from '@tabler/icons-react';
import {Layout, Menu, Typography} from 'antd';
import {ItemType, MenuDividerType, MenuItemGroupType, MenuItemType, SubMenuType} from 'antd/es/menu/hooks/useItems';
import {useEffect, useMemo, useState} from 'react';
import {Link, Navigate, Outlet, useLocation} from 'react-router-dom';
import './Header.css';
import HeaderUser from './HeaderUser';
import NoComplex from './NoComplex';
import Notifications from './Notifications';
import ThemeSwitch from './ThemeSwitch';
const {Header, Content, Footer, Sider} = Layout;
const {Text} = Typography;

const App = () => {
  const {pathname} = useLocation();
  const ua = useUserAgent();
  const [dataIsReady, setDataIsReady] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [breakpoint, setBreakpoint] = useState(false);
  useReactiveVar(relatedComplexes);
  useReactiveVar(selectedComplex);
  useReactiveVar(relatedUnits);

  const units = relatedUnits();
  const complexes = relatedComplexes();

  const {loading, error, data, refetch} = useQuery(ViewerDocument, {
    variables: {first: 200},
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const menuFiltered = useMemo(() => {
    const isAdmin = hasRole('ADMIN');
    const superRole = hasSuperRole(['ADMIN']);
    const adminOnlyRoutes = ['unit', 'building', 'complex', 'insights'];
    const superOnlyRoutes = ['unit', 'user', 'insights', 'feedback', 'complex', 'complex/list', 'location'];

    const filterMenu = (menu: MenuItem) => {
      if (!menu || !menu.key) return false;

      const hasAdmin = adminOnlyRoutes.includes(menu.key as string);
      const hasSuper = superOnlyRoutes.includes(menu.key as string);

      if (hasAdmin || hasSuper) {
        return (hasAdmin && isAdmin) || (hasSuper && superRole);
      }
      return true;
    };

    let menuFiltered = MenuItems;
    menuFiltered = menuFiltered.filter(menu => filterMenu(menu));
    menuFiltered = menuFiltered.map(menu => {
      if (menu.children) {
        menu.children = menu.children.filter(child => filterMenu(child));
      }
      return menu;
    });

    if (units.length > 0) {
      const menuUnits = units.map(unit => {
        return {
          key: 'unit/' + unit.id,
          icon: <IconHome size={fontSize} />,
          label: <Link to={'unit/' + unit.id}>{unitTypeShort[unit.type] + ' ' + unit.number}</Link>,
        } as MenuItem;
      });

      menuFiltered = [...menuFiltered, ...menuUnits];
    }

    return menuFiltered;
  }, [complexes, units]);

  useEffect(() => {
    refetchViewer(refetch);
  }, [refetch]);

  useEffect(() => {
    if (dataIsReady && data?.viewer) {
      client.mutate({
        mutation: UpdateSessionDocument,
        variables: {
          appId: 3,
          appVersion: __APP_VERSION__,
          device: ua?.device ? ua.device.vendor : undefined,
          system: ua?.os ? ua.os.name : undefined,
          systemVersion: ua?.os ? ua.os.version : undefined,
          langCode: userLocale,
          timezone: timeZone(),
        } as UpdateSessionMutationVariables,
      });
    }
  }, [dataIsReady, data?.viewer]);

  useEffect(() => {
    if (!data && !error) {
      return;
    }

    if (data?.viewer) {
      hydrateStore(data?.viewer as User);
    }

    setDataIsReady(true);
  }, [data, error]);

  const hasNetworkError = Boolean(error?.networkError);
  const loggedIn = dataIsReady && data?.viewer;
  const hasComplex = dataIsReady && complexes.length > 0;

  if (hasNetworkError) return <ErrorRender error={error!} refetch={refetch} />;
  if (loading || !dataIsReady) return <LoadingFullscreen />;
  if (!loggedIn) return <Navigate replace to="/login" state={{from: pathname}} />;
  if (!hasComplex && !hasSuperRole(['ADMIN'])) return <NoComplex />;

  const viewer = data?.viewer as User;
  Sentry.setUser({id: viewer?.id, ip_address: '{{auto}}'});
  const selectedKeys = pathname.charAt(0) === '/' && pathname.length > 1 ? pathname.substring(1) : pathname;
  const openKeys = selectedKeys.split(/(?=\/)/g);

  return (
    <Layout style={{minHeight: '100vh'}} hasSider>
      <Sider
        breakpoint="md"
        collapsedWidth={breakpoint ? '0' : undefined}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        onBreakpoint={value => setBreakpoint(value)}
      >
        <div className="logo-container">
          <Link to="/">
            <Icon component={Logo} className="logo white logo-menu" />
          </Link>
        </div>
        <Menu
          theme="dark"
          selectedKeys={[selectedKeys]}
          defaultOpenKeys={openKeys}
          mode="inline"
          items={menuFiltered as ItemType[]}
        />
      </Sider>
      <Layout>
        <Header className="header" style={{color: 'white'}}>
          <ComplexSelector isHeader={true} />
          <HeaderUser viewer={viewer} />
          <Notifications viewer={viewer} />
          <ThemeSwitch />
        </Header>
        <Content className="content">
          <div
            style={{
              minHeight: 360,
              paddingTop: '15px',
              paddingBottom: '24px',
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer className="footer">
          <Text type="secondary">Kipo © {new Date().getFullYear()}</Text>
          <br />
          <Text type="secondary">v{__APP_VERSION__}</Text>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
