import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {superRoles} from '@/constants/roles';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasSuperRole, selectedComplex, selectedComplexFilter} from '@/services/store';
import {toSnakeCase} from '@/utils/case';
import {fromNowMaxTooltip} from '@/utils/dates';
import {enumToAllIndex} from '@/utils/enum';
import {titleLoading} from '@/utils/misc';
import {getFullName} from '@/utils/names';
import {FilterOutlined, LeftOutlined, RightOutlined, SearchOutlined} from '@ant-design/icons';
import {makeVar, useQuery, useReactiveVar} from '@apollo/client';
import UserAvatar from '@comp/UserAvatar';
import {User, UserEdge, UserRoles, UsersDocument, UsersQueryVariables, UserStatuses} from '@gql/graphql';
import {Button, Col, Empty, Input, InputRef, Row, Skeleton, Space, Table, Typography} from 'antd';
import {ColumnsType, TableProps} from 'antd/es/table';
import {ColumnType, FilterValue, SorterResult, SortOrder} from 'antd/es/table/interface';
import {useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';

const {Text} = Typography;

type DataIndex = keyof User;

interface tableFilterType {
  name: FilterValue | null | undefined;
  email: FilterValue | null | undefined;
  celNumber: FilterValue | null | undefined;
  idNumber: FilterValue | null | undefined;
  status: UserStatuses[] | undefined;
  role: UserRoles[] | undefined;
}

const emptyFilter: tableFilterType = {
  name: undefined,
  email: undefined,
  celNumber: undefined,
  idNumber: undefined,
  status: undefined,
  role: undefined,
};

interface tableOrderType {
  field?: string;
  direction?: string;
}

const emptyOrder: tableOrderType = {
  field: undefined,
  direction: undefined,
};

const graphFilterStore = makeVar<tableFilterType>(emptyFilter);
const graphOrderStore = makeVar<tableOrderType>(emptyOrder);
const cursorStackStore = makeVar<string[]>([]);
const afterStore = makeVar<string | null>(null);
const title = 'Usuarios';
const List = () => {
  useDocumentTitle(title);
  const searchInput = useRef<InputRef>(null);
  const [firstLoad, setFirstLoad] = useState<Array<UserEdge>>();

  useReactiveVar(selectedComplex);
  useReactiveVar(graphFilterStore);
  useReactiveVar(graphOrderStore);
  useReactiveVar(cursorStackStore);
  useReactiveVar(afterStore);

  const graphFilters = graphFilterStore();
  const graphOrder = graphOrderStore();
  const cursorStack = cursorStackStore();
  const after = afterStore();

  const clearAllFilters = () => {
    graphFilterStore(emptyFilter);
    graphOrderStore(emptyOrder);
    resetPagination();
  };

  const hasFilters = graphFilters && !Object.values(graphFilters).every(el => el === undefined);
  const hasOrder = graphOrder && !Object.values(graphOrder).every(el => el === undefined);

  const hasRole = hasSuperRole(['ADMIN']);

  const clearAllButton =
    hasFilters || hasOrder ? (
      <Button onClick={clearAllFilters} key="clear">
        <FilterOutlined /> Limpiar filtros
      </Button>
    ) : undefined;

  const headButtons = [clearAllButton];

  const highlight = (searchTerm: any, toHighlight: any) => {
    if (!searchTerm || searchTerm.length == 0) return searchTerm;
    return (
      <Highlighter
        autoEscape
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchTerm]}
        textToHighlight={toHighlight ? toHighlight.toString() : ''}
      />
    );
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<User> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
      return (
        <div style={{padding: 8}}>
          <Input
            ref={searchInput}
            placeholder="Buscar"
            value={selectedKeys as string[]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm({closeDropdown: true})}
            style={{width: 188, marginBottom: 8, display: 'block'}}
          />
          <Button
            type="primary"
            onClick={() => confirm({closeDropdown: true})}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90, marginRight: 8}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) handleResetSearch(clearFilters);
            }}
            size="small"
            style={{width: 90}}
          >
            Limpiar
          </Button>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record, index) => {
      if (dataIndex == 'firstName' || dataIndex == 'lastName') {
        const fullName = getFullName(record);
        const term = graphFilters.name ? graphFilters.name : undefined;
        const name = term ? highlight(term, fullName) : fullName;
        return (
          <Space>
            <UserAvatar viewer={record} size="default" />
            <Link to={'/user/' + record.id + '/edit'}>{name}</Link>
          </Space>
        );
      }

      if (dataIndex == 'email') {
        const term = graphFilters.email ? graphFilters.email : undefined;
        const email = term ? highlight(term, text) : text;
        return email;
      }

      if (dataIndex == 'celNumber') {
        const term = graphFilters.celNumber ? graphFilters.celNumber : undefined;
        const celNumber = term ? highlight(term, text) : text;
        return celNumber;
      }

      if (dataIndex == 'idNumber') {
        const term = graphFilters.idNumber ? graphFilters.idNumber : undefined;
        const idNumber = term ? highlight(term, text) : text;
        return idNumber;
      }
    },
  });

  const handleResetSearch = (clearFilters: Function) => {
    clearFilters({confirm: true, closeDropdown: true});
  };

  const resetPagination = () => {
    cursorStackStore([]);
    afterStore(undefined);
  };

  const calcSortOrder = (field: string) => {
    let order: SortOrder = null;
    if (graphOrder && graphOrder.field === toSnakeCase(field).toUpperCase()) {
      order = graphOrder.direction == 'ASC' ? 'ascend' : 'descend';
    }
    return order;
  };

  const handleTableChange: TableProps<User>['onChange'] = (pagination, filters, sorter) => {
    const newSorter = sorter as SorterResult<User>;
    const newFilter = {...emptyFilter};
    for (const key in filters) {
      const value = filters[key];
      if (value !== null && value.length > 0) {
        switch (key) {
          case 'name':
            newFilter.name = Array.isArray(value) ? (value[0] as any) : value;
            break;

          case 'email':
            newFilter.email = Array.isArray(value) ? (value[0] as any) : value;
            break;

          case 'celNumber':
            newFilter.celNumber = Array.isArray(value) ? (value[0] as any) : value;
            break;

          case 'idNumber':
            newFilter.idNumber = Array.isArray(value) ? (value[0] as any) : value;
            break;

          default:
            newFilter[key as keyof typeof newFilter] = value as any;
            break;
        }
      }
    }

    if (graphFilters != newFilter) {
      resetPagination();
    }
    graphFilterStore(newFilter);

    if (newSorter.order) {
      const orderField = toSnakeCase(newSorter.columnKey as string).toUpperCase();
      let orderDirection = newSorter.order as string;
      orderDirection = orderDirection == 'descend' ? 'DESC' : 'ASC';
      graphOrderStore({field: orderField, direction: orderDirection});
    } else {
      graphOrderStore(undefined);
    }
  };

  let columns: ColumnsType<User> = [
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      key: 'name',
      filteredValue: graphFilters.name || null,
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      key: 'email',
      filteredValue: graphFilters.email || null,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Celular',
      dataIndex: 'celNumber',
      key: 'celNumber',
      filteredValue: graphFilters.celNumber || null,
      ...getColumnSearchProps('celNumber'),
    },
    {
      title: 'Documento',
      dataIndex: 'idNumber',
      key: 'idNumber',
      filteredValue: graphFilters.idNumber || null,
      ...getColumnSearchProps('idNumber'),
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
      filteredValue: graphFilters?.role || null,
      filters: enumToAllIndex(superRoles).map(value => {
        return {
          text: superRoles[value as UserRoles],
          value: value,
        };
      }),
      render: type => superRoles[type as UserRoles],
    },
    {
      title: 'Creado',
      dataIndex: 'insertedAt',
      key: 'insertedAt',
      sorter: true,
      filteredValue: null,
      sortOrder: calcSortOrder('insertedAt'),
      render: value => fromNowMaxTooltip(value),
    },
  ];

  columns = columns.filter(item => item.title);

  const pageSize = 10;
  const complexes = {complexes: selectedComplexFilter()};
  const queryVariables = {
    first: pageSize,
    after: after,
    filter: graphFilters ? {...graphFilters, ...complexes} : complexes,
    order: graphOrder?.field ? {...graphOrder} : undefined,
  } as UsersQueryVariables;

  const {loading, error, data, refetch} = useQuery(UsersDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  if (!hasRole) return <Error403 />;
  if (loading && !firstLoad && !data) return <Skeleton paragraph={{rows: 4}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (data?.users?.edges && data.users.edges != firstLoad) setFirstLoad(data.users.edges as Array<UserEdge>);
  if (!data?.users?.edges && !firstLoad) return <NotFound />;
  if (!firstLoad) return <NotFound />;

  const edges = firstLoad;
  const users = edges.map((edge: UserEdge) => edge?.node as User);
  const totalCount = data?.users?.total ? data?.users?.total : 0;
  const hasNextPage = data?.users?.pageInfo.hasNextPage;
  const endCursor = data?.users?.pageInfo.endCursor;

  const prevPage = () => {
    let existingStack = cursorStack;
    existingStack.pop();
    cursorStackStore(existingStack);
    let lastEndCursor = existingStack.length > 0 ? existingStack[existingStack.length - 1] : undefined;
    afterStore(lastEndCursor);
  };

  const nextPage = () => {
    let existingStack = cursorStack;
    if (endCursor) existingStack.push(endCursor);
    cursorStackStore(existingStack);
    afterStore(endCursor);
  };

  const paginationButtons = () => (
    <Row justify="end">
      <Col>
        <Button onClick={prevPage} size="small" key="prev" type="link" disabled={cursorStack.length == 0}>
          <LeftOutlined />
          Anterior
        </Button>{' '}
        <Text type="secondary">
          {cursorStack.length + 1}/{Math.ceil(totalCount / pageSize)}
        </Text>{' '}
        <Button onClick={nextPage} size="small" key="next" type="link" disabled={!hasNextPage}>
          Siguiente
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <PageContent fullWidth header={{title: titleLoading(title, loading), extra: [headButtons]}}>
        <Table<User>
          footer={() => paginationButtons()}
          pagination={false}
          columns={columns}
          dataSource={users}
          onChange={handleTableChange}
          rowKey="id"
          size="middle"
          scroll={{x: true}}
          locale={{
            emptyText: <Empty description="Sin usuarios"></Empty>,
          }}
        />
      </PageContent>
    </>
  );
};

export default List;
