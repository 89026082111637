import client from '@/graphql';
import {File as gqlFile, UploadFileDocument} from '@gql/graphql';
import {PlusOutlined} from '@ant-design/icons';
import {App, Modal, Upload as AntdUpload} from 'antd';
import {UploadChangeParam, UploadFile} from 'antd/es/upload';
import {Fragment, useEffect, useState} from 'react';

interface UploadFilesProps {
  value?: gqlFile[];
  onChange?: (value: gqlFile[] | undefined) => void;
  maxUploads?: number;
}

const UploadFiles: React.FC<UploadFilesProps> = ({value, onChange, maxUploads = 1}) => {
  const [fileData, setFileData] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const {message} = App.useApp();

  useEffect(() => {
    if (value && value?.length > 0) {
      let initList = value.map(file => {
        return {
          uid: file.id,
          status: 'done',
          url: file.urls?.original,
          name: file.filename,
          response: {
            data: {
              uploadFile: file,
            },
          },
        } as UploadFile;
      });

      setFileList(initList);
    }
  }, []);

  const handleDocumentPreview = (file: UploadFile) => {
    let url = file.response && file.response.data ? file.response.data.uploadFile.urls.original : file.thumbUrl;

    if (file.type && file.type.startsWith('image')) {
      setShowPreview(true);
      setFileData(url || file.thumbUrl);
    } else {
      window.open(url || file.thumbUrl, '_blank');
    }
  };

  const handleDocumentChange = (info: UploadChangeParam) => {
    setFileList(info.fileList);
    let responses = info.fileList.filter(list => list.status == 'done').map(({response}) => response.data.uploadFile);
    onChange?.(responses);
  };

  const beforeFileUpload = (file: File) => {
    const isLt50M = file.size / 1024 / 1024 < 50;
    if (!isLt50M) {
      message.error('El adjunto debe tener un tamaño menor a 50MB');
    }
    return isLt50M;
  };

  const handlePreviewCancel = () => setShowPreview(false);

  return (
    <Fragment>
      <AntdUpload
        fileList={fileList}
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        onPreview={handleDocumentPreview}
        beforeUpload={beforeFileUpload}
        accept=".xlsx,.xls,.jpg,.jpeg,.png,.doc,.docx,.ppt,.pptx,.txt,.pdf,.3gp,.flv,.m4v,.mpg,.mpeg,.mp4,.mov,.avi,.zip,.rar,.epub"
        onChange={handleDocumentChange}
        customRequest={({file, onError, onProgress, onSuccess}) => {
          client
            .mutate({
              mutation: UploadFileDocument,
              variables: {file: file, type: 'DOCUMENT'},
            })
            .then(result => {
              if (onSuccess) onSuccess(result);
            })
            .catch(onError);

          return {
            abort() {
              console.warn('Upload was aborted.');
            },
          };
        }}
      >
        {fileList && fileList.length >= maxUploads ? null : (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Subir</div>
          </div>
        )}
      </AntdUpload>
      <Modal open={showPreview} footer={null} onCancel={handlePreviewCancel}>
        <img style={{width: '100%'}} src={fileData} />
      </Modal>
    </Fragment>
  );
};

export default UploadFiles;
