import {blue, gray, green, red, yellow} from './colors';

export const complexType = {
  URBANIZATION: 'Urbanización',
  BUILDING: 'Edificio',
  MALL: 'Centro comercial',
  OFFICE_CENTER: 'Centro oficinas',
  MIXED: 'Mixto',
  OTHER: 'Otro',
};

export const complexStatus = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  NO_PAYMENT: 'En mora',
  ENDED: 'Terminado',
  SUSPENDED: 'Suspendido',
};

export const complexStatusColor = {
  ACTIVE: green,
  INACTIVE: blue,
  NO_PAYMENT: red,
  ENDED: gray,
  SUSPENDED: yellow,
};

export const complexFeatures = {
  PACKAGES: 'Paquetes',
  GUESTS: 'Invitados',
  PETITIONS: 'Solicitudes',
  PETITIONS_DOORMAN: 'Solicitudes portería',
  PANIC_ALERT: 'Botón pánico',
};

export const complexStratum = [
  '1 — Bajo-bajo',
  '2 — Bajo',
  '3 — Medio-bajo',
  '4 — Medio',
  '5 — Medio-alto',
  '6 — Alto',
];
