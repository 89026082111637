import {hasRoles, selectedComplex} from '@/services/store';
import {NotificationTwoTone, PlusCircleOutlined} from '@ant-design/icons';
import {useReactiveVar} from '@apollo/client';
import {HomeQuery, NewsEdge} from '@gql/graphql';
import {Card, Empty, List, Skeleton} from 'antd';
import {Fragment} from 'react';
import {Link} from 'react-router-dom';

const NewsWidget = ({data}: {data: HomeQuery}) => {
  useReactiveVar(selectedComplex);
  const canCreate = hasRoles(['ADMIN', 'COUNCIL']);

  return (
    <Card
      title={
        <Fragment>
          <NotificationTwoTone /> Noticias
        </Fragment>
      }
      bordered={false}
      style={{marginBottom: '24px'}}
      extra={
        canCreate ? (
          <Link to={'news/new'}>
            <PlusCircleOutlined /> Nueva
          </Link>
        ) : undefined
      }
    >
      <Content data={data} />
    </Card>
  );
};

const Content = ({data}: {data: HomeQuery}) => {
  if (!data?.news?.edges) return <Skeleton />;
  const newsList = data.news?.edges.filter((element): element is NewsEdge => element !== null);

  return (
    <List
      locale={{emptyText: <Empty description="Sin noticias" />}}
      bordered={newsList.length > 0}
      dataSource={newsList}
      renderItem={({node}) => (
        <List.Item>
          <Link to={'news/' + node?.id}>{node?.title}</Link>
        </List.Item>
      )}
    />
  );
};

export default NewsWidget;
