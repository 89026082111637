import Error403 from '@/components/loaders/Error403';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasRoles, refetchViewer, selectedComplex} from '@/services/store';
import {formItemLayout} from '@/utils/forms';
import {useMutation, useReactiveVar} from '@apollo/client';
import {CreateBuildingDocument} from '@gql/graphql';
import {Button, Form, Input, notification} from 'antd';
import {useNavigate} from 'react-router-dom';

const New = () => {
  useDocumentTitle('Nuevo edificio');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let complex = useReactiveVar(selectedComplex)[0];

  const [createBuilding, {loading: newLoading}] = useMutation(CreateBuildingDocument, {
    update: (cache, {data}) => {
      if (data?.createBuilding?.id) {
        notification.success({
          message: 'Creado con éxito',
          description: 'Se ha creado el edificio',
        });
        refetchViewer()();
        navigate('/complex/building');
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (selectedComplex().length !== 1) return <SelectComplex />;
  if (!hasRoles(['ADMIN'])) return <Error403 />;

  const onInputFinish = async (values: any) => {
    createBuilding({variables: {complexId: complex.id, ...values}});
  };

  return (
    <PageContent header={{title: `Nuevo edificio`, onBack: () => navigate('/complex/building')}}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          {...formItemLayout}
          label="Nombre"
          name="name"
          rules={[
            {required: true, message: 'Debes escribir el nombre'},
            {max: 50, message: 'El nombre es demasiado largo'},
          ]}
        >
          <Input placeholder="Torre 2, Bloque 17..." style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Crear
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default New;
