export const appBackground = "#ffffff";
export const topBarTextColor = "#ffffff";

export const primary = "#2670CA";
export const primaryDark = "#202C4C";
export const primaryLight = "#1F49B6";

export const textLighter = "#d0d0d0";
export const textLight = "#989ca8";
export const textDark = "#868686";
export const textDarker = "#5b6973";

export const borderLight = "#efeeee";

export const second = "#7d5ba6";
export const background = "#ffffff";
export const textPrimary = "#ffffff";
export const textSecondary = "#000000";
export const placeholder = "gray";

export const red = "#d63031";
export const yellow = "#fdcb6e";
export const orange = "#e17055";
export const green = "#00b894";
export const greenLight = "#55efc4";
export const gray = "#b2bec3";
export const blue = "#0282f9";
