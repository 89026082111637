import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import client from '@/graphql';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {LockOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {
  AvailableLoginMethodsDocument,
  RequestAuthCodeDocument,
  RequestAuthCodeMutationVariables,
  UpdateEmailDocument,
  ViewerFullProfileDocument,
} from '@gql/graphql';
import {Button, Form, Input, notification, Select, Typography} from 'antd';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
const {Text} = Typography;

const title = 'Actualizar perfil';

enum State {
  INPUT,
  CODE,
}

const UpdateEmail = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState(State.INPUT);
  const [timeNewCode, setTimeNewCode] = useState(0);
  const [phoneData, setPhoneData] = useState({});
  const [invalidCode, setInvalidCode] = useState(false);
  const [requestCode, requestCodeResult] = useMutation(RequestAuthCodeDocument);

  useEffect(() => {
    setTimeout(() => {
      if (timeNewCode > 0) setTimeNewCode(timeNewCode - 1);
    }, 1000);
  });

  const {loading, error, data, refetch} = useQuery(ViewerFullProfileDocument, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.viewer) return <NotFound />;

  const onInputFinish = async (values: any) => {
    setIsLoading(true);

    await client
      .query({
        query: AvailableLoginMethodsDocument,
        variables: values,
        fetchPolicy: 'network-only',
      })
      .then(({data: {availableLoginMethods}}) => {
        setPhoneData(values);
        if (!availableLoginMethods?.length) {
          if (timeNewCode == 0) requestCode({variables: values});
          setFormState(State.CODE);
        } else {
          notification.error({
            message: 'Ya está siendo usado',
            description: 'Ya hay una cuenta con ese correo, primero elimina esa cuenta para poder asignar el número.',
          });
        }
        return true;
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions!.code) {
              case 'INVALID_EMAIL':
                return true;
              default:
                return false;
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCodeFinish = async (values: any) => {
    setIsLoading(true);
    setInvalidCode(false);
    let variables = {
      ...phoneData,
      ...values,
    };

    await client
      .mutate({
        mutation: UpdateEmailDocument,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({data}) => {
        if (data?.updateEmail?.id) {
          notification.success({
            message: 'Correo actualizado',
            description: 'Ya puedes iniciar sesión con el nuevo correo, el viejo fue desligado de tu cuenta. ',
          });
          navigate('/');
        }
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (err.extensions!.code) {
              case 'INCORRECT_CODE':
                setInvalidCode(true);
              default:
                return true;
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (formState == State.INPUT)
    return (
      <PageContent header={{title: title, onBack: () => navigate(-1)}}>
        <Form form={form} onFinish={onInputFinish}>
          <Form.Item
            {...formItemLayout}
            name="email"
            label="Nuevo correo"
            rules={[
              {
                type: 'email',
                message: 'Ingresa un correo válido',
              },
              {
                required: true,
                message: 'Ingresa tu correo',
              },
            ]}
          >
            <Input type="email" autoFocus placeholder="Ingresa tu correo" style={{maxWidth: 250}} />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Siguiente
            </Button>
          </Form.Item>
        </Form>
      </PageContent>
    );

  if (formState == State.CODE) {
    if (requestCodeResult.loading && timeNewCode == 0) setTimeNewCode(120);
    return (
      <PageContent header={{title: title, onBack: () => navigate(-1)}}>
        <Form form={form} onFinish={onCodeFinish}>
          <Form.Item
            {...formItemLayout}
            label="Código"
            name="code"
            rules={[{required: true, message: 'Ingresa el código'}]}
            hasFeedback={invalidCode}
            extra={invalidCode ? <Text type="danger">Código no valido</Text> : undefined}
            validateStatus={invalidCode ? 'error' : undefined}
          >
            <Input
              autoFocus
              autoComplete="one-time-code"
              inputMode="numeric"
              prefix={<LockOutlined />}
              placeholder="Ingresa el código del SMS"
              style={{maxWidth: 250}}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Cambiar
            </Button>

            <Button
              disabled={timeNewCode != 0}
              type="link"
              onClick={() => {
                requestCode({variables: phoneData as RequestAuthCodeMutationVariables});
              }}
            >
              {`Reenviar código ${timeNewCode > 0 ? '(' + timeNewCode + 's)' : ''}`}
            </Button>

            <Button
              type="link"
              onClick={() => {
                setPhoneData({});
                setFormState(State.INPUT);
              }}
            >
              Atrás
            </Button>
          </Form.Item>
        </Form>
      </PageContent>
    );
  }

  return <></>;
};

export default UpdateEmail;
