import {Maybe} from '@gql/graphql';
import {useRef, useEffect} from 'react';

const suffix = ' | Kipo';

export function setDocumentTitle(title: Maybe<string> | undefined, noSuffix?: boolean) {
  if (!title) return undefined;
  const suffixText = !noSuffix ? suffix : '';
  document.title = `${title}${suffixText}`;
}

function useDocumentTitle(title: string, noSuffix?: boolean, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const suffixText = !noSuffix ? suffix : '';

  useEffect(() => {
    document.title = `${title}${suffixText}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
}

export default useDocumentTitle;
