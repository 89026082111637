import Editor from '@/components/Editor';
import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {findById, hasRoles, hasSuperRole} from '@/services/store';
import {diffFromNow, isBeforeToday} from '@/utils/dates';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {buildBuildingList} from '@/utils/misc';
import {useMutation, useQuery} from '@apollo/client';
import {Poll, SinglePollDocument, SinglePollOptionQueryVariables, UpdatePollDocument} from '@gql/graphql';
import {Button, DatePicker, Form, Input, notification, Select} from 'antd';
import dayjs from 'dayjs';
import {useNavigate, useParams} from 'react-router-dom';

const title = 'Editar votación';

const Edit = () => {
  let {pollId} = useParams();
  if (!pollId) return <NotFound />;

  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [updatePoll, {loading: mutLoad}] = useMutation(UpdatePollDocument, {
    onCompleted: () => {
      notification.success({
        message: 'Editada con éxito',
        description: 'Se ha editado la votación.',
      });
      navigate('/poll/' + pollId);
    },
  });

  const {loading, error, data, refetch} = useQuery(SinglePollDocument, {
    variables: {id: pollId, first: 0} as SinglePollOptionQueryVariables,
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const poll = data.node as Poll;
  const complex = findById(poll.complexId);
  if (!complex) return <Error403 />;

  const createdSinceDays = Math.abs(diffFromNow(poll.insertedAt, 'days'));
  const hasRole = complex ? hasRoles(['ADMIN', 'COUNCIL'], [complex]) : undefined;
  const canEdit = (hasRole && createdSinceDays < 1 && poll.status == 'ACTIVE') || hasSuperRole(['ADMIN']);

  if (!canEdit) return <Error403 />;

  const buildingList = buildBuildingList(complex.buildings, true);

  const onInputFinish = async (values: any) => {
    let args = structuredClone(values);
    if (args.buildingId == 'ALL') args.buildingId = null;
    updatePoll({variables: {id: poll.id, ...args}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/poll/' + pollId)}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          ...poll,
          buildingId: poll.buildingId ?? 'ALL',
          closeAt: poll.closeAt ? dayjs(poll.closeAt) : null,
        }}
      >
        <Form.Item
          name="question"
          extra="Recuerda abrir y cerrar los signos de pregunta: ¿?"
          rules={[
            {required: true, message: 'Escribe la pregunta'},
            {max: 200, message: 'La pregunta es muy larga'},
          ]}
        >
          <Input size="large" placeholder="Pregunta" />
        </Form.Item>

        <Form.Item
          name="description"
          rules={[
            {required: true, message: 'Escribe la votación'},
            {max: 2000, message: 'La votacións es muy larga'},
          ]}
        >
          <Editor maxLength={2000} placeholder="Escribe una descripción (opcional)" />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="closeAt"
          label="Fecha límite"
          extra="Si seleccionas una fecha, la encuesta será cerrada en ese momento."
        >
          <DatePicker
            showNow={false}
            format="DD/MM/YYYY h:mm a"
            showTime={{use12Hours: true, format: 'h:mm a'}}
            disabledDate={isBeforeToday}
          />
        </Form.Item>

        {buildingList && (
          <Form.Item
            {...formItemLayout}
            name="buildingId"
            label="Mostrar en"
            extra="Limita la visibilidad de la votación a las propiedades del edificio seleccionado. Úsalo si la votación es únicamente de interés para ellos."
          >
            <Select style={{maxWidth: 200}} options={buildingList} />
          </Form.Item>
        )}

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={mutLoad}>
            Editar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default Edit;
