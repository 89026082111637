import Error403 from '@/components/loaders/Error403';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import {unitTypeShort} from '@/constants/unit';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {buildBuildingList} from '@/utils/misc';
import {hasRoles, hasSuperRole, selectedComplex} from '@/services/store';
import {useMutation, useReactiveVar} from '@apollo/client';
import {CreateUnitDocument} from '@gql/graphql';
import {Button, Form, Input, InputNumber, notification, Radio, Select} from 'antd';
import {useNavigate} from 'react-router-dom';

const New = () => {
  useDocumentTitle('Nueva propiedad');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const complex = useReactiveVar(selectedComplex)[0];

  const [createUnit, {loading: newLoading}] = useMutation(CreateUnitDocument, {
    update: (cache, {data}) => {
      if (data?.createUnit?.id) {
        notification.success({
          message: 'Creada con éxito',
          description: 'Se ha creado la propiedad',
        });
        navigate('/unit/' + data.createUnit.id);
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (selectedComplex().length !== 1) return <SelectComplex />;
  if (!hasRoles(['ADMIN']) && !hasSuperRole(['ADMIN'])) return <Error403 />;

  const onInputFinish = async (values: any) => {
    createUnit({variables: {complexId: complex.id, ...values}});
  };

  const buildingList = buildBuildingList(complex.buildings, false);

  return (
    <PageContent header={{title: `Nueva propiedad`, onBack: () => navigate('/unit')}}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          {...formItemLayout}
          label="Número"
          name="number"
          rules={[
            {required: true, message: 'Debes escribir el número/nombre'},
            {max: 10, message: 'Demasiado largo'},
          ]}
        >
          <Input placeholder="302, 45A...." style={{maxWidth: 100}} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Tipo"
          name="type"
          rules={[{required: true, message: 'Debes seleccionar el tipo'}]}
        >
          <Radio.Group>
            {enumToKV(unitTypeShort).map(({key, value}) => (
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        {buildingList && (
          <Form.Item {...formItemLayout} name="buildingId" label="Edificio">
            <Select style={{maxWidth: 200}} options={buildingList} allowClear />
          </Form.Item>
        )}

        <Form.Item {...formItemLayout} label="Piso" name="floor" rules={[{max: 15, message: 'Demasiado largo'}]}>
          <Input placeholder="17, N2..." style={{maxWidth: 100}} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Teléfono"
          name="phone"
          extra="Teléfono fijo o móvil de contacto"
          rules={[{max: 30, message: 'Demasiado largo'}]}
        >
          <Input style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Coeficiente" name="coefficient">
          <InputNumber style={{width: 200}} min="0" step="0.000001" />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Crear
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default New;
