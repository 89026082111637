import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {feedbackStatus, feedbackTypeShort} from '@/constants/feedback';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasSuperRole} from '@/services/store';
import {enumToKV} from '@/utils/enum';
import {formItemLayout} from '@/utils/forms';
import {useMutation, useQuery} from '@apollo/client';
import {Feedback, SingleFeedbackDocument, UpdateFeedbackDocument} from '@gql/graphql';
import {Button, Form, Input, notification, Select} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';

const title = 'Editar feedback';

const Edit = () => {
  const {feedbackId} = useParams();
  if (!feedbackId) return <NotFound />;
  if (!hasSuperRole(['ADMIN'])) return <Error403 />;

  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [updateFeedback, {loading: editLoading}] = useMutation(UpdateFeedbackDocument, {
    onCompleted: () => {
      notification.success({
        message: 'Editado con éxito',
        description: 'Se ha editado el feedback.',
      });
      navigate('/feedback');
    },
  });

  const {loading, error, data, refetch} = useQuery(SingleFeedbackDocument, {
    variables: {id: feedbackId},
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const feedback = data.node as Feedback;
  if (!feedback) return <NotFound />;

  const onInputFinish = async (values: any) => {
    updateFeedback({variables: {id: feedbackId, ...values}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/feedback')}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          ...feedback,
        }}
      >
        <Form.Item {...formItemLayout} label="Mensaje" name="description" rules={[{required: true}]}>
          <Input.TextArea
            showCount
            maxLength={500}
            placeholder="Escribe el mensaje..."
            autoSize={{minRows: 3, maxRows: 5}}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Tipo" name="type">
          <Select placeholder="Tipo" style={{maxWidth: 200}}>
            {enumToKV(feedbackTypeShort).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Estado" name="status">
          <Select placeholder="Tipo" style={{maxWidth: 200}}>
            {enumToKV(feedbackStatus).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Editar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default Edit;
