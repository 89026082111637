import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useState } from "react";

import { useCharacterLimit } from "../shared/useCharacterLimit";

export function CharacterLimitPlugin({
  limit = 0,
}: {
  limit: number;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();

  const [remainingCharacters, setRemainingCharacters] = useState(0);

  const characterLimitProps = {
    remainingCharacters: setRemainingCharacters,
    strlen: (text: string) => text.length,
  };

  if (limit == 0) return <span></span>;

  useCharacterLimit(editor, limit, characterLimitProps);

  return (
    <span
      className={`characters-limit ${
        remainingCharacters < 0 ? "characters-limit-exceeded" : ""
      }`}
    >
      {remainingCharacters}
    </span>
  );
}
