import ErrorRender from '@/components/loaders/ErrorRender';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {petitionPriority, petitionStatus, petitionStatusColor, petitionType, priorityData} from '@/constants/petition';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {toSnakeCase} from '@/utils/case';
import {fromNowMaxTooltip} from '@/utils/dates';
import {enumToAllIndex} from '@/utils/enum';
import {titleLoading} from '@/utils/misc';
import {getFullOne} from '@/utils/names';
import {hasRoles, selectedComplex, selectedComplexFilter} from '@/services/store';
import {FilterOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined, SearchOutlined} from '@ant-design/icons';
import {makeVar, useQuery, useReactiveVar} from '@apollo/client';
import {
  Petition,
  PetitionEdge,
  PetitionFilter,
  PetitionListAdminDocument,
  PetitionListDocument,
  PetitionListQueryVariables,
  PetitionSort,
  PetitionStatusType,
  PetitionType,
  User,
} from '@gql/graphql';
import {Button, Col, Empty, Input, InputRef, Row, Skeleton, Space, Table, Tag, Typography} from 'antd';
import {ColumnsType, TableProps} from 'antd/es/table';
import {ColumnType, FilterValue, SorterResult, SortOrder} from 'antd/es/table/interface';
import {Fragment, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link, useNavigate} from 'react-router-dom';
import Insights from './Insights';
import RecentComments from './RecentComments';
import UserAvatar from '@comp/UserAvatar';
const {Text} = Typography;

type DataIndex = keyof Petition;

interface tableFilterType {
  status: PetitionStatusType[] | undefined;
  type: PetitionType[] | undefined;
  priority: number[] | undefined;
  title: FilterValue | null | undefined;
  creatorName: FilterValue | null | undefined;
}

interface tableOrderType {
  field?: string;
  direction?: string;
}

const emptyFilter: tableFilterType = {
  status: undefined,
  type: undefined,
  priority: undefined,
  title: undefined,
  creatorName: undefined,
};

const emptyOrder: tableOrderType = {
  field: undefined,
  direction: undefined,
};

const graphFilterStore = makeVar<tableFilterType>(emptyFilter);
const graphOrderStore = makeVar<tableOrderType>(emptyOrder);
const cursorStackStore = makeVar<string[]>([]);
const afterStore = makeVar<string | null>(null);
const title = 'Solicitudes';
const PetitionList = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const searchInput = useRef<InputRef>(null);
  const [firstLoad, setFirstLoad] = useState<Array<PetitionEdge>>();

  useReactiveVar(selectedComplex);
  useReactiveVar(graphFilterStore);
  useReactiveVar(graphOrderStore);
  useReactiveVar(cursorStackStore);
  useReactiveVar(afterStore);

  const graphFilters = graphFilterStore();
  const graphOrder = graphOrderStore();
  const cursorStack = cursorStackStore();
  const after = afterStore();

  const clearAllFilters = () => {
    graphFilterStore(emptyFilter);
    graphOrderStore(emptyOrder);
    resetPagination();
  };

  const createButton = (
    <Button onClick={() => navigate('/petition/new')} key="add" type="primary">
      <PlusCircleOutlined /> Nueva
    </Button>
  );

  const hasFilters = graphFilters && !Object.values(graphFilters).every(el => el === undefined);
  const hasOrder = graphOrder && !Object.values(graphOrder).every(el => el === undefined);

  const clearAllButton =
    hasFilters || hasOrder ? (
      <Button onClick={clearAllFilters} key="clear">
        <FilterOutlined /> Limpiar filtros
      </Button>
    ) : undefined;
  let headButtons = [clearAllButton, createButton];

  const highlight = (searchTerm: any, toHighlight: any) => {
    if (!searchTerm || searchTerm.length == 0) return searchTerm;
    return (
      <Highlighter
        autoEscape
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchTerm]}
        textToHighlight={toHighlight ? toHighlight.toString() : ''}
      />
    );
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Petition> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
      return (
        <div style={{padding: 8}}>
          <Input
            ref={searchInput}
            placeholder="Buscar"
            value={selectedKeys as string[]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm({closeDropdown: true})}
            style={{width: 188, marginBottom: 8, display: 'block'}}
          />
          <Button
            type="primary"
            onClick={() => confirm({closeDropdown: true})}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90, marginRight: 8}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) handleResetSearch(clearFilters);
            }}
            size="small"
            style={{width: 90}}
          >
            Limpiar
          </Button>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record, index) => {
      if (dataIndex == 'title') {
        let term = graphFilters?.title ? graphFilters?.title : undefined;
        let title = term ? highlight(term, text) : text;
        return <Link to={'/petition/' + record.id}>{title}</Link>;
      }

      if (dataIndex == 'createdBy') {
        const fullName = getFullOne(record.createdBy!);
        const term = graphFilters.creatorName ? graphFilters.creatorName : undefined;
        const name = term ? highlight(term, fullName) : fullName;
        return (
          <Space>
            <UserAvatar viewer={record.createdBy!} size="small" />
            {name}
          </Space>
        );
      }
    },
  });

  const handleResetSearch = (clearFilters: Function) => {
    clearFilters({confirm: true, closeDropdown: true});
  };

  const resetPagination = () => {
    cursorStackStore([]);
    afterStore(undefined);
  };

  const calcSortOrder = (field: string) => {
    let order: SortOrder = null;
    if (graphOrder && graphOrder.field === toSnakeCase(field).toUpperCase()) {
      order = graphOrder.direction == 'ASC' ? 'ascend' : 'descend';
    }
    return order;
  };

  const handleTableChange: TableProps<Petition>['onChange'] = (pagination, filters, sorter) => {
    const newSorter = sorter as SorterResult<Petition>;
    const newFilter = {...emptyFilter};

    for (const key in filters) {
      const value = filters[key];
      if (value !== null && value.length > 0) {
        switch (key) {
          case 'currentStatus':
            newFilter.status = value as PetitionStatusType[];
            break;

          case 'title':
            newFilter.title = Array.isArray(value) ? (value[0] as any) : value;
            break;

          case 'createdBy':
            newFilter.creatorName = value[0] as any;
            break;

          default:
            newFilter[key as keyof typeof newFilter] = value as any;
            break;
        }
      }
    }

    if (graphFilters != newFilter) {
      resetPagination();
    }
    graphFilterStore(newFilter);

    if (newSorter.order) {
      const orderField = toSnakeCase(newSorter.columnKey as string).toUpperCase();
      let orderDirection = newSorter.order as string;
      orderDirection = orderDirection == 'descend' ? 'DESC' : 'ASC';
      graphOrderStore({field: orderField, direction: orderDirection});
    } else {
      graphOrderStore(undefined);
    }
  };

  const hasRole = hasRoles(['ADMIN', 'COUNCIL'], selectedComplex());

  let columns: ColumnsType<Petition> = [
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      width: '25%',
      filteredValue: graphFilters?.title || null,
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Estado',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      filteredValue: graphFilters?.status || null,
      filters: enumToAllIndex(petitionStatus).map(value => {
        return {
          text: petitionStatus[value as PetitionStatusType],
          value: value,
        };
      }),
      render: value => (
        <Tag color={petitionStatusColor[value as PetitionStatusType]}>
          {petitionStatus[value as PetitionStatusType]}
        </Tag>
      ),
    },
    hasRole
      ? {
          title: 'Prioridad',
          dataIndex: 'priority',
          key: 'priority',
          sorter: true,
          sortOrder: calcSortOrder('priority'),
          filteredValue: graphFilters?.priority || null,
          filters: petitionPriority.map(item => {
            return {
              text: (
                <Fragment>
                  {item.icon} {item.string}
                </Fragment>
              ),
              value: item.value,
            };
          }),
          render: value => (
            <Fragment>
              {priorityData(value)?.icon} {priorityData(value)?.string}
            </Fragment>
          ),
        }
      : {},
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      filteredValue: graphFilters?.type || null,
      filters: enumToAllIndex(petitionType).map(value => {
        return {
          text: petitionType[value as PetitionType],
          value: value,
        };
      }),
      render: type => petitionType[type as PetitionType],
    },
    {
      title: 'Creada por',
      dataIndex: 'createdBy',
      key: 'createdBy',
      ellipsis: true,
      filteredValue: graphFilters?.creatorName || null,
      ...getColumnSearchProps('createdBy'),
    },
    {
      title: 'Fecha',
      dataIndex: 'insertedAt',
      key: 'insertedAt',
      sorter: true,
      filteredValue: null,
      sortOrder: calcSortOrder('insertedAt'),
      render: value => fromNowMaxTooltip(value),
    },
    {
      title: 'Última actividad',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      filteredValue: null,
      sortOrder: calcSortOrder('updatedAt'),
      render: value => fromNowMaxTooltip(value),
    },
  ];

  columns = columns.filter(item => item.title);

  const pageSize = 10;
  const complexes = {complexes: selectedComplexFilter()};
  const queryVariables = {
    first: pageSize,
    after: after,
    filter: graphFilters ? {...(graphFilters as PetitionFilter), ...complexes} : complexes,
    order: graphOrder?.field ? {...(graphOrder as PetitionSort)} : undefined,
  } as PetitionListQueryVariables;

  const queryDocument = hasRole ? PetitionListAdminDocument : PetitionListDocument;

  const {loading, error, data, refetch} = useQuery(queryDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading && !firstLoad && !data) return <Skeleton paragraph={{rows: 4}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (data?.petitions?.edges && data.petitions.edges != firstLoad)
    setFirstLoad(data.petitions.edges as Array<PetitionEdge>);
  if (!data?.petitions?.edges && !firstLoad) return <NotFound />;
  if (!firstLoad) return <NotFound />;

  const edges = firstLoad;
  const petitions = edges.map((edge: PetitionEdge) => edge?.node as Petition);
  const totalCount = data?.petitions?.total ? data?.petitions?.total : 0;
  const hasNextPage = data?.petitions?.pageInfo.hasNextPage;
  const endCursor = data?.petitions?.pageInfo.endCursor;

  const prevPage = () => {
    let existingStack = cursorStack;
    existingStack.pop();
    cursorStackStore(existingStack);
    let lastEndCursor = existingStack.length > 0 ? existingStack[existingStack.length - 1] : undefined;
    afterStore(lastEndCursor);
  };

  const nextPage = () => {
    let existingStack = cursorStack;
    if (endCursor) existingStack.push(endCursor);
    cursorStackStore(existingStack);
    afterStore(endCursor);
  };

  const paginationButtons = () => (
    <Row justify="end">
      <Col>
        <Button onClick={prevPage} size="small" key="prev" type="link" disabled={cursorStack.length == 0}>
          <LeftOutlined />
          Anterior
        </Button>{' '}
        <Text type="secondary">
          {cursorStack.length + 1}/{Math.max(1, Math.ceil(totalCount / pageSize))}
        </Text>{' '}
        <Button onClick={nextPage} size="small" key="next" type="link" disabled={!hasNextPage}>
          Siguiente
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <PageContent fullWidth header={{title: titleLoading(title, loading), extra: [headButtons]}}>
        <Table<Petition>
          footer={() => paginationButtons()}
          pagination={false}
          columns={columns}
          dataSource={petitions}
          onChange={handleTableChange}
          rowKey="id"
          size="middle"
          scroll={{x: true}}
          locale={{
            emptyText: <Empty description="Sin solicitudes"></Empty>,
          }}
        />
      </PageContent>

      {hasRole && (
        <Fragment>
          <Row justify="center" gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              <RecentComments />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              <Insights />
            </Col>
          </Row>
        </Fragment>
      )}
    </>
  );
};

export default PetitionList;
