import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import { joinCodeStatus } from '@/constants/joinCode';
import { rolesString, unitRoleRights } from '@/constants/roles';
import client from '@/graphql';
import { findById, getUnitRole, hasRoles } from '@/services/store';
import { relativeDateTooltip } from '@/utils/dates';
import { fontSize } from '@/utils/theme';
import { useQuery } from '@apollo/client';
import {
  CancelJoinCodeDocument,
  JoinCode,
  SingleUnitJoinCodeDocument,
  SingleUnitJoinCodeQueryVariables,
  Unit
} from '@gql/graphql';
import { IconTrash } from '@tabler/icons-react';
import { App, Button, Empty, Popconfirm, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';

const JoinCodeList = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;

  const {message, notification} = App.useApp();
  const queryVariables = {id: unitId, first: 10} as SingleUnitJoinCodeQueryVariables;
  const {loading, error, data, refetch, fetchMore} = useQuery(SingleUnitJoinCodeDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 10}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const unit = data.node as Unit;
  if (!unit) return <NotFound />;

  const joinCodes = unit.joinCodes?.edges?.map(edge => edge?.node as JoinCode);
  const pageInfo = unit.joinCodes?.pageInfo;

  const complex = findById(unit.complexId);
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const viewerAdmin = hasRole ? 'ADMIN' : undefined;
  const viewerRole = getUnitRole(unit) || viewerAdmin;
  if (!viewerRole) return <Error403 />;

  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Ver anteriores...
        </Button>
      </div>
    ) : null;

  const cancelJoinCode = async (id: string) => {
    await client
      .mutate({
        mutation: CancelJoinCodeDocument,
        variables: {id: id},
        refetchQueries: [{query: SingleUnitJoinCodeDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.cancelJoinCode?.id) {
          message.success('El código ya no es válido');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al cancelar',
          description: 'No se pudo cancelar el código. Intenta más tarde.',
        });
      });
  };

  const deleteButton = (id?: string) => {
    if (!id) return undefined;

    return (
      <Popconfirm
        key="delConfirm"
        placement="topRight"
        title="¿Cancelar el código?"
        description="Ya no será válido. No se podrá volver a activar."
        onConfirm={() => cancelJoinCode(id)}
        okText={'Cancelar'}
        okType="danger"
        cancelText="Atrás"
      >
        <Button danger>
          <IconTrash size={fontSize} />
        </Button>
      </Popconfirm>
    );
  };

  const columns: ColumnsType<JoinCode> = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      render: (code: string | null) =>
        code ? (
          <Typography.Text copyable>{code}</Typography.Text>
        ) : (
          <Tooltip title="No tienes permiso para ver éste código">
            <Typography.Text italic>[oculto]</Typography.Text>
          </Tooltip>
        ),
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
      render: (text: string) => rolesString[text],
    },
    {
      title: 'Usos',
      dataIndex: 'uses',
      key: 'uses',
      render: (uses: number, {maxUses}) => `${uses}/${maxUses}`,
    },
    {
      title: 'Expira',
      dataIndex: 'expire',
      key: 'expire',
      render: date => relativeDateTooltip(date),
    },
    {
      title: 'Creado',
      dataIndex: 'insertedAt',
      key: 'insertedAt',
      render: date => relativeDateTooltip(date),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => joinCodeStatus[text],
    },
    {
      title: 'Acciones',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        unitRoleRights[viewerRole][record.role!] && record.status === 'ACTIVE' ? deleteButton(record.id) : undefined,
    },
  ];

  return (
    <>
      <Table<JoinCode>
        columns={columns}
        size="small"
        pagination={false}
        rowKey={record => record.id}
        dataSource={joinCodes}
        locale={{emptyText: <Empty description="Sin códigos de invitación"></Empty>}}
      />
      {loadMore}
    </>
  );
};

export default JoinCodeList;
