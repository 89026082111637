import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {findById, hasRoles, hasSuperRole} from '@/services/store';
import {formItemLayout} from '@/utils/forms';
import {useMutation, useQuery} from '@apollo/client';
import {Building, SingleBuildingDocument, UpdateBuildingDocument} from '@gql/graphql';
import {Button, Form, Input, notification} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';

const title = 'Editar edificio';

const Edit = () => {
  let {buildingId} = useParams();
  if (!buildingId) return <NotFound />;

  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [updateBuilding, {loading: editLoading}] = useMutation(UpdateBuildingDocument, {
    onCompleted: () => {
      notification.success({
        message: 'Editado con éxito',
        description: 'Se ha editado el edificio.',
      });
      navigate('/complex/building');
    },
  });

  const {loading, error, data, refetch} = useQuery(SingleBuildingDocument, {
    variables: {id: buildingId},
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const building = data.node as Building;
  if (!building) return <NotFound />;

  if (!hasSuperRole(['ADMIN'])) {
    const complex = findById(building.complexId);
    if (!complex) return <Error403 />;
    if (!hasRoles(['ADMIN'], [complex])) return <Error403 />;
  }

  const onInputFinish = async (values: any) => {
    updateBuilding({variables: {id: buildingId, ...values}});
  };
  return (
    <PageContent header={{title: title, onBack: () => navigate('/complex/building')}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          ...building,
        }}
      >
        <Form.Item
          {...formItemLayout}
          label="Nombre"
          name="name"
          rules={[
            {required: true, message: 'Debes escribir el nombre'},
            {max: 50, message: 'El nombre es demasiado largo'},
          ]}
        >
          <Input placeholder="Torre 2, Bloque 17..." style={{maxWidth: 200}} />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Editar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default Edit;
