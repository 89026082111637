import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { EditorState } from "lexical";
import { useState } from "react";
import { CharacterLimitPlugin } from "./plugins/CharacterLimitPlugin";
import ContentEditable from "./ui/ContentEditable";

import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ClickableLinkPlugin from "./plugins/ClickableLinkPlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import LinkPlugin from "./plugins/LinkPlugin";

import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";

import ToolbarPlugin from "./plugins/ToolbarPlugin";

import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import TableCellNodes from "./nodes/TableCellNodes";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import { TableContext, TablePlugin as NewTablePlugin } from "./plugins/TablePlugin";

import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";

import { $convertFromMarkdownString, $convertToMarkdownString } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PLAYGROUND_TRANSFORMERS } from "./plugins/MarkdownTransformers";

import EditorNodes from "./nodes/EditorNodes";
import MarkdownShortcutPlugin from "./plugins/MarkdownShortcutPlugin";

import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import Placeholder from "./ui/Placeholder";

import "./styles.css";

interface EditorInputProps {
  value?: string;
  onChange?: (value: string) => void;
  maxLength?: number;
  placeholder?: string;
}

const Editor: React.FC<EditorInputProps> = ({ value, onChange, maxLength, placeholder }): JSX.Element => {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | undefined>(undefined);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const onContentChange = (editorState: EditorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(PLAYGROUND_TRANSFORMERS);
      onChange?.(markdown);
    });
  };

  const prepopulateInitial = () => {
    if (value) $convertFromMarkdownString(value, PLAYGROUND_TRANSFORMERS);
  };

  const initialConfig = {
    namespace: "Playground",
    editorState: prepopulateInitial,
    theme: PlaygroundEditorTheme,
    onError: (error: Error) => {
      throw error;
    },
    nodes: [...EditorNodes],
  };

  const cellEditorConfig = {
    namespace: "Playground",
    nodes: [...TableCellNodes],
    onError: (error: Error) => {
      throw error;
    },
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <TableContext>
        <div className="editor-shell">
          <ToolbarPlugin />
          <div className="editor-container">
            <OnChangePlugin onChange={onContentChange} />
            {maxLength && <CharacterLimitPlugin limit={maxLength} />}
            <RichTextPlugin
              contentEditable={
                <div className="editor" ref={onRef}>
                  <ContentEditable className="ContentEditable__root" />
                </div>
              }
              placeholder={<Placeholder>{placeholder ? placeholder : "Escribe aquí..."}</Placeholder>}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            {/* <TablePlugin /> */}
            <MarkdownShortcutPlugin />
            {/* <NewTablePlugin cellEditorConfig={cellEditorConfig}>
              <AutoFocusPlugin />
              <RichTextPlugin
                contentEditable={
                  <ContentEditable className="TableNode__contentEditable" />
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <LinkPlugin />
              <ClickableLinkPlugin />
            </NewTablePlugin> */}
            <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
            <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
            <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <AutoFocusPlugin />
          </div>
        </div>
      </TableContext>
    </LexicalComposer>
  );
};

export default Editor;
