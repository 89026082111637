import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {unitTypeShort} from '@/constants/unit';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {formItemLayout} from '@/utils/forms';
import {useMutation, useQuery} from '@apollo/client';
import {CreateUnitContactDocument, SingleUnitDocument, Unit} from '@gql/graphql';
import {Button, Form, Input, notification, Select, Typography} from 'antd';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
const Option = Select.Option;
const {Text} = Typography;
const MemberNew = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;

  useDocumentTitle('Nuevo contacto');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [createUnitMember, {loading: newLoading}] = useMutation(CreateUnitContactDocument, {
    update: (cache, {data}) => {
      if (data?.createUnitContact?.id) {
        notification.success({
          message: 'Añadido con éxito',
          description: 'Se ha creado al nuevo contacto.',
        });
        navigate('/unit/' + unitId + '?tab=contact');
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  const {loading, error, data, refetch} = useQuery(SingleUnitDocument, {
    variables: {id: unitId},
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;
  const unit = data.node as Unit;
  if (!unit) return <NotFound />;
  const unitName = `${unit.type ? unitTypeShort[unit.type] : ''} ${unit.number}`;

  const onInputFinish = async (values: any) => {
    createUnitMember({variables: {unitId: unitId, ...values}});
  };

  return (
    <PageContent header={{title: `Nuevo contacto (${unitName})`, onBack: () => navigate('/unit/' + unitId)}}>
      <Form form={form} onFinish={onInputFinish}>
        <Form.Item
          {...formItemLayout}
          label="Nombres"
          name="firstName"
          rules={[
            {required: true, message: 'Escribe el nombre'},
            {max: 30, message: 'Es muy largo'},
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Apellidos" name="lastName" rules={[{max: 30, message: 'Es muy largo'}]}>
          <Input />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Descripción"
          name="description"
          rules={[
            {required: true, message: 'Escribe la descripción'},
            {max: 60, message: 'La desc. es muy larga'},
          ]}
        >
          <Input placeholder="Inmobiliaria, dueño, trabajador..." />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Correo"
          name="email"
          rules={[
            {max: 100, message: 'El correo es muy largo'},
            {type: 'email', message: 'Ingres un correo válido'},
          ]}
        >
          <Input placeholder="mark@hotmail.com" />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Teléfono" name="phone">
          <Input placeholder="Número celular o fijo" />
        </Form.Item>

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={newLoading}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default MemberNew;
