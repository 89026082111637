import {rolesString} from '@/constants/roles';
import {unitTypeShort} from '@/constants/unit';
import client from '@/graphql';
import {Complex, SearchComplexUserDocument, SearchComplexUserQueryVariables, UnitRoleEdge, User} from '@gql/graphql';
import useDebounce from '@/hooks/useDebounce';
import {hasRoles, selectedComplex} from '@/services/store';
import {LoadingOutlined} from '@ant-design/icons';
import {useReactiveVar} from '@apollo/client';
import {Col, Row, Select, Typography} from 'antd';
import type {DefaultOptionType} from 'antd/es/select';
import React, {useEffect, useState} from 'react';
import Error403 from '../loaders/Error403';
import SelectComplex from '../loaders/SelectComplex';
import UserAvatar from '../UserAvatar';
import './style.css';
const {Text} = Typography;
interface UserSearchProps {
  onChange?: (value: undefined | null) => void;
}

const UserSearch: React.FC<UserSearchProps> = ({onChange}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const debouncedSearch = useDebounce<string | undefined>(search, 500);
  const complexes = useReactiveVar(selectedComplex);
  let complex = complexes[0];
  if (complexes.length > 1) return <SelectComplex />;
  if (!hasRoles(['ADMIN', 'COUNCIL'])) return <Error403 />;

  const handleSearch = async (search: string) => {
    setSearch(search);
  };

  const listLabel = (edge: UnitRoleEdge) => {
    const user = edge?.node?.user as User;
    const role = edge.node?.role ? rolesString[edge.node.role] : undefined;
    const unit = edge.node?.unit?.type
      ? unitTypeShort[edge.node?.unit.type] + ' ' + edge.node?.unit?.number
      : undefined;

    return (
      <Col span={24}>
        <Row justify="start" align="middle">
          <Col style={{marginRight: 8}}>
            <UserAvatar viewer={user} />
          </Col>
          <Col>
            <Row justify="start">
              {user.firstName} {user.lastName}
            </Row>
            <Row justify="start">
              {role} en {unit}
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  const fetchUserList = async (term?: string): Promise<void> => {
    if (!term || term == '') {
      setOptions([]);
      return;
    }
    setLoading(true);
    await client
      .query({
        query: SearchComplexUserDocument,
        variables: {first: 50, id: complex.id, filter: {memberName: term}} as SearchComplexUserQueryVariables,
        fetchPolicy: 'network-only',
      })
      .then(({data: {node}}) => {
        node = node as Complex;
        if (!node.unitRoles?.edges) return [];
        const data = node.unitRoles.edges.map(edge => {
          const user = edge?.node?.user as User;
          return {
            label: listLabel(edge as UnitRoleEdge),
            value: user.id,
          };
        });

        setOptions(data);
      })
      .catch(() => setOptions([]))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchUserList(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <div className="user-search">
      <Select
        showSearch
        onClear={() => setOptions([])}
        allowClear
        style={{maxWidth: 300}}
        options={options}
        placeholder="Buscar usuario..."
        listItemHeight={46}
        listHeight={400}
        filterOption={false}
        onSearch={handleSearch}
        onChange={onChange}
        notFoundContent={
          loading ? (
            <LoadingOutlined />
          ) : (
            <>
              {search && <Text strong>Sin resultados</Text>}
              <Typography.Paragraph type="secondary">
                Escribe un nombre para buscar el usuario. Recuerda que el usuario debe ser miembro de una propiedad.
              </Typography.Paragraph>
            </>
          )
        }
      />
    </div>
  );
};

export default UserSearch;
