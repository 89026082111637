import PageContent from '@/components/PageContent';
import {feedbackStatus, feedbackType, feedbackTypeShort} from '@/constants/feedback';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {enumToKV} from '@/utils/enum';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {useMutation} from '@apollo/client';
import UploadFiles from '@comp/Upload/UploadFiles';
import {CreateFeedbackDocument} from '@gql/graphql';
import {Button, Form, Input, notification, Select, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
const {Text, Paragraph} = Typography;

const title = 'Enviar comentarios';

const New = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [createFeedback, {loading: editLoading}] = useMutation(CreateFeedbackDocument, {
    update: (cache, {data}) => {
      if (data?.createFeedback?.id) {
        notification.success({
          message: 'Enviado con éxito',
          description: 'De ser necesario, te responderemos a tu correo o celular.',
        });
        navigate(-1);
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  const onInputFinish = async (values: any) => {
    let data = structuredClone(values);
    if (values.file) {
      data.fileId = values.file[0].id;
    }

    createFeedback({variables: data});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate(-1)}}>
      <Paragraph>
        Si necesitas ayuda con el servicio de Kipo o deseas dejar una sugerencia, escribe todos los detalles para
        ayudarte. Te contactaremos a tu correo o celular en caso de ser necesario.
      </Paragraph>

      <Form form={form} onFinish={onInputFinish}>
        <Form.Item {...formItemLayout} label="Motivo" name="type" rules={[{required: true}]}>
          <Select style={{maxWidth: 200}}>
            {enumToKV(feedbackType).map(({key, value}) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Mensaje"
          name="description"
          rules={[{required: true}, {max: 500, message: 'El título es muy largo'}]}
        >
          <Input.TextArea
            showCount
            maxLength={500}
            placeholder="Escribe el mensaje..."
            autoSize={{minRows: 3, maxRows: 5}}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Adjunto" name="file" extra="Adjunta una captura o documento.">
          <UploadFiles maxUploads={1} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default New;
