import {CaretDownOutlined, CaretRightOutlined, CaretUpOutlined} from '@ant-design/icons';
import {Tooltip, Typography} from 'antd';
const {Text} = Typography;

export const calcPercentage = (num: number, total: number) => {
  if (total === 0) {
    return 0;
  } else {
    return (num / total) * 100;
  }
};
export const calcDisplayPercentage = (num: number, total: number) => {
  let value = 0;
  if (num && total > 0) {
    value = (num / total) * 100;
  }
  return 100 - Math.max(1.5, value);
};

export const roundPorcentages = (numbers: number[]) => {
  const allEqual = numbers.every(v => v === numbers[0]);
  if (allEqual) {
    return numbers.map(x => Math.floor(x));
  } else {
    return largestRemainderRound(numbers, 100);
  }
};

const largestRemainderRound = (numbers: number[], desiredTotal: number) => {
  let result = numbers
    .map((number, index) => {
      return {
        floor: Math.floor(number),
        remainder: getRemainder(number),
        index: index,
      };
    })
    .sort((a, b) => {
      return b.remainder - a.remainder;
    });

  let lowerSum = result.reduce((sum, current) => {
    return sum + current.floor;
  }, 0);

  let delta = desiredTotal - lowerSum;
  for (let i = 0; i < delta; i++) {
    result[i].floor++;
  }

  return result
    .sort((a, b) => {
      return a.index - b.index;
    })
    .map(result => {
      return result.floor;
    });
};

const getRemainder = (number: number): any => {
  let remainder = number - Math.floor(number);
  return remainder.toFixed(4);
};

export const getVariation = (value: (number | null)[] | null | undefined, hasDecreased?: boolean) => {
  if (!value || !value[0] || !value[1]) return <></>;
  let current = value[0];
  let previus = value[1];
  let diff = current - previus;
  let percentage = previus == 0 ? (diff == 0 ? 0 : 100) : Math.round(((current - previus) / previus) * 100);

  if (!previus) return null;

  let icon;
  if (diff > 0) {
    icon = <CaretUpOutlined />;
  } else if (diff < 0) {
    icon = <CaretDownOutlined />;
  } else {
    icon = <CaretRightOutlined />;
  }

  let color = undefined;
  if (percentage > 0) {
    color = !hasDecreased ? '#3f8600' : '#cf1322';
  } else if (percentage < 0) {
    color = !hasDecreased ? '#cf1322' : '#3f8600';
  }

  return (
    <Tooltip title={previus + ' el periodo anterior'}>
      <Text style={{color: color}}>
        {icon}
        {Math.abs(percentage)}%
      </Text>
    </Tooltip>
  );
};

export const getPercentage = (current: number, total: number) => {
  let percentage = total == 0 ? 0 : Math.round((current / total) * 100);
  return (
    <Tooltip title={'Con respecto al total de ' + total}>
      <Text style={{marginLeft: '5px'}}>{Math.abs(percentage)}%</Text>
    </Tooltip>
  );
};
