import {fontSize} from '@/utils/theme';
import {
  DownloadOutlined,
  FileExcelTwoTone,
  FilePdfTwoTone,
  FilePptTwoTone,
  FileTextOutlined,
  FileTextTwoTone,
  FileWordTwoTone,
  VideoCameraOutlined,
} from '@ant-design/icons';
import {DocumentFile, File} from '@gql/graphql';
import {Space, Typography} from 'antd';
import {Fragment, useCallback, useEffect, useState} from 'react';

const MediaDocument = ({file}: {file: File}) => {
  const [visible, setVisible] = useState(false);
  const [mask, setMask] = useState(false);
  const links = file.urls as DocumentFile;

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key == 'Escape' && visible) setVisible(false);
    },
    [visible],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction, false);
  }, [visible]);

  if (!links || !links.original) return null;
  const fileExt = file.filename ? (file.filename.split('.').pop() as string) : '';
  const isWord = ['doc', 'docx'].includes(fileExt);
  const isSheet = ['xls', 'xlsx'].includes(fileExt);
  const isPresen = ['ppt', 'pptx', 'pps'].includes(fileExt);
  const isPDF = ['pdf'].includes(fileExt);
  const showDefault = !isWord && !isSheet && !isPresen && !isPDF;

  const suffixCount = 6;
  const fileName = file.filename;
  const start = fileName?.slice(0, fileName.length - suffixCount).trim();
  const suffix = fileName?.slice(-suffixCount).trim();

  return (
    <div className="thumb-doc" onMouseOver={() => setMask(true)} onMouseLeave={() => setMask(false)}>
      <div style={{height: 60, maxWidth: 160}} className="thumb-doc-container">
        <Space size={0} style={{lineHeight: 1}}>
          {isWord && <FileWordTwoTone style={{fontSize: 40}} />}
          {isSheet && <FileExcelTwoTone style={{fontSize: 40}} />}
          {isPresen && <FilePptTwoTone style={{fontSize: 40}} />}
          {isPDF && <FilePdfTwoTone style={{fontSize: 40}} />}
          {showDefault && <FileTextTwoTone style={{fontSize: 40}} />}
          <Typography.Paragraph ellipsis={{suffix, rows: 3}} style={{fontSize: fontSize - 1, lineHeight: 1, margin: 0}}>
            {start}
          </Typography.Paragraph>
        </Space>
      </div>
      <div
        className="thumb-doc-mask"
        style={{opacity: mask === true ? 1 : 0}}
        onClick={() => {
          setMask(false);
          if (links.original) window.open(links.original, '_blank');
        }}
      >
        <DownloadOutlined />
      </div>
    </div>
  );
};

export default MediaDocument;
