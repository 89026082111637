import {ApolloError, ServerParseError} from '@apollo/client';
import {GraphQLError} from 'graphql';
import Error403 from './Error403';
import Error500 from './Error500';
import ErrorNetwork from './ErrorNetwork';

interface ErrorRenderProps {
  error: ApolloError;
  refetch: Function;
}

const ErrorRender: React.FC<ErrorRenderProps> = ({error, refetch}) => {
  if (error.networkError) {
    let info = error.networkError as ServerParseError;
    console.warn('[ErrorRender] networkError', info.statusCode);

    switch (info.statusCode) {
      case 500:
        return <Error500 />;

      default:
        return <ErrorNetwork refetch={refetch} />;
    }
  } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    let info = error.graphQLErrors[0] as GraphQLError;
    console.warn('[ErrorRender] GraphQLError', info.extensions?.code);

    switch (info.extensions?.status_code) {
      case 403:
        return <Error403 />;

      default:
        break;
    }
  }

  return <Error500 />;
};

export default ErrorRender;
