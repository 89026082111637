import client from '@/graphql';
import {BillboardFile, CoverFile, File as gqlFile, FileType, Maybe, UploadFileDocument} from '@gql/graphql';
import {DeleteOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {App, Button, Image, Popover, Upload as AntdUpload} from 'antd';
import {RcFile, UploadChangeParam} from 'antd/es/upload';
import {Fragment, useState} from 'react';
import './UploadCover.css';

interface UploadImageProps {
  value?: gqlFile;
  onChange?: (value: gqlFile | undefined) => void;
  fileType: FileType;
}

const UploadImage: React.FC<UploadImageProps> = ({value, onChange, fileType}) => {
  const [imageData, setImageData] = useState<gqlFile | undefined>(value);
  const [uploadin, setUploading] = useState(false);
  const {message} = App.useApp();

  let urlsType: CoverFile | BillboardFile;
  let imageURL: Maybe<string> | undefined;
  let acceptedSize = 5;
  let acceptedExt = '';
  let acceptedMIME: string[];
  switch (fileType) {
    case 'COVER':
      urlsType = imageData?.urls as CoverFile;
      imageURL = urlsType?.cover;
      acceptedExt = '.jpg, .jpeg, .png';
      acceptedMIME = ['image/jpeg', 'image/png'];
      break;

    case 'BILLBOARD':
      urlsType = imageData?.urls as BillboardFile;
      imageURL = urlsType?.billboard;
      acceptedExt = '.jpg, .jpeg, .png, .pdf';
      acceptedMIME = ['image/jpeg', 'image/png', 'application/pdf'];
      break;

    default:
      break;
  }

  function beforeCoverUpload(file: RcFile) {
    const validMIME = acceptedMIME.includes(file.type);
    if (!validMIME) {
      message.error(`Solo puedes subir archivos ${acceptedExt}`);
    }
    const validSize = file.size / 1024 / 1024 < acceptedSize;
    if (!validSize) {
      message.error(`La imagene debe tener un tamaño menor a ${acceptedSize}MB`);
    }
    return validMIME && validSize;
  }

  const handleCoverChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploading(false);
      setImageData(info.file.response.data.uploadFile);
      onChange?.(info.file.response.data.uploadFile);
    }
  };

  return (
    <Fragment>
      <AntdUpload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeCoverUpload}
        accept={acceptedExt}
        onChange={handleCoverChange}
        customRequest={({file, onError, onProgress, onSuccess}) => {
          client
            .mutate({
              mutation: UploadFileDocument,
              variables: {file: file, type: fileType},
            })
            .then(result => {
              if (onSuccess) onSuccess(result);
            })
            .catch(onError);

          return {
            abort() {
              console.warn('Upload was aborted.');
            },
          };
        }}
      >
        {!imageData && !uploadin && (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Subir</div>
          </div>
        )}
        {uploadin && (
          <div>
            <LoadingOutlined />
            <div className="ant-upload-text">Subiendo...</div>
          </div>
        )}
        {imageData && imageURL && (
          <Popover
            placement="rightTop"
            content={
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setImageData(undefined);
                  onChange?.(undefined);
                }}
              />
            }
          >
            <Image src={imageURL} placeholder={true} style={{maxWidth: 300}} preview={false} />
          </Popover>
        )}
      </AntdUpload>
    </Fragment>
  );
};

export default UploadImage;
