interface IDictionary {
  [index: string]: string;
}

export function enumToIndex(data: object, index: number) {
  let keys = Object.keys(data);
  return keys[index];
}

export function enumToAllIndex(data: object) {
  return Object.keys(data);
}

export function enumToValue(data: IDictionary, type: string) {
  return data[type];
}

export function enumToArray(data: object) {
  return Object.values(data);
}

export function enumToKV(data: object) {
  let result: IDictionary[] = [];
  for (const [key, value] of Object.entries(data)) {
    result.push({key: key, value: value});
  }
  return result;
}
