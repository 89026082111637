import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import UserAvatar from '@/components/UserAvatar';
import {humanSex} from '@/constants/misc';
import {rolesString, unitRoleRights} from '@/constants/roles';
import client from '@/graphql';
import {findById, getUnitRole, getViewer, hasRoles, refetchViewer} from '@/services/store';
import {relativeDateTooltip, toNowTooltip} from '@/utils/dates';
import {DeleteOutlined, DownOutlined, SwapOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {
  RemoveUnitRoleDocument,
  SetUnitRoleDocument,
  SingleUnitMembersDocument,
  SingleUnitMembersQueryVariables,
  Unit,
  UnitRoles,
} from '@gql/graphql';
import {App, Button, Descriptions, Dropdown, Empty, List, MenuProps, Space, Typography} from 'antd';
import {useState} from 'react';
import {useParams} from 'react-router-dom';

const MemberList = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;
  const [withRights, setWithRights] = useState(false);

  const {message, notification} = App.useApp();
  const queryVariables = {id: unitId, first: 10, withRights: false} as SingleUnitMembersQueryVariables;
  const {loading, error, data, refetch, fetchMore} = useQuery(SingleUnitMembersDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const unit = data.node as Unit;
  if (!unit) return <NotFound />;

  const roles = unit.roles?.edges?.map(edge => edge?.node);
  const pageInfo = unit.roles?.pageInfo;

  const viewer = getViewer();
  const complex = findById(unit.complexId);
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;

  const viewerAdmin = hasRole ? 'ADMIN' : undefined;
  const viewerRole = getUnitRole(unit) || viewerAdmin;

  if (hasRole && !withRights) {
    refetch({withRights: true});
    setWithRights(true);
  }

  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Ver más...
        </Button>
      </div>
    ) : null;

  const setRole = async (userId: string, role: UnitRoles) => {
    await client
      .mutate({
        mutation: SetUnitRoleDocument,
        variables: {unitId: unit.id, userId: userId, role: role},
        refetchQueries: [{query: SingleUnitMembersDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.setUnitRole?.id) {
          message.success('Se ha cambiado el rol con éxito.');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al cambiar',
          description: 'No se pudo cambiar el rol. Intenta más tarde.',
        });
      });
  };

  const removeRole = async (roleId: string) => {
    await client
      .mutate({
        mutation: RemoveUnitRoleDocument,
        variables: {roleId: roleId},
        refetchQueries: [{query: SingleUnitMembersDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.removeUnitRole?.id) {
          message.success('Se ha removido el rol con éxito.');
          if (data.removeUnitRole.user?.id == viewer?.id) refetchViewer()();
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al remover',
          description: 'No se pudo remover el rol. Intenta más tarde.',
        });
      });
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        loading={loading}
        locale={{emptyText: <Empty description="Sin usuarios" />}}
        dataSource={roles}
        renderItem={role => {
          const allCanActions = viewerRole ? unitRoleRights[viewerRole][role!.role!] : undefined;
          const canDelete = allCanActions ? allCanActions.length > 0 : false;
          const canActions = allCanActions ? allCanActions.filter(e => e !== role!.role) : [];

          let items: MenuProps['items'] = canActions.map((action, index) => {
            return {
              key: index,
              label: `Cambiar a ${rolesString[action]}`,
              icon: <SwapOutlined />,
              onClick: () => setRole(role!.user!.id, action as UnitRoles),
            };
          });

          if (canDelete)
            items.push(
              {
                type: 'divider',
              },
              {
                key: 'delete',
                label: 'Remover',
                icon: <DeleteOutlined />,
                danger: true,
                onClick: () => removeRole(role!.id),
              },
            );

          return (
            <List.Item key={role?.id}>
              <List.Item.Meta
                className="comment-meta"
                title={
                  <Space>
                    <Typography.Text style={{fontSize: '13px'}} strong>
                      {`${role?.user?.firstName} ${role?.user?.lastName}`}
                    </Typography.Text>
                    <Typography.Text type="secondary" style={{fontSize: '11px'}}>
                      {toNowTooltip(role?.insertedAt)}
                    </Typography.Text>
                  </Space>
                }
                avatar={<UserAvatar viewer={role?.user} />}
                description={
                  <Descriptions column={{xs: 1, sm: 1, md: 1, lg: 2}} size={'small'}>
                    <Descriptions.Item label="Rol">{role?.role ? rolesString[role.role] : '—'}</Descriptions.Item>
                    <Descriptions.Item label="Agregado por">
                      {role?.createdBy ? `${role?.createdBy?.firstName} ${role?.createdBy?.lastName}` : '—'}
                    </Descriptions.Item>
                    {hasRole && (
                      <>
                        <Descriptions.Item label="Celular">
                          {role?.user?.celNumber ? `+${role?.user?.celCountry} ${role?.user?.celNumber}` : '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Correo">{role?.user?.email ?? '—'}</Descriptions.Item>
                        <Descriptions.Item label="Cumpleaños">
                          {role?.user?.birthdayNonYear ? relativeDateTooltip(role?.user?.birthdayNonYear) : '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Sexo">
                          {role?.user?.sex ? humanSex[role?.user?.sex] : '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Documento">
                          {role?.user?.idNumber ? `${role?.user?.idType} ${role.user.idNumber}` : '—'}
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                }
              />
              <Space>
                {' '}
                <Dropdown menu={{items}} disabled={!allCanActions ? true : false}>
                  <Button>
                    <Space>
                      Acciones
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </List.Item>
          );
        }}
      />
      {loadMore}
    </>
  );
};

export default MemberList;
