import {antdConfig, themeMode} from '@/utils/theme';
import {RouterRoot} from '@/Router';
import {ApolloProvider, useReactiveVar} from '@apollo/client';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {App as AntdApp, ConfigProvider} from 'antd';
import esES from 'antd/locale/es_ES';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import client from './graphql';
import './index.css';

if (import.meta.env.PROD)
  Sentry.init({
    dsn: 'https://0e7ed25b79bb4eddb4aa6bd11410b54d@o4504108536168448.ingest.sentry.io/4504371576504320',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5, //1.0 to capture 100%
  });

const App = () => {
  const mode = useReactiveVar(themeMode);

  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <ConfigProvider theme={antdConfig(mode)} locale={esES}>
          <AntdApp>
            <RouterProvider router={createBrowserRouter(RouterRoot)} />
          </AntdApp>
        </ConfigProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
