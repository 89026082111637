import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import {vehicleType} from '@/constants/vehicle';
import client from '@/graphql';
import {findById, getUnitRole, hasRoles} from '@/services/store';
import {toNowTooltip} from '@/utils/dates';
import {getFullOne} from '@/utils/names';
import {DeleteOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {
  SingleUnitVehiclesDocument,
  SingleUnitVehiclesQueryVariables,
  SoftDeleteVehicleDocument,
  Unit,
  Vehicle,
} from '@gql/graphql';
import {App, Button, Empty, Popconfirm, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {useParams} from 'react-router-dom';

const VehicleList = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;

  const {message, notification} = App.useApp();
  const queryVariables = {id: unitId, first: 10} as SingleUnitVehiclesQueryVariables;
  const {loading, error, data, refetch, fetchMore} = useQuery(SingleUnitVehiclesDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const unit = data.node as Unit;
  if (!unit) return <NotFound />;

  const vehicles = unit.vehicles?.edges?.map(edge => edge?.node as Vehicle);
  const pageInfo = unit.vehicles?.pageInfo;

  const complex = findById(unit.complexId);
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const viewerAdmin = hasRole ? 'ADMIN' : undefined;
  const viewerRole = getUnitRole(unit) || viewerAdmin;
  if (!viewerRole) return <Error403 />;

  const loadMore =
    pageInfo && pageInfo.hasNextPage ? (
      <div style={{textAlign: 'center', marginTop: 12}}>
        <Button
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
            })
          }
        >
          Ver anteriores...
        </Button>
      </div>
    ) : null;

  const deleteVehicle = async (id: string) => {
    await client
      .mutate({
        mutation: SoftDeleteVehicleDocument,
        variables: {id: id},
        refetchQueries: [{query: SingleUnitVehiclesDocument, variables: queryVariables}],
      })
      .then(({data}) => {
        if (data?.softDeleteVehicle?.id) {
          message.success('Se ha removido el vehículo con éxito.');
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error al remover',
          description: 'No se pudo remover el vehículo. Intenta más tarde.',
        });
      });
  };

  const deleteButton = (id?: string) => {
    if (!id) return undefined;

    return (
      <Popconfirm
        key="delConfirm"
        placement="topRight"
        title={'¿Remover este vehículo?'}
        onConfirm={() => deleteVehicle(id)}
        okText={'Remover'}
        okType="danger"
        cancelText="Cancelar"
      >
        <Button danger>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  const expandedRowRender = (record: Vehicle) => {
    const columns: ColumnsType<Vehicle> = [
      {
        title: 'Añadido',
        dataIndex: 'insertedAt',
        key: 'insertedAt',
        render: text => (text ? toNowTooltip(text) : '—'),
      },
      {
        title: 'Añadido por',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: text => getFullOne(text),
      },
    ];

    return (
      <Table columns={columns} size="small" rowKey={record => record.id} dataSource={[record]} pagination={false} />
    );
  };

  const columns: ColumnsType<Vehicle> = [
    {
      title: 'Placa',
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => vehicleType[text],
    },
    {
      title: 'Marca',
      dataIndex: 'brand',
      key: 'brand',
      render: text => (text ? text : '—'),
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: text => (text ? text : '—'),
    },
    {
      title: 'Acciones',
      key: 'action',
      align: 'center',
      render: (text, record) => (viewerRole ? deleteButton(record.id) : undefined),
    },
  ];

  return (
    <>
      <Table<Vehicle>
        columns={columns}
        size="small"
        pagination={false}
        rowKey={record => record.id}
        expandedRowRender={record => expandedRowRender(record)}
        dataSource={vehicles}
        locale={{emptyText: <Empty description="Sin vehículos"></Empty>}}
      />
      {loadMore}
    </>
  );
};

export default VehicleList;
