interface IDictionary {
  [index: string]: string;
}

export const vehicleType: IDictionary = {
  CAR: "Automóvil",
  MOTORCYCLE: "Motocicleta",
  SUV: "Camioneta",
  VAN: "Buseta",
  OTHER: "Otro",
};

export const fuelType = {
  GASOLINE: "Gasolina",
  ELECTRIC: "Eléctrico",
  HYBRID_ELECTRIC: "Híbrido eléctrico",
  DIESEL: "Diésel",
  HYBRID_GNV: "Gas",
};

export const vehicleStatus = {
  ADDED: "Añadido",
  ACTIVE: "Activo",
  DELETED: "Eliminado",
};

export const vehicleService = {
  PRIVATE: "Particular",
  PUBLIC: "Publico",
};

export const vechicleMakers = [
  "Chevrolet",
  "Renault",
  "Toyota",
  "Mazda",
  "Ford",
  "Nissan",
  "Volkswagen",
  "Kia",
  "BMW",
  "Mercedes-Benz",
  "Suzuki",
  "Hyundai",
  "Honda",
  "Audi",
  "Jeep",
  "Mitsubishi",
  "Dodge",
  "Peugeot",
  "Volvo",
  "Fiat",
  "JAC",
  "Mini",
  "Foton",
  "Citroën",
  "Land Rover",
  "Subaru",
  "Ssangyong",
  "SEAT",
  "Porsche",
  "Chery",
  "Acura",
  "DFM/DFSK",
  "Lexus",
  "Daihatsu",
  "International",
  "Skoda",
  "JMC",
  "RAM",
  "Jaguar",
  "DS",
  "Great Wall",
  "Changan",
  "BYD",
  "Hafei",
  "MG",
  "Opel",
  "Mahindra",
  "Daewoo",
  "Zotye",
  "Mack",
  "Alfa Romeo",
  "Hummer",
  "Brilliance",
  "Maserati",
  "Chana",
  "Chrysler",
  "Iveco",
  "Cadillac",
  "Geely",
  "DFSK",
  "Changhe",
  "Baic",
  "Jetour",
  "Infiniti",
  "Lincoln",
  "Lada",
  "Rover",
  "Gonow",
  "Tesla",
  "Dongfeng",
  "Lifan",
  "Zhongxing",
  "Haval",
  "Higer",
  "Lancia",
  "Ferrari",
  "DFM",
  "Jinbei",
  "Maxus",
];
