import Editor from '@/components/Editor';
import Error403 from '@/components/loaders/Error403';
import SelectComplex from '@/components/loaders/SelectComplex';
import PageContent from '@/components/PageContent';
import UploadFiles from '@/components/Upload/UploadFiles';
import UploadImage from '@/components/Upload/UploadImage';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {formItemLayout, tailFormItemLayout} from '@/utils/forms';
import {buildBuildingList} from '@/utils/misc';
import {hasRoles, selectedComplex} from '@/services/store';
import {DownOutlined} from '@ant-design/icons';
import {useMutation, useReactiveVar} from '@apollo/client';
import {CreateNewsDocument, File} from '@gql/graphql';
import {Dropdown, Form, Input, notification, Radio, Select} from 'antd';
import {useNavigate} from 'react-router-dom';

const title = 'Nueva noticia';

const New = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let complex = useReactiveVar(selectedComplex)[0];
  const [createNews, {data, loading, error}] = useMutation(CreateNewsDocument, {
    update: (cache, {data}) => {
      if (data?.createNews?.id) {
        notification.success({
          message: 'Creada con éxito',
          description: 'Se ha creado la noticia.',
        });
        navigate('/news/' + data.createNews.id);
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (selectedComplex().length !== 1) return <SelectComplex />;
  if (!hasRoles(['ADMIN', 'COUNCIL'])) return <Error403 />;

  const buildingList = buildBuildingList(complex.buildings, true);

  const onInputFinish = async (values: any) => {
    let args = structuredClone(values);
    if (args.cover && args.files) args.files.push(args.cover);
    if (args.cover && !args.files) args.files = [args.cover];
    if (args.files) args.files = args.files.map((item: File) => item.id);
    if (args.buildingId == 'ALL') args.buildingId = null;
    args.cover = undefined;

    createNews({variables: {complexId: complex.id, ...args}});
  };

  return (
    <PageContent header={{title: title, onBack: () => navigate('/')}}>
      <Form
        form={form}
        onFinish={onInputFinish}
        initialValues={{
          allowComments: true,
          allowReactions: true,
          buildingId: 'ALL',
        }}
      >
        <Form.Item
          name="title"
          extra="Escribe un título corto. Evita las mayúsculas sostenidas."
          rules={[
            {required: true, message: 'Escribe un título'},
            {max: 60, message: 'El título es muy largo'},
          ]}
        >
          <Input size="large" placeholder="Título de la noticia" />
        </Form.Item>

        <Form.Item
          name="body"
          rules={[
            {required: true, message: 'Escribe la noticia'},
            {max: 2000, message: 'La noticias es muy larga'},
          ]}
        >
          <Editor maxLength={2000} placeholder="Contenido de la noticia..." />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label="Portada"
          name="cover"
          extra="Imagen de ilustración visible en la parte superior de la noticia."
        >
          <UploadImage fileType={'COVER'} />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Adjuntos" name="files" extra="Adjunta documentos, imágenes o vídeos.">
          <UploadFiles maxUploads={5} />
        </Form.Item>

        {buildingList && (
          <Form.Item
            {...formItemLayout}
            name="buildingId"
            label="Mostrar en"
            extra="Limita la visibilidad de la noticia a las propiedades del edificio seleccionado. Úsalo si la noticia es únicamente de interés para ellos."
          >
            <Select style={{maxWidth: 200}} options={buildingList} />
          </Form.Item>
        )}

        <Form.Item {...formItemLayout} label="Comentarios" name="allowComments">
          <Radio.Group>
            <Radio.Button value={true}>Activados</Radio.Button>
            <Radio.Button value={false}>Desactivados</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Reacciones" name="allowReactions">
          <Radio.Group>
            <Radio.Button value={true}>Activadas</Radio.Button>
            <Radio.Button value={false}>Desactivadas</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item hidden name="status">
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Dropdown.Button
            type="primary"
            icon={<DownOutlined />}
            loading={loading}
            menu={{
              items: [{label: 'Guardar como borrador', key: '1'}],
              onClick: () => {
                form.setFieldValue('status', 'DRAFT');
                form.submit();
              },
            }}
            onClick={() => {
              form.setFieldValue('status', 'PUBLISHED');
              form.submit();
            }}
          >
            Publicar
          </Dropdown.Button>
        </Form.Item>
      </Form>
    </PageContent>
  );
};

export default New;
