import {Maybe, User} from '@gql/graphql';
import {getFirstLetter, getUniqueColor} from '@/utils/names';
import {UserOutlined} from '@ant-design/icons';
import {Avatar as AvatarAntd, AvatarProps} from 'antd';

export interface customProps {
  viewer?: Maybe<User>;
}

type CustomAvatarProps = AvatarProps & customProps;

export const avatarConfig = (props: CustomAvatarProps) => {
  if (!props.viewer) return {icon: <UserOutlined />};
  const size = props.size ? props.size : 'large';
  const thumb = props.viewer.avatar?.urls?.thumb;
  let src;

  if (props.src) {
    src = props.src;
  } else if (props.src === null) {
    src = undefined;
  } else if (thumb) {
    src = thumb;
  }

  let name = getFirstLetter(props.viewer);
  let defaultConf = {
    size: size,
    children: name,
    style: {
      backgroundColor: !src ? getUniqueColor(props.viewer) : 'transparent',
      verticalAlign: 'middle',
    },
  };

  if (src) {
    return {src: src, ...defaultConf};
  } else {
    return defaultConf;
  }
};

const UserAvatar = (props: CustomAvatarProps) => {
  const config = avatarConfig(props);

  return <AvatarAntd {...config} />;
};
export default UserAvatar;
