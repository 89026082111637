import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import UserAvatar from '@/components/UserAvatar';
import {pollPrivacyDesc, pollPrivacyStr} from '@/constants/poll';
import {unitTypeShort} from '@/constants/unit';
import {findById, getViewer, hasRoles, relatedUnits} from '@/services/store';
import {calcDisplayPercentage, calcPercentage, roundPorcentages} from '@/utils/porcentages';
import {InfoCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {
  InputMaybe,
  Poll,
  PollAnswerFilter,
  SetPollVoteDocument,
  SinglePollDocument,
  SinglePollQueryVariables,
  UnitRoles,
  User,
} from '@gql/graphql';
import {App, Drawer, Row, Segmented, Select, Spin, Tooltip, Typography} from 'antd';
import {Fragment, useState} from 'react';
import Answers from './Answers';
import './style.css';
const {Text} = Typography;

const Options = ({pollId}: {pollId: string}) => {
  if (!pollId) return <NotFound />;
  const {message, notification} = App.useApp();
  const viewer = getViewer();
  const viewerUnits = relatedUnits();
  const [selectedUnit, setSelectedUnit] = useState<string>();
  const [selectedOption, setSelectedOption] = useState<string>();
  const [filters, setFilters] = useState<InputMaybe<PollAnswerFilter>>();
  const [firstLoad, setFirstLoad] = useState<Poll>();
  const [open, setOpen] = useState(false);

  const queryVariables = {id: pollId, first: 0} as SinglePollQueryVariables;
  const {loading, data, error, refetch} = useQuery(SinglePollDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const [setVote, {loading: setLoading}] = useMutation(SetPollVoteDocument, {
    update: (cache, {data}) => {
      const action = data?.setPollVote?.action;
      if (action) {
        switch (action) {
          case 'UPDATE':
            message.success('Voto cambiado con éxito');
            break;
          case 'CREATE':
            message.success('Voto registrado con éxito');
            break;
          case 'DELETE':
            message.success('Voto retirado con éxito');
            break;
        }
        refetch();
      } else {
        notification.error({
          message: 'Ha ocurrido un error :(',
          description: 'Verifica la información o intenta más tarde.',
        });
      }
    },
  });

  if (loading && !firstLoad) return <LoadingSkeleton paragraph={{rows: 4}} active />;
  if (error) return <ErrorRender error={error} refetch={refetch} />;
  if (data?.node && data?.node != firstLoad) setFirstLoad(data?.node as Poll);
  if (!data?.node && !firstLoad) return <NotFound />;

  const poll = firstLoad;
  if (!poll || !poll.options || !poll.privacy) return <NotFound />;

  if (!selectedUnit && viewerUnits.length > 0) {
    setSelectedUnit(viewerUnits[0].id);
  }

  const complex = findById(poll.complexId);
  const hasRole = complex ? hasRoles(['ADMIN', 'COUNCIL'], [complex]) : undefined;

  const unitList = viewerUnits
    .filter(({complexId}) => complexId === poll.complexId)
    .map(({id, number, type}) => {
      return {label: unitTypeShort[type] + ' ' + number, value: id};
    });

  const optionVoteCount = poll.options.map(opt => (opt?.answers?.countTotal ? opt?.answers?.countTotal : 0));
  const maxVoterCount = Math.max(...optionVoteCount);
  const totalVoterCount = optionVoteCount.reduce((a, b) => a + b, 0);

  const alreadyVotedForAny = poll.options.map(opt => (opt?.answers?.countMine ? opt?.answers?.countMine > 0 : false));
  const alreadyVoted = alreadyVotedForAny.includes(true);

  const hasUnits = unitList.length > 0;
  const isClosed = poll.status === 'CLOSED' || poll.status === 'EXPIRED';
  const canVote = !isClosed && hasUnits && selectedUnit;
  let canSeeVotes = false;

  if (canVote || !isClosed) {
    canSeeVotes = false;
  } else if (poll.privacy === 'ANONYMOUS') {
    canSeeVotes = false;
  } else if (hasRole) {
    canSeeVotes = true;
  } else if (poll.privacy === 'PUBLIC') {
    canSeeVotes = true;
  }

  let percentages = roundPorcentages(optionVoteCount.map(x => calcPercentage(x, totalVoterCount)));
  let percentagesDisplay = optionVoteCount.map(x => calcDisplayPercentage(x, maxVoterCount));

  const unitSelectorContent =
    selectedUnit && unitList.length > 1
      ? [
          <Select
            key="unitId"
            defaultValue={selectedUnit}
            size="small"
            onChange={val => setSelectedUnit(val)}
            options={unitList}
          />,
        ]
      : undefined;

  const roleFilterOptions = [
    {label: 'Todos', value: 'ALL'},
    {label: 'Propietarios', value: 'OWNER_DISTINC'},
    {label: 'Residentes', value: 'HABITANT'},
  ];

  const roleFilterContent = isClosed ? (
    <Segmented
      key="typeFilter"
      defaultValue="ALL"
      onChange={value => {
        let variables = {
          ...queryVariables,
        };

        if (value != 'ALL') {
          let valueStr = value.toString();
          let role: UnitRoles = valueStr.replace('_DISTINC', '') as UnitRoles;
          let distinc = valueStr.includes('_DISTINC');

          variables = {
            ...variables,
            filter: {unitRole: {role: role, distinc: distinc}},
          } as SinglePollQueryVariables;
        } else {
          variables = {
            ...variables,
            filter: {},
          } as SinglePollQueryVariables;
        }
        setFilters(variables.filter);
        refetch(variables);
      }}
      options={roleFilterOptions}
    />
  ) : undefined;

  return (
    <PageContent
      header={{
        title: 'Votación',
        tags: unitSelectorContent,
      }}
    >
      <Fragment>
        <Row justify="center" align="top">
          {roleFilterContent}
        </Row>
        <Spin spinning={loading} indicator={<LoadingOutlined style={{fontSize: 24}} />}>
          {poll.options.map((item, index) => {
            if (!item) return undefined;
            return (
              <div key={item.id} className="poll-option">
                <div
                  className="poll-option-wrapper"
                  style={{
                    cursor: (canVote || canSeeVotes) && !setLoading ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (canVote && selectedUnit) {
                      setVote({
                        variables: {optionId: item.id, unitId: selectedUnit},
                      });
                    } else if (canSeeVotes) {
                      setSelectedOption(item.id);
                      setOpen(true);
                    }
                  }}
                >
                  <div className="poll-option-text-wrapper">
                    {setLoading && (
                      <div className="poll-percentage">
                        <LoadingOutlined />
                      </div>
                    )}
                    {!(canVote && !alreadyVoted) && !setLoading && (
                      <div className="poll-percentage">
                        <Tooltip placement="left" title={optionVoteCount[index] + ' votos'}>
                          {percentages[index]}%
                        </Tooltip>
                      </div>
                    )}
                    {canVote && !alreadyVoted && !setLoading && (
                      <div className="poll-radio">
                        <div className="poll-radio-circle"></div>
                      </div>
                    )}
                    {alreadyVotedForAny[index] && (
                      <div className="poll-option-mark">
                        <UserAvatar viewer={viewer as User} size={15} />
                      </div>
                    )}
                    <div className="poll-option-text">{item.option}</div>
                  </div>
                </div>
                <div className="MuiLinearProgress-root poll-option-progress-root">
                  {!(canVote && !alreadyVoted) && (
                    <div
                      style={{
                        transform: `translateX(-${percentagesDisplay[index] + '%'})`,
                      }}
                      className="MuiLinearProgress-bar poll-option-progress-bar"
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
          <Row justify="space-between" align="bottom" style={{marginTop: '20px'}}>
            <Text type="secondary" italic>
              {totalVoterCount} votos
            </Text>
            <Text type="secondary" italic>
              <Tooltip title={pollPrivacyDesc[poll.privacy]}>
                Votación {pollPrivacyStr[poll.privacy]} <InfoCircleOutlined />
              </Tooltip>
            </Text>
          </Row>
        </Spin>
        <Drawer title="Votos" placement="right" destroyOnClose={true} onClose={() => setOpen(false)} open={open}>
          <Answers optionId={selectedOption} filters={filters} hasRole={hasRole} />
        </Drawer>
      </Fragment>
    </PageContent>
  );
};

export default Options;
