import useDocumentTitle from '@/hooks/useDocumentTitle';
import {selectedComplex, selectedComplexFilter} from '@/services/store';
import {useQuery, useReactiveVar} from '@apollo/client';
import ErrorRender from '@comp/loaders/ErrorRender';
import {HomeDocument, HomeQueryVariables} from '@gql/graphql';
import {Col, Row} from 'antd';
import {Fragment} from 'react';
import BillboardsWidget from './BillboardsWidget';
import HomeSkeleton from './HomeSkeleton';
import NewsWidget from './NewsWidget';
import PollsWidget from './PollsWidget';

const Home = () => {
  useDocumentTitle('Inicio');
  useReactiveVar(selectedComplex);

  const queryVariables: HomeQueryVariables = {
    billboardFirst: 6,
    newsFirst: 5,
    pollsFirst: 5,
    complexes: selectedComplexFilter(),
  };

  const {loading, error, data, refetch} = useQuery(HomeDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (loading && !data) return <HomeSkeleton />;
  if (!data?.billboards?.edges) return <HomeSkeleton />;

  return (
    <Fragment>
      <Row justify="center" gutter={16}>
        <Col flex="auto" xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
          <NewsWidget data={data} />
          <PollsWidget data={data} />
        </Col>
        <Col flex="auto" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <BillboardsWidget data={data} refetch={refetch} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Home;
