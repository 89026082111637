import client from '@/graphql';
import {SignOutDocument} from '@gql/graphql';
import {clearStore, setAccessToken} from '@/services/store';
import {useMutation} from '@apollo/client';
import LoadingFullscreen from '@comp/loaders/LoadingFullscreen';
import {useEffect} from 'react';
import {Navigate} from 'react-router-dom';

const Logout = () => {
  const [logOut, {data, error}] = useMutation(SignOutDocument, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onCompleted: () => {
      setAccessToken(null);
    },
  });

  useEffect(() => {
    const removeCache = async () => {
      await client.clearStore();
    };
    removeCache();
    logOut();
    clearStore();
  }, []);

  if (data || error) {
    return <Navigate replace to="/login" />;
  }

  return <LoadingFullscreen />;
};

export default Logout;
