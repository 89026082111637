interface IDictionary {
  [index: string]: string;
}

export const joinCodeStatus: IDictionary = {
  ACTIVE: 'Activo',
  EXPIRED: 'Expiró',
  INACTIVE: 'Cancelado',
  USED: 'Usado',
};
