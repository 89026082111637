import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import LoadingSkeleton from '@/components/loaders/LoadingSkeleton';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import {fontSize} from '@/utils/theme';
import {unitTypeShort} from '@/constants/unit';
import {setDocumentTitle} from '@/hooks/useDocumentTitle';
import {findById, getUnitRole, hasRoles} from '@/services/store';
import {CarOutlined, ContactsOutlined, DownOutlined, EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {SingleUnitDocument, Unit} from '@gql/graphql';
import {IconKey, IconNotebook, IconPaw, IconUserPlus} from '@tabler/icons-react';
import {Button, Descriptions, Dropdown, MenuProps, Tabs} from 'antd';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import ContactList from './ContactList';
import JoinCodeList from './JoinCodeList';
import MemberList from './MemberList';
import NoteList from './NoteList';
import PetList from './PetList';
import VehicleList from './VehicleList';

const View = () => {
  let {unitId} = useParams();
  if (!unitId) return <NotFound />;

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const {loading, error, data, refetch} = useQuery(SingleUnitDocument, {
    variables: {id: unitId},
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  if (loading) return <LoadingSkeleton avatar paragraph={{rows: 6}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (!data?.node) return <NotFound />;

  const unit = data.node as Unit;
  if (!unit) return <NotFound />;
  const unitName = `${unit.type ? unitTypeShort[unit.type] : ''} ${unit.number}`;

  setDocumentTitle(unitName);

  const complex = findById(unit.complexId);
  const hasRole = complex ? hasRoles(['ADMIN'], [complex]) : undefined;
  const viewerRole = getUnitRole(unit) || hasRole;

  if (!viewerRole) return <Error403 />;

  const selectedTab = searchParams.get('tab');
  let defaultTab = hasRole ? 'note' : 'member';
  defaultTab = selectedTab ? selectedTab : defaultTab;

  const itemsTab = [
    {
      key: 'note',
      children: <NoteList />,
      label: (
        <>
          <IconNotebook size={fontSize} /> Notas
        </>
      ),
    },
    {
      key: 'member',
      children: <MemberList />,
      label: (
        <>
          <IconUserPlus size={fontSize} /> Miembros
        </>
      ),
    },
    {
      key: 'contact',
      children: <ContactList />,
      label: (
        <>
          <ContactsOutlined /> Contactos
        </>
      ),
    },
    {
      key: 'pet',
      children: <PetList />,
      label: (
        <>
          <IconPaw size={fontSize} /> Mascotas
        </>
      ),
    },
    {
      key: 'vehicle',
      children: <VehicleList />,
      label: (
        <>
          <CarOutlined /> Vehículos
        </>
      ),
    },
    {
      key: 'join',
      children: <JoinCodeList />,
      label: (
        <>
          <IconKey size={fontSize} /> Código inv.
        </>
      ),
    },
  ].filter(({key}) => (hasRole ? true : key !== 'note' && key !== 'contact'));

  const items: MenuProps['items'] = [
    {
      key: 'newNote',
      label: <Link to={`/unit/${unitId}/note/new`}>Nota</Link>,
      icon: <IconNotebook size={fontSize} />,
    },
    {
      key: 'newContact',
      label: <Link to={`/unit/${unitId}/contact/new`}>Contacto</Link>,
      icon: <ContactsOutlined />,
    },
    {
      key: 'newPet',
      label: <Link to={`/unit/${unitId}/pet/new`}>Mascota</Link>,
      icon: <IconPaw size={fontSize} />,
    },
    {
      key: 'newVehicle',
      label: <Link to={`/unit/${unitId}/vehicle/new`}>Vehículo</Link>,
      icon: <CarOutlined />,
    },
    {
      key: 'newJoin',
      label: <Link to={`/unit/${unitId}/join/new`}>Código invitación</Link>,
      icon: <IconKey size={fontSize} />,
    },
  ].filter(({key}) => (hasRole ? true : key !== 'newNote' && key !== 'newContact'));

  const createButton = (
    <Dropdown key="createButton" menu={{items}} placement="bottomRight">
      <Button type="primary">
        <PlusCircleOutlined /> Crear <DownOutlined />
      </Button>
    </Dropdown>
  );

  const editButton = hasRole ? (
    <Button key="editButton" onClick={() => navigate('/unit/' + unitId + '/edit')}>
      <EditOutlined /> Editar
    </Button>
  ) : undefined;
  return (
    <>
      <PageContent
        header={{
          title: unitName,
          onBack: hasRole ? () => navigate('/unit') : undefined,
          extra: [editButton, createButton],
        }}
      >
        <Descriptions size="small" column={3} style={{marginBottom: 30}}>
          <Descriptions.Item label="Número">{unit.number}</Descriptions.Item>
          <Descriptions.Item label="Edificio">{unit.building ? unit.building.name : '—'}</Descriptions.Item>
          <Descriptions.Item label="Piso">{unit.floor ? unit.floor : '—'}</Descriptions.Item>
          <Descriptions.Item label="Conjunto">{unit.complex ? unit.complex.name : '—'}</Descriptions.Item>
          <Descriptions.Item label="Coeficiente">
            {unit.coefficient ? unit.coefficient?.toFixed(8) : '—'}
          </Descriptions.Item>
        </Descriptions>
        <Tabs defaultActiveKey={defaultTab} onChange={key => setSearchParams({tab: key})} items={itemsTab} />
      </PageContent>
    </>
  );
};

export default View;
