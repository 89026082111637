import { Card, Col, Result, Row } from "antd";
import { Fragment } from "react";

const Error403: React.FC = () => {
  return (
    <Fragment>
      <Row justify="center">
        <Col className="max-content-width">
          <Card style={{ textAlign: "center" }} bordered={false}>
            <Result
              status="403"
              title="No tienes permisos suficientes"
              subTitle="Intenta cambiar de conjunto en la parte superior o comunícate con
              un administrador si crees que es un error."
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Error403;
