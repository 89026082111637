import {primary} from '@/constants/colors';
import {findById, relatedComplexes, selectedComplex} from '@/services/store';
import {DownOutlined} from '@ant-design/icons';
import {useReactiveVar} from '@apollo/client';
import {Scalars} from '@gql/graphql';
import {IconBuildingSkyscraper} from '@tabler/icons-react';
import type {MenuProps} from 'antd';
import {Dropdown} from 'antd';
import React from 'react';

const onClick = (cid: Scalars['ID']) => {
  if (cid == 'ALL') {
    selectedComplex(relatedComplexes());
  } else {
    let complex = findById(cid);
    if (complex) selectedComplex([complex]);
  }
};

interface ComplexSelectorProps {
  isHeader?: boolean;
}
const ComplexSelector: React.FC<ComplexSelectorProps> = ({isHeader}) => {
  const selected = useReactiveVar(selectedComplex);
  const related = useReactiveVar(relatedComplexes);
  const selectable = selected.length == 1 ? selected[0].id : 'ALL';
  let allOption: MenuProps['items'] = [];

  if (related.length > 1) {
    allOption = [
      {
        key: 'ALL',
        label: 'Todos',
        onClick: () => onClick('ALL'),
      },
      {
        type: 'divider',
      },
    ];
  }

  const relatedItems = related.map(complex => {
    return {
      key: complex.id,
      label: complex.name,
      onClick: () => onClick(complex.id),
    };
  });

  let items: MenuProps['items'] = [...allOption, ...relatedItems];

  const headerComponent = (
    <Dropdown
      menu={{items, selectable: true, defaultSelectedKeys: [selectable]}}
      overlayStyle={{paddingLeft: 10}}
      disabled={relatedItems.length <= 1}
    >
      <span className="action account" style={{fontSize: '20px'}}>
        <IconBuildingSkyscraper size="30px" style={{verticalAlign: 'text-top'}} color={primary} />
        <span className="name">
          {selected.length > 1 && 'Todos'}
          {selected.length == 1 && selected[0].name}
          {related.length > 1 && <DownOutlined style={{fontSize: '12px', marginLeft: '3px'}} />}
        </span>
      </span>
    </Dropdown>
  );

  items = [...relatedItems];

  const contentComponent = (
    <Dropdown open menu={{items}} overlayStyle={{paddingLeft: 10}}>
      <span className="action account" style={{fontSize: '20px'}}>
        <IconBuildingSkyscraper style={{fontSize: 72}} size={72} color="#2670CA" />
        <div style={{fontSize: '20px'}}>Selecciona un conjunto</div>
      </span>
    </Dropdown>
  );

  return isHeader ? headerComponent : contentComponent;
};

export default ComplexSelector;
