import {LoadingOutlined} from '@ant-design/icons';
import {Spin, theme} from 'antd';
const {useToken} = theme;

const LoadingFullscreen = () => {
  const {token} = useToken();

  return (
    <div
      style={{
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: token.colorBgContainer,
      }}
    >
      <Spin indicator={<LoadingOutlined style={{fontSize: 50}} />} />
    </div>
  );
};

export default LoadingFullscreen;
