import { PictureOutlined } from "@ant-design/icons";
import { DocumentFile, File } from "@gql/graphql";
import { Image } from "antd";
import { useCallback, useEffect, useState } from "react";

const MediaImage = ({ image }: { image: File }) => {
  const [visible, setVisible] = useState(false);
  const links = image.urls as DocumentFile;

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key == "Escape" && visible) {
        setVisible(false);
      }
    },
    [visible]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [visible]);

  if (!links || !links.thumb || !links.original) return null;

  return (
    <div className="thumb-image">
      <Image
        key={image.id}
        placeholder
        width={60}
        height={60}
        src={links.thumb}
        preview={{
          visible: visible,
          src: links.original,
          mask: <PictureOutlined />,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </div>
  );
};

export default MediaImage;
