import {ageGroup} from '@/constants/account';
import {humanSex} from '@/constants/misc';
import {petType} from '@/constants/pets';
import {vehicleType} from '@/constants/vehicle';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {selectedComplex, selectedComplexFilter} from '@/services/store';
import {calcDisplayPercentage, calcPercentage, getPercentage, roundPorcentages} from '@/utils/porcentages';
import {useQuery, useReactiveVar} from '@apollo/client';
import ErrorRender from '@comp/loaders/ErrorRender';
import PageContent from '@comp/PageContent';
import {InsightsDocument, InsightsQueryVariables} from '@gql/graphql';
import {Card, Col, Row, Skeleton, Statistic, Tooltip, Typography} from 'antd';
const {Text} = Typography;

const Insights = () => {
  useDocumentTitle('Estadísticas');
  useReactiveVar(selectedComplex);

  const queryVariables: InsightsQueryVariables = {
    complexes: selectedComplexFilter(),
  };

  const {loading, error, data, refetch} = useQuery(InsightsDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (loading && !data) return <Skeleton active />;
  if (!data) return <Skeleton active />;

  const unitTotal = data.unitInsights?.total ?? 0;
  const unitWith = unitTotal - (data.unitInsights?.withoutMembers ?? 0);

  const memberTotal = data.userInsights?.total ?? 0;
  const memberAge = (data.userInsights?.age ?? []).slice();
  const memberAge0_17 = memberAge
    .filter(data => data?.age && data?.age <= 17)
    .reduce((acc, value) => acc + (value?.count ?? 0), 0);
  const memberAge18_25 = memberAge
    .filter(data => data?.age && data?.age >= 18 && data?.age <= 25)
    .reduce((acc, value) => acc + (value?.count ?? 0), 0);
  const memberAge26_35 = memberAge
    .filter(data => data?.age && data?.age >= 26 && data?.age <= 35)
    .reduce((acc, value) => acc + (value?.count ?? 0), 0);
  const memberAge36_45 = memberAge
    .filter(data => data?.age && data?.age >= 36 && data?.age <= 45)
    .reduce((acc, value) => acc + (value?.count ?? 0), 0);
  const memberAge46_55 = memberAge
    .filter(data => data?.age && data?.age >= 46 && data?.age <= 55)
    .reduce((acc, value) => acc + (value?.count ?? 0), 0);
  const memberAge56 = memberAge
    .filter(data => data?.age && data?.age >= 56)
    .reduce((acc, value) => acc + (value?.count ?? 0), 0);
  const memberAgeUnknown =
    memberTotal - memberAge0_17 - memberAge18_25 - memberAge26_35 - memberAge36_45 - memberAge46_55 - memberAge56;

  const memberAgeTotalCounts = [
    memberAge0_17,
    memberAge18_25,
    memberAge26_35,
    memberAge36_45,
    memberAge46_55,
    memberAge56,
    memberAgeUnknown,
  ];
  const memberAgeMaxCount = Math.max(...memberAgeTotalCounts);
  const memberAgePer = roundPorcentages(memberAgeTotalCounts.map(x => calcPercentage(x, memberTotal)));
  const memberAgePerDisplay = memberAgeTotalCounts.map(x => calcDisplayPercentage(x, memberAgeMaxCount));

  const memberSex = (data.userInsights?.sex ?? []).slice().sort((d1, d2) => (d2?.count ?? 0) - (d1?.count ?? 0));
  const memberSexTotalCounts = memberSex.map(data => data?.count ?? 0);
  const memberSexMaxCount = Math.max(...memberSexTotalCounts);
  const memberSexPer = roundPorcentages(memberSexTotalCounts.map(x => calcPercentage(x, memberTotal)));
  const memberSexPerDisplay = memberSexTotalCounts.map(x => calcDisplayPercentage(x, memberSexMaxCount));

  const vehicleTotal = data.vehicleInsights?.total ?? 0;
  const vehicleTypes = (data.vehicleInsights?.type ?? []).slice().sort((d1, d2) => (d2?.count ?? 0) - (d1?.count ?? 0));
  const vehicleTotalCounts = vehicleTypes.map(data => data?.count ?? 0);
  const vehicleMaxCount = Math.max(...vehicleTotalCounts);
  const vehiclePer = roundPorcentages(vehicleTotalCounts.map(x => calcPercentage(x, vehicleTotal)));
  const vehiclePerDisplay = vehicleTotalCounts.map(x => calcDisplayPercentage(x, vehicleMaxCount));

  const petTotal = data.petInsights?.total ?? 0;
  const petTypes = (data.petInsights?.type ?? []).slice().sort((d1, d2) => (d2?.count ?? 0) - (d1?.count ?? 0));
  const petTotalCounts = petTypes.map(data => data?.count ?? 0);
  const petMaxCount = Math.max(...petTotalCounts);
  const petPer = roundPorcentages(petTotalCounts.map(x => calcPercentage(x, petTotal)));
  const petPerDisplay = petTotalCounts.map(x => calcDisplayPercentage(x, petMaxCount));
  return (
    <>
      <PageContent header={{title: 'Propiedades'}} noCard>
        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Card bordered={false}>
              <Statistic title="Total" value={unitTotal} />
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false}>
              <Statistic title="Con miembros" value={unitWith} suffix={getPercentage(unitWith, unitTotal)} />
            </Card>
          </Col>
        </Row>
      </PageContent>
      <PageContent header={{title: 'Miembros'}} noCard>
        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Card bordered={false}>
              <Statistic title="Total" value={data.userInsights?.total ?? 0} />
            </Card>
            <Card bordered={false} style={{marginTop: 16}}>
              <Text type="secondary">Sexo</Text>
              {memberSex?.map((data, index) => {
                return (
                  <div className="poll-option">
                    <div className="poll-option-wrapper">
                      <div className="poll-option-text-wrapper">
                        <div className="poll-percentage">
                          <Tooltip placement="left" title={memberSexTotalCounts[index]}>
                            {memberSexPer[index]}%
                          </Tooltip>
                        </div>
                        <div className="poll-option-text">{humanSex[data?.sex!] ?? 'Sin información'}</div>
                      </div>
                    </div>
                    <div className="MuiLinearProgress-root poll-option-progress-root">
                      <div
                        style={{
                          transform: `translateX(-${memberSexPerDisplay[index] + '%'})`,
                        }}
                        className="MuiLinearProgress-bar poll-option-progress-bar"
                      ></div>
                    </div>
                  </div>
                );
              })}
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false}>
              <Text type="secondary">Edad</Text>
              {memberAgeTotalCounts?.map((data, index) => {
                return (
                  <div className="poll-option">
                    <div className="poll-option-wrapper">
                      <div className="poll-option-text-wrapper">
                        <div className="poll-percentage">
                          <Tooltip placement="left" title={memberAgeTotalCounts[index]}>
                            {memberAgePer[index]}%
                          </Tooltip>
                        </div>
                        <div className="poll-option-text">{ageGroup[index]}</div>
                      </div>
                    </div>
                    <div className="MuiLinearProgress-root poll-option-progress-root">
                      <div
                        style={{
                          transform: `translateX(-${memberAgePerDisplay[index] + '%'})`,
                        }}
                        className="MuiLinearProgress-bar poll-option-progress-bar"
                      ></div>
                    </div>
                  </div>
                );
              })}
            </Card>
          </Col>
        </Row>
      </PageContent>
      <PageContent header={{title: 'Vehículos'}} noCard>
        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Card bordered={false}>
              <Statistic title="Total" value={data.vehicleInsights?.total ?? 0} />
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false}>
              <Text type="secondary">Tipo</Text>
              {vehicleTypes?.map((data, index) => {
                return (
                  <div className="poll-option">
                    <div className="poll-option-wrapper">
                      <div className="poll-option-text-wrapper">
                        <div className="poll-percentage">
                          <Tooltip placement="left" title={vehicleTotalCounts[index]}>
                            {vehiclePer[index]}%
                          </Tooltip>
                        </div>
                        <div className="poll-option-text">{vehicleType[data?.type!]}</div>
                      </div>
                    </div>
                    <div className="MuiLinearProgress-root poll-option-progress-root">
                      <div
                        style={{
                          transform: `translateX(-${vehiclePerDisplay[index] + '%'})`,
                        }}
                        className="MuiLinearProgress-bar poll-option-progress-bar"
                      ></div>
                    </div>
                  </div>
                );
              })}
            </Card>
          </Col>
        </Row>
      </PageContent>
      <PageContent header={{title: 'Mascotas'}} noCard>
        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Card bordered={false}>
              <Statistic title="Total" value={data.petInsights?.total ?? 0} />
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false}>
              <Text type="secondary">Tipo</Text>
              {petTypes?.map((data, index) => {
                return (
                  <div className="poll-option">
                    <div className="poll-option-wrapper">
                      <div className="poll-option-text-wrapper">
                        <div className="poll-percentage">
                          <Tooltip placement="left" title={petTotalCounts[index]}>
                            {petPer[index]}%
                          </Tooltip>
                        </div>
                        <div className="poll-option-text">{petType[data?.type!]}</div>
                      </div>
                    </div>
                    <div className="MuiLinearProgress-root poll-option-progress-root">
                      <div
                        style={{
                          transform: `translateX(-${petPerDisplay[index] + '%'})`,
                        }}
                        className="MuiLinearProgress-bar poll-option-progress-bar"
                      ></div>
                    </div>
                  </div>
                );
              })}
            </Card>
          </Col>
        </Row>
      </PageContent>
    </>
  );
};

export default Insights;
