import Error403 from '@/components/loaders/Error403';
import ErrorRender from '@/components/loaders/ErrorRender';
import NotFound from '@/components/loaders/NotFound';
import PageContent from '@/components/PageContent';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {hasRoles, hasSuperRole, selectedComplex, selectedComplexFilter} from '@/services/store';
import {toSnakeCase} from '@/utils/case';
import {titleLoading} from '@/utils/misc';
import {getFullOne} from '@/utils/names';
import {FilterOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined, SearchOutlined} from '@ant-design/icons';
import {makeVar, useQuery, useReactiveVar} from '@apollo/client';
import {Unit, UnitEdge, UnitFilter, UnitsDocument, UnitSort, UnitsQueryVariables, UnitType, User} from '@gql/graphql';
import {Button, Col, Empty, Input, InputRef, Row, Skeleton, Table, Typography} from 'antd';
import {ColumnsType, TableProps} from 'antd/es/table';
import {ColumnType, FilterValue, SorterResult, SortOrder} from 'antd/es/table/interface';
import {useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link, useNavigate} from 'react-router-dom';

const {Text} = Typography;

type DataIndex = keyof Unit;

interface tableFilterType {
  number: FilterValue | null | undefined;
  type: UnitType[] | undefined;
  memberName: FilterValue | null | undefined;
}

const emptyFilter: tableFilterType = {
  number: undefined,
  type: undefined,
  memberName: undefined,
};

interface tableOrderType {
  field?: string;
  direction?: string;
}

const emptyOrder: tableOrderType = {
  field: undefined,
  direction: undefined,
};

const graphFilterStore = makeVar<tableFilterType>(emptyFilter);
const graphOrderStore = makeVar<tableOrderType>(emptyOrder);
const cursorStackStore = makeVar<string[]>([]);
const afterStore = makeVar<string | null>(null);
const title = 'Propiedades';
const UnitList = () => {
  useDocumentTitle(title);
  const navigate = useNavigate();
  const searchInput = useRef<InputRef>(null);
  const [firstLoad, setFirstLoad] = useState<Array<UnitEdge>>();

  useReactiveVar(selectedComplex);
  useReactiveVar(graphFilterStore);
  useReactiveVar(graphOrderStore);
  useReactiveVar(cursorStackStore);
  useReactiveVar(afterStore);

  const graphFilters = graphFilterStore();
  const graphOrder = graphOrderStore();
  const cursorStack = cursorStackStore();
  const after = afterStore();

  const clearAllFilters = () => {
    graphFilterStore(emptyFilter);
    graphOrderStore(emptyOrder);
    resetPagination();
  };

  const hasFilters = graphFilters && !Object.values(graphFilters).every(el => el === undefined);
  const hasOrder = graphOrder && !Object.values(graphOrder).every(el => el === undefined);

  const hasRole = hasRoles(['ADMIN'], selectedComplex()) || hasSuperRole(['ADMIN']);
  const multipleComplex = selectedComplex().length !== 1;

  const clearAllButton =
    hasFilters || hasOrder ? (
      <Button onClick={clearAllFilters} key="clear">
        <FilterOutlined /> Limpiar filtros
      </Button>
    ) : undefined;

  const createButton = hasRole ? (
    <Button onClick={() => navigate('/unit/new')} key="add" type="primary">
      <PlusCircleOutlined /> Nueva
    </Button>
  ) : undefined;

  let headButtons = [clearAllButton, createButton];

  const highlight = (searchTerm: any, toHighlight: any) => {
    if (!searchTerm || searchTerm.length == 0) return searchTerm;
    return (
      <Highlighter
        autoEscape
        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
        searchWords={[searchTerm]}
        textToHighlight={toHighlight ? toHighlight.toString() : ''}
      />
    );
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Unit> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
      return (
        <div style={{padding: 8}}>
          <Input
            ref={searchInput}
            placeholder="Buscar"
            value={selectedKeys as string[]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm({closeDropdown: true})}
            style={{width: 188, marginBottom: 8, display: 'block'}}
          />
          <Button
            type="primary"
            onClick={() => confirm({closeDropdown: true})}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90, marginRight: 8}}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) handleResetSearch(clearFilters);
            }}
            size="small"
            style={{width: 90}}
          >
            Limpiar
          </Button>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record, index) => {
      if (dataIndex == 'number') {
        let term = graphFilters.number ? graphFilters.number : undefined;
        let number = term ? highlight(term, text) : text;
        return <Link to={'/unit/' + record.id}>{number}</Link>;
      }

      if (dataIndex == 'roles') {
        let term = graphFilters.memberName ? graphFilters.memberName : undefined;
        let textName = record.roles?.edges
          ?.map(edge => {
            const user = edge!.node!.user as User;
            return getFullOne(user);
          })
          .join(', ');
        textName = textName == '' ? '—' : textName;
        return term ? highlight(term, textName) : textName;
      }
    },
  });

  const handleResetSearch = (clearFilters: Function) => {
    clearFilters({confirm: true, closeDropdown: true});
  };

  const resetPagination = () => {
    cursorStackStore([]);
    afterStore(undefined);
  };

  const calcSortOrder = (field: string) => {
    let order: SortOrder = null;
    if (graphOrder && graphOrder.field === toSnakeCase(field).toUpperCase()) {
      order = graphOrder.direction == 'ASC' ? 'ascend' : 'descend';
    }
    return order;
  };

  const handleTableChange: TableProps<Unit>['onChange'] = (pagination, filters, sorter) => {
    const newSorter = sorter as SorterResult<Unit>;
    const newFilter = {...emptyFilter};
    for (const key in filters) {
      const value = filters[key];
      if (value !== null && value.length > 0) {
        switch (key) {
          case 'number':
            newFilter.number = Array.isArray(value) ? (value[0] as any) : value;
            break;

          case 'roles':
            newFilter.memberName = Array.isArray(value) ? (value[0] as any) : value;
            break;

          default:
            newFilter[key as keyof typeof newFilter] = value as any;
            break;
        }
      }
    }

    if (graphFilters != newFilter) {
      resetPagination();
    }
    graphFilterStore(newFilter);

    if (newSorter.order) {
      const orderField = toSnakeCase(newSorter.columnKey as string).toUpperCase();
      let orderDirection = newSorter.order as string;
      orderDirection = orderDirection == 'descend' ? 'DESC' : 'ASC';
      graphOrderStore({field: orderField, direction: orderDirection});
    } else {
      graphOrderStore(undefined);
    }
  };

  let columns: ColumnsType<Unit> = [
    {
      title: 'Número',
      dataIndex: 'number',
      key: 'number',
      sorter: true,
      sortOrder: calcSortOrder('number'),
      filteredValue: graphFilters.number || null,
      ...getColumnSearchProps('number'),
    },
    multipleComplex
      ? {
          title: 'Conjunto',
          dataIndex: 'complex',
          key: 'complex',
          filteredValue: null,
          render: (text, record, index) => (text ? text.name : '—'),
        }
      : {},
    {
      title: 'Edificio',
      dataIndex: 'building',
      key: 'buildings',
      filteredValue: null,
      render: (text, record, index) => (text ? text.name : '—'),
    },
    {
      title: 'No. Miembros',
      dataIndex: ['roles', 'total'],
      key: 'rolesCount',
      filteredValue: null,
      sorter: false,
      sortOrder: calcSortOrder('rolesCount'),
    },
    {
      title: 'Miembros',
      dataIndex: 'roles',
      key: 'roles',
      filteredValue: graphFilters.memberName || null,
      ...getColumnSearchProps('roles'),
    },
  ];

  columns = columns.filter(item => item.title);

  const pageSize = 10;
  const complexes = {complexes: selectedComplexFilter()};
  const queryVariables = {
    first: pageSize,
    after: after,
    filter: graphFilters ? {...(graphFilters as UnitFilter), ...complexes} : complexes,
    order: graphOrder?.field ? {...(graphOrder as UnitSort)} : undefined,
  } as UnitsQueryVariables;

  const {loading, error, data, refetch} = useQuery(UnitsDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });
  if (!hasRole) return <Error403 />;
  if (loading && !firstLoad && !data) return <Skeleton paragraph={{rows: 4}} active />;
  if (error && !data) return <ErrorRender error={error} refetch={refetch} />;
  if (data?.units?.edges && data.units.edges != firstLoad) setFirstLoad(data.units.edges as Array<UnitEdge>);
  if (!data?.units?.edges && !firstLoad) return <NotFound />;
  if (!firstLoad) return <NotFound />;

  const edges = firstLoad;
  const units = edges.map((edge: UnitEdge) => edge?.node as Unit);
  const totalCount = data?.units?.total ? data?.units?.total : 0;
  const hasNextPage = data?.units?.pageInfo.hasNextPage;
  const endCursor = data?.units?.pageInfo.endCursor;

  const prevPage = () => {
    let existingStack = cursorStack;
    existingStack.pop();
    cursorStackStore(existingStack);
    let lastEndCursor = existingStack.length > 0 ? existingStack[existingStack.length - 1] : undefined;
    afterStore(lastEndCursor);
  };

  const nextPage = () => {
    let existingStack = cursorStack;
    if (endCursor) existingStack.push(endCursor);
    cursorStackStore(existingStack);
    afterStore(endCursor);
  };

  const paginationButtons = () => (
    <Row justify="end">
      <Col>
        <Button onClick={prevPage} size="small" key="prev" type="link" disabled={cursorStack.length == 0}>
          <LeftOutlined />
          Anterior
        </Button>{' '}
        <Text type="secondary">
          {cursorStack.length + 1}/{Math.ceil(totalCount / pageSize)}
        </Text>{' '}
        <Button onClick={nextPage} size="small" key="next" type="link" disabled={!hasNextPage}>
          Siguiente
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <PageContent fullWidth header={{title: titleLoading(title, loading), extra: [headButtons]}}>
        <Table<Unit>
          footer={() => paginationButtons()}
          pagination={false}
          columns={columns}
          dataSource={units}
          onChange={handleTableChange}
          rowKey="id"
          size="middle"
          scroll={{x: true}}
          locale={{
            emptyText: <Empty description="Sin propiedades"></Empty>,
          }}
        />
      </PageContent>
    </>
  );
};

export default UnitList;
